import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Stack } from "../../../../../ui-kit/layouts/Stack";
import { DText14 } from "../../../../../ui-kit/typography/Typography";
import styled from "styled-components";
import DropdownSellerSearch from "../../dropdownVideoSearch/DropdownSellerSearch";
import DropdownSellerProductsSearch from "../../dropdownVideoSearch/DropdownSellerProductsSearch";
// import TipAgeSelect from "./TipAgeSelect";

const StyledLabel = styled(DText14)`
	color: #94a0b1;
`;

const SponseredTipEdit = ({
	tipSettingsState,
	setTipSettingsState,
	sellerState,
	setSellerState,
}) => {
	useEffect(() => {
		if (!tipSettingsState?.predefined_products?.[0]?.seller) return;
		setSellerState(
			tipSettingsState?.predefined_products.length
				? {
						...tipSettingsState?.predefined_products?.[0]?.seller,
						id: tipSettingsState?.predefined_products?.[0]?.seller_id,
				  }
				: null
		);
	}, [tipSettingsState]);
	return (
		<Stack space="16px">
			<Stack space="5px" style={{ zIndex: 5 }}>
				<StyledLabel className="form-field-label">Рекламодатель</StyledLabel>

				<DropdownSellerSearch
					selected={sellerState?.title}
					onClear={() => (
						setSellerState(null),
						setTipSettingsState({ ...tipSettingsState, predefined_products: [] })
					)}
					onSelect={(newValue) => (
						setSellerState(newValue),
						setTipSettingsState({ ...tipSettingsState, seller_id: newValue.id })
					)}
				/>
			</Stack>
			{sellerState ? (
				<Stack space="5px" style={{ zIndex: 4 }}>
					<StyledLabel className="form-field-label">Список продуктов</StyledLabel>

					<DropdownSellerProductsSearch
						sellerId={sellerState.id}
						selected={tipSettingsState?.predefined_products?.[0]?.title}
						onClear={() => setTipSettingsState({ ...tipSettingsState, predefined_products: [] })}
						onSelect={(newValue) =>
							setTipSettingsState({ ...tipSettingsState, predefined_products: [newValue] })
						}
					/>
				</Stack>
			) : null}
		</Stack>
	);
};
// const mapStateToProps = (state) => {
// 	return {
// 		categoriesList: state.categoriesList,
// 	};
// };

// const mapDispatchToProps = {
// 	getCategories,
// };

export default connect(null, null)(SponseredTipEdit);
