import { gql } from "@apollo/client";

export const GET_VIDEO_PROFIT = gql`
  query getStatisticPage($video_id: Int, $lte: DateTime, $gte: DateTime) {
    getVideosPage(
      pagination: { page: 1, limit: 1 }
      filter: {
        owner_id: 1
        video_id: $video_id
        created_at: { gte: $gte, lte: $lte }
      }
      sort: { field: video, direction: DESC }
    ) {
      items {
        profit
      }
    }
  }
`;

export const GET_VIDEO_MOVIEGOER_AGG = gql`
  query getMoviegoerAggItem($video_id: Int, $lte: DateTime, $gte: DateTime) {
    getMoviegoerAggItem(
      filter: {
        owner_id: 1
        video_id: $video_id
        created_at: { gte: $gte, lte: $lte }
      }
    ) {
      countries {
        percent
        total_count
        name
        cities {
          percent
          total_count
          name
        }
      }
      sex_info {
        percent
        total_count
        name
      }
      age_info {
        percent
        total_count
        name
      }
    }
  }
`;
export const GET_VIDEO_SELLER_AGG = gql`
  query getSellerAggItem($video_id: Int, $lte: DateTime, $gte: DateTime) {
    getSellerAggItem(
      filter: {
        owner_id: 1
        video_id: $video_id
        created_at: { gte: $gte, lte: $lte }
      }
    ) {
      countries {
        percent
        total_count
        name
        cities {
          percent
          total_count
          name
        }
      }
    }
  }
`;
