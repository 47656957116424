import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { customStyles } from "../../../static";
import "./selectEpisodes.scss";

/**
 *
 * type: 1 - Видео
 * type: 2 - Сезон
 * type: 3 - Movie
 */
const SelectEpisodes = ({ seasons, setSideVideoStaticState, mainVideoState }) => {
	const [episodesState, setEpisodesState] = useState({
		list: [],
		currentValue: null,
	});
	useEffect(() => {
		if (!seasons?.length) {
			setSideVideoStaticState({
				id: mainVideoState.id,
				type: 3,
			});
			return;
		}
		const newArray = [
			{
				title: "Все эпизоды",
				id: mainVideoState.id,
				type: 3,
			},
		];
		seasons.map((season, index) => {
			newArray.push({ ...season, title: `Сезон ${index + 1}, ${season.title}`, type: 2 });
			if (season?.episodes.length > 0) {
				season.episodes.map((episod, index) =>
					newArray.push({ ...episod, title: `Эпизод ${index + 1}, ${episod.title}`, type: 2 })
				);
			}
		});
		setEpisodesState({
			...episodesState,
			list: newArray,
			currentValue: {
				title: "Все эпизоды",
				id: mainVideoState.id,
				type: 3,
			},
		});
	}, [mainVideoState]);

	useEffect(() => {
		if (!!episodesState?.currentValue?.id) setSideVideoStaticState(episodesState.currentValue);
	}, [episodesState]);

	return (
		<>
			<div className="select-episodes-wrapper">
				<Select
					name="seller"
					value={episodesState.currentValue}
					// defaultValue={episodesState.list[0]}
					options={episodesState.list}
					styles={customStyles}
					onChange={(newValue) => setEpisodesState({ ...episodesState, currentValue: newValue })}
					getOptionLabel={(option) => option.title}
					getOptionValue={(option) => option.id}
					// placeholder="Все эпизоды"
					className="episodes-search"
					classNamePrefix="select"
				/>
			</div>
			<div className="form-container-right-divider" />
		</>
	);
};

export default connect(null, null)(SelectEpisodes);
