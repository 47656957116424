export const getPeriod = () => {
  const actualDate = new Date();
  const startDate = new Date("2023-01-01");
  const yearAgo = new Date().setFullYear(actualDate.getFullYear() - 1);
  const monthAgo = new Date().setMonth(actualDate.getMonth() - 1);
  const weekAgo = new Date().setDate(actualDate.getDate() - 7);
  const dayAgo = new Date().setDate(actualDate.getDate() - 1);
  return {
    0: {
      startDate: dayAgo,
      endDate: actualDate.getTime(),
      title: "За день",
      name: "day",
      group_by: "hour",
    },
    1: {
      startDate: weekAgo,
      endDate: actualDate.getTime(),
      title: "За неделю",
      name: "week",
      group_by: "day",
    },
    2: {
      startDate: monthAgo,
      endDate: actualDate.getTime(),
      title: "За месяц",
      name: "month",
      group_by: "day",
    },
    3: {
      startDate: yearAgo,
      endDate: actualDate.getTime(),
      title: "За год",
      name: "year",
      group_by: "month",
    },
    4: {
      startDate: startDate,
      endDate: actualDate.getTime(),
      title: "За всё время",
      name: "full",
      group_by: "month",
    },
  };
};
