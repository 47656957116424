export const compareSort = (a, b) => {
	if (a.order > b.order) {
		if (a.order === null) {
			return -1;
		}
		return 1;
	}
	if (a.order < b.order) {
		if (a.order === null) {
			return 1;
		}
		return -1;
	}
	if (a.order === b.order && a.id > b.id) {
		return 1;
	} else {
		return -1;
	}
};
