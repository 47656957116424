import React, { useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { StatisticBlockContainer } from "../../../../ui-kit/wrappers-containers/StatisticBlockContainer";
import { Stack } from "../../../../ui-kit/layouts/Stack";
import { DText13, DText15, H4Text } from "../../../../ui-kit/typography/Typography";
import { Cluster } from "../../../../ui-kit/layouts/Cluster";
import axios from "axios";
import { baseUrl, cinemaUrl } from "../../../../Actions/consts";
import { formatDate } from "../../VideosPage/sctipts";
import { Link } from "react-router-dom";
import { PeriodFilters } from "../UsersStatistic/PeriodFilters/PeriodFilters";
import { Tooltip } from "../../../../ui-kit/tooltip/Tooltip";
import { getBestVideos } from "./getBestVideos";
import { Tabs } from "../../../../ui-kit/tabs/Tabs";

const BestVideos = () => {
	const dispatch = useDispatch();
	const [videosState, setVideosState] = useState([]);
	const [filtersState, setFiltersState] = useState({});

	useEffect(async () => {
		if (!filtersState.from_datetime || !filtersState.to_datetime) return;

		// Лучшие видео и shorts блогеров
		getBestVideos(
			{
				size: 5,
				page: 1,
				date_start: filtersState.from_datetime,
				date_end: filtersState.to_datetime,
			},
			dispatch,
			(e) => setVideosState(e)
		);
	}, [filtersState]);

	return (
		<StatisticBlockContainer className="content-wrapper" marginTop="16px">
			<Stack space="28px" style={{ width: "100%" }}>
				<Cluster justify="space-between" align="center">
					<H4Text>Лучшие видео и shorts блогеров</H4Text>
					<PeriodFilters filtersState={filtersState} setFiltersState={setFiltersState} />
				</Cluster>
				<Stack>
					<div className="list-table-header home-best-videos">
						<div className="list-table-header-element">
							<span>Видео</span>
						</div>
						<div className="list-table-header-element">
							<span>Просмотры</span>
						</div>
						<div className="list-table-header-element">
							<span>Нравится</span>
						</div>
						<div className="list-table-header-element">
							<span>Комментарии</span>
						</div>
					</div>
					<div className="list-table home-best-videos">
						{videosState.length
							? videosState.map((video, index) => (
									<VideoItem video={video} filtersState={filtersState} key={index} />
							  ))
							: null}
					</div>
				</Stack>
			</Stack>
		</StatisticBlockContainer>
	);
};

const VideoItem = ({ video, filtersState }) => {
	const [videoViewState, setVideoViewState] = useState(null);
	const [dashboardVideoState, setDashboardVideoState] = useState(null);
	const dispatch = useDispatch();

	useEffect(async () => {
		if (!filtersState.from_datetime || !filtersState.to_datetime) return;
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.post(`${baseUrl}/domain/other`, {
				method: "GET",
				service: 1,
				endpoint: `/api/video/watch-amount?content_creator_video_id=${
					video.id
				}&date_start=${new Date(filtersState.from_datetime).getTime()}&${new Date(
					filtersState.to_datetime
				).getTime()}&is_first=true`,
				data: "",
			})
			.then((prod) => {
				setVideoViewState(prod.data.amount);
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.get(`${baseUrl}/domain/movies/external/${video.id}`)
			.then((video) => {
				setDashboardVideoState(video.data);
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	}, [video, filtersState]);
	return (
		<Link className="list-table-item" to={`/blogger-video?id=${dashboardVideoState?.id}`}>
			<div className="list-table-item-element">
				<div className="preview-container">
					<img src={video.preview} />
					<div className="blur-bg" style={{ backgroundImage: `url(${video.preview})` }}></div>
				</div>
				<Stack className="stack" space="8px">
					<DText15>{video.title}</DText15>
					<DText13 style={{ color: "#94A0B1" }}>
						Опубликовано {formatDate(video.created_at).split(",")[0]}
					</DText13>
				</Stack>
			</div>
			<div className="list-table-item-element">
				<span>{`${video.views.toLocaleString("ru-RU")} (${
					videoViewState ? videoViewState.toLocaleString("ru-RU") : 0
				})`}</span>
				<Tooltip
					text={`Всего: ${video.views.toLocaleString("ru-RU")} \n Уникальных: ${
						videoViewState ? videoViewState.toLocaleString("ru-RU") : 0
					}`}
				/>
			</div>
			<div className="list-table-item-element">
				<span>{video.like_count.toLocaleString("ru-RU")}</span>
			</div>
			<div className="list-table-item-element">
				<span>{video.comments_amount.toLocaleString("ru-RU")}</span>
			</div>
		</Link>
	);
};

// const mapStateToProps = (state) => {
// return {
// 	bloggerState: state.bloggerState,
// };
// };

// const mapDispatchToProps = {
// };

// const mapDispatchToProps = {
// };

export default connect(null, null)(BestVideos);
