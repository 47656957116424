import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import "./BloggerStatistiс.scss";
import { OwnerContacts } from "./PersonalInfo/OwnerContacts.jsx";
import BestCategories from "./bestCategories/BestCategories.jsx";
import styled from "styled-components";
import MeaningfulCards from "./meaningful-cards/MeaningfulCards";
import BloggerUserStatistic from "./UsersStatistic/BloggerUserStatistic";
import BestVideos from "./best-videos/BestVideos";
import VideoViewBlock from "./VideoViewsBlock/VideoViewBlock";
import { DText14, DText15, H3Text } from "../../../../ui-kit/typography/Typography";
import { Stack } from "../../../../ui-kit/layouts/Stack";
import { PeriodFilters } from "../../HomePage/UsersStatistic/PeriodFilters/PeriodFilters";
import { Cluster } from "../../../../ui-kit/layouts/Cluster";
import { formatedDate } from "../../static";
import { formatDate } from "../../VideosPage/sctipts";
import { getCurrentOwner, putCurrentOwner } from "../../../../Actions/OwnerActions";
import { useUserAccessContext } from "../../context/UserAccessContext";
import { checkUserWritePerm } from "../../scripts/utils";

const HeaderWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	&.small-container {
		display: flex;
		align-items: center;
	}
`;

const StyledTitleWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 24px;
	img {
		min-width: 80px;
		width: 80px;
		height: 80px;
		object-fit: cover;
		border-radius: 80px;
	}
`;

const OwnerStatisticComponent = ({ title, getCurrentOwner, currentOwner, putCurrentOwner }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const [filtersState, setFiltersState] = useState({});
	const [ownerState, setOwnerState] = useState(null);
	useEffect(() => {
		document.title = title;
	}, [title]);
	const { userAccessState } = useUserAccessContext();

	useEffect(() => {
		getCurrentOwner(location.search.split("=")[1]);
	}, []);

	useEffect(() => {
		if (location.search.split("=")[1] == currentOwner?.id) setOwnerState(currentOwner);
	}, [currentOwner]);

	const handleToggleClick = () => {
		putCurrentOwner(ownerState.id, { ...ownerState, is_active: !ownerState.is_active });
	};

	return ownerState ? (
		<section className="personal-area-item edit viewer-statistic">
			<header className="personal-area edit-videos-header" style={{ marginBottom: "0" }}>
				<button className="form-page-back" onClick={() => navigate("/owners")}>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M11.9142 7.50015L11.2071 8.20726L8.41421 11.0002H18H19V13.0002H18H8.41421L11.2071 15.793L11.9142 16.5002L10.5 17.9144L9.79289 17.2073L5.29289 12.7073C4.90237 12.3167 4.90237 11.6836 5.29289 11.293L9.79289 6.79304L10.5 6.08594L11.9142 7.50015Z"
							fill="#94A0B1"
						/>
					</svg>
					Вернуться назад
				</button>
				<div className="title-wrapper">
					<StyledTitleWrapper>
						{ownerState && ownerState.preview && ownerState.preview?.length > 0 ? (
							<img src={`https://atips.io/${ownerState.preview}`} alt="profile" />
						) : (
							<img src="images/profile-frame.png" alt="profile" />
						)}
						<h1 style={{ margin: "0" }}>
							{ownerState?.name},
							<br />
							ID {ownerState?.id}
						</h1>
					</StyledTitleWrapper>
					<Cluster align="center" space="16px">
						<DText15 style={{ color: "#94A0B1" }}>
							{`${
								filtersState?.period_name === "full"
									? "..."
									: formatedDate(filtersState?.from_datetime)
							} — ${formatedDate(filtersState?.to_datetime)}`}
						</DText15>
						<PeriodFilters filtersState={filtersState} setFiltersState={setFiltersState} />
					</Cluster>
				</div>
			</header>

			<HeaderWrapper className="statistic-type-title-seller">
				<Cluster space="16px" align="center">
					<DText14 style={{ color: "#94A0B1" }}>
						Дата регистрации {formatDate(ownerState?.created_at)?.split(",")?.[0]}
					</DText14>
				</Cluster>

				<div
					id="product-tumbler"
					className={`${!ownerState?.is_active && "disabled"} ${
						!checkUserWritePerm(userAccessState, "owners") ? "readOnly" : ""
					}`}
					onClick={() => checkUserWritePerm(userAccessState, "owners") && handleToggleClick()}
				>
					<div id="product-tumbler-text">
						<span>Активно</span>
						<span>Приостановлено</span>
					</div>
				</div>
			</HeaderWrapper>

			<MeaningfulCards ownerState={ownerState} filtersState={filtersState} />
			<div className="flex-wrapper">
				<OwnerContacts ownerState={ownerState} />
			</div>
			<Stack style={{ marginTop: "16px" }} space="35px">
				<H3Text>Видео правообладателя</H3Text>
				<VideoViewBlock ownerState={ownerState} filtersState={filtersState} />
			</Stack>
			<BestCategories ownerState={ownerState} filtersState={filtersState} />
			<div className="flex-wrapper">
				<BestVideos ownerState={ownerState} />
			</div>
			<div className="flex-wrapper">
				<BloggerUserStatistic ownerState={ownerState} />
			</div>
		</section>
	) : null;
};

const mapStateToProps = (state) => {
	return {
		currentOwner: state.currentOwner,
	};
};

const mapDispatchToProps = {
	getCurrentOwner,
	putCurrentOwner,
};

export default connect(mapStateToProps, mapDispatchToProps)(OwnerStatisticComponent);
