import React, { useContext, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { DText15, H3Text, H4Text, H5Text } from "../../../../../ui-kit/typography/Typography";
import { Stack } from "../../../../../ui-kit/layouts/Stack";
import styled from "styled-components";
import { MeanCardContainer } from "../../../../../common/mean-cards/MeanCard";
import { useLazyQuery } from "@apollo/client";
import { Cluster } from "../../../../../ui-kit/layouts/Cluster";
import {
	getBloggerPercent,
	getTotalAverageTime,
	getTotalTips,
	getTotalVideos,
	getTotalViewAmount,
} from "./get-totals.js";
import ModalBloggerPercentEdit from "./ModalBloggerPercentEdit";
import { ModalContext } from "../../../../modal/ModalContext/ModalContext";

import { GET_MAIN_OWNER_PROFIT, GET_TIPS_OWNER_SHOW } from "../../../../../GraphQL/OwnerStatistic";
import ModalOwnerPercentHistory from "./ModalOwnerPercentHistory";

const StyledPercentIconButton = styled.div`
	cursor: pointer;
	&:hover {
		svg {
			path {
				fill: #94a0b1;
			}
		}
	}
`;

const Containter = styled.div`
	display: flex;
	gap: 16px;
	width: 100%;
	justify-content: space-between;
`;

const MeaningfulCards = ({ ownerState, filtersState }) => {
	const dispatch = useDispatch();
	const { handleModal } = useContext(ModalContext);

	const [videosCount, setVideosCount] = useState(0);
	const [averageViewState, setAverageViewState] = useState(0);
	const [totalTips, setTotalTips] = useState(0);
	const [totalViewAmount, setTotalViewAmount] = useState(0);
	const [uniqViewAmount, setUniqViewAmount] = useState(0);
	const [percentState, setPercentState] = useState(30);

	const [getStatistic, { data: revenue }] = useLazyQuery(GET_MAIN_OWNER_PROFIT, {
		variables: {
			owner_id: null,
		},
	});
	const [getTipsShow, { data: tipsShow }] = useLazyQuery(GET_TIPS_OWNER_SHOW, {
		variables: {
			owner_id: null,
		},
	});

	useEffect(() => {
		if (
			!ownerState ||
			!filtersState.from_datetime ||
			!filtersState.to_datetime ||
			!filtersState.period_name
		)
			return;
		getStatistic({
			variables: {
				gte: new Date(filtersState.from_datetime).toISOString(),
				lte: new Date(filtersState.to_datetime).toISOString(),
				owner_id: ownerState.id,
			},
		});
		getTipsShow({
			variables: {
				gte: new Date(filtersState.from_datetime).toISOString(),
				lte: new Date(filtersState.to_datetime).toISOString(),
				owner_id: ownerState.id,
			},
		});
		getTotalVideos(
			{
				owner_id: ownerState.id,
			},
			dispatch,
			(e) => setVideosCount(e)
		);
		getBloggerPercent({ owner_id: ownerState.id, limit: 20, offset: 0 }, dispatch, (e) =>
			setPercentState(e)
		);
		getTotalViewAmount(
			{
				owner_id: ownerState.id,
				date_start: new Date(filtersState.from_datetime).toISOString(),
				date_end: new Date(filtersState.to_datetime).toISOString(),
			},
			dispatch,
			(e) => setTotalViewAmount(e)
		);
		getTotalViewAmount(
			{
				owner_id: ownerState.id,
				date_start: new Date(filtersState.from_datetime).toISOString(),
				date_end: new Date(filtersState.to_datetime).toISOString(),
				is_first: true,
			},
			dispatch,
			(e) => setUniqViewAmount(e)
		);
		getTotalAverageTime(
			{
				owner_id: ownerState.id,
				date_start: new Date(filtersState.from_datetime).toISOString(),
				date_end: new Date(filtersState.to_datetime).toISOString(),
			},
			dispatch,
			(e) => setAverageViewState(e)
		);
		getTotalTips(
			{
				from_datetime: new Date(filtersState.from_datetime).toISOString(),
				to_datetime: new Date(filtersState.to_datetime).toISOString(),
				owner_: ownerState.id,
				is_atips: ownerState.id === 1,
			},
			dispatch,
			(e) => setTotalTips(e)
		);
	}, [ownerState, filtersState]);

	return (
		<Stack space="16px" style={{ marginTop: "32px" }}>
			<Containter>
				<MeanCardContainer className="purple">
					<Stack stretch={true} space="auto">
						<H3Text>
							{Number(revenue?.getStatisticPage?.aggregation?.total_sum || 0).toLocaleString(
								"ru-RU"
							)}
							₽
						</H3Text>
						<Cluster space="6px" align="center">
							<DText15 style={{ whiteSpace: "nowrap" }}>Общий доход</DText15>
							<Cluster align="center" space="12px">
								<H5Text>{percentState?.percent || 0}%</H5Text>
								<StyledPercentIconButton
									className="list-table-item-edit action-button"
									onClick={() =>
										handleModal(
											<ModalBloggerPercentEdit
												actualPrice={percentState?.percent || 0}
												owner_id={ownerState.id}
												onUpdatePrice={(e) => setPercentState({ ...percentState, percent: e })}
											/>
										)
									}
								>
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M16.6203 2.24939C16.4328 2.06184 16.1784 1.95647 15.9132 1.95646C15.648 1.95645 15.3936 2.06181 15.2061 2.24935L5.58581 11.8695C5.21073 12.2446 5.00001 12.7533 5.00001 13.2837L5.00001 16.9999C5.00001 17.2651 5.10537 17.5195 5.2929 17.707C5.48044 17.8946 5.73479 17.9999 6.00001 17.9999H9.71622C10.2467 17.9999 10.7554 17.7892 11.1305 17.4141L20.7503 7.79399C21.1408 7.40348 21.1409 6.77035 20.7504 6.37982L16.6203 2.24939ZM7.00001 13.2837L15.9131 4.3707L18.6291 7.08687L9.71622 15.9999H7.00001L7.00001 13.2837ZM3 20.0002H2V22.0002H3H21H22V20.0002H21H3Z"
											fill="#ffffff"
										/>
									</svg>
								</StyledPercentIconButton>
								<StyledPercentIconButton
									className="list-table-item-history action-button"
									action="modal"
									target="price-history-modal"
									onClick={() =>
										handleModal(<ModalOwnerPercentHistory owner_id={ownerState.id} />)
									}
								>
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12ZM12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM13 8V7H11V8V12C11 12.3344 11.1671 12.6466 11.4453 12.8321L14.4453 14.8321L15.2774 15.3868L16.3868 13.7227L15.5547 13.1679L13 11.4648V8Z"
											fill="#ffffff"
										/>
									</svg>
								</StyledPercentIconButton>
							</Cluster>
						</Cluster>
					</Stack>
				</MeanCardContainer>

				<MeanCardContainer className="white">
					<Stack space="16px">
						<Stack stretch={true}>
							<H4Text>
								{Number(tipsShow?.getStatisticPage?.pageInfo?.itemsCount || 0).toLocaleString(
									"ru-RU"
								)}
							</H4Text>
							<DText15>Просмотров типсов</DText15>
						</Stack>
						<Stack stretch={true}>
							<H4Text>
								{Number(revenue?.getStatisticPage?.pageInfo?.itemsCount || 0).toLocaleString(
									"ru-RU"
								)}
							</H4Text>
							<DText15>Кликов типсов</DText15>
						</Stack>
					</Stack>
				</MeanCardContainer>
				<MeanCardContainer className="white">
					<Stack space="16px">
						<Stack stretch={true}>
							<H4Text>{Number(totalTips).toLocaleString("ru-RU")}</H4Text>
							<DText15>Всего типсов</DText15>
						</Stack>
						<Stack stretch={true}>
							<H4Text>{videosCount.toLocaleString("ru-RU")}</H4Text>
							<DText15>Всего видео</DText15>
						</Stack>
					</Stack>
				</MeanCardContainer>
			</Containter>
			<Containter>
				<MeanCardContainer className="white">
					<Stack space="16px">
						<Stack stretch={true}>
							<H4Text>{Number(totalViewAmount).toLocaleString("ru-RU")}</H4Text>
							<DText15>Всего просмотров</DText15>
						</Stack>
						<Stack stretch={true}>
							<H4Text>{Number(uniqViewAmount).toLocaleString("ru-RU")}</H4Text>
							<DText15>Уникальных</DText15>
						</Stack>
					</Stack>
				</MeanCardContainer>
				<MeanCardContainer className="white">
					<Stack stretch={true} space="auto">
						<H3Text>
							{Number(
								revenue?.getStatisticPage?.pageInfo?.itemsCount
									? revenue?.getStatisticPage?.aggregation?.total_sum /
											revenue?.getStatisticPage?.pageInfo?.itemsCount || 0
									: 0
							)
								.toFixed(2)
								.toLocaleString("ru-RU")}
							₽
						</H3Text>
						<DText15>Средний чек</DText15>
					</Stack>
				</MeanCardContainer>
				<MeanCardContainer className="white">
					<Stack stretch={true} space="auto">
						<H3Text>
							{averageViewState > 60
								? `${(averageViewState / 60).toFixed(2)} мин.`
								: `${Math.round(averageViewState)} сек.`}
						</H3Text>
						<DText15>Средняя продолжительность просмотра</DText15>
					</Stack>
				</MeanCardContainer>
			</Containter>
		</Stack>
	);
};

const mapStateToProps = (state) => {
	return {
		// ownerState: state.ownerState,
		advertismentTotal: state.advertismentTotalReducer,
	};
};

const mapDispatchToProps = {
	// getViewers,
};

export default connect(mapStateToProps, mapDispatchToProps)(MeaningfulCards);
