import Multiselect from "multiselect-react-dropdown";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { connect } from "react-redux";
import { postSeason, putSeason } from "../../../../Actions/SerialsActions";
import { ModalContext } from "../../../modal/ModalContext/ModalContext.tsx";

const AddSeasonModal = ({
	filmState,
	setFilmState,
	index,
	postSeason,
	loader,
	elem = null,
	videoId,
	putSeason,
}) => {
	const { handleModal, modal } = useContext(ModalContext);
	const [newSeasonState, setNewSeasonState] = useState({
		serial_id: !!elem ? elem?.serial_id : videoId,
		title: elem !== null ? elem.title : "",
		order: !!elem ? elem.order : filmState.seasons.length + 1,
	});
	const handleInputChange = (evt) => {
		// обработчик заполнение инпутов
		const val = evt.target.value; // присваиваем значение инпута
		setNewSeasonState({ ...newSeasonState, title: val }); // изменяем состояние
	};

	const addSeason = () => {
		if (elem !== null) {
			putSeason(newSeasonState, elem.id);
			// setFilmState({ ...filmState, setFilmState });
		} else {
			postSeason(newSeasonState);
		}
	};

	return (
		<>
			<div className="add-category-wrapper">
				<div className="modal-field-title">
					{elem === null ? "Добавить" : "Переименовать"} сезон
				</div>
				<div className="modal-field-close" onClick={() => handleModal()}>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M4.70701 3.29304L3.99991 2.58594L2.58569 4.00015L3.2928 4.70726L10.5857 12.0002L3.2928 19.293L2.58569 20.0002L3.99991 21.4144L4.70701 20.7073L11.9999 13.4144L19.2928 20.7073L19.9999 21.4144L21.4141 20.0002L20.707 19.293L13.4141 12.0002L20.707 4.70726L21.4141 4.00015L19.9999 2.58594L19.2928 3.29304L11.9999 10.5859L4.70701 3.29304Z"
							fill="#212121"
						></path>
					</svg>
				</div>
				<div className="flex-block" style={{ flexDirection: "column" }}>
					<div className="form-field">
						<div className="form-field-label">Название сезона</div>
						<div className="input-field">
							<input
								value={newSeasonState.title}
								placeholder="Введите название сезона"
								onChange={(e) => handleInputChange(e)}
							/>
						</div>
					</div>
					{/* <div className="form-field">
						<div className="form-field-label">Ссылка</div>
						<div className="input-field">
							<input
								value={newSeasonState.slug}
								placeholder="Введите ссылку"
								onChange={(e) => setNewSeasonState({ ...newSeasonState, slug: e.target.value })}
							/>
						</div>
					</div> */}
				</div>
				<div className="modal-buttons">
					<div className="button action-button border-button" onClick={() => handleModal()}>
						<span>Отменить</span>
					</div>
					<div
						className={`button action-button  ${loader.loading !== 0 ? "preloader" : ""}`}
						onClick={() => addSeason()}
					>
						<span>{elem === null ? "Создать" : "Изменить"} сезон</span>
					</div>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		loader: state.loadingStatus,
	};
};

const mapDispatchToProps = {
	postSeason,
	putSeason,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSeasonModal);
