export const customStyles = {
	// кастомные стили для react-select
	option: (provided, { data, isDisabled, isFocused, isSelected }) => ({
		...provided,
		backgroundColor: "#ffffff",
		color: "#212121",
		paddingLeft:
			data.orderIndex === 1
				? "20px !important"
				: data.orderIndex === 2
				? "30px !important"
				: "40px !important",
		opacity: data.orderIndex === 1 || data.orderIndex === 2 ? 0.5 : 1,
	}),
	group: (provided, state) => ({
		...provided,
		backgroundColor: "#ffffff",
		color: "#212121",
	}),
	control: (provided, state) => ({
		...provided,
		backgroundColor: state.isDisabled ? "#d1d8e1 !important" : "#d1d8e1",
		border: "1px solid #f4f4f8",
		borderRadius: "8px",
		color: "#212121",
		fontFamily: "Averta CY",
		fontSize: "15px",
		height: "auto",
		minHeight: "54px",
		outline: "none",
		padding: "11px 80px 8px 16px",
		position: "relative",
		width: "100%",
		display: "flex",
		cursor: "pointer",
	}),
	singleValue: (provided, state) => {
		const color = state.isDisabled ? "#94a0b1" : "#212121";
		const opacity = state.isDisabled ? 0.5 : 1;
		return { ...provided, opacity, color };
	},
};

export const formatedDate = (date) => {
	const dateInst = new Date(date);
	return `${dateInst.getDate()} ${SHORT_MONTH[dateInst.getMonth()]} ${dateInst.getFullYear()} г.`;
};

export const SHORT_MONTH = {
	0: "янв.",
	1: "фев.",
	2: "март",
	3: "апр.",
	4: "май",
	5: "июнь",
	6: "июль",
	7: "авг.",
	8: "сент.",
	9: "окт.",
	10: "нояб.",
	11: "дек.",
};
