import React, { useContext, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Badge } from "../../../../../ui-kit/badge/Badge.jsx";
import statisticGraphic from "./card-statistic.svg";
import { IndicatorArrow } from "../../../../../ui-kit/indicator-arrow/IndicatorArrow.jsx";
import { useLazyQuery } from "@apollo/client";
import { GET_VIDEO_TIPS } from "../../../../../GraphQL/Statistic.js";
import {
	getTotalDashboardVideoAverageTime,
	getTotalDashboardVideoFavourite,
	getTotalDashboardVideoViewAmount,
	getTotalDashboardVideoViewAmountUninq,
	getTotalDashboardVideoViewTime,
} from "./dasboard-video-total-fetches.js";

const MeaningfulVideoStatisticCards = ({ filtersState, mainVideoState, additionalVideoState }) => {
	const dispatch = useDispatch();

	const [averageViewState, setAverageViewState] = useState(0);
	const [totalViewState, setTotalViewState] = useState(0);
	const [totalFavouriteState, setTotalFavouriteState] = useState(0);
	const [totalAmountState, setTotalAmountState] = useState(0); // уникальные
	const [totalViewAmountState, setTotalViewAmountState] = useState(0); // неуникальные
	const [getShowStatistic, { data: showData }] = useLazyQuery(GET_VIDEO_TIPS, {
		variables: {
			click: 0,
			video_id: null,
			lte: new Date().toISOString(),
			gte: "2022-03-01T03:10:34.000Z",
		},
	});
	const [getClickStatistic, { data: clickData }] = useLazyQuery(GET_VIDEO_TIPS, {
		variables: {
			click: 1,
			video_id: null,
			lte: new Date().toISOString(),
			gte: "2022-03-01T03:10:34.000Z",
		},
	});

	useEffect(() => {
		if (
			!mainVideoState ||
			!filtersState.from_datetime ||
			!filtersState.to_datetime ||
			!filtersState.period_name
		)
			return;
		getShowStatistic({
			variables: {
				click: 0,
				video_id: mainVideoState?.video?.[0].id,
				lte: new Date(filtersState.to_datetime).toISOString(),
				gte: new Date(filtersState.from_datetime).toISOString(),
			},
		});
		getClickStatistic({
			variables: {
				click: 1,
				video_id: mainVideoState?.video?.[0].id,
				lte: new Date(filtersState.to_datetime).toISOString(),
				gte: new Date(filtersState.from_datetime).toISOString(),
			},
		});
		getTotalDashboardVideoViewAmount(
			{
				video_id: mainVideoState?.video?.[0].id,
				date_start: new Date(filtersState.from_datetime).toISOString(),
				date_end: new Date(filtersState.to_datetime).toISOString(),
				is_first: true,
			},
			dispatch,
			(e) => setTotalAmountState(e)
		);
		getTotalDashboardVideoViewAmountUninq(
			{
				video_id: mainVideoState?.video?.[0].id,
				date_start: new Date(filtersState.from_datetime).toISOString(),
				date_end: new Date(filtersState.to_datetime).toISOString(),
			},
			dispatch,
			(e) => setTotalViewAmountState(e)
		);
		getTotalDashboardVideoAverageTime(
			{
				video_id: mainVideoState?.video?.[0].id,
				date_start: new Date(filtersState.from_datetime).toISOString(),
				date_end: new Date(filtersState.to_datetime).toISOString(),
			},
			dispatch,
			(e) => setAverageViewState(e)
		);
		getTotalDashboardVideoViewTime(
			{
				video_id: mainVideoState?.video?.[0].id,
				date_start: new Date(filtersState.from_datetime).toISOString(),
				date_end: new Date(filtersState.to_datetime).toISOString(),
			},
			dispatch,
			(e) => setTotalViewState(e)
		);
		getTotalDashboardVideoFavourite(
			{
				page: 1,
				size: 1,
				group_by:
					filtersState.period_name === "day"
						? "day"
						: filtersState.period_name === "week"
						? "day"
						: "month",
				video_id: mainVideoState?.id,
				date_start: new Date(filtersState.from_datetime).toISOString(),
				date_end: new Date(filtersState.to_datetime).toISOString(),
			},
			dispatch,
			(e) => setTotalFavouriteState(e)
		);
	}, [mainVideoState, filtersState]);

	return (
		<div className="meaningful-statistic-cards-wrapper">
			<div className="card-wrapper purple">
				<div className="card-main-info ">
					<div className="card-main-info__value">
						<b>
							{Number(clickData?.getStatisticPage?.aggregation.income_profit).toLocaleString(
								"ru-RU"
							) || 0}{" "}
							₽
						</b>
					</div>
				</div>
				<div className="card-description">Общий доход с видео</div>
			</div>
			<div className="card-wrapper">
				<div className="card-main-info">
					<div className="card-main-info__value">
						<b>
							{totalViewState > 60
								? `${(totalViewState / 60).toFixed(2)} мин.`
								: `${Math.round(totalViewState)} сек.`}
						</b>
					</div>
				</div>
				<div className="card-description">Общее время просмотра видео</div>
				<img className="card-graph" src={statisticGraphic} alt="graph" />
			</div>
			<div className="card-wrapper">
				<div className="card-main-info">
					<div className="card-main-info__value">
						<b>
							{averageViewState > 60
								? `${(averageViewState / 60).toFixed(2)} мин.`
								: `${Math.round(averageViewState)} сек.`}
						</b>
					</div>
				</div>
				<div className="card-description">Средний просмотр видео</div>
			</div>
			<div className="card-wrapper double">
				<div className="card-main-info">
					<div className="card-main-info__value">
						<b>{Number(totalAmountState || 0).toLocaleString("ru-RU")}</b>
					</div>
				</div>
				<div className="card-description">Уникальных просмотров видео</div>
				<div className="card-main-info">
					<div className="card-main-info__value">
						<b>{Number(totalViewAmountState || 0).toLocaleString("ru-RU")}</b>
					</div>
				</div>
				<div className="card-description">Всего просмотров видео</div>
			</div>
			<div className="card-wrapper double">
				<div className="card-main-info">
					<div className="card-main-info__value">
						<b>
							{showData?.getStatisticPage?.pageInfo?.itemsCount
								? showData?.getStatisticPage?.pageInfo?.itemsCount.toLocaleString("ru-RU")
								: 0}
						</b>
					</div>
				</div>
				<div className="card-description">Просмотров типсов</div>
				<div className="card-main-info">
					<div className="card-main-info__value">
						<b>
							{clickData?.getStatisticPage?.pageInfo?.itemsCount
								? clickData?.getStatisticPage?.pageInfo?.itemsCount.toLocaleString("ru-RU")
								: 0}
						</b>
					</div>
				</div>
				<div className="card-description">Кликов типсов</div>
			</div>
			<div className="card-wrapper">
				<div className="card-main-info">
					<div className="card-main-info__value">
						<b>{Number(totalFavouriteState || 0).toLocaleString("ru-RU")}</b>
					</div>
				</div>
				<div className="card-description">Добавлено в избранное</div>
			</div>
		</div>
	);
};

export default connect(null, null)(MeaningfulVideoStatisticCards);
