import React, { useContext, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { StatisticBlockContainer } from "../../../../ui-kit/wrappers-containers/StatisticBlockContainer.jsx";
import ShowMoreButton from "../../../universal/ShowMore/ShowMoreButton.jsx";
import { getViewerWatchlistEntries, getWatchTime } from "./get-viewer-totals.js";
import { EmptyFallback } from "../../BloggerPage/pages/Buttons.jsx";
import { Cluster } from "../../../../ui-kit/layouts/Cluster.jsx";
import { GET_VIEWER_VIDEO_TIPS } from "../../../../GraphQL/ViewerPage.js";
import { useLazyQuery, useQuery } from "@apollo/client";
import axios from "axios";
import { baseUrl } from "../../../../Actions/consts.js";
import { formatTimeCode } from "../../VideosPage/sctipts.js";

const ViewedVideosStatisticComponent = ({ viewerState }) => {
	const dispatch = useDispatch();
	const [entriesState, setEntriesState] = useState({
		items: [],
		total: 0,
		page: 1,
		size: 10,
	});

	useEffect(() => {
		if (!viewerState) return;

		getViewerWatchlistEntries(
			{
				page: 1,
				size: 10,
				user_id: viewerState.external_id,
			},
			dispatch,
			(e) => setEntriesState(e)
		);
	}, [viewerState]);

	const handleLoadMoreClick = () => {
		getViewerWatchlistEntries(
			{
				page: entriesState.page + 1,
				size: entriesState.size,
				user_id: viewerState.external_id,
			},
			dispatch,
			(e) => setEntriesState({ ...e, items: [...entriesState.items, ...e.items] })
		);
	};
	return (
		<StatisticBlockContainer className="content-wrapper">
			<div className="gadgets-statistic-header">
				<div className="gadgets-statistic-header__title">Просмотренные видео</div>
			</div>
			{entriesState.items?.length > 0 ? (
				<div className="table-wrapper">
					<div className="list-table-header">
						<div
							className="list-table-header-element non-sort"
							style={{ width: "32.5%", paddingLeft: "0" }}
						>
							<span>Название</span>
						</div>
						<div className="list-table-header-element non-sort" style={{ width: "11.2%" }}>
							<span>Формат</span>
						</div>
						<div className="list-table-header-element non-sort" style={{ width: "20%" }}>
							<span>Жанр</span>
						</div>
						<div className="list-table-header-element non-sort" style={{ width: "16.9%" }}>
							<span>Время просмотра</span>
						</div>
						<div className="list-table-header-element non-sort" style={{ width: "14.9%" }}>
							<span>Показы типсов</span>
						</div>
						<div className="list-table-header-element non-sort" style={{ width: "11.7%" }}>
							<span>Клики типсов</span>
						</div>
					</div>

					<div className="list-table video-list">
						{entriesState.items.map((elem) => (
							<VideoItem elem={elem} user_id={viewerState.external_id} viewer_id={viewerState.id} />
						))}
					</div>
				</div>
			) : (
				<Cluster justify="center" style={{ width: "100%" }}>
					<EmptyFallback>Ничего не найдено</EmptyFallback>
				</Cluster>
			)}
			{entriesState.total > entriesState.size * entriesState.page ? (
				<ShowMoreButton onClick={() => handleLoadMoreClick()} />
			) : null}
		</StatisticBlockContainer>
	);
};

const VideoItem = ({ elem, user_id, viewer_id }) => {
	const dispatch = useDispatch();
	const [watchTimeState, setWatchTimeState] = useState(0);
	const [getTipsStatistic, { data: tips, loading: tipsShowLoading }] = useLazyQuery(
		GET_VIEWER_VIDEO_TIPS,
		{
			variables: {},
		}
	);
	const [dashboardMovieState, setDashboardMovieState] = useState(null);

	useEffect(async () => {
		if (elem.video) {
			getWatchTime(
				{
					date_start: "2023-01-01T00:00:00.000Z",
					date_end: new Date().toISOString(),
					viewer_id: user_id,
					video_id: elem.video.id,
				},
				dispatch,
				(e) => setWatchTimeState(e)
			);
			getTipsStatistic({
				variables: {
					moviegoer_id: viewer_id,
					video_id: elem.video.id,
				},
			});
		} else {
			getWatchTime(
				{
					date_start: "2023-01-01T00:00:00.000Z",
					date_end: new Date().toISOString(),
					viewer_id: user_id,
					content_creator_video_id: elem?.content_creator_video_id,
				},
				dispatch,
				(e) => setWatchTimeState(e)
			);
			await axios
				.get(`${baseUrl}/domain/movies/external/${`${elem?.content_creator_video_id}`}`)
				.then((video) => {
					setDashboardMovieState(video.data);
					getTipsStatistic({
						variables: {
							moviegoer_id: viewer_id,
							video_id: video.data?.video?.[0].id,
						},
					});
				})
				.finally(() => {})
				.catch((error) => {
					console.log(error);
				});
		}
	}, [elem]);
	return tips ? (
		<div className="list-table-item">
			{elem?.video ? (
				<>
					<div className="list-table-item-element" style={{ width: "32.5%", paddingLeft: "0" }}>
						<span>{elem.video.title}</span>
					</div>
					<div className="list-table-item-element" style={{ width: "11.2%" }}>
						<span>{elem.video?.movie_format_id === 1 ? "Фильм" : "Сериал"}</span>
					</div>
					<div className="list-table-item-element" style={{ width: "20%" }}>
						<span>
							{elem.video.genres?.length &&
								elem.video.genres.map((genre, index) =>
									index === elem.video.genres.length - 1 ? genre.title : `${genre.title}, `
								)}
						</span>
					</div>
				</>
			) : (
				<>
					<div className="list-table-item-element" style={{ width: "32.5%", paddingLeft: "0" }}>
						<span>{elem?.content_creator_video?.title}</span>
					</div>
					<div className="list-table-item-element" style={{ width: "11.2%" }}>
						<span>{dashboardMovieState?.movie_format?.title}</span>
					</div>
					<div className="list-table-item-element" style={{ width: "20%" }}>
						<span>
							{dashboardMovieState?.genres?.length &&
								dashboardMovieState?.genres.map((genre, index) =>
									index === dashboardMovieState.genres.length - 1 ? genre.title : `${genre.title}, `
								)}
						</span>
					</div>
				</>
			)}
			<div className="list-table-item-element" style={{ width: "16.9%" }}>
				<span>{formatTimeCode(watchTimeState)}</span>
			</div>
			<div className="list-table-item-element" style={{ width: "14.9%" }}>
				<span>{tips?.show.pageInfo?.itemsCount}</span>
			</div>
			<div className="list-table-item-element" style={{ width: "11.7%" }}>
				<span>{tips?.click.pageInfo?.itemsCount}</span>
			</div>
		</div>
	) : null;
};
export default connect(null, null)(ViewedVideosStatisticComponent);
