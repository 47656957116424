import Multiselect from "multiselect-react-dropdown";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { customStyles } from "../../../static";

const initialGenderStatusState = [
	{
		id: 375,
		title: "Мужской",
	},
	{
		id: 376,
		title: "Женский",
	},
];

const TipGenderSelect = ({ tipSettingsState, setTipSettingsState }) => {
	const handleSelectClick = (elem) => {
		const attrs = [...tipSettingsState.attributes_settable];
		if (attrs.length > 0) {
			const maleIdx = attrs.findIndex((item) => item.id === 375);
			const femaleIdx = attrs.findIndex((item) => item.id === 376);
			if (maleIdx > -1) {
				attrs.splice(maleIdx, 1);
			}
			if (femaleIdx > -1) {
				attrs.splice(femaleIdx, 1);
			}
			attrs.push(elem);
			setTipSettingsState({ ...tipSettingsState, attributes_settable: attrs });
		} else {
			attrs.push(elem.id);
			setTipSettingsState({ ...tipSettingsState, attributes_settable: attrs });
		}
	};

	const handleDeleteClick = () => {
		const attrs = [...tipSettingsState.attributes_settable];
		if (attrs.length > 0) {
			const maleIdx = attrs.findIndex((item) => item.id === 375);
			const femaleIdx = attrs.findIndex((item) => item.id === 376);
			if (maleIdx > -1) {
				attrs.splice(maleIdx, 1);
			}
			if (femaleIdx > -1) {
				attrs.splice(femaleIdx, 1);
			}
			setTipSettingsState({ ...tipSettingsState, attributes_settable: attrs });
		}
	};
	return (
		<>
			<div className="input-field" style={{ position: "relative" }}>
				<Select
					name="tip-gender"
					value={
						tipSettingsState.attributes_settable.find((elem) => elem.id === 375) ||
						tipSettingsState.attributes_settable.find((elem) => elem.id === 376) || ""
					}
					options={initialGenderStatusState}
					styles={customStyles}
					onChange={(newValue) => handleSelectClick(newValue)}
					getOptionLabel={(option) => option.title}
					getOptionValue={(option) => option.id}
					placeholder="Все"
					isSearchable={true}
					className="search-wrapper side-filters_city"
					classNamePrefix="select"
					noOptionsMessage={({ inputValue }) => (!inputValue ? "Загрузка" : "Нет значений")}
				/>
				<button
					className={`clear-chosen-container ${
						!!tipSettingsState.attributes_settable.find((elem) => elem.id === 375) ||
						!!tipSettingsState.attributes_settable.find((elem) => elem.id === 376)
							? "active"
							: ""
					}`}
					style={{zIndex: 2}}
					onClick={() => handleDeleteClick()}
				>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M8.70701 7.29304L7.99991 6.58594L6.58569 8.00015L7.2928 8.70726L10.5857 12.0002L7.2928 15.293L6.58569 16.0002L7.99991 17.4144L8.70701 16.7073L11.9999 13.4144L15.2928 16.7073L15.9999 17.4144L17.4141 16.0002L16.707 15.293L13.4141 12.0002L16.707 8.70726L17.4141 8.00015L15.9999 6.58594L15.2928 7.29305L11.9999 10.5859L8.70701 7.29304Z"
							fill="#212121"
						></path>
					</svg>
				</button>
			</div>
		</>
	);
};

export default connect(null, null)(TipGenderSelect);
