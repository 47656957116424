import React from "react";
import { connect } from "react-redux";
import { handleStatisticFilteredClick } from "./scripts";

const StatisticTitlesComponent = ({ activeColumnState, paramsState, setParamsState }) => {
	return (
		<div className="list-table-header stat-list">
			{Object.keys(activeColumnState).map((key, index) => (
				<div
					className={`list-table-header-element ${
						!activeColumnState[key]?.active ? "hidden" : ""
					} ${activeColumnState[key].sortable ? "" : "non-sort"}`}
					style={{ width: activeColumnState[key]?.width }}
					key={index}
					onClick={() =>
						activeColumnState[key].sortable &&
						handleStatisticFilteredClick("sort", key, paramsState, setParamsState)
					}
				>
					<span>{activeColumnState[key].title}</span>
				</div>
			))}
		</div>
	);
};

export default connect(null, null)(StatisticTitlesComponent);
