import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useLazyQuery } from "@apollo/client";
import {
	getTotalBloggers,
	getTotalMov,
	getTotalSellers,
	getTotalTips,
	getTotalVideos,
} from "./get-totals.js";
import { GET_MAIN_PROFIT, GET_TIPS_SHOW } from "../../../../GraphQL/HomePage.js";
import { getFormatedNumberValue } from "../scripts.js";
import { getAllExternalSellerBalance } from "../../../../Actions/SellersActions.js";
import { removeEmpty } from "../../../../Actions/ActionsScirpts.js";
import { Tooltip } from "../../../../ui-kit/tooltip/Tooltip.jsx";
import VideosAmountTooltip from "./VideosAmountTooltip.jsx";

const MeaningfulCards = ({
	filtersState,
	externalAllSellerBalance,
	getAllExternalSellerBalance,
}) => {
	const dispatch = useDispatch();

	const [totalMov, setTotalMov] = useState(0);
	const [totalSel, setTotalSel] = useState(0);
	const [totalBlog, setTotalBlog] = useState(0);
	const [tipsCount, setTipsCount] = useState(0);
	const [sellerBalance, setSellerBalance] = useState(null);
	const [getStatistic, { data: revenue }] = useLazyQuery(GET_MAIN_PROFIT, {
		variables: {},
	});
	const [videosCount, setVideosCount] = useState(0);
	const [getTipsStatistic, { data: tipsShow }] = useLazyQuery(GET_TIPS_SHOW, {
		variables: {},
	});
	useEffect(() => {
		if (!filtersState.from_datetime || !filtersState.to_datetime || !filtersState.period_name)
			return;
		getStatistic({
			variables: {
				gte: new Date(filtersState.from_datetime).toISOString(),
				lte: new Date(filtersState.to_datetime).toISOString(),
			},
		});
		getTipsStatistic({
			variables: {
				gte: new Date(filtersState.from_datetime).toISOString(),
				lte: new Date(filtersState.to_datetime).toISOString(),
			},
		});
		getTotalVideos(dispatch, (e) => setVideosCount(e));
		getTotalTips(
			{
				from_datetime: new Date(filtersState.from_datetime).toISOString(),
				to_datetime: new Date(filtersState.to_datetime).toISOString(),
			},
			dispatch,
			(e) => setTipsCount(e)
		);
		getTotalSellers(
			{
				from_datetime: new Date(filtersState.from_datetime).toISOString(),
				to_datetime: new Date(filtersState.to_datetime).toISOString(),
			},
			dispatch,
			(e) => setTotalSel(e)
		);
		getTotalBloggers(
			{
				created_at_start: new Date(filtersState.from_datetime).toISOString(),
				created_at_end: new Date(filtersState.to_datetime).toISOString(),
			},
			dispatch,
			(e) => setTotalBlog(e)
		);
		getTotalMov(
			{
				from_datetime: new Date(filtersState.from_datetime).toISOString(),
				to_datetime: new Date(filtersState.to_datetime).toISOString(),
			},
			dispatch,
			(e) => setTotalMov(e)
		);
	}, [filtersState]);

	useEffect(() => {
		const filtered = removeEmpty({ ...filtersState });
		if (filtered.hasOwnProperty("date_key")) {
			delete filtered.date_key;
		}
		getAllExternalSellerBalance();
	}, [filtersState]);

	useEffect(() => {
		setSellerBalance(externalAllSellerBalance);
	}, [externalAllSellerBalance]);

	return (
		<>
			<div className="home-svodka-items">
				<div className="home-svodka-item color-svodka">
					<div className="home-svodka-item-number home-svodka-item-number-title">
						<div className="first-row">
							{getFormatedNumberValue(revenue?.getStatisticPage?.aggregation?.total_sum || 0)}
							<small> ₽</small>
						</div>
						<div className="home-svodka-item-text">Общий доход</div>
					</div>
					<div className="home-svodka-item-number home-svodka-item-number-title">
						<div className="first-row">
							{getFormatedNumberValue(
								revenue?.getStatisticPage?.aggregation?.total_sum -
									revenue?.getStatisticPage?.aggregation?.income_profit || 0
							)}
							<small> ₽</small>
						</div>
						<div className="home-svodka-item-text">Прибыль</div>
					</div>
				</div>
				<div className="home-svodka-item">
					<div className="home-svodka-item-number home-svodka-item-number-title">
						<div className="first-row">
							{getFormatedNumberValue(sellerBalance?.total_replenish_amount)}
							<small> ₽</small>
						</div>
						<div className="home-svodka-item-text">Всего внесено рекламодателями</div>
					</div>
					<div className="home-svodka-item-number home-svodka-item-number-title">
						<div className="first-row">
							{getFormatedNumberValue(sellerBalance?.current_balance)}
							<small> ₽</small>
						</div>
						<div className="home-svodka-item-text">Общий остаток</div>
					</div>
				</div>
				<div className="home-svodka-item">
					<div className="home-svodka-item-number home-svodka-item-number-title">
						<div className="first-row">
							{getFormatedNumberValue(tipsShow?.getStatisticPage?.pageInfo?.itemsCount || 0)}{" "}
						</div>
						<div className="home-svodka-item-text">Просмотров типсов</div>
					</div>
					<div className="home-svodka-item-number home-svodka-item-number-title">
						<div className="first-row">
							{getFormatedNumberValue(revenue?.getStatisticPage?.pageInfo?.itemsCount || 0)}{" "}
						</div>
						<div className="home-svodka-item-text">Кликов типсов</div>
					</div>
				</div>
				<div className="home-svodka-item">
					<div className="home-svodka-item-number home-svodka-item-number-title">
						<div className="first-row">{getFormatedNumberValue(tipsCount)}</div>
						<div className="home-svodka-item-text">Всего типсов</div>
					</div>
					<div className="home-svodka-item-number home-svodka-item-number-title">
						<div className="first-row">{getFormatedNumberValue(videosCount)}</div>
						<div className="home-svodka-item-text">Всего видео</div>
						<VideosAmountTooltip />
					</div>
				</div>
			</div>
			<div className="home-svodka-items">
				<div className="home-svodka-item" style={{ background: "#FFE6A6" }}>
					<div className="home-svodka-item-number">{getFormatedNumberValue(totalMov)}</div>
					<div className="home-svodka-item-info">Всего зрителей</div>
				</div>
				<div className="home-svodka-item" style={{ background: "#E9D6FF" }}>
					<div className="home-svodka-item-number">{getFormatedNumberValue(totalSel || 0)}</div>
					<div className="home-svodka-item-info">Всего рекламодателей</div>
				</div>
				<div className="home-svodka-item" style={{ background: "#D3E5FF" }}>
					<div className="home-svodka-item-number">{getFormatedNumberValue(totalBlog || 0)}</div>
					<div className="home-svodka-item-info">Всего блогеров</div>
				</div>
				<div className="home-svodka-item" style={{ background: "#FFD0E9" }}>
					<div className="home-svodka-item-number">
						{getFormatedNumberValue(
							(
								revenue?.getStatisticPage?.aggregation?.total_sum /
								revenue?.getStatisticPage?.pageInfo?.itemsCount
							).toFixed(2)
						)}
						<small> ₽</small>
					</div>
					<div className="home-svodka-item-info">Средний чек</div>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		externalAllSellerBalance: state.externalAllSellerBalanceReducer,
	};
};

const mapDispatchToProps = {
	getAllExternalSellerBalance,
};

export default connect(mapStateToProps, mapDispatchToProps)(MeaningfulCards);
