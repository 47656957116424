import React, { useContext } from "react";
// Context has been created
const UserAccessContext = React.createContext(false);
/**
 * Проавайдер контекста с данными и правами пользовтеля.
 */
const UserAccessProvider = ({ children }) => {
	const [userAccessState, setUserAccessState] = React.useState(null);
	return (
		<UserAccessContext.Provider value={{ userAccessState, setUserAccessState }}>
			{children}
		</UserAccessContext.Provider>
	);
};

/**
 * контекст с данными и правами пользовтеля.
 * Далее, разграничение на права и доступы происходит благодаря состоянию этого контекста
 */
export const useUserAccessContext = () => {
	return useContext(UserAccessContext);
};
export { UserAccessContext, UserAccessProvider };
