import axios from "axios";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { getPriceHistory, putSellerPrice } from "../../../../Actions/PriceActions";
import { ModalContext } from "../../../modal/ModalContext/ModalContext.tsx";
import { customStyles } from "../../static";
import { baseUrl } from "../../../../Actions/consts";
import DropdownSellerSearch from "../../BrightboxesPage/dropdownVideoSearch/DropdownSellerSearch";
import styled from "styled-components";
import { Button } from "../../../../ui-kit/button/Button";

const StyledInput = styled.div`
	& > div {
		background: #f4f4f8;
		border: 1px solid #f4f4f8;
		border-radius: 8px;
		cursor: pointer;
		display: block;
		font-size: 15px;
		height: 54px;
		outline: none;
		position: relative;
	}
`;

const ModalAddSeller = ({ putSellerPrice, loader, onReady }) => {
	const { handleModal } = useContext(ModalContext);
	const [priceState, setPriceState] = useState("");
	const [sellerState, setSellerState] = useState({});

	const handlePriceChange = (e) => {
		e.preventDefault();

		const value = e.target.value;
		setPriceState(value);
	};

	const handleAddSeller = () => {
		if (!sellerState?.id) return;
		const newPlatforms = sellerState.platforms.map((elem) => ({
			platform_id: elem.platform_id,
			price: Number(priceState),
		}));
		putSellerPrice(sellerState.id, { platforms: newPlatforms }, onReady);
		handleModal();
	};

	return (
		<div className="modal-field add-price-seler-modal active">
			<div className="modal-field-title">Добавить продавца</div>
			<div className="modal-field-close" onClick={() => handleModal()}>
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M4.70701 3.29304L3.99991 2.58594L2.58569 4.00015L3.2928 4.70726L10.5857 12.0002L3.2928 19.293L2.58569 20.0002L3.99991 21.4144L4.70701 20.7073L11.9999 13.4144L19.2928 20.7073L19.9999 21.4144L21.4141 20.0002L20.707 19.293L13.4141 12.0002L20.707 4.70726L21.4141 4.00015L19.9999 2.58594L19.2928 3.29304L11.9999 10.5859L4.70701 3.29304Z"
						fill="#212121"
					></path>
				</svg>
			</div>
			<div className="flex-block">
				<div className="form-field">
					<div className="form-field-label">Выберите продавца</div>
					<div className="input-field">
						<StyledInput className="input-field">
							<DropdownSellerSearch
								base_price={true}
								selected={sellerState?.title}
								onClear={() => setSellerState(null)}
								onSelect={(newValue) => setSellerState(newValue)}
							/>
						</StyledInput>
					</div>
				</div>
			</div>
			<div className="flex-block">
				<div className="form-field">
					<div className="form-field-label">Стоимость клика, ₽</div>
					<div className="input-field" style={{ width: "160px" }}>
						<input value={priceState} placeholder="0" type="number" onChange={handlePriceChange} />
					</div>
				</div>
			</div>
			<div className="modal-buttons">
				<div className="button action-button border-button" onClick={() => handleModal()}>
					<span>Отменить</span>
				</div>
				<Button
					text="Добавить продавца"
					variant="primary"
					onClick={() => handleAddSeller()}
					disabled={Boolean(!sellerState?.id || !(priceState > 0))}
				/>
				{/* <div
					className={`button action-button ${loader.loading ? "preloader" : ""}`}
					disabled="true"
					onClick={() => handleAddSeller()}
				>
					<span>Добавить продавца</span>
				</div> */}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		priceHistoryList: state.priceHistoryList,
		loader: state.loadingStatus,
	};
};

const mapDispatchToProps = {
	getPriceHistory,
	putSellerPrice,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalAddSeller);
