export const initialUser = {
	name: "",
	inn: "",
	ogrn: "",
	ogrnip: "",
	legal_name: "",
	legal_address: "",
	actual_address: "",
	ceo_name: "",
	email: "",
	bank_inn: "",
	kpp: "",
	bank_name: "",
	checking_account: "",
	correspondent_account: "",
	country: "",
	bik: "",
	phone_number: "",
	is_active: true,
};
