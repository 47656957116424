import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Cluster } from "../../../../../ui-kit/layouts/Cluster";
import { getFormatedNumberValue } from "../UsersStatistic/scripts";
import { Tooltip } from "../../../../../ui-kit/tooltip/Tooltip";
import { useLazyQuery } from "@apollo/client";
import axios from "axios";
import { baseUrl } from "../../../../../Actions/consts";
import { GET_TOP5_BLOGGER_STATISTIC } from "../../../../../GraphQL/BloggerStatistic";
import "./best-categories.scss";

const BestCategories = ({ bloggerState, filtersState }) => {
	const [getStatistic, { data, loading, error }] = useLazyQuery(GET_TOP5_BLOGGER_STATISTIC, {
		variables: {
			blogger_id: null,
		},
	});

	useEffect(() => {
		if (
			!bloggerState ||
			!filtersState.from_datetime ||
			!filtersState.to_datetime ||
			!filtersState.period_name
		)
			return;
		getStatistic({
			variables: {
				blogger_id: bloggerState.serial_id,
				gte: new Date(filtersState.from_datetime).toISOString(),
				lte: new Date(filtersState.to_datetime).toISOString(),
			},
		});
	}, [bloggerState, filtersState]);

	const [bestTipsState, setBestTipsState] = useState([]);
	const [bestSellersState, setBestSellersState] = useState([]);

	useEffect(() => {
		if (
			data?.getSellersEfficiencyItem?.best_product_sellers &&
			data?.getSellersEfficiencyItem?.best_service_sellers
		) {
			const arr = [
				...data?.getSellersEfficiencyItem?.best_product_sellers,
				...data?.getSellersEfficiencyItem?.best_service_sellers,
			];
			arr.sort((a, b) => {
				if (a.profit > b.profit) {
					return -1;
				}
				if (a.profit < b.profit) {
					return 1;
				}
				return 0;
			});
			arr.slice(0, 5);
			setBestSellersState(arr);
		}
		if (
			data?.getSellersEfficiencyItem?.best_product_sellers &&
			data?.getSellersEfficiencyItem?.best_service_sellers
		) {
			const arr = [
				...data?.getTipsEfficiencyItem?.best_products_tips,
				...data?.getTipsEfficiencyItem?.best_service_tips,
			];
			arr.sort((a, b) => {
				if (a.profit > b.profit) {
					return -1;
				}
				if (a.profit < b.profit) {
					return 1;
				}
				return 0;
			});
			arr.slice(0, 5);
			setBestTipsState(arr);
		}
	}, [data]);

	return (
		<>
			<div className="flex-wrapper">
				<Cluster space="16px" style={{ width: "100%", marginTop: "16px" }}>
					<div className="home-table half blogger-stat">
						<div className="form-container-block-title">
							<b>Лучшие товары</b>
						</div>
						<div className="list-table-header home-best-products">
							<div className="list-table-header-element">
								<span>Название</span>
							</div>
							<div className="list-table-header-element">
								<span>Показы</span>
							</div>
							<div className="list-table-header-element">
								<span>Переходы</span>
							</div>
							<div className="list-table-header-element">
								<span>Доход, ₽</span>
							</div>
							<div className="list-table-header-element"></div>
						</div>
						<div className="list-table home-best-products">
							{data?.getEfficiencyItem?.best_products?.map((elem, index) => (
								<ProductItem elem={elem} key={index} />
							))}
						</div>
					</div>
					<div className="home-table half blogger-stat">
						<div className="form-container-block-title">
							<b>Лучшие услуги</b>
						</div>
						<div className="list-table-header home-best-products">
							<div className="list-table-header-element">
								<span>Название</span>
							</div>
							<div className="list-table-header-element">
								<span>Показы</span>
							</div>
							<div className="list-table-header-element">
								<span>Переходы</span>
							</div>
							<div className="list-table-header-element">
								<span>Доход, ₽</span>
							</div>
							<div className="list-table-header-element"></div>
						</div>
						<div className="list-table home-best-products">
							{data?.getEfficiencyItem?.best_services?.map((elem, index) => (
								<ProductItem elem={elem} key={index} />
							))}
						</div>
					</div>
				</Cluster>
			</div>
			<div className="flex-wrapper">
				<Cluster space="16px" style={{ width: "100%", marginTop: "16px" }}>
					<div className="home-table half blogger-stat">
						<div className="form-container-block-title">
							<b>Лучшие типсы</b>
						</div>
						<div className="list-table-header home-best-categories">
							<div className="list-table-header-element">
								<span>ID</span>
							</div>
							<div className="list-table-header-element">
								<span>Переходы</span>
							</div>
							<div className="list-table-header-element">
								<span>Доход, ₽</span>
							</div>
							<div className="list-table-header-element"></div>
						</div>
						<div className="list-table home-best-categories">
							{bestTipsState?.map((elem, index) => (
								<TipsItem elem={elem} key={index} />
							))}
						</div>
					</div>
					<div className="home-table half blogger-stat">
						<div className="form-container-block-title">
							<b>Лучшие рекламодатели</b>
						</div>
						<div className="list-table-header home-best-categories">
							<div className="list-table-header-element">
								<span>Название</span>
							</div>
							<div className="list-table-header-element">
								<span>Типсы</span>
							</div>
							<div className="list-table-header-element">
								<span>Закупки, ₽</span>
							</div>
							<div className="list-table-header-element"></div>
						</div>
						<div className="list-table home-best-categories">
							{bestSellersState?.map((elem, index) => (
								<SellerItem elem={elem} key={index} />
							))}
						</div>
					</div>
				</Cluster>
			</div>
		</>
	);
};

export const TipsItem = ({ elem }) => {
	return (
		<div className="list-table-item">
			<div className="list-table-item-element">
				{/* <img src={productState?.URL_HD_IMAGE} alt="" /> */}
				<span>{elem?.tips_id}</span>
			</div>
			<div className="list-table-item-element">
				<span>{getFormatedNumberValue(elem.clicks_count)}</span>
			</div>
			<div className="list-table-item-element">
				<span>{getFormatedNumberValue(elem.profit)}</span>
			</div>
			<div className={`list-table-item-element ${true > 0 ? "green" : "orange"}`}>
				<span style={{ color: "#94A0B150" }}>{Math.round(elem.percent)}%</span>
			</div>
			<Tooltip text={elem.tips_id} />
		</div>
	);
};
export const SellerItem = ({ elem }) => {
	return (
		<div className="list-table-item">
			<div className="list-table-item-element">
				{/* <img src={productState?.URL_HD_IMAGE} alt="" /> */}
				<span>{elem?.seller}</span>
			</div>
			<div className="list-table-item-element">
				<span>{getFormatedNumberValue(elem.clicks_count)}</span>
			</div>
			<div className="list-table-item-element">
				<span>{getFormatedNumberValue(elem.profit)}</span>
			</div>
			<div className={`list-table-item-element ${true > 0 ? "green" : "orange"}`}>
				<span style={{ color: "#94A0B150" }}>{Math.round(elem.percent)}%</span>
			</div>
			<Tooltip text={elem.seller} />
		</div>
	);
};

export const ProductItem = ({ elem }) => {
	const [productState, setProductState] = useState();
	const dispatch = useDispatch();

	useEffect(async () => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.get(`${baseUrl}/product/${elem.product_id}`)
			.then((prod) => {
				setProductState(prod.data);
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	}, [elem]);

	return productState ? (
		<div className="list-table-item">
			<div className="list-table-item-element">
				<img src={productState?.URL_HD_IMAGE} alt="" />
				<span>{elem?.name}</span>
			</div>
			<div className="list-table-item-element">
				<span>{getFormatedNumberValue(elem.views_count)}</span>
			</div>
			<div className="list-table-item-element">
				<span>{getFormatedNumberValue(elem.clicks_count)}</span>
			</div>
			<div className="list-table-item-element">
				<span>{getFormatedNumberValue(elem.profit)}</span>
			</div>
			<div className={`list-table-item-element ${true > 0 ? "green" : "orange"}`}>
				<span style={{ color: "#94A0B150" }}>{Math.round(elem.percent)}%</span>
			</div>
			<Tooltip text={elem.name} />
		</div>
	) : null;
};

export default connect(null, null)(BestCategories);
