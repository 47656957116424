import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import "./EditVideoStatistic.scss";
import { StatisticBlockContainer } from "../../../../../ui-kit/wrappers-containers/StatisticBlockContainer.jsx";
import { H5Text } from "../../../../../ui-kit/typography/Typography.jsx";
import { baseUrl } from "../../../../../Actions/consts";
import axios from "axios";
import styled from "styled-components";
import { EmptyNotification } from "../../../../../ui-kit/search/notifications";

const StyledSpan = styled.span`
	width: 40%;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
`;

const customCategoryCalculates = (xs, key) => {
	const grouppedResult = xs.reduce((rv, x) => {
		if (rv[x?.category?.id]) {
			(rv[x?.category?.id] = rv[x?.category?.id] || {}).list.push(x);
		} else {
			(rv[x?.category?.id] = rv[x?.category?.id] || {}).list = [x];
			(rv[x?.category?.id] = rv[x?.category?.id] || {}).title = x?.category?.title;
		}
		return rv;
	}, {});
	const resultCollection = Object.keys(grouppedResult).map((key) => {
		return {
			id: key,
			length: grouppedResult[key].list?.length,
			title: grouppedResult[key].title,
		};
	});
	resultCollection.sort((a, b) => {
		if (a.length > b.length) {
			return 1;
		}
		if (a.length < b.length) {
			return -1;
		}
		return 0;
	});
	return resultCollection;
};

const TipCategoryStatistic = ({ mainVideoState, dashboardVideoState }) => {
	const dispatch = useDispatch();
	const [categoriesState, setCategoriesState] = useState({
		collection: 0,
		total: 0,
	});
	useEffect(async () => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.get(`${baseUrl}/domain/tips/1000/0`, {
				params: {
					is_published: true,
					video_id_: dashboardVideoState?.video[0]?.id,
					owner_: 1,
					owner_id_: mainVideoState?.user_id,
				},
			})
			.then(async (video) => {
				setCategoriesState({
					collection: customCategoryCalculates(
						video.data.tips?.length > 0 ? video.data.tips : [],
						"id"
					),
					total: video.data.paginate_?.total_rows,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	}, [dashboardVideoState]);
	return (
		<StatisticBlockContainer marginTop="16px">
			<div className="gadgets-statistic-header">
				<H5Text className="gadgets-statistic-header__title">Рекламные категории типсов</H5Text>
			</div>
			{categoriesState.collection?.length === 0 ? (
				<EmptyNotification>Нет данных</EmptyNotification>
			) : (
				<div className="form-container-progress-blocks pink">
					{categoriesState.collection?.length > 0
						? categoriesState.collection?.map((elem, index) => (
								<div className="form-container-progress-block" key={index}>
									<StyledSpan style={{ width: "100%" }}>{elem?.title}</StyledSpan>
									<div className="form-container-progress-block-right">
										<div className="form-container-progress-block-line">
											<div
												className="form-container-progress-block-linefill"
												style={{
													width: Math.round((elem.length / categoriesState.total) * 100) + "%",
												}}
											></div>
										</div>
										<span>{Math.round((elem.length / categoriesState.total) * 100)}%</span>
										<span style={{ width: "40px" }}>{elem.length}</span>
									</div>
								</div>
						  ))
						: null}
				</div>
			)}
		</StatisticBlockContainer>
	);
};

// const mapStateToProps = (state) => {
// 	return {
// 	};
// };

// const mapDispatchToProps = {
// };

export default connect(null, null)(TipCategoryStatistic);
