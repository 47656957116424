import React, { useContext, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import styled from "styled-components";
import { addMoviePlaylist } from "../../../../Actions/PlayListActions.js";
import { ModalContext } from "../../../modal/ModalContext/ModalContext.tsx";
import DropdownVideoSearch from "../../BrightboxesPage/dropdownVideoSearch/DropdownVideoSearch.jsx";
import { takeId } from "../../scripts/PersonalAreaScripts.js";
import DropdownMovieSearch from "../../BrightboxesPage/dropdownVideoSearch/DropdownMovieSearch.jsx";

const StyledSearchControl = styled.div`
	background: #f4f4f8;
	border-radius: 8px;
`;

export const initialRadioState = [
	{
		id: 1,
		name: "Фильмы и сериалы",
	},
	{
		id: 2,
		name: "Видео блогеров",
	},
];

const AddVideoPlaylistsModal = ({
	videosList,
	playlistId,
	addMoviePlaylist,
	loading,
	playlist,
}) => {
	const { handleModal, modal } = useContext(ModalContext);
	const dispatch = useDispatch();
	const [videoOptions, setVideoOptions] = useState({
		selected: "",
		list: [],
	});

	useEffect(() => {
		if (loading === 2) {
			dispatch({ type: "HIDE_LOADER" });

			handleModal();
		}
	}, [loading, modal]);

	useEffect(() => {
		setVideoOptions({ videoOptions, list: videosList.list });
	}, [videosList]);

	const handleVideoClick = async () => {
		if (!videoOptions.selected?.id) return;

		let arr = [...playlist.movies];
		arr = takeId(arr, "movie_id");
		arr.push(videoOptions.selected?.id);
		const resultobj = arr.map((elem, index) => ({ [String(elem)]: String(index + 1) }));

		addMoviePlaylist(playlistId, { ...playlist, movies: resultobj, genre: "" });
	};

	const handleVideoSelect = (newValue) => {
		if (playlist.movies.find((video) => video.movie_id === newValue.id)) return;

		setVideoOptions({ ...videoOptions, selected: newValue });
	};

	return (
		<div className="modal-field" style={{ width: "600px" }}>
			<div className="modal-field-title">Добавить видео в плейлист</div>
			<div className="modal-field-close" onClick={() => handleModal()}>
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M4.70701 3.29304L3.99991 2.58594L2.58569 4.00015L3.2928 4.70726L10.5857 12.0002L3.2928 19.293L2.58569 20.0002L3.99991 21.4144L4.70701 20.7073L11.9999 13.4144L19.2928 20.7073L19.9999 21.4144L21.4141 20.0002L20.707 19.293L13.4141 12.0002L20.707 4.70726L21.4141 4.00015L19.9999 2.58594L19.2928 3.29304L11.9999 10.5859L4.70701 3.29304Z"
						fill="#212121"
					></path>
				</svg>
			</div>
			<div className="flex-block-col">
				<div className="form-field" style={{ marginTop: "32px" }}>
					<div className="input-field">
						<StyledSearchControl>
							<DropdownMovieSearch
								selected={videoOptions?.selected?.title || ""}
								onClear={() => setVideoOptions({ ...videoOptions, selected: "" })}
								onSelect={(newValue) => handleVideoSelect(newValue)}
								placeholder="Выберите"
							/>
						</StyledSearchControl>
					</div>
				</div>
			</div>
			<div className="modal-buttons delete">
				<button className="button action-button border-button">
					<span>Отменить</span>
				</button>

				<button
					className={`button action-button create-category-button ${
						loading === 1 ? "preloader" : ""
					}`}
					onClick={handleVideoClick}
				>
					<span>Добавить</span>
				</button>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		videosList: state.videosList,
		loading: state.loadingStatus.loading,
	};
};

const mapDispatchToProps = {
	addMoviePlaylist,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddVideoPlaylistsModal);
