import React, { useContext, useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { ModalContext } from "../../../modal/ModalContext/ModalContext";
import { Cluster } from "../../../../ui-kit/layouts/Cluster";
import styled from "styled-components";
import Lottie from "react-lottie-player";
import preloaderJson from "../../preloader.json";

const StyledButton = styled.div`
	width: 48px;
	height: 48px;
	border-radius: 56px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 2px solid #d1d8e1;
	cursor: pointer;
	&:hover {
		svg {
			path {
				fill: #0052e4;
			}
		}
		border: 2px solid #0052e4;
		background: transparent;
	}
`;

const StyledVideoPreview = styled.video`
	width: 800px;
	max-height: 70vh;
`;

export const VideoPreviewModal = ({ src, onEdit, timeCode, isEditable = false }) => {
	const { handleModal, modal } = useContext(ModalContext);
	const videoRef = useRef(null);
	const [loadingState, setLoadingState] = useState(false);

	useEffect(() => {
		if (videoRef?.current) {
			videoRef.current.currentTime = timeCode;
		}
	}, []);
	return (
		<>
			<div className="add-category-wrapper">
				{loadingState ? (
					<Cluster
						align="center"
						justify="center"
						style={{ width: "800px", height: "100%", position: "absolute" }}
					>
						<Lottie loop animationData={preloaderJson} play style={{ width: 50, height: 50 }} />
					</Cluster>
				) : null}
				<div className="modal-field-title">Кадр из видео</div>
				<div className="modal-field-close" onClick={() => handleModal()}>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M4.70701 3.29304L3.99991 2.58594L2.58569 4.00015L3.2928 4.70726L10.5857 12.0002L3.2928 19.293L2.58569 20.0002L3.99991 21.4144L4.70701 20.7073L11.9999 13.4144L19.2928 20.7073L19.9999 21.4144L21.4141 20.0002L20.707 19.293L13.4141 12.0002L20.707 4.70726L21.4141 4.00015L19.9999 2.58594L19.2928 3.29304L11.9999 10.5859L4.70701 3.29304Z"
							fill="#212121"
						></path>
					</svg>
				</div>
				<StyledVideoPreview
					controls={false}
					ref={videoRef}
					onLoadStart={(e) => setLoadingState(true)}
					onLoadedDataCapture={(e) => setLoadingState(false)}
				>
					<source src={src} type="video/mp4" />
				</StyledVideoPreview>
				<Cluster className="modal-buttons delete" style={{ justifyContent: "space-between" }}>
					{isEditable ? (
						<StyledButton className="" onClick={() => (handleModal(), onEdit())}>
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M16.6203 2.24935C16.4328 2.0618 16.1784 1.95643 15.9132 1.95642C15.648 1.95641 15.3936 2.06177 15.2061 2.24931L5.58581 11.8695C5.21073 12.2445 5.00001 12.7532 5.00001 13.2837L5.00001 16.9999C5.00001 17.2651 5.10537 17.5195 5.2929 17.707C5.48044 17.8945 5.73479 17.9999 6.00001 17.9999H9.71622C10.2467 17.9999 10.7554 17.7892 11.1305 17.4141L20.7503 7.79396C21.1408 7.40344 21.1409 6.77031 20.7504 6.37979L16.6203 2.24935ZM7.00001 13.2837L15.9131 4.37066L18.6291 7.08683L9.71622 15.9999H7.00001L7.00001 13.2837ZM3 20.0001H2L2 22.0001H3H21H22V20.0001H21H3Z"
									fill="#94A0B1"
								/>
							</svg>
						</StyledButton>
					) : (
						<div></div>
					)}
					<button
						className={`button action-button create-category-button`}
						onClick={() => handleModal()}
					>
						<span>Принять</span>
					</button>
				</Cluster>
			</div>
		</>
	);
};

// const mapStateToProps = (state) => {
// 	return {
// 		loading: state.loadingStatus.loading,
// 	};
// };

// const mapDispatchToProps = {
// 	deleteUser,
// };
