import React from "react";
import "./styles/ShowMoreButton.scss";

const ShowMoreButton = ({
	marginBottom = "16px",
	marginTop = "40px",
	variant = "transparent",
	onClick,
}) => {
	return (
		<div
			className={`show-more-wrapper ${variant}`}
			style={{ marginTop: marginTop, marginBottom: marginBottom }}
		>
			<button className="button_show-more" onClick={onClick}>
				Показать ещё
			</button>
		</div>
	);
};

export default ShowMoreButton;
