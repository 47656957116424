import axios from "axios";
import { baseUrl, marketUrl, bloggerUrl } from "./consts";
import * as qs from "qs";

export const getCurrentBlogger = (id) => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.post(`${baseUrl}/domain/other`, {
				method: "GET",
				service: 2,
				endpoint: `/api/dashboard/users/${id}`,
				data: "",
			})
			.then((seller) => {
				dispatch({
					type: "GET_CURRENT_BLOGGER",
					seller,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const getReviewExternalBloggerTotal = () => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.post(`${baseUrl}/domain/other`, {
				method: "GET",
				service: 2,
				endpoint: `/api/dashboard/users?size=1&page=1&application_status=not_accepted`,
				data: "",
			})
			.then((seller) => {
				dispatch({
					type: "GET_REVIEW_EXTERNAL_BLOGGER_TOTAL",
					seller,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};
export const getBlockedExternalBloggerTotal = () => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.post(`${baseUrl}/domain/other`, {
				method: "GET",
				service: 2,
				endpoint: `/api/dashboard/users?size=1&page=1&application_status=rejected`,
				data: "",
			})
			.then((seller) => {
				dispatch({
					type: "GET_BLOCKED_EXTERNAL_BLOGGER_TOTAL",
					seller,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const getDeletedExternalBloggerTotal = () => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.post(`${baseUrl}/domain/other`, {
				method: "GET",
				service: 2,
				endpoint: `/api/dashboard/users?size=1&page=1&application_status=deleted`,
				data: "",
			})
			.then((seller) => {
				dispatch({
					type: "GET_DELETED_EXTERNAL_BLOGGER_TOTAL",
					seller,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const getActiveExternalBloggerTotal = () => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.post(`${baseUrl}/domain/other`, {
				method: "GET",
				service: 2,
				endpoint: `/api/dashboard/users?size=1&page=1&application_status=accepted`,
				data: "",
			})
			.then((seller) => {
				dispatch({
					type: "GET_ACTIVE_EXTERNAL_BLOGGER_TOTAL",
					seller,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const getAllReviewExternalBlogger = (arg) => {
	if (!!arg?.filters && arg?.filters !== null) {
		for (let key in arg.filters) {
			arg = { ...arg, [key]: arg.filters[key] };
		}
		delete arg.filters;
	}
	if ("reversed_" in arg && arg.reversed_ === true && "order_by" in arg)
		arg = { ...arg, order_by: `-${arg.order_by}` };
	delete arg.reversed_;

	const queryString = Object.keys(arg)
		.map((key) => key + "=" + arg[key])
		.join("&");

	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});

		await axios
			.post(`${baseUrl}/domain/other`, {
				method: "GET",
				service: 2,
				endpoint: `/api/dashboard/users?application_status=not_accepted&${queryString}`,
				data: "",
			})
			.then((seller) => {
				dispatch({
					type: "GET_ALL_REVIEW_EXTERNAL_BLOGGER",
					seller,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};
export const getAllDeletedExternalBlogger = (arg) => {
	if (!!arg?.filters && arg?.filters !== null) {
		for (let key in arg.filters) {
			arg = { ...arg, [key]: arg.filters[key] };
		}
		delete arg.filters;
	}
	if ("reversed_" in arg && arg.reversed_ === true && "order_by" in arg)
		arg = { ...arg, order_by: `-${arg.order_by}` };
	delete arg.reversed_;

	const queryString = Object.keys(arg)
		.map((key) => key + "=" + arg[key])
		.join("&");

	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.post(`${baseUrl}/domain/other`, {
				method: "GET",
				service: 2,
				endpoint: `/api/dashboard/users?application_status=deleted&${queryString}`,
				data: "",
			})
			.then((seller) => {
				dispatch({
					type: "GET_ALL_DELETED_EXTERNAL_BLOGGER",
					seller,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};
export const getAllBlockedExternalBlogger = (arg) => {
	if (!!arg?.filters && arg?.filters !== null) {
		for (let key in arg.filters) {
			arg = { ...arg, [key]: arg.filters[key] };
		}
		delete arg.filters;
	}
	if ("reversed_" in arg && arg.reversed_ === true && "order_by" in arg)
		arg = { ...arg, order_by: `-${arg.order_by}` };
	delete arg.reversed_;

	const queryString = Object.keys(arg)
		.map((key) => key + "=" + arg[key])
		.join("&");

	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.post(`${baseUrl}/domain/other`, {
				method: "GET",
				service: 2,
				endpoint: `/api/dashboard/users?application_status=rejected&${queryString}`,
				data: "",
			})
			.then((seller) => {
				dispatch({
					type: "GET_ALL_BLOCKED_EXTERNAL_BLOGGER",
					seller,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const getAllActiveExternalBlogger = (arg) => {
	if (!!arg?.filters && arg?.filters !== null) {
		for (let key in arg.filters) {
			arg = { ...arg, [key]: arg.filters[key] };
		}
		delete arg.filters;
	}
	if ("reversed_" in arg && arg.reversed_ === true && "order_by" in arg)
		arg = { ...arg, order_by: `-${arg.order_by}` };
	delete arg.reversed_;

	const queryString = Object.keys(arg)
		.map((key) => key + "=" + arg[key])
		.join("&");

	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.post(`${baseUrl}/domain/other`, {
				method: "GET",
				service: 2,
				endpoint: `/api/dashboard/users?application_status=accepted&${queryString}`,
				data: "",
			})
			.then((seller) => {
				dispatch({
					type: "GET_ALL_ACTIVE_EXTERNAL_BLOGGER",
					seller,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const patchExternalBlogger = (id, data) => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.post(`${baseUrl}/domain/other`, {
				method: "PATCH",
				service: 2,
				endpoint: `/api/dashboard/users/${id}`,
				data_json: data,
			})
			.then((seller) => {
				dispatch({
					type: "PATCH_EXTERNAL_BLOGGER",
					seller,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const getBloggerVideos = (arg) => {
	if (!!arg.filters && arg?.filters !== null) {
		for (let key in arg.filters) {
			arg = { ...arg, [key]: arg.filters[key] };
		}
		delete arg.filters;
	}
	let is_active = true;
	if (!arg?.status?.length || arg?.status?.length === 2) {
		is_active = undefined;
	} else if (arg.status?.find((elem) => elem === 0) === 0) {
		is_active = false;
	}
	if ("title" in arg && !arg?.title?.length) {
		delete arg.title;
	}
	if ("format" in arg && arg.format.length > 1) {
		delete arg.format;
	}

	const queryString = Object.keys(arg)
		.map((key) => key + "=" + arg[key])
		.join("&");

	const endpoint =
		is_active === undefined
			? `/api/dashboard/videos?${queryString}`
			: `/api/dashboard/videos?is_active=${is_active}&${queryString}`;
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.post(`${baseUrl}/domain/other`, {
				method: "GET",
				service: 2,
				endpoint,
				data: "",
			})
			.then((video) => {
				dispatch({
					type: "GET_BLOGGER_VIDEOS",
					video,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const getBloggerVideoCategory = () => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.get(`https://atips.io/filmdata/videocategoriesall.json`)
			.then((genre) => {
				dispatch({
					type: "GET_VIDEO_CATEGORY",
					genre,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};
