import React from "react";
import { Navigate, Outlet } from "react-router-dom";

/**
 * guard авторизации
 */
const PrivateRoute = ({ user_id }) => {
	return !!user_id ||
		(localStorage.getItem("access")?.length > 0 && !!localStorage.getItem("user")) ? (
		<Outlet />
	) : (
		<Navigate to="/sign_in" />
	);
};

export { PrivateRoute };
