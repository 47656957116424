import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { ModalContext } from "../../../../modal/ModalContext/ModalContext.tsx";
import EditPoliticsComponent from "../../../PoliticsPage/EditPoliticsComponent.jsx";
import { customStyles } from "../../../static";
import "./EditVideoPolitics.scss";
import { useUserAccessContext } from "../../../context/UserAccessContext";
import { checkUserWritePerm } from "../../../scripts/utils";

const EditVideoPolitics = ({ politicsList, mainVideoState, setMainVideoState, bloggerVideo }) => {
	const { handleModal } = useContext(ModalContext);
	const [politicsState, setPoliticsState] = useState([]);
	const [selectedPoliticsState, setSelectedPoliticsState] = useState(
		mainVideoState.politics?.length > 0 ? mainVideoState.politics[0] : null
	);
	const { userAccessState } = useUserAccessContext();
	useEffect(() => {
		setPoliticsState(politicsList);
		if (politicsList.length > 0 && selectedPoliticsState === null) {
			setSelectedPoliticsState(politicsList[0]);
		} else if (politicsList.length > 0 && selectedPoliticsState !== null) {
			const foundPolitic = politicsList.find((elem) => selectedPoliticsState?.id === elem.id);
			if (foundPolitic) setSelectedPoliticsState(foundPolitic);
		}
	}, [politicsList]);

	useEffect(() => {
		setMainVideoState({ ...mainVideoState, politics: [selectedPoliticsState] });
	}, [selectedPoliticsState]);

	const isDisabled =
		!checkUserWritePerm(userAccessState, "videos") ||
		mainVideoState.movie_format_id === 3 ||
		mainVideoState.movie_format_id === 4 ||
		mainVideoState.movie_format_id === 5;

	return (
		<div className="form-container first-col video-politics">
			<div className="form-container">
				<div className="form-container-block">
					<div className="form-container-block-title">
						<b>Текущая политика</b>
						{!isDisabled ? (
							<button
								className="button_edit-politics button"
								onClick={() => handleModal(<EditPoliticsComponent data={selectedPoliticsState} />)}
							>
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M16.62 2.25a1 1 0 0 0-1.414 0l-9.62 9.62A2 2 0 0 0 5 13.283V17a1 1 0 0 0 1 1h3.716a2 2 0 0 0 1.415-.586l9.62-9.62a1 1 0 0 0 0-1.414l-4.13-4.13ZM7 13.283l8.913-8.913 2.716 2.716L9.716 16H7v-2.716ZM3 20H2v2h20v-2H3Z"
										fill="#212121"
									/>
								</svg>
								<span>Редактировать</span>
							</button>
						) : null}
					</div>

					<div className="flex-block-col">
						<div className="form-field">
							<div className="form-field-label">Политика</div>
							<div className="input-field">
								<Select
									isDisabled={
										!checkUserWritePerm(userAccessState, "videos") ||
										mainVideoState.movie_format_id === 3 ||
										mainVideoState.movie_format_id === 4 ||
										mainVideoState.movie_format_id === 5
									}
									className="search-wrapper"
									classNamePrefix="select"
									name="video"
									styles={customStyles}
									value={bloggerVideo ? bloggerVideo.access_policy : selectedPoliticsState}
									options={politicsState}
									onChange={(newValue) => setSelectedPoliticsState(newValue)}
									getOptionLabel={(option) => option.title}
									getOptionValue={(option) => option.id}
									placeholder={politicsState.name}
									isSearchable={false}
									noOptionsMessage={() => "нет данных"}
								/>
							</div>
						</div>
						<div className="form-field">
							<div className="input-field">
								<span>
									{selectedPoliticsState?.available ? "Показывать" : "Запретить"}{" "}
									{`в${
										selectedPoliticsState?.locations_settable?.length
											? " " + selectedPoliticsState?.locations_settable?.length + " странах:"
											: "о всех странах"
									}`}
								</span>
							</div>
							<div className="input-field">
								<div className="selected-country-wrapper">
									{selectedPoliticsState?.locations_settable?.map((elem) => (
										<div className="selected-country" key={elem.id}>
											{elem?.title}
										</div>
									))}
								</div>
							</div>
						</div>
						<div className="form-field">
							<div className="form-field-label">Типсы</div>
							<div className="input-field">
								{selectedPoliticsState.availabletips ? (
									<span>
										{!selectedPoliticsState.locationstips_settable ||
										selectedPoliticsState.locationstips_settable.length === 0
											? "Показывать во всём мире"
											: `Показывать в ${selectedPoliticsState.locationstips_settable.length} странах`}
									</span>
								) : (
									<span>
										{!selectedPoliticsState.locationstips_settable ||
										selectedPoliticsState.locationstips_settable.length === 0
											? "Не показывать во всём мире"
											: `Не показывать в ${selectedPoliticsState.locationstips_settable.length} странах`}
									</span>
								)}
							</div>
							<div className="input-field">
								<div className="selected-country-wrapper">
									{selectedPoliticsState?.locationstips_settable?.map((elem) => (
										<div className="selected-country" key={elem.id}>
											{elem.title}
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
					<button
						// disabled
						className="button border-button"
						onClick={() => handleModal(<EditPoliticsComponent />)}
					>
						<svg
							width="16"
							height="16"
							viewBox="0 0 16 16"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M9 2.667v-1H7V7H1.667v2H7v5.333h2V9h5.333V7H9V2.667Z"
								fill="#212121"
							/>
						</svg>
						<span>Добавить политику</span>
					</button>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		politicsList: state.politicsList,
		politicsLocation: state.politicsLocation,
	};
};

// const mapDispatchToProps = {
// 	getPolitics,
// };

export default connect(mapStateToProps, null)(EditVideoPolitics);
