export const handleStatisticFilteredClick = (type, value, mainFilterState, setMainFilterState) => {
	if (type === "search") {
		if (mainFilterState.filters?.title?.length === 0) {
			const timeObj = mainFilterState.filters;
			delete timeObj.title;
			setMainFilterState({
				...mainFilterState,
				page: 1,
				filters: timeObj,
			});
		} else {
			setMainFilterState({
				...mainFilterState,
				page: 1,
				filters: { ...mainFilterState.filters, title: value },
			});
		}
	} else if (type === "sellerSearch") {
		if (mainFilterState.filters?.name?.length === 0) {
			const timeObj = mainFilterState.filters;
			delete timeObj.name;
			setMainFilterState({
				...mainFilterState,
				page: 1,
				filters: timeObj,
			});
		} else {
			setMainFilterState({
				...mainFilterState,
				page: 1,
				filters: { ...mainFilterState.filters, name: value },
			});
		}
	} else if (type === "sort") {
		if (
			mainFilterState?.order_by === value &&
			(mainFilterState?.reversed_ === false || mainFilterState?.reversed_ === true)
		) {
			setMainFilterState({
				...mainFilterState,
				order_by: value,
				reversed_: !mainFilterState.reversed_,
				page: 1,
			});
		} else {
			setMainFilterState({
				...mainFilterState,
				order_by: value,
				reversed_: false,
				page: 1,
			});
		}
	} else if (value.length === 0) {
		if (!!mainFilterState.filters && type in mainFilterState.filters) {
			let objectCopy = { ...mainFilterState, page: 1 };
			delete objectCopy.filters[type];
			setMainFilterState(objectCopy);
		}
	} else if (
		type === "formats_ids" ||
		type === "genres_ids" ||
		type === "area" ||
		type === "areaId" ||
		type === "areaid"
	) {
		setMainFilterState({
			...mainFilterState,
			page: 1,
			filters: { ...mainFilterState.filters, [type]: value },
		});
	} else if (type === "years_") {
		setMainFilterState({
			...mainFilterState,
			page: 1,
			filters: { ...mainFilterState.filters, [type]: value },
		});
	}
};
