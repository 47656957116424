export const initialGendersValue = [
  {
    id: 1,
    title: "Женщины",
    percentValue: 68,
    currentValue: "8 353 632",
  },
  {
    id: 2,
    title: "Мужчины",
    percentValue: 32,
    currentValue: "4 856 764",
  },
];

export const initialAgesValue = [
  {
    id: 1,
    title: "18-24",
    percentValue: 5,
    currentValue: "643 575",
  },
  {
    id: 2,
    title: "24-35",
    percentValue: 10,
    currentValue: "3 856 659",
  },
  {
    id: 3,
    title: "35-48",
    percentValue: 54,
    currentValue: "22 856 976",
  },
  {
    id: 4,
    title: "48-56",
    percentValue: 18,
    currentValue: "6 856 764",
  },
  {
    id: 5,
    title: "56-65",
    percentValue: 12,
    currentValue: "4 100 436",
  },
  {
    id: 6,
    title: "65+",
    percentValue: 1,
    currentValue: "256 754",
  },
];

const cities = [
  {
    id: 1,
    name: "Москва",
    percentValue: 64,
    currentValue: "12 435 436",
  },
  {
    id: 2,
    name: "Санкт-Петербург",
    percentValue: 24,
    currentValue: "2 108 235",
  },
  {
    id: 3,
    name: "Нижний Новгород",
    percentValue: 4,
    currentValue: "746 280",
  },
  {
    id: 4,
    name: "Екатеринбург",
    percentValue: 2,
    currentValue: "532 280",
  },
  {
    id: 5,
    name: "Новосибирск",
    percentValue: 1,
    currentValue: "356 224",
  },
  {
    id: 6,
    name: "Казань",
    percentValue: 1,
    currentValue: "348 542",
  },
];

export const COUNTRIES_COLOR = {
  0: "#7027c2",
  1: "#FD4AA7",
  2: "#CC5EF5",
  3: "#0DC268",
  4: "#FF9E00",
};

export const initialRegionValue = [
  {
    id: 1,
    name: "Россия",
    percentValue: 72,
    currentValue: "16 526 997",
    color: "#7027c2",
    cities,
  },
  {
    id: 2,
    name: "Беларусь",
    percentValue: 16,
    color: "#FD4AA7",
    currentValue: "2 108 235",
  },
  {
    id: 3,
    name: "Казахстан",
    percentValue: 4,
    color: "#CC5EF5",
    currentValue: "746 280",
  },
  {
    id: 4,
    name: "Армения",
    percentValue: 2,
    color: "#0DC268",
    currentValue: "532 280",
  },
  {
    id: 5,
    name: "Азербайджан",
    percentValue: 1,
    color: "#FF9E00",
    currentValue: "356 224",
  },
];
