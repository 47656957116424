export const initialMonthData = () => {
  const actualDate = new Date();
  const acutalDateNumber = actualDate.getDate();
  const result = [];
  for (let i = 30; i > 0; i--) {
    result.push({
      actualDate: 0,
      date: new Date(new Date().setDate(acutalDateNumber - i))
        .toLocaleString("ru", options)
        .split(" ", 3)
        .join(" "),
    });
  }
  return result;
};
export const initialWeekData = () => {
  const actualDate = new Date();
  const acutalDateNumber = actualDate.getDate();
  const result = [];
  for (let i = 7; i > 0; i--) {
    result.push({
      actualDate: 0,
      date: new Date(new Date().setDate(acutalDateNumber - i))
        .toLocaleString("ru", options)
        .split(" ", 3)
        .join(" "),
    });
  }
  return result;
};

export const initialDayData = () => {
  const actualDate = new Date();
  const hours = actualDate.getHours();
  const result = [];
  for (let i = 24; i >= 0; i--) {
    result.push({
      actualDate: 0,
      date: new Date(new Date().setHours(hours - i))
        .toLocaleString("ru", options)
        .split(" ", 3)
        .join(" "),
    });
  }
  return result;
};

export let options = {
  year: "numeric",
  month: "long",
  day: "numeric",
};

export const initialIncomeValueState = () => {
  const actualDate = new Date();
  const monthDate = new Date();
  monthDate.setMonth(monthDate.getMonth() - 1);
  let arr = [];
  for (
    let index = 0;
    index <= (actualDate.getTime() - monthDate.getTime()) / (1000 * 3600 * 24);
    index
  ) {
    arr.push({
      ozon: Math.floor(Math.random() * (250000 - 10000 + 1)) + 10000,
      wildberries: Math.floor(Math.random() * (250000 - 10000 + 1)) + 10000,
      avito: Math.floor(Math.random() * (250000 - 10000 + 1)) + 10000,
      date: monthDate.toLocaleString("ru", options).split(" ", 3).join(" "),
    });
    new Date(monthDate.setDate(monthDate.getDate() + 1));
  }
  return arr;
};
