import React, { useEffect, useState } from "react";
import styled from "styled-components";

const TabsWrapper = styled.div`
	/* border-bottom: 1px solid #e2e8f0; */
	display: flex;
	gap: 32px;
	position: relative;
	margin-bottom: 8px;
	&:after {
		content: "";
		position: absolute;
		width: calc(100% + 64px);
		height: 2px;
		background-color: #e2e8f0;
		left: -32px;
		bottom: 0;
	}
`;

const Tab = styled.button`
	border: none;
	border-bottom: 2px solid transparent;
	outline: none;
	background: transparent;
	align-items: center;
	box-sizing: border-box;
	color: #212121;
	font-family: "Averta CY";
	display: block;
	font-size: 15px;
	font-weight: 400;
	line-height: 160%;
	padding-bottom: 9px;
	position: relative;
	cursor: pointer;
	z-index: 1;
	&:hover {
		color: #005bff;
	}
	&.active {
		border-bottom: 4px solid #005bff;
	}
`;

export const CategoryTabs = ({ collection, active, onChange }) => {
	const [tabs, setTabs] = useState(collection);
	useEffect(() => {
		setTabs(collection);
	}, [collection]);

	return (
		<TabsWrapper>
			{tabs.length &&
				tabs.map((elem) => (
					<Tab
						key={elem.id}
						className={`statistics-bookmark ${elem.id === active.id ? "active" : ""}`}
						onClick={() => onChange(elem)}
					>
						{elem.title}
					</Tab>
				))}
		</TabsWrapper>
	);
};
