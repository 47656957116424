import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { getVideoGenres } from "../../../../../Actions/videos-actions/VideosActions.js";
import "../../styles/VideosPage.scss";
import { ModalContext } from "../../../../modal/ModalContext/ModalContext";
import { getVideoFormats } from "../../../../../Actions/FormatsActions.js";
import { baseUrl } from "../../../../../Actions/consts.js";
import { history } from "../../../../../Store/Store";
import { putCurrentMovie } from "../../../../../Actions/videos-actions/putMovieVideo.js";
import { deleteVideo } from "../../../../../Actions/videos-actions/deleteMovieVideo.js";
import styled from "styled-components";
import BloggerVideosFilters from "./BloggerVideosFilters.jsx";
import { getBloggerVideos } from "../../../../../Actions/BloggersActions.js";
import ExtertnalPaginationBlock from "../../../../universal/PaginationBlock/ExtertnalPaginationBlock.jsx";
import { nativeMapBy } from "../../../scripts/mapBy.js";
import { formatedDate } from "../../../static.js";
import axios from "axios";
import { Tooltip } from "../../../../../ui-kit/tooltip/Tooltip.jsx";

export const handleOrderClick = (key, filterState, setFilterState) => {
	if (!("order_by" in filterState)) {
		setFilterState({ ...filterState, order_by: key });
		return;
	}
	if (filterState.order_by !== key) {
		setFilterState({ ...filterState, order_by: key });
	} else {
		setFilterState({ ...filterState, order_by: `-${key}` });
	}
};

const initialDropDownState = {
	formats_ids: false,
	genres_ids: false,
	year: false,
};

const Backdrop = styled.div`
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	background: transparent;
	position: fixed;
	z-index: 0;
`;

const BloggerVideos = ({ videosList, getBloggerVideos, bloggerVideoCaregoryList, setTotal }) => {
	let navigate = useNavigate();
	const { handleModal } = useContext(ModalContext);

	const [mainVideoState, setMainVideoState] = useState([]);
	const [searchParams, setSearchParams] = useSearchParams();
	const [firstLoad, setFirstLoad] = useState(true);
	const [lastSearch, setLastSearch] = useState(true);
	const [categoryState, setCategoryState] = useState({});
	const [mainFilterState, setMainFilterState] = useState({
		filters: JSON.parse(searchParams.get("filters")),
		order_by: searchParams.get("order_by") !== null ? searchParams.get("order_by") : "-created_at",
		size: searchParams.get("size") !== null ? searchParams.get("size") : 20,
		page: searchParams.get("page") !== null ? searchParams.get("page") : 1,
	});

	useEffect(() => {
		setCategoryState(nativeMapBy(bloggerVideoCaregoryList, "id"));
	}, [bloggerVideoCaregoryList]);

	useEffect(() => {
		if (mainFilterState?.title?.length > 0) {
			getBloggerVideos({
				...mainFilterState,
			});
			if (!firstLoad && lastSearch) {
				setSearchParams({
					...mainFilterState,
					filters: JSON.stringify({ ...mainFilterState.filters }),
				});
			} else if (!lastSearch) {
				setLastSearch(true);
			}
			setFirstLoad(false);
		} else {
			getBloggerVideos(mainFilterState);
			if (!firstLoad && lastSearch) {
				setSearchParams({
					...mainFilterState,
					filters: JSON.stringify({ ...mainFilterState.filters }),
				});
			} else if (!lastSearch) {
				setLastSearch(true);
			}
			setFirstLoad(false);
		}
	}, [mainFilterState]);

	useEffect(() => {
		return history.listen((location, action) => {
			if (action === "POP") {
				const params = new URLSearchParams(location.search);
				setLastSearch(false);
				setMainFilterState({
					filters: params.get("filters") !== null ? JSON.parse(params.get("filters")) : {},
					page: params.get("page") !== null ? params.get("page") : 1,
					size: params.get("size") !== null ? params.get("size") : 20,
				});
			}
		});
	}, [history]);

	useEffect(() => {
		if (videosList?.list) {
			setMainVideoState(videosList.list);
		}
		setTotal(videosList.pagination.max_elements);
	}, [videosList]);

	return (
		<>
			<div className="videos-content-wrapper content-wrapper">
				<BloggerVideosFilters
					mainFilterState={mainFilterState}
					setMainFilterState={setMainFilterState}
				/>
				<div className="list-table-header video-list">
					<div
						className="list-table-header-element"
						onClick={() => handleOrderClick("title", mainFilterState, setMainFilterState)}
						style={{ width: "383%", paddingLeft: "20px" }}
					>
						<span>Название</span>
					</div>
					<div
						className="list-table-header-element non-sort"
						// onClick={() => handleOrderClick("title", mainFilterState, setMainFilterState)}
						style={{ width: "100%" }}
					>
						<span className="non-sort">Формат</span>
					</div>
					<div
						className="list-table-header-element"
						onClick={() => handleOrderClick("user_name", mainFilterState, setMainFilterState)}
						style={{ width: "128%" }}
					>
						<span>Автор</span>
					</div>
					<div
						className="list-table-header-element"
						onClick={() => handleOrderClick("categories", mainFilterState, setMainFilterState)}
						style={{ width: "135%" }}
					>
						<span>Категория</span>
					</div>
					<div
						className="list-table-header-element"
						onClick={() => handleOrderClick("created_at", mainFilterState, setMainFilterState)}
						style={{ width: "166%" }}
					>
						<span>Дата публикации</span>
					</div>
					<div
						className="list-table-header-element non-sort"
						// onClick={() => handleOrderClick("tips_count", mainFilterState, setMainFilterState)}
						style={{ width: "89%" }}
					>
						<span className="non-sort">Типсы</span>
					</div>
					<div
						className="list-table-header-element"
						onClick={() => handleOrderClick("views_amount", mainFilterState, setMainFilterState)}
						style={{ width: "117%" }}
					>
						<span>Просмотры</span>
					</div>
					<div
						className="list-table-header-element"
						onClick={() => handleOrderClick("is_banned", mainFilterState, setMainFilterState)}
						style={{ width: "92px", minWidth: "92px" }}
					>
						<span>Бан</span>
					</div>
					<div
						className="list-table-header-element"
						onClick={() => handleOrderClick("is_active", mainFilterState, setMainFilterState)}
						style={{ width: "92px", minWidth: "92px" }}
					>
						<span>Статус</span>
					</div>
				</div>
				<div className="list-table video-list">
					{mainVideoState.length > 0
						? mainVideoState.map((video) => (
								<VideoItem video={video} categoryState={categoryState} key={video.id} />
						  ))
						: null}
				</div>
			</div>
			{Object.keys(videosList.pagination).length !== 0 &&
			!!videosList.pagination.max_pages &&
			mainVideoState.length > 0 &&
			videosList.pagination.max_pages > 1 ? (
				<ExtertnalPaginationBlock
					filterState={mainFilterState}
					setFilterState={setMainFilterState}
					pagination={videosList.pagination}
				/>
			) : null}
		</>
	);
};

const VideoItem = ({ video, categoryState }) => {
	const [domainVideo, setDomainVideo] = useState();
	const [tipsCount, setTipsCount] = useState();

	useEffect(async () => {
		await axios
			.get(`${baseUrl}/domain/movies/external/${`${video.id}`}`)
			.then((video) => {
				setDomainVideo(video.data);
			})
			.finally(() => {})
			.catch((error) => {
				console.log(error);
			});
	}, [video]);
	useEffect(async () => {
		if (!domainVideo) return;
		await axios
			.get(`${baseUrl}/domain/tips/1/0?video_id_=${domainVideo?.video?.[0]?.id}&is_published=true`)
			.then((tip) => {
				setTipsCount(tip?.data?.paginate_?.total_rows || 0);
			})
			.catch((error) => {
				return null;
			});
	}, [domainVideo]);

	return domainVideo ? (
		<div className="list-table-item" key={domainVideo.id}>
			<div className="list-table-item-element" style={{ width: "383%" }}>
				<span>
					<Link to={`/blogger-video?id=${domainVideo?.id}`}>{video?.title}</Link>
				</span>
			</div>
			<div className="list-table-item-element" style={{ width: "100%" }}>
				<span>
					{domainVideo?.movie_format?.id === 3
						? "Видео"
						: domainVideo?.movie_format?.id === 4
						? "Shorts"
						: "Не указано"}
				</span>
			</div>
			<div className="list-table-item-element" style={{ width: "128%" }}>
				<span>{video?.user?.name || "Не указано"}</span>
			</div>
			<div className="list-table-item-element" style={{ width: "135%" }}>
				<span>
					{video.categories.map((elem, index) =>
						index !== video.categories.length - 1
							? `${categoryState[elem]?.title || "Не указано"}, `
							: `${categoryState[elem]?.title || "Не указано"}`
					) || "Не указано"}
				</span>
			</div>
			<div className="list-table-item-element" style={{ width: "166%" }}>
				<span>{formatedDate(video.created_at)}</span>
			</div>
			<div className="list-table-item-element" style={{ width: "89%" }}>
				<span>{tipsCount || 0}</span>
			</div>
			<div className="list-table-item-element" style={{ width: "117%" }}>
				<span>
					{video?.all_views_amount} ({video?.views_amount})
				</span>{" "}
				<Tooltip
					text={`Всего: ${video?.all_views_amount.toLocaleString("ru-RU")} \n Уникальных: ${
						video?.views_amount ? video?.views_amount.toLocaleString("ru-RU") : 0
					}`}
				/>
			</div>
			<div
				className="list-table-item-element"
				style={{
					width: "92px",
					minWidth: "92px",
					justifyContent: "flex-end",
					paddingRight: "16px",
				}}
			>
				<div
					className={`list-table-item-status ${
						video?.is_banned || domainVideo?.is_banned ? "disactive" : ""
					}`}
				>
					<div className="help-tip">
						<span>{video?.is_banned || domainVideo?.is_banned ? "З" : "Не з"}абанен</span>
						<img src="images/icons/ui/arrow.svg" alt="" />
					</div>
				</div>
			</div>
			<div
				className="list-table-item-element"
				style={{
					width: "92px",
					minWidth: "92px",
					justifyContent: "flex-end",
					paddingRight: "16px",
				}}
			>
				<div className={`list-table-item-status ${!domainVideo?.published ? "disactive" : ""}`}>
					<div className="help-tip">
						<span>{domainVideo?.published ? "О" : "Не о"}публиковано</span>
						<img src="images/icons/ui/arrow.svg" alt="" />
					</div>
				</div>
			</div>
		</div>
	) : null;
};

const mapStateToProps = (state) => {
	return {
		videosList: state.bloggerVideosList,
		videoGenresList: state.videoGenresList,
		videoFormatsList: state.videoFormatsList,
		mainLoader: state.mainLoader,
		bloggerVideoCaregoryList: state.bloggerVideoCaregoryList,
	};
};

const mapDispatchToProps = {
	getBloggerVideos,
	getVideoGenres,
	deleteVideo,
	getVideoFormats,
	putCurrentMovie,
};

export default connect(mapStateToProps, mapDispatchToProps)(BloggerVideos);
