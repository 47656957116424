import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { ModalContext } from "../../../modal/ModalContext/ModalContext.tsx";
import { initialUserStatic } from "../userStatic.js";
import { deleteUser, getCurrentUser, postUser, putUser } from "../../../../Actions/UsersActions.js";
import { onInputTextAreaChange } from "../../scripts/PersonalAreaScripts.js";
import "./styles/EditUsers.scss";
import { postUploadImages } from "../../../../Actions/videos-actions/VideosActions.js";
import UniversalDeleteModal from "../../../universal/UniversalDeleteModal.jsx";
import Select from "react-select";
import { customStyles } from "../../static.js";
import { getAllActiveOwner } from "../../../../Actions/OwnerActions.js";
import EditUserSidebar from "./EditUserSidebar.jsx";
import { useUserAccessContext } from "../../context/UserAccessContext.js";
import { checkUserWritePerm } from "../../scripts/utils.js";
import { Input } from "../../../../ui-kit/input/Input.jsx";
import { Cluster } from "../../../../ui-kit/layouts/Cluster.jsx";
import { Stack } from "../../../../ui-kit/layouts/Stack.jsx";

const EditUsers = ({
	getCurrentUser,
	currentUser,
	postUser,
	putUser,
	uploadImage,
	postUploadImages,
	title,
	getAllActiveOwner,
	deleteUser,
	ownersList,
}) => {
	const navigate = useNavigate();
	const location = useLocation();
	const { userAccessState } = useUserAccessContext();
	const [mainUserState, setMainUserState] = useState(initialUserStatic);
	const [errorState, setErrorState] = useState(false);
	const [uploadFlagState, setUploadFlagState] = useState(() => false); // т.к. общее состояние аплоадов, делаю флажок: к хранилищу доступ только при поднятом флаге

	// т.е. пока false он даже не смотрит на аплоад
	useEffect(() => {
		document.title = title;
	}, [title]);
	useEffect(() => {
		getAllActiveOwner();
		if (location.pathname !== "/newuser") {
			getCurrentUser(location.search.split("=")[1]);
		}
	}, []);
	const goBack = () => {
		setMainUserState(initialUserStatic);
		navigate(-1);
	};

	useEffect(() => {
		if (
			currentUser.length !== 0 &&
			(location.pathname === "/newuser" || location.search.split("=")[1] == currentUser.id)
		) {
			setMainUserState({ ...currentUser, password: null });
		}
	}, [currentUser]);

	const handleAccessCheckClick = (value, key) => {
		if ((mainUserState[value] === 1 && key === 1) || (mainUserState[value] === 2 && key === 2)) {
			setMainUserState({ ...mainUserState, [value]: 0 });
		} else if (key === 1) {
			setMainUserState({ ...mainUserState, [value]: 1 });
		} else if (key === 2) {
			setMainUserState({ ...mainUserState, [value]: 2 });
		}
	};

	useEffect(() => {
		if (!!uploadImage.filename && uploadImage.filename.length > 0 && uploadFlagState) {
			setMainUserState({ ...mainUserState, avatar_url: uploadImage.filename });
			setUploadFlagState(false);
		}
	}, [uploadImage]);
	return (
		<section className="personal-area-item edit">
			<header className="personal-area edit-videos-header" style={{ marginBottom: "0" }}>
				<button className="form-page-back" onClick={() => goBack()}>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M11.9142 7.50015L11.2071 8.20726L8.41421 11.0002H18H19V13.0002H18H8.41421L11.2071 15.793L11.9142 16.5002L10.5 17.9144L9.79289 17.2073L5.29289 12.7073C4.90237 12.3167 4.90237 11.6836 5.29289 11.293L9.79289 6.79304L10.5 6.08594L11.9142 7.50015Z"
							fill="#94A0B1"
						/>
					</svg>
					Вернуться назад
				</button>
				{location.pathname === "/newuser" || location.search.split("=")[1] == mainUserState.id ? (
					<h1>
						{mainUserState.first_name !== null && mainUserState.second_name !== null
							? `${mainUserState.first_name} ${mainUserState.second_name}`
							: "Новый пользователь"}
					</h1>
				) : null}
			</header>
			{location.pathname === "/newuser" || location.search.split("=")[1] == mainUserState.id ? (
				<div className="form-container-blocks">
					<div className="form-container first-col">
						<div className="form-container">
							<div className="form-container-block">
								<div className="form-container-block-title">
									<b>Профиль</b>
								</div>
								<Stack space="24px">
									<Cluster space="16px">
										<Input
											label="Имя"
											placeholder=""
											value={mainUserState.first_name !== null ? mainUserState.first_name : ""}
											error={errorState && mainUserState.first_name.length === 0 ? true : false}
											disabled={!checkUserWritePerm(userAccessState, "users")}
											onChange={(e) =>
												onInputTextAreaChange(e, "first_name", mainUserState, setMainUserState)
											}
										/>
										<Input
											label="Фамилия"
											placeholder=""
											value={mainUserState.second_name !== null ? mainUserState.second_name : ""}
											error={errorState && mainUserState.first_name.length === 0 ? true : false}
											disabled={!checkUserWritePerm(userAccessState, "users")}
											onChange={(e) =>
												onInputTextAreaChange(e, "second_name", mainUserState, setMainUserState)
											}
										/>
									</Cluster>
									<Cluster space="16px">
										<Input
											label="Логин"
											disabled={!checkUserWritePerm(userAccessState, "users")}
											error={errorState && mainUserState.first_name.length === 0 ? true : false}
											placeholder="Задайте логин"
											value={mainUserState.email !== null ? mainUserState.email : ""}
											className={errorState && mainUserState.email.length === 0 ? "error" : ""}
											onChange={(e) =>
												onInputTextAreaChange(e, "email", mainUserState, setMainUserState)
											}
										/>
										<Input
											label="Пароль"
											// disabled={!checkUserWritePerm(userAccessState, "users")}
											error={errorState && mainUserState.first_name.length === 0 ? true : false}
											placeholder="Придумайте пароль"
											value={mainUserState.password !== null ? mainUserState.password : ""}
											type="password"
											className={errorState && mainUserState?.password?.length === 0 ? "error" : ""}
											onChange={(e) =>
												onInputTextAreaChange(e, "password", mainUserState, setMainUserState)
											}
										/>
									</Cluster>
								</Stack>
							</div>
							<div className="form-container-block">
								<div className="form-container-block-title">
									<b>Правообладатель</b>
								</div>
								<div className="flex-block flex-start">
									<div className="form-field">
										<div className="form-field-label">Имя</div>
										<div className="input-field2">
											<Select
												isDisabled={!checkUserWritePerm(userAccessState, "users")}
												name="tip-gender"
												value={
													ownersList.list.find((elem) => elem.id == mainUserState.owner) ||
													ownersList.list?.[0]
												}
												options={ownersList.list}
												styles={customStyles}
												onChange={(newValue) =>
													setMainUserState({ ...mainUserState, owner: newValue.id })
												}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
												placeholder="Выберите правообладателя"
												isSearchable={true}
												className="search-wrapper side-filters_city"
												classNamePrefix="select"
												noOptionsMessage={({ inputValue }) =>
													!inputValue ? "Загрузка" : "Нет значений"
												}
											/>
										</div>
									</div>
								</div>
							</div>
							{mainUserState.owner == 1 ? (
								<div className="form-container-block">
									<div className="flex-block flex-start no-gap">
										<div
											className="form-container-block-title"
											style={{ width: "196px", marginBottom: "0px" }}
										>
											<b style={{ marginBottom: "0px" }}>Права доступа</b>
										</div>
										<div
											className="form-container-block-title"
											style={{ width: "100px", marginBottom: "0px" }}
										>
											Просмотр
										</div>
										<div
											className="form-container-block-title"
											style={{ width: "100px", marginBottom: "0px" }}
										>
											Редактирование
										</div>
									</div>

									<div className="flex-block flex-start no-gap">
										<div className="flex-block-column">
											<PermissionSelectField
												disabled={!checkUserWritePerm(userAccessState, "users")}
												title="Типсы"
												mainUserState={mainUserState}
												onSelect={handleAccessCheckClick}
												keyValue="subparam_1"
											/>
											<PermissionSelectField
												disabled={!checkUserWritePerm(userAccessState, "users")}
												title="Видео"
												mainUserState={mainUserState}
												onSelect={handleAccessCheckClick}
												keyValue="subparam_2"
											/>
											<PermissionSelectField
												disabled={!checkUserWritePerm(userAccessState, "users")}
												title="Статистика"
												mainUserState={mainUserState}
												onSelect={handleAccessCheckClick}
												keyValue="subparam_3"
											/>
											<PermissionSelectField
												disabled={!checkUserWritePerm(userAccessState, "users")}
												title="Блогеры"
												mainUserState={mainUserState}
												onSelect={handleAccessCheckClick}
												keyValue="subparam_11"
											/>
											<PermissionSelectField
												disabled={!checkUserWritePerm(userAccessState, "users")}
												title="Рекламодатели"
												mainUserState={mainUserState}
												onSelect={handleAccessCheckClick}
												keyValue="subparam_4"
											/>
											<PermissionSelectField
												disabled={!checkUserWritePerm(userAccessState, "users")}
												title="Зрители"
												mainUserState={mainUserState}
												onSelect={handleAccessCheckClick}
												keyValue="subparam_5"
											/>
											<PermissionSelectField
												disabled={!checkUserWritePerm(userAccessState, "users")}
												title="Правообладатели"
												mainUserState={mainUserState}
												onSelect={handleAccessCheckClick}
												keyValue="subparam_12"
											/>
											<PermissionSelectField
												disabled={!checkUserWritePerm(userAccessState, "users")}
												title="Категории типсов"
												mainUserState={mainUserState}
												onSelect={handleAccessCheckClick}
												keyValue="subparam_6"
											/>
											<PermissionSelectField
												disabled={!checkUserWritePerm(userAccessState, "users")}
												title="Плейлисты"
												mainUserState={mainUserState}
												onSelect={handleAccessCheckClick}
												keyValue="subparam_7"
											/>
											<PermissionSelectField
												disabled={!checkUserWritePerm(userAccessState, "users")}
												title="Цены"
												mainUserState={mainUserState}
												onSelect={handleAccessCheckClick}
												keyValue="subparam_8"
											/>
											<PermissionSelectField
												disabled={!checkUserWritePerm(userAccessState, "users")}
												title="Политика"
												mainUserState={mainUserState}
												onSelect={handleAccessCheckClick}
												keyValue="subparam_9"
											/>
											<PermissionSelectField
												// disabled={!checkUserWritePerm(userAccessState, "users")}
												title="Пользователи"
												mainUserState={mainUserState}
												onSelect={handleAccessCheckClick}
												keyValue="subparam_10"
											/>

											<PermissionSelectField
												disabled={!checkUserWritePerm(userAccessState, "users")}
												title="Жалобы"
												mainUserState={mainUserState}
												onSelect={handleAccessCheckClick}
												keyValue="subparam_13"
											/>
										</div>
									</div>
								</div>
							) : null}
						</div>
					</div>
					<div className="form-container-right second-col">
						<EditUserSidebar
							mainUserState={mainUserState}
							setMainUserState={setMainUserState}
							setErrorState={setErrorState}
						/>
					</div>
				</div>
			) : null}
		</section>
	);
};

const PermissionSelectField = ({ title, mainUserState, keyValue, onSelect, disabled = false }) => {
	return (
		<div className="flex-block-column-label">
			<span>{title}</span>
			<div
				className={`check-button-item ${mainUserState[keyValue] === 1 ? "selected" : ""} ${
					disabled ? "disabled" : ""
				}`}
				style={{ width: "100px" }}
				onClick={() => !disabled && onSelect(keyValue, 1)}
			>
				<span></span>
			</div>
			<div
				className={`check-button-item ${mainUserState[keyValue] === 2 ? "selected" : ""} ${
					disabled ? "disabled" : ""
				}`}
				style={{ width: "100px" }}
				onClick={() => !disabled && onSelect(keyValue, 2)}
			>
				<span></span>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		loadingStatus: state.loadingStatus,
		currentUser: state.currentUser,
		uploadImage: state.uploadImage,
		ownersList: state.ownersList.active,
	};
};

const mapDispatchToProps = {
	getCurrentUser,
	postUser,
	putUser,
	postUploadImages,
	deleteUser,
	getAllActiveOwner,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditUsers);
