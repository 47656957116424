import styled from "styled-components";
import { DTBold15, DText15 } from "../../ui-kit/typography/Typography";

export const CategoryContentWrapper = styled.div`
	display: flex;
	left: 27px;
	margin: 24px 0px;
	height: calc(100% - 114px);
	width: calc(100%);
`;

export const CategoryColumnWrapper = styled.div`
	border-right: 1px solid #e2e8f0;
	box-sizing: border-box;
	display: block;
	height: 100%;
	overflow-y: scroll;
	padding-left: 5px;
	position: relative;
	width: 20%;
	&::-webkit-scrollbar {
		width: 4px;
	}
	&::-webkit-scrollbar-thumb {
		background: rgba(148, 160, 177, 0.3);
		border-radius: 2px;
	}
	&::-webkit-scrollbar-track {
		background: none;
	}
`;

export const ParentElement = styled.div`
	border-radius: 8px;
	cursor: pointer;
	display: flex;
	align-items: center;
	font-size: 15px;
	font-weight: 600;
	line-height: 21px;
	margin-right: 5px;
	padding: 10px 16px 10px 12px;
	position: relative;
	span {
		display: flex;
	}
	justify-content: space-between;
	.actions-wrapper {
		width: 24px;
		display: flex;
		visibility: hidden;
	}
	&.selected {
		background: #e2e8f0;
		color: #0052e4;
		.actions-wrapper {
			visibility: visible;
			width: 24px;
			display: flex;
			&.hidden {
				visibility: hidden;
			}
		}
	}
	&:hover {
		color: #0052e4;
	}
`;

export const ChildElement = styled.div`
	border-radius: 8px;
	color: #94a0b1;
	cursor: pointer;
	font-size: 15px;
	font-weight: 400;
	line-height: 21px;
	margin-right: 5px;
	padding: 10px 12px;
	position: relative;
	justify-content: space-between;
	display: flex;
	align-items: center;
	.actions-wrapper {
		visibility: hidden;
	}
	&:hover {
		color: #0052e4;
		.actions-wrapper {
			visibility: visible;
			width: 64px;
			display: flex;
			&.hidden {
				visibility: hidden;
			}
		}
	}
`;

export const FilteredItemWrapper = styled.div`
	border-bottom: 1px solid #e2e8f0;
	cursor: pointer;
	display: block;
	font-size: 15px;
	font-weight: 400;
	line-height: 21px;
	margin-right: 5px;
	padding: 10px 12px;
	position: relative;
	&:hover {
		background: #f4f4f8;
		${DTBold15} {
			color: #0052e4;
		}
	}
	${DText15} {
		color: #94a0b1;
	}
`;

export const FilteredItemsContainer = styled.div`
	width: 100%;
	height: 100%;
	overflow: auto;
	padding-bottom: 100px;
	&::-webkit-scrollbar {
		width: 4px;
	}
	&::-webkit-scrollbar-thumb {
		background: rgba(148, 160, 177, 0.3);
		border-radius: 2px;
	}
	&::-webkit-scrollbar-track {
		background: none;
	}
`;