const initialFormatsArray = [
	{
		id: 1,
		title: "Наши фильмы",
	},
	{
		id: 2,
		title: "Наши сериалы",
	},
	{
		id: 3,
		title: "Видео блогера",
	},
	{
		id: 4,
		title: "Shorts",
	},
	// {
	// id: 5,
	// title: "live",
	// },
	{
		id: 6,
		title: "Партнерские фильмы",
	},
	{
		id: 7,
		title: "Партнерские сериалы",
	},
];

export const VideoFormatsReducer = (state = [], action) => {
	switch (action.type) {
		case "GET_VIDEO_FORMATS":
			return initialFormatsArray;
			return action.videoFormat.data;
		default:
			return state;
	}
};
