import styled from "styled-components";
import * as React from "react";

export const RowWrapper = styled.div`
	display: flex;
	gap: 24px;
	width: 100%;
	padding: 14px 0;
	align-items: center;
`;

export const RowTitle = styled.div`
	font-style: normal;
	font-weight: 400;
	font-size: 13px;
	color: #94a0b1;
	width: 100%;
`;

export const RowValue = styled.div`
	font-style: normal;
	font-weight: 400;
	font-size: 13px;
	color: #212121;
	width: 100%;
`;

export const SellerInfoRow = ({ title, value }) => {
	return (
		<RowWrapper>
			<RowTitle style={{userSelect: "text"}}>{title}</RowTitle>
			<RowValue style={{userSelect: "text"}}>{value}</RowValue>
		</RowWrapper>
	);
};
