import { compareSort } from "./reducersScript";

const initialUpload = {
	filename: "",
};

const initialVideos = {
	list: [],
	pagination: {},
};

const EMPTY_VIDEO = {
	list: [],
	pagination: {
		max_elements: 0,
		max_pages: 0,
		page: 0,
		limit: 0,
	},
};

const VideosReducer = (video = initialVideos, action) => {
	switch (action.type) {
		case "GET_VIDEOS":
			const data = action.video.data;
			return {
				list: data.movies,
				pagination: {
					max_elements: data.paginate_.total_rows,
					max_pages: data.paginate_.total_pages,
					page: data.paginate_.page_in.page,
					limit: data.paginate_.offset_in.limit,
				},
			};
		case "GET_EMPTY_VIDEOS":
			return EMPTY_VIDEO;
		case "GET_FULL_VIDEOS":
			return {
				...video,
				list: action.video.data,
			};

		case "PUT_VIDEO_LIST":
			return {
				...video,
				list: (video.list = video.list.map((item, index) =>
					item.id === action.video.id ? { ...item, published: action.video.published } : item
				)),
			};
		case "DELETE_CURRENT_VIDEO":
			return {
				...video,
				list: video.list.filter((item, index) => item.id !== action.payload),
			};

		default:
			return video;
	}
};

const VideoGenresReducer = (videoGenres = [], action) => {
	switch (action.type) {
		case "GET_VIDEO_GENRES":
			return action.videoGenres.data;
		default:
			return videoGenres;
	}
};

const VideoAgesReducer = (videoAges = [], action) => {
	switch (action.type) {
		case "GET_VIDEO_AGES":
			return action.videoAges.data;
		default:
			return videoAges;
	}
};

const UploadImageReducer = (uploadImg = initialUpload, action) => {
	switch (action.type) {
		case "POST_UPLOAD_IMG":
			return action.uploadImg.data;
		case "RESET_UPLOAD":
			return initialUpload;
		default:
			return uploadImg;
	}
};

const UploadGalleryImageReducer = (uploadImg = initialUpload, action) => {
	switch (action.type) {
		case "POST_UPLOAD_GALLERY_IMG":
			return action.uploadImg.data;
		case "RESET_UPLOAD":
			return initialUpload;
		default:
			return uploadImg;
	}
};

const VideoHashtagsReducer = (hashtags = [], action) => {
	switch (action.type) {
		case "GET_VIDEO_HASHTAGS":
			return action.hashtags.data;
		default:
			return hashtags;
	}
};

const VideoFilmCrewReducer = (filmCrew = [], action) => {
	switch (action.type) {
		case "GET_FILM_CREW":
			return action.filmCrew.data;
		default:
			return filmCrew;
	}
};

const VideoSingleFilmCrewReducer = (filmCrew = [], action) => {
	switch (action.type) {
		case "PUT_FILM_CREW":
			return action.filmCrew.data;
		default:
			return filmCrew;
	}
};

const VideoCurrentReducer = (video = [], action) => {
	let timeArray = [];
	switch (action.type) {
		case "GET_CURRENT_VIDEO":
			if (action.video.data.movie_format?.id === 2) {
				timeArray = action.video.data?.childs;
				timeArray && timeArray.sort(compareSort);
				return { ...action.video.data, childs: timeArray };
			}
			return action.video.data;
		case "PUT_VIDEO":
			if (!!action.video.data?.childs) {
				timeArray = action.video.data?.childs;
				timeArray.sort(compareSort);
				timeArray.map((elem) =>
					elem.childs.length > 0 ? { ...elem, childs: elem.childs.sort(compareSort) } : elem
				);
				return { ...action.video.data, childs: timeArray };
			} else {
				return action.video.data;
			}
		case "POST_VIDEOS":
			return action.video.data;

		case "RESET_VIDEO":
			return [];
		default:
			return video;
	}
};

const VideoCurrentAdditionalReducer = (video = [], action) => {
	let timeArray = [];
	switch (action.type) {
		case "GET_CURRENT_ADDITIONAL_VIDEO":
			return action.video.data;
		case "DELETE_SEASON":
			timeArray = video.seasons;
			let newArray = timeArray.filter((item, index) => item.id !== action.payload);
			newArray.sort(compareSort);
			return {
				...video,
				seasons: newArray,
			};
		case "POST_SEASON":
			timeArray = video.seasons;
			timeArray.push(action.season.data);
			return {
				...video,
				seasons: timeArray,
			};
		case "PUT_SEASON":
			timeArray = video.seasons.map((item) =>
				item.id === action.season.data.id ? (item = action.season.data) : item
			);
			timeArray.sort(compareSort);
			return {
				...video,
				seasons: timeArray,
			};
		case "POST_SERIES":
			let postSeasonId = action.payload.seasonId;
			timeArray = video.seasons;
			const postActualSeasonIndex = timeArray.findIndex((season) => season.id === postSeasonId);
			timeArray[postActualSeasonIndex].episodes.push(action.payload.series.data);
			return {
				...video,
				seasons: timeArray,
			};
		case "PUT_SERIES":
			let putSeasonId = action.series.data.season_id;
			timeArray = video.seasons;
			let putActualSeasonIndex = timeArray.findIndex((season) => season.id === putSeasonId);
			const newTimeArray = timeArray[putActualSeasonIndex].episodes.map((elem) =>
				elem.id == action.series.data.id ? (elem = action.series.data) : elem
			);
			timeArray[putActualSeasonIndex].episodes = newTimeArray;
			return {
				...video,
				seasons: timeArray,
			};
		case "DELETE_SERIES":
			let deleteSeasonId = action.payload.season_id;
			timeArray = video.seasons;
			const deleteActualSeasonIndex = timeArray.findIndex((season) => season.id === deleteSeasonId);
			timeArray[deleteActualSeasonIndex].episodes = timeArray[
				deleteActualSeasonIndex
			].episodes.filter((item, index) => item.id !== action.payload.id);
			timeArray[deleteActualSeasonIndex].episodes
				.sort(compareSort)
				.map((elem, index) => (elem.sort = index + 1));
			return {
				...video,
				seasons: timeArray,
			};
		case "RESET_VIDEO":
			return [];
		default:
			return video;
	}
};

const SerialCurrentAdditionalReducer = (video = [], action) => {
	let timeArray = [];
	switch (action.type) {
		case "GET_CURRENT_ADDITIONAL_SERIAL":
			return action.video.data;
		case "RESET_VIDEO":
			return [];
		default:
			return video;
	}
};

export {
	VideosReducer,
	VideoGenresReducer,
	VideoAgesReducer,
	UploadImageReducer,
	UploadGalleryImageReducer,
	VideoHashtagsReducer,
	VideoFilmCrewReducer,
	VideoCurrentReducer,
	VideoSingleFilmCrewReducer,
	VideoCurrentAdditionalReducer,
	SerialCurrentAdditionalReducer,
};
