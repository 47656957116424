import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { ModalContext } from "../../components/modal/ModalContext/ModalContext";
import { AttributesModal } from "../attribute-modal/AttributesModal";
import { Stack } from "../../ui-kit/layouts/Stack";
import { sortStringArrByAlphabet } from "../../components/PersonalArea/scripts/utils.js";

export const AttributeSelectWrapper = styled.div`
	background: #f4f4f8;
	border: 1px solid #f4f4f8;
	border-radius: 8px;
	box-sizing: border-box;
	color: #212121;
	font-family: "Averta CY";
	font-size: 15px;
	height: auto;
	min-height: 54px;
	outline: none;
	padding: 8px 80px 8px 16px;
	position: relative;
	width: 100% !important;
	display: flex;
	cursor: pointer;
	&:hover {
		border: 1px solid #d1d8e1;
	}
	&:hover {
		border: 1px solid #d1d8e1;
	}
	align-items: center;
	svg {
		position: absolute;
		right: 12px;
		top: calc(50% - 12px);
	}
	span.category-value {
		display: block;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		font-size: 15px;
	}
`;

const AttrLabel = styled.span`
	color: #94a0b1;
	font-weight: 400;
	font-size: 15px;
`;
const AttrTitle = styled.span`
	color: #212121;
	font-family: Averta CY;
	font-size: 15px;
`;

export const AttributeSelect = ({
	selectedAttrs = [],
	parentCollection,
	collection,
	onSelect,
	onChangedAttrSelect,
}) => {
	const [displayValues, setDisplayValues] = useState({});
	const { handleModal } = useContext(ModalContext);
	const [selected, setSelected] = useState();
	const [collectionState, setCollectionState] = useState([]);
	useEffect(() => {
		setSelected(selectedAttrs);
		let attrMap = {};
		parentCollection.forEach((elem) => {
			attrMap = { ...attrMap, [elem.id]: { title: elem.title, childs: [] } };
		});
		selectedAttrs.forEach((elem) => {
			if (elem.frame_id in attrMap) {
				attrMap = {
					...attrMap,
					[elem.frame_id]: {
						...attrMap[elem.frame_id],
						childs: [...attrMap[elem.frame_id].childs, elem.title],
					},
				};
			}
		});
		setDisplayValues(attrMap);
	}, [selectedAttrs, parentCollection]);

	useEffect(() => {
		setCollectionState(collection);
	}, [collection]);

	return (
		<AttributeSelectWrapper
			onClick={() =>
				handleModal(
					<AttributesModal
						onChangedAttrSelect={onChangedAttrSelect}
						collection={collectionState}
						selected={selected}
						onSelect={onSelect}
					/>
				)
			}
		>
			<Stack>
				{Object.keys(displayValues)
					.sort((a, b) => {
						if (displayValues[a].title.toLowerCase() < displayValues[b].title.toLowerCase()) {
							return -1;
						}
						if (displayValues[a].title.toLowerCase() > displayValues[b].title.toLowerCase()) {
							return 1;
						}
						return 0;
					})
					.map((key) => {
						if (!displayValues[key].childs.length) return null;

						return (
							<div className="category-value" key={key}>
								<AttrLabel>{displayValues[key].title}: </AttrLabel>
								<AttrTitle>
									{sortStringArrByAlphabet(displayValues[key].childs || []).join(", ")}
								</AttrTitle>
							</div>
						);
					})}
			</Stack>
			<button
				type="button"
				className="icon-button icon-btn icon-btn--xxs"
				style={{ position: "absolute", right: "16px", top: "50%" }}
			>
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M2 7H5V5H2L2 7ZM8 7L22 7V5L8 5V7ZM8 13L22 13V11L8 11V13ZM22 19H8V17H22V19ZM5 13H2V11H5V13ZM2 19H5L5 17H2L2 19Z"
						fill="#212121"
					></path>
				</svg>
			</button>
		</AttributeSelectWrapper>
	);
};
