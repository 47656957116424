import React, { useContext, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { getBrightboxes } from "../../../../Actions/BrightboxesActions.js";
import BrightboxesFiltersComponent from "../filters/AtipsBrightboxesFiltersComponent.jsx";
import { Link, useSearchParams } from "react-router-dom";
import { handleFilteredClick } from "../../scripts/PersonalAreaScripts.js";
import PaginationBlock from "../../../universal/PaginationBlock/PaginationBlock.jsx";

import { history } from "../../../../Store/Store.ts";
import { removeEmpty } from "../../../../Actions/ActionsScirpts.js";
import { DText15 } from "../../../../ui-kit/typography/Typography.jsx";
import { ModalContext } from "../../../modal/ModalContext/ModalContext";
import { useQuery } from "@apollo/client";
import { GET_TIPS_CLICK_SHOW } from "../../../../GraphQL/TipsPage.js";
import TipEditModal from "../modals/tips-edit-modal/TipEditModal.jsx";
import axios from "axios";
import { baseUrl } from "../../../../Actions/consts.js";
import { VideoPreviewModal } from "../modals/VideoPreviewModal.jsx";
import styled from "styled-components";
import { checkUserWritePerm } from "../../scripts/utils.js";
import { useUserAccessContext } from "../../context/UserAccessContext.js";

const StyledProdPreview = styled.img`
	width: 32px;
	height: 32px;
	margin: auto 0;
	border-radius: 8px;
`;

const OwnerBrightboxes = ({ brightboxesList, getBrightboxes }) => {
	const [timeCodeState, setTimeCodeState] = useState();
	const [tipEditOpened, setTipEditOpened] = useState(false);
	const [actualTipEditState, setActualTipEditState] = useState({});
	const [actualSrcEditState, setActualSrcEditState] = useState("");

	const [searchParams, setSearchParams] = useSearchParams();
	const [mainBrightboxState, setMainBrightboxState] = useState([]);
	const [firstLoad, setFirstLoad] = useState(true);
	const [lastSearch, setLastSearch] = useState(true);
	const { userAccessState } = useUserAccessContext();

	const [mainFilterState, setMainFilterState] = useState({
		filters:
			searchParams.get("filters") !== null
				? JSON.parse(searchParams.get("filters"))
				: { is_atips: false, is_creator: false },
		limit: searchParams.get("limit") !== null ? searchParams.get("limit") : 15,
		offset: searchParams.get("offset") !== null ? searchParams.get("offset") : 0,
	});

	useEffect(() => {
		return history.listen((location, action) => {
			if (action === "POP") {
				const params = new URLSearchParams(location.search);
				setLastSearch(false);
				setMainFilterState({
					filters:
						params.get("filters") !== null
							? JSON.parse(params.get("filters"))
							: { is_atips: false, is_creator: false },
					offset: params.get("offset") !== null ? params.get("offset") : 0,
					limit: params.get("limit") !== null ? params.get("limit") : 15,
				});
			}
		});
	}, [history]);

	useEffect(() => {
		const filteredState = removeEmpty(mainFilterState);
		const filteredFilters = removeEmpty(filteredState.filters);
		getBrightboxes({ ...filteredState });
		if (!firstLoad && lastSearch) {
			setSearchParams({
				...filteredState,
				filters: JSON.stringify({ ...filteredFilters }),
			});
		} else if (!lastSearch) {
			setLastSearch(true);
		}
		setFirstLoad(false);
	}, [mainFilterState]);

	const refreshList = () => {
		const filteredState = removeEmpty(mainFilterState);
		const filteredFilters = removeEmpty(filteredState.filters);
		getBrightboxes({ ...filteredState });
		if (!firstLoad && lastSearch) {
			setSearchParams({
				...filteredState,
				filters: JSON.stringify({ ...filteredFilters }),
			});
		} else if (!lastSearch) {
			setLastSearch(true);
		}
	};

	useEffect(() => {
		setMainBrightboxState(brightboxesList?.list);
	}, [brightboxesList]);
	const handleOpenModalClick = (elem, src, timeCode) => {
		setTipEditOpened(true);
		setActualTipEditState(elem);
		setActualSrcEditState(src);
		setTimeCodeState(timeCode);
	};
	return mainBrightboxState?.length > 0 ? (
		<>
			<div className="videos-content-wrapper content-wrapper owner">
				<BrightboxesFiltersComponent
					paramsState={mainFilterState}
					setParamsState={setMainFilterState}
				/>
				<div className="list-table-header brightbox-list owner">
					<div
						className="list-table-header-element"
						onClick={() => handleFilteredClick("sort", "id", mainFilterState, setMainFilterState)}
					>
						<span>ID</span>
					</div>
					<div
						className="list-table-header-element"
						onClick={() =>
							handleFilteredClick("sort", "movie_title", mainFilterState, setMainFilterState)
						}
					>
						<span>Видео</span>
					</div>
					<div
						className="list-table-header-element non-sort"
						onClick={() =>
							handleFilteredClick("sort", "video_format_id", mainFilterState, setMainFilterState)
						}
					>
						<span>Правообладатель</span>
					</div>
					<div
						className="list-table-header-element"
						onClick={() =>
							handleFilteredClick("sort", "genres", mainFilterState, setMainFilterState)
						}
					>
						<span>Жанр</span>
					</div>
					<div
						className="list-table-header-element"
						onClick={() =>
							handleFilteredClick("sort", "category", mainFilterState, setMainFilterState)
						}
					>
						<span>Категория</span>
					</div>
					<div
						className="list-table-header-element"
						onClick={() => handleFilteredClick("sort", "show", mainFilterState, setMainFilterState)}
					>
						<span>Показы</span>
					</div>
					<div
						className="list-table-header-element"
						onClick={() =>
							handleFilteredClick("sort", "click", mainFilterState, setMainFilterState)
						}
					>
						<span>Клики</span>
					</div>
					<div
						className="list-table-header-element"
						onClick={() => handleFilteredClick("sort", "sum", mainFilterState, setMainFilterState)}
					>
						<span>Доход, ₽</span>
					</div>
					<div className="list-table-header-element non-sort">
						<span></span>
					</div>
					<div className="list-table-header-element non-sort">
						<span></span>
					</div>
					{checkUserWritePerm(userAccessState, "brightboxes") ? (
						<div className="list-table-header-element non-sort">
							<span></span>
						</div>
					) : null}
				</div>
				{mainBrightboxState.length > 0 ? (
					<div className="list-table brightbox-list owner">
						{mainBrightboxState.map((box, index) => (
							<TipsItem box={box} key={box.id} handleOpenModalClick={handleOpenModalClick} />
						))}
					</div>
				) : null}
			</div>
			<div id="fixed-statistics-itog" className="animte-menu">
				<div className="fixed-staistics-data">
					<span>Всего: {brightboxesList?.pagination.max_elements || 0}</span>
				</div>
				<div className="fixed-staistics-data">
					<span>Сумма: {(brightboxesList?.pagination.sum || 0).toLocaleString("ru-RU")} ₽</span>
				</div>
			</div>
			{Object.keys(brightboxesList.pagination).length !== 0 &&
			!!brightboxesList.pagination.max_pages &&
			mainBrightboxState.length > 0 &&
			brightboxesList.pagination.max_pages > 1 ? (
				<PaginationBlock
					filterState={mainFilterState}
					setFilterState={setMainFilterState}
					pagination={brightboxesList.pagination}
				/>
			) : null}
			{tipEditOpened && actualTipEditState ? (
				<TipEditModal
					opened={tipEditOpened}
					onClose={() => (setTipEditOpened(false), setActualTipEditState(null))}
					tips={actualTipEditState}
					onUpdateList={() => refreshList()}
					src={actualSrcEditState}
					timeCodeState={timeCodeState}
				/>
			) : null}
		</>
	) : (
		<DText15 style={{ color: "#94A0B1" }}>Пока нет типсов</DText15>
	);
};

const TipsItem = ({ box, handleOpenModalClick }) => {
	const { id, base_positions, video_id } = box;
	const { handleModal, modal } = useContext(ModalContext);
	const { userAccessState } = useUserAccessContext();
	const [ownerState, setOwnerState] = useState(null);
	const dispatch = useDispatch();
	const { data } = useQuery(GET_TIPS_CLICK_SHOW, {
		variables: {
			tips_id: id,
		},
	});
	const [urlState, setUrlState] = useState(null);
	const tipPosition = JSON.parse(base_positions);
	const timeCode = tipPosition.sec + 1;

	useEffect(async () => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.get(`${baseUrl}/video/${video_id}`)
			.then((video) => {
				if (video.data?.URL_SD_SOURCE) {
					let url = video.data?.URL_SD_SOURCE;
					url = url.split("/");
					url.pop();
					url = url.join("/");
					setUrlState(url);
				}
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.get(`${baseUrl}/owner/${box.owner}`)
			.then((owner) => {
				setOwnerState(owner.data);
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	}, [box]);
	return (
		<Link className="list-table-item action-button owner" to={`/statistic?id=${box.id}`}>
			<div className="list-table-item-element">
				<span>{id}</span>
			</div>
			<div className="list-table-item-element">
				<span>{box.video?.title}</span>
			</div>
			<div className="list-table-item-element">
				<span>{ownerState?.name || "Загрузка.."}</span>
			</div>
			<div className="list-table-item-element">
				<span>
					{box?.genres?.map((gen, index) => (index >= 1 ? `, ${gen?.title}` : gen?.title))}
				</span>
			</div>
			<div className="list-table-item-element">
				<span>{box.category?.title || "Нет значения"}</span>
			</div>
			<div className="list-table-item-element">
				<span>{(data && data.show.pageInfo.itemsCount) || 0}</span>
			</div>
			<div className="list-table-item-element">
				<span>{(data && data.click.pageInfo.itemsCount) || 0}</span>
			</div>
			<div className="list-table-item-element">
				<span>{(data && data.click.aggregation.total_sum) || 0 || 0}</span>
			</div>
			<div className="list-table-item-element">
				{box?.predefined_products?.map((gen, index) => (
					<StyledProdPreview key={index} src={gen?.URL_HD_IMAGE}></StyledProdPreview>
				))}
			</div>
			<div className="list-table-item-element">
				{urlState ? (
					<div
						className="list-table-item-edit action-button"
						onClick={(e) => (
							e.preventDefault(),
							e.stopPropagation(),
							handleModal(
								<VideoPreviewModal
									isEditable={checkUserWritePerm(userAccessState, "brightboxes")}
									src={`${urlState}/${urlState?.split("/")?.[5]}.720x480.mp4`}
									onEdit={() =>
										handleOpenModalClick(
											box,
											urlState ? `${urlState}/${urlState?.split("/")?.[5]}.720x480.mp4` : "",
											timeCode
										)
									}
									timeCode={timeCode}
								/>
							)
						)}
					>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<g clipPath="url(#clip0_1442_3428)">
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M4 0C1.79086 0 0 1.79086 0 4V18.5008C0 18.5009 0 18.5009 0 18.5009V20C0 22.2091 1.79086 24 4 24H20C22.2091 24 24 22.2091 24 20V9.88069L24.1248 9.78087C24.556 9.43586 24.626 8.80657 24.281 8.37531C24.2007 8.27495 24.105 8.19416 24 8.13362V4C24 1.79086 22.2091 0 20 0H4ZM22 8.91944V4C22 2.89543 21.1046 2 20 2H4C2.89543 2 2 2.89543 2 4V16.5794L5.42662 14.1808C5.79026 13.9262 6.27817 13.9418 6.62478 14.2191L11.0001 17.7194L22 8.91944ZM2 19.0207V20C2 21.1046 2.89543 22 4 22H20C21.1046 22 22 21.1046 22 20V11.4807L11.6248 19.7809C11.2596 20.073 10.7406 20.073 10.3754 19.7809L5.96011 16.2486L2 19.0207ZM10.0001 10C11.1047 10 12.0001 9.10457 12.0001 8C12.0001 6.89543 11.1047 6 10.0001 6C8.89552 6 8.00009 6.89543 8.00009 8C8.00009 9.10457 8.89552 10 10.0001 10ZM10.0001 12C12.2092 12 14.0001 10.2091 14.0001 8C14.0001 5.79086 12.2092 4 10.0001 4C7.79095 4 6.00009 5.79086 6.00009 8C6.00009 10.2091 7.79095 12 10.0001 12Z"
									fill="#94A0B1"
								/>
							</g>
							<defs>
								<clipPath id="clip0_1442_3428">
									<rect width="24" height="24" fill="white" />
								</clipPath>
							</defs>
						</svg>
					</div>
				) : null}
			</div>
			{checkUserWritePerm(userAccessState, "brightboxes") ? (
				<div className="list-table-item-element">
					<div
						className="list-table-item-edit action-button"
						onClick={(e) => (
							e.preventDefault(),
							e.stopPropagation(),
							handleOpenModalClick(
								box,
								urlState ? `${urlState}/${urlState?.split("/")?.[5]}.720x480.mp4` : "",
								timeCode
							)
						)}
					>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M16.6203 2.24935C16.4328 2.0618 16.1784 1.95643 15.9132 1.95642C15.648 1.95641 15.3936 2.06177 15.2061 2.24931L5.58581 11.8695C5.21073 12.2445 5.00001 12.7532 5.00001 13.2837L5.00001 16.9999C5.00001 17.2651 5.10537 17.5195 5.2929 17.707C5.48044 17.8945 5.73479 17.9999 6.00001 17.9999H9.71622C10.2467 17.9999 10.7554 17.7892 11.1305 17.4141L20.7503 7.79396C21.1408 7.40344 21.1409 6.77031 20.7504 6.37979L16.6203 2.24935ZM7.00001 13.2837L15.9131 4.37066L18.6291 7.08683L9.71622 15.9999H7.00001L7.00001 13.2837ZM3 20.0001H2L2 22.0001H3H21H22V20.0001H21H3Z"
								fill="#94A0B1"
							/>
						</svg>
					</div>
				</div>
			) : null}
		</Link>
	);
};

const mapStateToProps = (state) => {
	return {
		brightboxesList: state.brightboxesList,
	};
};

const mapDispatchToProps = {
	getBrightboxes,
};

export default connect(mapStateToProps, mapDispatchToProps)(OwnerBrightboxes);
