import React, { useEffect, useState } from "react";

/**
 */
export const UserStatisticInfoField = ({
  title,
  collection,
  color = "blue",
  marginTop = "25px",
}) => {
  // const [state, setState] = useState([]);

  // useEffect(() => {
  //   setState(collection);
  // }, [collection]);

  return (
    <div
      className={`form-container-progress-blocks ${color}`}
      style={{ marginTop: marginTop }}
    >
      {!!title && (
        <div className="form-container-progress-block header">
          <span>
            <b>{title}</b>
          </span>
        </div>
      )}
      {collection && collection.length
        ? collection.map((elem, index) => {
            return (
              <div className="form-container-progress-block" key={`${index}`}>
                <span>{elem.name}</span>
                <div className="form-container-progress-block-right">
                  <div className="form-container-progress-block-line">
                    <div
                      className="form-container-progress-block-linefill"
                      style={{
                        width: Math.round(elem.percent) + "%",
                      }}
                    ></div>
                  </div>

                  <span>{`${Math.round(elem.percent)}%`}</span>
                  <span>{elem.total_count}</span>
                </div>
              </div>
            );
          })
        : null}
    </div>
  );
};
