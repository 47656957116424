import React, { useContext, useMemo, useRef, useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import { postPlaylist, putPlaylist } from "../../../../Actions/PlayListActions.js";
import { ModalContext } from "../../../modal/ModalContext/ModalContext.tsx";
import { onInputTextAreaChange, takeId } from "../../scripts/PersonalAreaScripts.js";
import { checkUserWritePerm } from "../../scripts/utils.js";
import { postUploadImages } from "../../../../Actions/videos-actions/VideosActions.js";
import styled from "styled-components";
import "./modals.styles.scss";
import { Cluster } from "../../../../ui-kit/layouts/Cluster.jsx";
import { Stack } from "../../../../ui-kit/layouts/Stack.jsx";
import { Checkbox } from "../../../../ui-kit/checkbox/Checkbox.jsx";
import Multiselect from "multiselect-react-dropdown";
import { onRemoveItem, onSelectItem } from "../../VideosPage/sctipts.js";

const RemovePreviewButton = styled.div`
	background: #ffffff;
	position: absolute;
	border-radius: 80px;
	width: 32px;
	height: 32px;
	right: 8px;
	top: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 100;
	&:hover {
		svg {
			path {
				fill: #005bff;
			}
		}
	}
`;

const CreateEditPlaylistModal = ({
	data = null,
	postPlaylist,
	putPlaylist,
	loading,
	uploadImage,
	postUploadImages,
	userAccessState,
	videoGenresList,
}) => {
	const multiselectRef = useRef(null);
	const dispatch = useDispatch();
	const { handleModal, modal } = useContext(ModalContext);

	const [prewDragState, setPrewDragState] = useState(() => false);
	const [uploadFlagState, setUploadFlagState] = useState(() => false); // т.к. общее состояние аплоадов, делаю флажок: к хранилищу доступ только при поднятом флаге
	const [genresState, setGenresState] = useState([]);
	const [playListState, setPlayListState] = useState();

	useEffect(() => {
		if (loading === 2) {
			dispatch({ type: "HIDE_LOADER" });
			handleModal();
		}
	}, [loading, modal]);

	const handlePlaylist = () => {
		if (data) {
			let timeArray = [...data.movies];
			const arr = takeId(timeArray, "movie_id");
			const resultobj = arr.map((elem, index) => ({ [String(elem)]: String(index + 1) }));
			putPlaylist(data.id, {
				...data,
				movies: Array.isArray(playListState?.genre) ? [] : resultobj,
				title: playListState.title,
				image: playListState.image || "",
				onhome: playListState.onhome || false,
				isshort: playListState.isshort || false,
				genre: Array.isArray(playListState?.genre)
					? playListState?.genre?.map((elem) => elem.id)?.join(",")
					: "",
			});
		} else {
			postPlaylist({
				...playListState,
				genre: playListState?.genre?.map((elem) => elem.id)?.join(","),
			});
		}
	};

	const dragStartHandler = (e) => {
		e.preventDefault();
		setPrewDragState(true);
	};

	const dragLeaveHandler = (e) => {
		e.preventDefault();
		setPrewDragState(false);
	};

	const onDropHandler = (e) => {
		e.preventDefault();
		let files = [...e.dataTransfer.files];
		let reader = new FileReader();
		let file = files[0];
		reader.onloadend = () => {
			postUploadImages(file, "preview");
			setUploadFlagState(true);
		};
		reader.readAsDataURL(files[0]);
	};

	const onPrewPhotoChange = (event) => {
		if (event.target.files && event.target.files[0]) {
			let reader = new FileReader();
			let file = event.target.files[0];
			reader.onloadend = () => {
				postUploadImages(file, "preview");
				setUploadFlagState(true);
			};
			reader.readAsDataURL(file);
		}
	};

	useEffect(() => {
		if (uploadImage.filename.length > 0 && uploadFlagState) {
			setPlayListState({ ...playListState, image: uploadImage.filename });
			setUploadFlagState(false);
		}
	}, [uploadImage]);

	useEffect(() => {
		if (videoGenresList && videoGenresList?.length) setGenresState(videoGenresList);
	}, [videoGenresList]);

	useEffect(() => {
		if (data && genresState?.length) {
			if (data?.genre && data?.genre?.length > 0) {
				const genresIds = data?.genre?.split(",");
				const newGenresArray = genresIds?.map((elem) =>
					genresState.find((item) => item?.id === Number(elem))
				);
				if (newGenresArray?.length > 0 && newGenresArray?.[0]?.id) {
					setPlayListState({ ...data, genre: newGenresArray });
				} else {
					setPlayListState({ ...data, genre: [] });
				}
				return;
			}
			setPlayListState(data);
		}
	}, [data, genresState]);

	const isBloggerGenre = (genre) => {
		return genre?.id > 16 && genre?.id < 39;
	};

	const formatedGenres = useMemo(() => {
		if (!genresState?.length) return { filmGenres: [], bloggerGenres: [] };
		const filmGenres = [];
		const bloggerGenres = [];
		genresState.forEach((genre) => {
			if (isBloggerGenre(genre)) {
				bloggerGenres.push(genre);
			} else {
				filmGenres.push(genre);
			}
		});
		return { filmGenres, bloggerGenres };
	}, [genresState]);

	return (
		<div className="modal-field create-playlists" style={{ width: "600px" }}>
			<div className="modal-field-title">{!data?.id ? "Добавить" : "Изменить"} плейлист</div>
			<div className="modal-field-close" onClick={() => handleModal()}>
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M4.70701 3.29304L3.99991 2.58594L2.58569 4.00015L3.2928 4.70726L10.5857 12.0002L3.2928 19.293L2.58569 20.0002L3.99991 21.4144L4.70701 20.7073L11.9999 13.4144L19.2928 20.7073L19.9999 21.4144L21.4141 20.0002L20.707 19.293L13.4141 12.0002L20.707 4.70726L21.4141 4.00015L19.9999 2.58594L19.2928 3.29304L11.9999 10.5859L4.70701 3.29304Z"
						fill="#212121"></path>
				</svg>
			</div>
			<div className="flex-block-col">
				{/* {type === "CREATE" ? (
					<div className="form-field">
						<div className="input-field">
							<RadioButtons
								collection={initialRadioState}
								value={videoTypeState}
								setValue={setVideoTypeState}
							/>
						</div>
					</div>
				) : null} */}

				<div className="form-field" style={{ marginTop: "32px" }}>
					<div className="form-field-label">Название плейлиста</div>
					<div className="input-field">
						<input
							name="surname"
							value={playListState?.title}
							placeholder="Введите название плейлиста"
							onChange={(e) => onInputTextAreaChange(e, "title", playListState, setPlayListState)}
						/>
					</div>
				</div>
			</div>
			<Stack space="16px">
				<div className="form-container-block-subtitle">Превью</div>
				<Cluster
					align="center"
					justify="center"
					className="form-container-block-preview"
					onDragStart={(e) =>
						checkUserWritePerm(userAccessState, "playlists") && dragStartHandler(e)
					}
					onDragLeave={(e) =>
						checkUserWritePerm(userAccessState, "playlists") && dragLeaveHandler(e)
					}
					onDragOver={(e) =>
						checkUserWritePerm(userAccessState, "playlists") && dragStartHandler(e)
					}
					onDrop={(e) => checkUserWritePerm(userAccessState, "playlists") && onDropHandler(e)}>
					{playListState?.image?.length > 0 && checkUserWritePerm(userAccessState, "playlists") && (
						<RemovePreviewButton onClick={() => setPlayListState({ ...playListState, image: "" })}>
							<svg
								width="12"
								height="12"
								viewBox="0 0 12 12"
								fill="none"
								xmlns="http://www.w3.org/2000/svg">
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M2.70711 1.29289L2 0.585785L0.585785 2L1.29289 2.70711L4.58579 6L1.29289 9.29289L0.585785 10L2 11.4142L2.70711 10.7071L6 7.41421L9.29289 10.7071L10 11.4142L11.4142 10L10.7071 9.29289L7.41421 6L10.7071 2.70711L11.4142 2L10 0.585785L9.29289 1.29289L6 4.58579L2.70711 1.29289Z"
									fill="#212121"
								/>
							</svg>
						</RemovePreviewButton>
					)}
					<div className="form-container-block-preview-data">
						<img src="images/icons/uploadimage.svg" alt="upload" />
						{!prewDragState ? (
							<>
								<span>
									Перетащите изображение в это окно
									<br />
									или нажмите, чтобы выбрать
								</span>{" "}
								Рекомендуемый размер изображений
								<br />
								не менее 900×600 px
								<br />
								<br />
								Формат изображений: jpg, png
								<br />
								Размер файла не должен превышать 5 мб
							</>
						) : (
							<span>Отпустите изображение</span>
						)}
					</div>
					<div
						className="form-container-block-preview-image"
						style={{
							backgroundImage: `url('https://atips.io/${playListState?.image}')`,
							backgroundSize: "auto 90%",
							backgroundRepeat: "no-repeat",
							backgroundPosition: "50% 50%",
						}}></div>
					<input
						disabled={!checkUserWritePerm(userAccessState, "playlists")}
						type="file"
						name="URL_SD_PREVIEW"
						accept="image/jpg, image/jpeg, image/png, image/webp"
						onChange={onPrewPhotoChange}
					/>
				</Cluster>
				<Checkbox
					id={1}
					title="Shorts"
					isActive={playListState?.isshort}
					onSelect={() => setPlayListState({ ...playListState, isshort: !playListState?.isshort })}
				/>
				<Checkbox
					id={1}
					title="Отображать на главной"
					isActive={playListState?.onhome}
					onSelect={() => setPlayListState({ ...playListState, onhome: !playListState?.onhome })}
				/>
				<div className="form-field">
					<div className="form-field-label">Жанры</div>
					<div className="input-field">
						<Multiselect
							disable={!checkUserWritePerm(userAccessState, "videos")}
							className="multiCustom"
							ref={multiselectRef}
							options={formatedGenres.filmGenres} // Options to display in the dropdown
							selectedValues={
								playListState?.genre?.length > 0 && playListState?.genre[0] !== null
									? playListState?.genre?.filter((elem) => !isBloggerGenre(elem))
									: ""
							} // Preselected value to persist in dropdown
							onSelect={(selectedList, selectedItem) =>
								onSelectItem(selectedList, selectedItem, playListState, setPlayListState, "genre")
							} // Function will trigger on select event
							onRemove={(selectedList, removeItem) =>
								onRemoveItem(selectedList, removeItem, playListState, setPlayListState, "genre")
							} // Function will trigger on remove event
							displayValue="title" // Property name to display in the dropdown options
							hidePlaceholder={true}
							avoidHighlightFirstOption={true}
							emptyRecordMsg="Нет доступных"
							customCloseIcon={
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg">
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M8.70701 7.29304L7.99991 6.58594L6.58569 8.00015L7.2928 8.70726L10.5857 12.0002L7.2928 15.293L6.58569 16.0002L7.99991 17.4144L8.70701 16.7073L11.9999 13.4144L15.2928 16.7073L15.9999 17.4144L17.4141 16.0002L16.707 15.293L13.4141 12.0002L16.707 8.70726L17.4141 8.00015L15.9999 6.58594L15.2928 7.29305L11.9999 10.5859L8.70701 7.29304Z"
										fill="#212121"
									/>
								</svg>
							}
							placeholder="Выберите один или несколько жанров"
						/>
					</div>
				</div>
				<div className="form-field">
					<div className="form-field-label">Категории блогеров</div>
					<div className="input-field">
						<Multiselect
							disable={!checkUserWritePerm(userAccessState, "videos")}
							className="multiCustom"
							ref={multiselectRef}
							options={formatedGenres.bloggerGenres} // Options to display in the dropdown
							selectedValues={
								playListState?.genre?.length > 0 && playListState?.genre[0] !== null
									? playListState?.genre?.filter((elem) => isBloggerGenre(elem))
									: ""
							} // Preselected value to persist in dropdown
							onSelect={(selectedList, selectedItem) =>
								onSelectItem(selectedList, selectedItem, playListState, setPlayListState, "genre")
							} // Function will trigger on select event
							onRemove={(selectedList, removeItem) =>
								onRemoveItem(selectedList, removeItem, playListState, setPlayListState, "genre")
							} // Function will trigger on remove event
							displayValue="title"
							hidePlaceholder={true}
							avoidHighlightFirstOption={true}
							emptyRecordMsg="Нет доступных"
							customCloseIcon={
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg">
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M8.70701 7.29304L7.99991 6.58594L6.58569 8.00015L7.2928 8.70726L10.5857 12.0002L7.2928 15.293L6.58569 16.0002L7.99991 17.4144L8.70701 16.7073L11.9999 13.4144L15.2928 16.7073L15.9999 17.4144L17.4141 16.0002L16.707 15.293L13.4141 12.0002L16.707 8.70726L17.4141 8.00015L15.9999 6.58594L15.2928 7.29305L11.9999 10.5859L8.70701 7.29304Z"
										fill="#212121"
									/>
								</svg>
							}
							placeholder="Выберите один или несколько жанров"
						/>
					</div>
				</div>
			</Stack>

			<div className="modal-buttons delete">
				<button className="button action-button border-button" onClick={() => handleModal()}>
					<span>Отменить</span>
				</button>
				<button
					className="button action-button create-category-button "
					onClick={() => handlePlaylist()}>
					<span>{!data?.id ? "Создать плейлист" : "Сохранить"}</span>
				</button>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		videoGenresList: state.videoGenresList,
		uploadImage: state.uploadImage,
		loading: state.loadingStatus.loading,
	};
};

const mapDispatchToProps = {
	postPlaylist,
	putPlaylist,
	postUploadImages,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateEditPlaylistModal);
