const initialSellers = {
	list: [],
	pagination: {},
};

export const ProductReducer = (state = initialSellers, action) => {
	switch (action.type) {
		case "GET_PRODUCT":
			return action.prod.data;
		default:
			return state;
	}
};
