import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { StatisticBlockContainer } from "../../../../../ui-kit/wrappers-containers/StatisticBlockContainer.jsx";
import { SellerInfoRow } from "./SellerStatisticBody.jsx";

const RowDivider = styled.div`
	width: 100%;
	height: 1px;
	background: #e2e8f0;
`;

export const SellerContacts = ({ externalSellerState }) => {
	return (
		<>
			<StatisticBlockContainer className="content-wrapper">
				<div className="gadgets-statistic-header">
					<div className="gadgets-statistic-header__title">Контактные данные</div>
				</div>
				<div className="table-wrapper" style={{ marginTop: "16px" }}>
					<SellerInfoRow title="Email" value={externalSellerState?.email || "Не указано"} />
					<RowDivider />
					<SellerInfoRow
						title="Название организации"
						value={externalSellerState?.legal_name || "Не указано"}
					/>
					<RowDivider />
					<SellerInfoRow title="ИНН" value={externalSellerState?.inn || "Не указано"} />
					<RowDivider />
					<SellerInfoRow
						title="ОГРН или ОГРНИП"
						value={externalSellerState?.ogrn || externalSellerState?.ogrnip || "Не указано"}
					/>
					<RowDivider />
					<SellerInfoRow
						title="Юридический адрес"
						value={externalSellerState?.legal_address || "Не указано"}
					/>
					<RowDivider />
					<SellerInfoRow
						title="Фактический адрес"
						value={externalSellerState?.actual_address || "Не указано"}
					/>
					<RowDivider />
					<SellerInfoRow
						title="ФИО гендиректора или представителя по доверенности"
						value={externalSellerState?.ceo_name || "Не указано"}
					/>
					<RowDivider />
					<SellerInfoRow
						title="ФИО контактного лица"
						value={externalSellerState?.contact_name || "Не указано"}
					/>
					<RowDivider />
					<SellerInfoRow
						title="Телефон контактного лица"
						value={externalSellerState?.contact_phone_number || "Не указано"}
					/>
				</div>
			</StatisticBlockContainer>
			<StatisticBlockContainer className="content-wrapper">
				<div className="gadgets-statistic-header">
					<div className="gadgets-statistic-header__title">Банковские реквизиты</div>
				</div>
				<div className="table-wrapper" style={{ marginTop: "16px" }}>
					<SellerInfoRow title="ИНН" value={externalSellerState?.bank_inn || "Не указано"} />
					<RowDivider />
					<SellerInfoRow title="КПП" value={externalSellerState?.kpp || "Не указано"} />
					<RowDivider />
					<SellerInfoRow
						title="Расчетный счет"
						value={externalSellerState?.checking_account || "Не указано"}
					/>
					<RowDivider />
					<SellerInfoRow title="Банк" value={externalSellerState?.bank_name || "Не указано"} />
					<RowDivider />
					<SellerInfoRow
						title="Корреспондентский счет"
						value={externalSellerState?.correspondent_account || "Не указано"}
					/>
					<RowDivider />
					<SellerInfoRow title="БИК" value={externalSellerState?.bik || "Не указано"} />
				</div>
			</StatisticBlockContainer>
		</>
	);
};
