import React, { useContext, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { getPoliticsLocation, postPolitic, putPolitic } from "../../../Actions/PoliticsAction.js";
import { RadioButtons } from "../../../ui-kit/radio/RadioButtons.jsx";
import { ModalContext } from "../../modal/ModalContext/ModalContext.tsx";
import { onInputTextAreaChange, takeId } from "../scripts/PersonalAreaScripts.js";
import {
	accessCountry,
	initialAccessTipsState,
	initialAccessVideoState,
} from "./mockCollection.js";
import SelectCountries from "./SelectCountries.jsx";
import Select from "react-select";
import { customStyles } from "../static.js";


/**
 * data - не обязательный аргумент, это сущность политики
 * 
 * Если в data передаем политику - редактируем его
 *  
 * Если в data ничего не передавать, значит создаем новую политику
 */
const EditPoliticsComponent = ({
	data = null,
	politicsLocation,
	postPolitic,
	putPolitic,
	getPoliticsLocation,
	loading,
}) => {
	const dispatch = useDispatch();
	const { handleModal, modal } = useContext(ModalContext);

	const [selectedTipsLocation, setSelectedTipsLocation] = useState(
		data?.locationstips_settable || []
	);
	const [tipsAccessState, setTipsAccessState] = useState(
		data &&
			data?.locationstips_settable?.length > 0 &&
			data?.locationstips_settable?.length !== politicsLocation?.attributes?.length
			? 2
			: 1
	);
	const [accessTips, setAccessTips] = useState(
		data && data.availabletips === false ? initialAccessTipsState[1] : initialAccessTipsState[0]
	);
	const [accessVideo, setAccessVideo] = useState(
		data?.available === false ? initialAccessVideoState[1] : initialAccessVideoState[0]
	);
	const [selectedVideoLocation, setSelectedVideoLocation] = useState(
		data?.locations_settable || []
	);
	const [politicTitleState, setPoliticTitleState] = useState({
		title: data?.title || "",
	});
	useEffect(() => {
		if (loading === 2) {
			dispatch({ type: "HIDE_LOADER" });
			handleModal();
		}
	}, [loading, modal]);

	useEffect(() => {
		if (politicsLocation.length === 0) {
			getPoliticsLocation(5);
		}
	}, []);
	const [titleState] = useState("");

	// useEffect(() => {
	// 	if (!politicsLocation.length) return;

	// 	if (typeof selectedVideoLocation === "object" && "id" in selectedVideoLocation) {
	// 		const found = politicsLocation.find((elem) => selectedVideoLocation?.id === elem.id);
	// 		if (found) setSelectedVideoLocation(found);
	// 	}
	// }, [politicsLocation]);

	const [videoAccessState, setVideoAccessState] = useState(
		data &&
			data?.locations_settable?.length > 0 &&
			data?.locations_settable?.length !== politicsLocation?.attributes?.length
			? 2
			: 1
	);

	const [descriptionState, setDescriptionState] = useState({
		description: null,
	});

	useEffect(() => {
		const tx = document.getElementsByTagName("textarea");
		for (let i = 0; i < tx.length; i++) {
			tx[i].setAttribute("style", "height:" + tx[i].scrollHeight + "px;overflow-y:hidden;");
			tx[i].addEventListener("keydown", OnInput, false);
		}

		function OnInput() {
			this.style.height = "auto";
			this.style.height = this.scrollHeight + "px";
		}
		return () => {
			for (let i = 0; i < tx.length; i++) {
				tx[i].setAttribute("style", "height:" + tx[i].scrollHeight + "px;");
				tx[i].removeEventListener("", OnInput, false);
			}
		};
	}, [descriptionState]);

	const handleSelectPolitic = () => {
		let locationArray = takeId(selectedVideoLocation);
		let tipsLocationArray = takeId(selectedTipsLocation);
		if (videoAccessState === 1) {
			locationArray = [];
		}

		if (tipsAccessState === 1) {
			tipsLocationArray = [];
		}
		if (data) {
			putPolitic(data.id, {
				...politicTitleState,
				available: accessVideo.value,
				locations_settable: locationArray,
				locationstips_settable: tipsLocationArray,
				availabletips: accessTips.value,
			});
		} else {
			postPolitic({
				...politicTitleState,
				available: accessVideo.value,
				locations_settable: locationArray,
				locationstips_settable: tipsLocationArray,
				availabletips: accessTips.value,
			});
		}
	};
	return (
		<div className="modal-field edit-politic-modal active">
			<div className="modal-field-title">
				{data && data.title ? "Редактировать" : "Добавить новую"} политику
			</div>
			<div className="modal-field-close" onClick={() => handleModal()}>
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M4.70701 3.29304L3.99991 2.58594L2.58569 4.00015L3.2928 4.70726L10.5857 12.0002L3.2928 19.293L2.58569 20.0002L3.99991 21.4144L4.70701 20.7073L11.9999 13.4144L19.2928 20.7073L19.9999 21.4144L21.4141 20.0002L20.707 19.293L13.4141 12.0002L20.707 4.70726L21.4141 4.00015L19.9999 2.58594L19.2928 3.29304L11.9999 10.5859L4.70701 3.29304Z"
						fill="#212121"
					></path>
				</svg>
			</div>
			<div className="flex-block-col">
				<div className="form-field">
					<div className="form-field-label">Название политики</div>
					<div className="input-field">
						<input
							value={politicTitleState.title}
							type="string"
							placeholder="Введите название политики"
							onChange={(e) =>
								onInputTextAreaChange(e, "title", politicTitleState, setPoliticTitleState)
							}
						/>
					</div>
				</div>
				{/* <div className="form-field">
					<div className="form-field-label">Описание политики</div>
					<div className="input-field">
						<textarea
							name=""
							value={descriptionState.description === null ? "" : descriptionState.description}
							onChange={(e) =>
								onInputTextAreaChange(e, "description", descriptionState, setDescriptionState)
							}
							placeholder="Введите описание политики"
						></textarea>
					</div>
				</div> */}
			</div>
			<div className="flex-block-col">
				<div className="form-field">
					<div className="form-field-label">Доступ к видео</div>
					<div className="input-field">
						<Select
							className="search-wrapper"
							classNamePrefix="select"
							name="video"
							styles={customStyles}
							value={accessVideo}
							options={initialAccessVideoState}
							onChange={(newValue) => setAccessVideo(newValue)}
							getOptionLabel={(option) => option.title || option.label}
							// getOptionValue={(option) => option.id}
							placeholder="Разрешить видео"
							isSearchable={false}
							noOptionsMessage={() => "нет данных"}
						/>
					</div>
				</div>
				<div className="form-field">
					<div className="form-field-label bold">Где:</div>
					<div className="input-field">
						<RadioButtons
							collection={accessCountry}
							value={videoAccessState}
							setValue={setVideoAccessState}
						/>
					</div>
				</div>
				{videoAccessState === 2 ? (
					<SelectCountries
						selectedVideoLocation={selectedVideoLocation}
						setSelectedVideoLocation={setSelectedVideoLocation}
					/>
				) : null}
			</div>

			<div className="flex-block-col" style={{ border: "none", paddingBottom: "0" }}>
				<div className="form-field">
					<div className="form-field-label">Типсы</div>
					<div className="input-field">
						<Select
							className="search-wrapper"
							classNamePrefix="select"
							name="video"
							styles={customStyles}
							defaultValue={accessTips}
							options={initialAccessTipsState}
							onChange={(newValue) => setAccessTips(newValue)}
							getOptionLabel={(option) => option.title || option.label}
							// getOptionValue={(option) => option.id}
							placeholder="Показывать типсы"
							isSearchable={false}
							noOptionsMessage={() => "нет данных"}
						/>
					</div>
				</div>
				<div className="form-field">
					<div className="form-field-label bold">Где:</div>
					<div className="input-field">
						<RadioButtons
							collection={accessCountry}
							value={tipsAccessState}
							setValue={setTipsAccessState}
						/>
					</div>
				</div>
				{tipsAccessState === 2 ? (
					<SelectCountries
						selectedVideoLocation={selectedTipsLocation}
						setSelectedVideoLocation={setSelectedTipsLocation}
					/>
				) : null}
			</div>

			<div className="modal-buttons">
				<button className="button action-button border-button" onClick={() => handleModal()}>
					<span>Отменить</span>
				</button>
				<button
					className={`button action-button create-category-button ${
						loading === 1 ? "preloader" : ""
					}`}
					disabled={!politicTitleState || !politicTitleState?.title.length}
					onClick={() => handleSelectPolitic()}
				>
					<span>{data ? "Сохранить" : "Создать"}</span>
				</button>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		politicsLocation: state.politicsLocation,
		loading: state.loadingStatus.loading,
	};
};

const mapDispatchToProps = {
	postPolitic,
	putPolitic,
	getPoliticsLocation,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPoliticsComponent);
