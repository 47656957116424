import { gql } from "@apollo/client";

export const GET_MAIN_PROFIT = gql`
	query getStatisticPage($lte: DateTime, $gte: DateTime) {
		getStatisticPage(
			pagination: { page: 1, limit: 1 }
			filter: { owner_id: 1, click: 1, created_at: { gte: $gte, lte: $lte } }
			sort: { field: click, direction: DESC }
		) {
			items {
				price
			}
			aggregation {
				total_sum
				income_profit
			}
			pageInfo {
				itemsCount
			}
		}
	}
`;

export const GET_TIPS_SHOW = gql`
	query getStatisticPage($lte: DateTime, $gte: DateTime) {
		getStatisticPage(
			pagination: { page: 1, limit: 1 }
			filter: { owner_id: 1, click: 0, created_at: { gte: $gte, lte: $lte } }
			sort: { field: click, direction: DESC }
		) {
			items {
				price
			}
			pageInfo {
				itemsCount
			}
		}
	}
`;
export const GET_VIDEOS_COUNT = gql`
	query getVideosPage($lte: DateTime, $gte: DateTime) {
		getVideosPage(
			pagination: { page: 1, limit: 1 }
			filter: { owner_id: 1, created_at: { gte: $gte, lte: $lte } }
			sort: { field: video, direction: DESC }
		) {
			items {
				video
			}
			pageInfo {
				itemsCount
			}
		}
	}
`;
export const GET_SELLERS_COUNT = gql`
	query getSellersPage($lte: DateTime, $gte: DateTime) {
		new: getSellersPage(
			pagination: { page: 1, limit: 1 }
			filter: { owner_id: 1, created_at: { gte: $gte, lte: $lte } }
			sort: { field: seller, direction: DESC }
		) {
			items {
				seller
			}
			pageInfo {
				itemsCount
			}
		}
		total: getSellersPage(
			pagination: { page: 1, limit: 1 }
			filter: { owner_id: 1 }
			sort: { field: seller, direction: DESC }
		) {
			items {
				seller
			}
			pageInfo {
				itemsCount
			}
		}
	}
`;
export const GET_SUMMARY_ITEM = gql`
	query getSummaryItem($lte: DateTime, $gte: DateTime) {
		getSummaryItem(filter: { owner_id: 1, created_at: { gte: $gte, lte: $lte } }) {
			total_moviegoer_count
		}
	}
`;
export const GET_TOP5_STATISTIC = gql`
	query getEfficiencyItem($lte: DateTime, $gte: DateTime) {
		getCatEfficiencyItem(filter: { owner_id: 1, created_at: { gte: $gte, lte: $lte } }) {
			best_products_cat {
				name
				category_id
				views_count
				clicks_count
				profit
				percent
			}
			best_services_cat {
				name
				category_id
				views_count
				clicks_count
				profit
				percent
			}
		}
		getEfficiencyItem(filter: { owner_id: 1, created_at: { gte: $gte, lte: $lte } }) {
			best_products {
				name
				product_id
				views_count
				clicks_count
				profit
				percent
			}
			best_services {
				name
				product_id
				views_count
				clicks_count
				profit
				percent
			}
		}
		getSellersEfficiencyItem(filter: { owner_id: 1, created_at: { gte: $gte, lte: $lte } }) {
			best_service_sellers {
				seller
				seller_id
				views_count
				clicks_count
				profit
				percent
			}
			best_product_sellers {
				seller
				seller_id
				views_count
				clicks_count
				profit
				percent
			}
		}
		getTipsEfficiencyItem(filter: { owner_id: 1, created_at: { gte: $gte, lte: $lte } }) {
			best_service_tips {
				tips_id
				views_count
				clicks_count
				profit
				percent
			}
			best_products_tips {
				tips_id
				views_count
				clicks_count
				profit
				percent
			}
		}
	}
`;

export const GET_MOVIEGOER_AGG = gql`
	query getMoviegoerAggItem($lte: DateTime, $gte: DateTime) {
		getMoviegoerAggItem(filter: { owner_id: 1, created_at: { gte: $gte, lte: $lte } }) {
			countries {
				percent
				total_count
				name
				cities {
					percent
					total_count
					name
				}
			}
			sex_info {
				percent
				total_count
				name
			}
			age_info {
				percent
				total_count
				name
			}
		}
	}
`;
export const GET_SELLER_AGG = gql`
	query getSellerAggItem($lte: DateTime, $gte: DateTime) {
		getSellerAggItem(filter: { owner_id: 1, created_at: { gte: $gte, lte: $lte } }) {
			countries {
				percent
				total_count
				name
				cities {
					percent
					total_count
					name
				}
			}
		}
	}
`;
