import { gql } from "@apollo/client";

export const GET_STATISTIC = gql`
	query getStatisticPage(
		$page: Int
		$limit: Int
		$user_id: Int!
		$lte: DateTime
		$tips_id: Int
		$gte: DateTime
		$format_id: Int
		$category_id: Int
		$seller_id: Int
		$order_by: StatisticSortFields!
		$direction: SortDirection!
		$sex_id: Int
		$age_id: Int
		$country_id: Int
		$city_id: Int
		$click: Int
		$video_id: Int
		$genre_id: Int
	) {
		getStatisticPage(
			pagination: { page: $page, limit: $limit }
			filter: {
				owner_id: $user_id
				seller_id: $seller_id
				category_id: $category_id
				format_id: $format_id
				created_at: { gte: $gte, lte: $lte }
				sex_id: $sex_id
				age_id: $age_id
				country_id: $country_id
				city_id: $city_id
				click: $click
				video_id: $video_id
				genre_id: $genre_id
				tips_id: $tips_id
			}
			sort: { field: $order_by, direction: $direction }
		) {
			items {
				tips_id
				price
				income
				percent
				platform
				platform_id
				seller
				seller_id
				seller_external
				product
				product_id
				category
				category_id
				video
				video_id
				format
				format_id
				genre
				genre_id
				moviegoer
				moviegoer_id
				moviegoer_external
				sex
				sex_id
				age
				age_id
				country
				country_id
				blogger
				blogger_id
				blogger_external
				city
				city_id
				click
				create_date
			}
			pageInfo {
				currentPage
				currentLimit
				itemsCount
				nextPageExist
			}
			aggregation {
				total_sum
				income_profit
			}
		}

		getSummaryItem(filter: { owner_id: $user_id }) {
			total_balance
		}
	}
`;

export const GET_VIDEO_TIPS = gql`
	query getStatisticPage($click: Int, $video_id: Int, $lte: DateTime, $gte: DateTime) {
		getStatisticPage(
			pagination: { page: 1, limit: 1 }
			filter: {
				owner_id: 1
				click: $click
				video_id: $video_id
				created_at: { gte: $gte, lte: $lte }
			}
			sort: { field: video, direction: DESC }
		) {
			items {
				video
				video_id
			}
			pageInfo {
				currentPage
				currentLimit
				itemsCount
				nextPageExist
			}
			aggregation {
				total_sum
				income_profit
			}
		}

		getVideosPage(
			pagination: { page: 1, limit: 1 }
			filter: { owner_id: 1, video_id: $video_id, created_at: { gte: $gte, lte: $lte } }
			sort: { field: video, direction: DESC }
		) {
			items {
				tips_count
				profit
			}
			pageInfo {
				currentPage
				currentLimit
				itemsCount
				nextPageExist
			}
		}
	}
`;
