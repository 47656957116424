import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import "./ViewerStatistiс.scss";
import { formatedDate } from "../../static.js";
import {
	getCurrentSeller,
	getExternalSeller,
	patchExternalSeller,
} from "../../../../Actions/SellersActions.js";
import МeaningfulStatisticCards from "./МeaningfulStatisticCards.jsx";
import { SellerContacts } from "./PersonalInfo/SellerContacts.jsx";
import BestCategories from "./bestCategories/BestCategories.jsx";
import PaymentsHistoryPage from "./PaymentsHistoryPage/PaymentsHistoryPage.jsx";
import styled from "styled-components";
import { getPeriod } from "../../HomePage/scripts";
import { CancelButton, SubmitButton } from "../pages/Buttons.jsx";
import { formatDate } from "../../VideosPage/sctipts";
import { PeriodFilters } from "../../HomePage/UsersStatistic/PeriodFilters/PeriodFilters";
import { getSellerBalance, handleFetchProdCounts } from "./get-seller-totals";
import { GET_SELLER_TIPS } from "../../../../GraphQL/SellerStatistic";
import { useLazyQuery } from "@apollo/client";
import { DText14 } from "../../../../ui-kit/typography/Typography";
import { Cluster } from "../../../../ui-kit/layouts/Cluster";
import { useUserAccessContext } from "../../context/UserAccessContext";
import { checkUserWritePerm } from "../../scripts/utils";
import CreateDocumentBlock from "./CreateDocumentBlock/CreateDocumentBlock";

const SelectButtonsWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const InfoButton = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 5px 12px;

	height: 32px;
	background: #e2e8f0;
	border-radius: 80px;

	font-family: "Averta CY";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 160%;
	color: #94a0b1;
`;

const HeaderWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	/* padding-right: 150px; */
	&.small-container {
		display: flex;
		align-items: center;
	}
`;

const StyledTitleWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 24px;
	img {
		min-width: 80px;
		width: 80px;
		height: 80px;
		border-radius: 80px;
	}
`;

const SellerStatisticComponent = ({
	title,
	getCurrentSeller,
	currentSeller,
	getExternalSeller,
	externalSeller,
	patchExternalSeller,
}) => {
	const { userAccessState } = useUserAccessContext();

	const [filtersState, setFiltersState] = useState({
		from_datetime: getPeriod()[4].startDate,
		to_datetime: getPeriod()[4].endDate,
		period_name: getPeriod()[4].period_name,
		group_by: getPeriod()[4].group_by,
		date_key: 4,
	});
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const [sellerState, setSellerState] = useState(null);
	const [externalSellerState, setExternalSellerState] = useState(null);
	const [categoriesCount, setCategoriesCount] = useState(0);
	const [servicesCount, setServicesCount] = useState(0);
	const [balanceState, setBalanceState] = useState(null);

	const [getTipsStatistic, { data: tips, loading: tipsShowLoading }] = useLazyQuery(
		GET_SELLER_TIPS,
		{
			variables: {},
		}
	);

	useEffect(() => {
		document.title = title;
	}, [title]);

	useEffect(() => {
		getCurrentSeller(location.search.split("=")[1]);
	}, []);

	useEffect(() => {
		if (!!currentSeller && "external_id" in currentSeller) {
			setSellerState(currentSeller);
			getExternalSeller(currentSeller.external_id);
		}
	}, [currentSeller]);

	useEffect(() => {
		setExternalSellerState(externalSeller);
	}, [externalSeller]);

	useEffect(() => {
		if (
			!sellerState ||
			!filtersState.from_datetime ||
			!filtersState.to_datetime ||
			!filtersState.period_name
		)
			return;

		getTipsStatistic({
			variables: {
				gte: new Date(filtersState.from_datetime).toISOString(),
				lte: new Date(filtersState.to_datetime).toISOString(),
				seller_id: sellerState.id,
			},
		});
		getSellerBalance(
			{
				user_id: sellerState.external_id,
				date_start: new Date(filtersState.from_datetime).toISOString(),
				date_end: new Date(filtersState.to_datetime).toISOString(),
			},
			dispatch,
			(e) => setBalanceState(e)
		);
	}, [sellerState, filtersState]);
	useEffect(() => {
		if (sellerState) {
			handleFetchProdCounts(
				{
					seller_id: sellerState.id,
					is_service: false,
					page_size: 1,
				},
				dispatch,
				(e) => setCategoriesCount(e)
			);
			handleFetchProdCounts(
				{
					seller_id: sellerState.id,
					is_service: true,
					page_size: 1,
				},
				dispatch,
				(e) => setServicesCount(e)
			);
		}
	}, [sellerState]);

	const handleCancelClick = () => {
		patchExternalSeller(externalSellerState.id, {
			is_active: false,
			application_status: "rejected",
		});
	};
	const handleSubmitClick = () => {
		patchExternalSeller(externalSellerState.id, {
			is_active: true,
			application_status: "accepted",
		});
	};
	const handleToggleClick = () => {
		if (
			"is_active" in externalSellerState &&
			!externalSellerState?.is_active &&
			externalSellerState.application_status === "rejected"
		)
			patchExternalSeller(externalSellerState.id, {
				is_active: true,
				application_status: "accepted",
			});
		else {
			patchExternalSeller(externalSellerState.id, {
				is_active: false,
				application_status: "rejected",
			});
		}
	};

	return sellerState && sellerState?.external_id ? (
		<section className="personal-area-item edit viewer-statistic">
			<header className="personal-area edit-videos-header" style={{ marginBottom: "0" }}>
				<button className="form-page-back" onClick={() => navigate("/sellers")}>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M11.9142 7.50015L11.2071 8.20726L8.41421 11.0002H18H19V13.0002H18H8.41421L11.2071 15.793L11.9142 16.5002L10.5 17.9144L9.79289 17.2073L5.29289 12.7073C4.90237 12.3167 4.90237 11.6836 5.29289 11.293L9.79289 6.79304L10.5 6.08594L11.9142 7.50015Z"
							fill="#94A0B1"
						/>
					</svg>
					Вернуться назад
				</button>
				<div className="title-wrapper">
					<StyledTitleWrapper>
						{externalSellerState &&
						externalSellerState.avatar_url &&
						externalSellerState.avatar_url?.length > 0 ? (
							<img src={externalSellerState.avatar_url} alt="profile" />
						) : (
							<img src="images/profile-frame.png" alt="profile" />
						)}
						<h1 style={{ margin: "0" }}>
							{sellerState.title},
							<br />
							ID {sellerState.id}
						</h1>
					</StyledTitleWrapper>
					{externalSellerState && externalSellerState?.application_status === "accepted" ? (
						<div className="list-title-selects">
							<div className="list-title-date">
								<span>
									{`${
										filtersState.date_key == 4 ? "..." : formatedDate(filtersState.from_datetime)
									} — ${formatedDate(filtersState.to_datetime)}`}
								</span>
							</div>
							<PeriodFilters filtersState={filtersState} setFiltersState={setFiltersState} />
						</div>
					) : null}
				</div>
			</header>
			<HeaderWrapper className="statistic-type-title-seller">
				<Cluster align="center" space="16px">
					{externalSellerState &&
					externalSellerState.application_status === "accepted" &&
					externalSellerState.is_active === true ? (
						<div className="small-container-seller">
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg">
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M13 0C12.4477 0 12 0.447715 12 1V11C12 11.5523 12.4477 12 13 12H23C23.5523 12 24 11.5523 24 11C24 4.92487 19.0751 0 13 0ZM14 10V2.05493C18.1716 2.51608 21.4839 5.82838 21.9451 10H14ZM0 13.0001C0 7.26204 4.3935 2.55015 10 2.04492V4.05501C5.50005 4.55245 2 8.36753 2 13.0001C2 17.9706 6.02944 22.0001 11 22.0001C15.6326 22.0001 19.4476 18.5 19.9451 14.0001H21.9552C21.4499 19.6066 16.738 24.0001 11 24.0001C4.92487 24.0001 0 19.0752 0 13.0001Z"
									fill="#212121"
								/>
							</svg>
							<span
								className="animte-menu"
								disabled
								onClick={() =>
									navigate(
										`/statistic?selected_sellers=${sellerState.id}&sellerName=${sellerState.title}`
									)
								}>
								Статистика по типсам
							</span>
						</div>
					) : externalSellerState && externalSellerState.application_status === "not_accepted" ? (
						<div style={{ display: "flex", alignItems: "center" }}>
							<InfoButton>На рассмотрении</InfoButton>
						</div>
					) : (
						<InfoButton>Отключен</InfoButton>
					)}
					<DText14 style={{ color: "#94A0B1", cursor: "default" }}>
						Дата регистрации {formatDate(sellerState?.created_at)?.split(",")?.[0]}
					</DText14>
				</Cluster>

				{externalSellerState && externalSellerState.application_status === "not_accepted" ? (
					checkUserWritePerm(userAccessState, "sellers") ? (
						<SelectButtonsWrapper>
							<SubmitButton onClick={() => handleSubmitClick()}>Принять</SubmitButton>
							<CancelButton onClick={() => handleCancelClick()}>Отклонить</CancelButton>
						</SelectButtonsWrapper>
					) : null
				) : (
					<div
						id="product-tumbler"
						className={`${!externalSellerState?.is_active && "disabled"} ${
							!checkUserWritePerm(userAccessState, "sellers") ? "readOnly" : ""
						}`}
						onClick={() => checkUserWritePerm(userAccessState, "sellers") && handleToggleClick()}>
						<div id="product-tumbler-text">
							<span>Активно</span>
							<span>Приостановлено</span>
						</div>
					</div>
				)}
			</HeaderWrapper>

			{externalSellerState && externalSellerState.application_status === "accepted" ? (
				<>
					<div className="short-cards-wrapper">
						<div className="card-wrapper blue">
							<div className="card-wrapper__first-col">
								<div className="card-wrapper__title">Цена за типс</div>
								<div className="card-wrapper__value" style={{ userSelect: "text" }}>
									{sellerState.platforms?.length > 0
										? sellerState.platforms?.[0]?.price + " ₽"
										: "Нет значений"}
								</div>
							</div>
							<div className="card-wrapper__second-col">
								<div className="card-wrapper__icon">
									<svg
										width="32"
										height="32"
										viewBox="0 0 32 32"
										fill="none"
										xmlns="http://www.w3.org/2000/svg">
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="m14.058 4.414-.57.822-4.913 7.098h14.85L18.51 5.236l-.569-.822 1.644-1.138.57.822 5.701 8.236h3.476a1 1 0 0 1 .98 1.197l-2.638 13.063A3 3 0 0 1 24.735 29H7.265a3 3 0 0 1-2.94-2.406l-2.64-13.063a1 1 0 0 1 .98-1.197h3.477l5.702-8.236.569-.823 1.644 1.139Zm11.257 9.92H28.11l-2.397 11.864a1 1 0 0 1-.98.802H7.266a1 1 0 0 1-.98-.802L3.889 14.334H25.315Z"
											fill="#73ACFF"
										/>
									</svg>
								</div>
							</div>
						</div>
						<div className="card-wrapper pink">
							<div className="card-wrapper__first-col">
								<div className="card-wrapper__title">Просмотров типсов</div>
								<div className="card-wrapper__value" style={{ userSelect: "text" }}>
									{tips?.show?.pageInfo?.itemsCount || 0}
								</div>
							</div>
							<div className="card-wrapper__second-col">
								<div className="card-wrapper__icon">
									<svg
										width="32"
										height="32"
										viewBox="0 0 32 32"
										fill="none"
										xmlns="http://www.w3.org/2000/svg">
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M4.667 5a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1H12a1 1 0 0 1 .858.485L16 28.723l3.143-5.238A1 1 0 0 1 20 23h7.333a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1H4.667Zm-3 1a3 3 0 0 1 3-3h22.666a3 3 0 0 1 3 3v16a3 3 0 0 1-3 3h-6.767l-3.708 6.181a1 1 0 0 1-1.715 0L11.433 25H4.668a3 3 0 0 1-3-3V6Z"
											fill="#FF8ECA"
										/>
									</svg>
								</div>
							</div>
						</div>
						<div className="card-wrapper yellow">
							<div className="card-wrapper__first-col">
								<div className="card-wrapper__title">Клики типсов</div>
								<div className="card-wrapper__value" style={{ userSelect: "text" }}>
									{tips?.click?.pageInfo?.itemsCount || 0}
								</div>
							</div>
							<div className="card-wrapper__second-col">
								<div className="card-wrapper__icon">
									<svg
										width="32"
										height="32"
										viewBox="0 0 32 32"
										fill="none"
										xmlns="http://www.w3.org/2000/svg">
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M21.3998 22.6278L23.8355 27.4071C24.0556 27.8395 23.8817 28.367 23.4463 28.5857L20.8207 29.9052C20.3853 30.1238 19.8541 29.9512 19.634 29.5187L17.1983 24.7394L13 27.3485L13.0096 12.8775C13.0096 12.1551 13.8406 11.7433 14.4228 12.1773L26 20.814L21.3998 22.6278Z"
											stroke="#F2BB2D"
											strokeWidth="2"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M17 0.667969C17.5523 0.667969 18 1.11568 18 1.66797V4.33464C18 4.88692 17.5523 5.33464 17 5.33464C16.4478 5.33464 16 4.88692 16 4.33464V1.66797C16 1.11568 16.4478 0.667969 17 0.667969ZM26.1871 5.89461C26.5776 5.50408 26.5776 4.87092 26.1871 4.48039C25.7966 4.08987 25.1634 4.08987 24.7729 4.48039L22.8929 6.36039C22.5023 6.75092 22.5023 7.38408 22.8929 7.77461C23.2834 8.16513 23.9166 8.16513 24.3071 7.77461L26.1871 5.89461ZM2 13.6667C1.44772 13.6667 1 14.1144 1 14.6667C1 15.219 1.44772 15.6667 2 15.6667H4.66667C5.21895 15.6667 5.66667 15.219 5.66667 14.6667C5.66667 14.1144 5.21895 13.6667 4.66667 13.6667H2ZM26.3334 14.6667C25.7811 14.6667 25.3334 15.1144 25.3334 15.6667C25.3334 16.219 25.7811 16.6667 26.3334 16.6667H29C29.5523 16.6667 30 16.219 30 15.6667C30 15.1144 29.5523 14.6667 29 14.6667H26.3334ZM8.107 21.5624C8.49753 21.9529 8.49753 22.5861 8.107 22.9766L6.227 24.8566C5.83648 25.2472 5.20331 25.2472 4.81279 24.8566C4.42227 24.4661 4.42227 23.8329 4.81279 23.4424L6.69279 21.5624C7.08331 21.1719 7.71648 21.1719 8.107 21.5624ZM7.227 3.48039C6.83648 3.08987 6.20332 3.08987 5.81279 3.48039C5.42227 3.87092 5.42227 4.50408 5.81279 4.89461L7.69279 6.77461C8.08331 7.16513 8.71648 7.16513 9.107 6.77461C9.49753 6.38408 9.49753 5.75092 9.107 5.36039L7.227 3.48039Z"
											fill="#F2BB2D"
										/>
									</svg>
								</div>
							</div>
						</div>
						<div className="card-wrapper purple double">
							<div className="card-wrapper__first-col">
								<div className="card-wrapper__title">Товаров</div>
								<div className="card-wrapper__value" style={{ userSelect: "text" }}>
									{categoriesCount || 0}
								</div>
							</div>
							<div className="divider"></div>
							<div className="card-wrapper__second-col">
								<div className="card-wrapper__title">Услуг</div>
								<div className="card-wrapper__value" style={{ userSelect: "text" }}>
									{servicesCount || 0}
								</div>
							</div>
						</div>
					</div>
					<МeaningfulStatisticCards
						balanceState={balanceState}
						balance={externalSellerState?.balance / 100 || 0}
					/>
					<div className="flex-wrapper">
						<SellerContacts externalSellerState={externalSellerState} />
					</div>
					<PaymentsHistoryPage id={currentSeller.external_id} balanceState={balanceState} />
					<CreateDocumentBlock id={currentSeller.external_id} />
					<BestCategories sellerState={sellerState} filtersState={filtersState} />
				</>
			) : (
				<div className="flex-wrapper">
					<SellerContacts externalSellerState={externalSellerState} />
				</div>
			)}
		</section>
	) : null;
};

const mapStateToProps = (state) => {
	return {
		currentSeller: state.currentSeller,
		externalSeller: state.externalSeller,
		areasList: state.areasList,
	};
};

const mapDispatchToProps = {
	getCurrentSeller,
	getExternalSeller,
	patchExternalSeller,
};

export default connect(mapStateToProps, mapDispatchToProps)(SellerStatisticComponent);
