import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { postLogout } from "../../../Actions/AuthorizationActions";
import { AsideToggleContext } from "../../PersonalArea/context/AsideToggleContext";
import "./styles/Sidebar.scss";

const Sidebar = ({ userState }) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();
	const [currentPageState, setCurrentPageState] = useState("/");
	const { toggle } = React.useContext(AsideToggleContext);
	useEffect(() => {
		setCurrentPageState(location.pathname);
	}, [location]);

	const handleLogout = () => {
		if (localStorage.getItem("access") !== null) {
			localStorage.removeItem("access");
			localStorage.removeItem("user");
		}
		if (localStorage.getItem("refresh") !== null) {
			localStorage.removeItem("refresh");
		}
		dispatch(postLogout());
		navigate("/sign_in");
	};
	return (
		<aside className={`aside_menu animte-menu ${toggle ? "closed" : ""}`} id="main-menu">
			<Link className="inner-logo" id="inner-logo" to="/">
				<div id="inner-logo-colors" className="animte-menu">
					<div className="inner-logo-color animte-menu">
						<img src="images/icons/main/logo_color.svg" alt="color" />
					</div>
					<div className="inner-logo-color animte-menu">
						<img src="images/icons/main/logo_color.svg" alt="color" />
					</div>
					<div className="inner-logo-color animte-menu">
						<img src="images/icons/main/logo_color.svg" alt="color" />
					</div>
				</div>
				<img
					src="images/icons/main/logo.svg"
					alt="logotype"
					id="logo-inner-image"
					className="animte-menu"
				/>
			</Link>
			<ul className="inner-menu-list inner-menu">
				<li className={`inner-menu-list__item ${currentPageState === "/" ? "active" : ""}`}>
					<Link to="/">
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M11.4855 0.142507C11.8022 -0.0475024 12.1978 -0.0475023 12.5145 0.142507L22.5145 6.14251C22.8157 6.32323 23 6.64874 23 7L23 22C23 22.5523 22.5523 23 22 23H16H8H6.8H2C1.44772 23 1 22.5523 1 22L1 7C1 6.64874 1.1843 6.32323 1.4855 6.14251L11.4855 0.142507ZM12 2.16619L3 7.56619V21H6.8H7L7 19L7 16L7 12C7 11.4477 7.44772 11 8 11L16 11C16.2652 11 16.5196 11.1054 16.7071 11.2929C16.8946 11.4804 17 11.7348 17 12V16V19V21H21L21 7.56619L12 2.16619ZM15 21V19L15 16V13L9 13V16V19V21H15Z"
								fill="#212121"
							/>
						</svg>
						<span className="animte-menu">Главная</span>
					</Link>
				</li>
				{userState !== null ? (
					<>
						{userState.brightboxes > 0 ? (
							<li
								className={`inner-menu-list__item ${
									currentPageState === "/tips" ||
									currentPageState === "/owner-tips" ||
									currentPageState === "/blogger-tips"
										? "active"
										: ""
								}`}
							>
								<Link to="/tips">
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M4 4C3.44772 4 3 4.44772 3 5V16C3 16.5523 3.44772 17 4 17H9C9.35126 17 9.67677 17.1843 9.85749 17.4855L12 21.0563L14.1425 17.4855C14.3232 17.1843 14.6487 17 15 17H20C20.5523 17 21 16.5523 21 16V5C21 4.44772 20.5523 4 20 4H4ZM1 5C1 3.34315 2.34315 2 4 2H20C21.6569 2 23 3.34315 23 5V16C23 17.6569 21.6569 19 20 19H15.5662L12.8575 23.5145C12.6768 23.8157 12.3513 24 12 24C11.6487 24 11.3232 23.8157 11.1425 23.5145L8.43381 19H4C2.34315 19 1 17.6569 1 16V5Z"
											fill="#212121"
										/>
									</svg>
									<span className="animte-menu">Типсы</span>
								</Link>
							</li>
						) : null}

						{userState.videos > 0 ? (
							<li
								className={`inner-menu-list__item ${
									currentPageState === "/videos" ||
									currentPageState === "/film" ||
									currentPageState === "/newfilm" ||
									currentPageState === "/newserial" ||
									currentPageState === "/blogger-video" ||
									currentPageState === "/owner-videos" ||
									currentPageState === "/blogger-videos" ||
									currentPageState === "/serial"
										? "active"
										: ""
								}`}
							>
								<Link to="/videos">
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<g clipPath="url(#clip0_1442_3436)">
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM16.0347 12.8619L10.507 16.1135C9.84039 16.5057 9 16.025 9 15.2516V8.74842C9 7.975 9.84039 7.49435 10.507 7.88648L16.0347 11.1381C16.692 11.5247 16.692 12.4753 16.0347 12.8619Z"
												fill="#212121"
											/>
										</g>
										<defs>
											<clipPath id="clip0_1442_3436">
												<rect width="24" height="24" fill="white" />
											</clipPath>
										</defs>
									</svg>
									<span className="animte-menu">Видео</span>
								</Link>
							</li>
						) : null}

						{userState.statistics > 0 ? (
							<li
								className={`inner-menu-list__item ${
									currentPageState === "/statistic" ? "active" : ""
								}`}
							>
								<Link to="/statistic">
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M13 0C12.4477 0 12 0.447715 12 1V11C12 11.5523 12.4477 12 13 12H23C23.5523 12 24 11.5523 24 11C24 4.92487 19.0751 0 13 0ZM14 10V2.05493C18.1716 2.51608 21.4839 5.82838 21.9451 10H14ZM0 13.0001C0 7.26204 4.3935 2.55015 10 2.04492V4.05501C5.50005 4.55245 2 8.36753 2 13.0001C2 17.9706 6.02944 22.0001 11 22.0001C15.6326 22.0001 19.4476 18.5 19.9451 14.0001H21.9552C21.4499 19.6066 16.738 24.0001 11 24.0001C4.92487 24.0001 0 19.0752 0 13.0001Z"
											fill="#212121"
										/>
									</svg>
									<span className="animte-menu">Статистика</span>
								</Link>
							</li>
						) : null}

						{userState.bloggers > 0 ? (
							<li
								className={`inner-menu-list__item ${
									currentPageState === "/bloggers" || currentPageState === "/bloggers-statistic"
										? "active"
										: ""
								}`}
							>
								<Link to="/bloggers">
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M12 1C12.3508 1 12.676 1.18381 12.8568 1.48436L16.2591 7.13783L22.6873 8.6266C23.029 8.70575 23.3043 8.95818 23.4127 9.2918C23.5211 9.62542 23.4468 9.99144 23.2168 10.2563L18.8914 15.2391L19.4619 21.8127C19.4923 22.1622 19.3372 22.502 19.0535 22.7082C18.7697 22.9144 18.3986 22.9568 18.0756 22.82L12 20.246L5.92448 22.82C5.60148 22.9568 5.2304 22.9144 4.94661 22.7082C4.66281 22.502 4.50781 22.1622 4.53814 21.8127L5.10864 15.2391L0.783241 10.2563C0.553287 9.99144 0.478951 9.62542 0.587351 9.2918C0.69575 8.95818 0.971034 8.70575 1.31278 8.6266L7.74091 7.13783L11.1432 1.48436C11.3241 1.18381 11.6492 1 12 1ZM12 3.93936L9.23608 8.53209C9.09645 8.7641 8.8687 8.92957 8.6049 8.99067L3.38284 10.2001L6.89669 14.248C7.0742 14.4525 7.16119 14.7202 7.13778 14.99L6.67432 20.3302L11.6099 18.2392C11.8593 18.1336 12.1408 18.1336 12.3901 18.2392L17.3257 20.3302L16.8623 14.99C16.8389 14.7202 16.9259 14.4525 17.1034 14.248L20.6172 10.2001L15.3952 8.99067C15.1314 8.92957 14.9036 8.7641 14.764 8.53209L12 3.93936Z"
											fill="#212121"
										/>
									</svg>

									<span className="animte-menu">Блогеры</span>
								</Link>
							</li>
						) : null}

						{userState.sellers > 0 ? (
							<li
								className={`inner-menu-list__item ${
									currentPageState === "/sellers" || currentPageState === "/sellers-statistic"
										? "active"
										: ""
								}`}
							>
								<Link to="/sellers">
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M8 8C8 5.79086 9.79086 4 12 4C14.2091 4 16 5.79086 16 8C16 10.2091 14.2091 12 12 12C9.79086 12 8 10.2091 8 8ZM12 2C8.68629 2 6 4.68629 6 8C6 11.3137 8.68629 14 12 14C15.3137 14 18 11.3137 18 8C18 4.68629 15.3137 2 12 2ZM8 16C4.68629 16 2 18.6863 2 22H4C4 19.7909 5.79086 18 8 18H16C18.2091 18 20 19.7909 20 22H22C22 18.6863 19.3137 16 16 16H8Z"
											fill="#212121"
										></path>
									</svg>
									<span className="animte-menu">Рекламодатели</span>
								</Link>
							</li>
						) : null}

						{userState.viewers > 0 ? (
							<li
								className={`inner-menu-list__item ${
									currentPageState === "/viewers" || currentPageState === "/viewers-statistic"
										? "active"
										: ""
								}`}
							>
								<Link to="/viewers">
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M13 8C13 6.34315 14.3431 5 16 5C17.6569 5 19 6.34315 19 8C19 9.65685 17.6569 11 16 11C14.3431 11 13 9.65685 13 8ZM16 3C13.2386 3 11 5.23858 11 8C11 10.7614 13.2386 13 16 13C18.7614 13 21 10.7614 21 8C21 5.23858 18.7614 3 16 3ZM4 10C4 8.89543 4.89543 8 6 8C7.10457 8 8 8.89543 8 10C8 11.1046 7.10457 12 6 12C4.89543 12 4 11.1046 4 10ZM6 6C3.79086 6 2 7.79086 2 10C2 12.2091 3.79086 14 6 14C8.20914 14 10 12.2091 10 10C10 7.79086 8.20914 6 6 6ZM8 21C8 17.6863 10.6863 15 14 15L18 15C21.3137 15 24 17.6863 24 21H22C22 18.7909 20.2091 17 18 17H14C11.7909 17 10 18.7909 10 21H8ZM2 21C2 18.7909 3.79086 17 6 17H8V15H6C2.68629 15 0 17.6863 0 21H2Z"
											fill="#212121"
										/>
									</svg>

									<span className="animte-menu">Зрители</span>
								</Link>
							</li>
						) : null}
						{userState.owners > 0 ? (
							<li
								className={`inner-menu-list__item ${
									currentPageState === "/owners" || currentPageState === "/owner-statistic"
										? "active"
										: ""
								}`}
							>
								<Link to="/owners">
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<g clipPath="url(#clip0_13744_11386)">
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16C13.36 16 14.5614 15.3213 15.2841 14.2841L16.7133 15.7133C15.6145 17.106 13.9117 18 12 18C8.68629 18 6 15.3137 6 12C6 8.68629 8.68629 6 12 6C13.9117 6 15.6145 6.89401 16.7133 8.28675L15.2841 9.71586C14.5614 8.6787 13.36 8 12 8Z"
												fill="#212121"
											/>
										</g>
										<defs>
											<clipPath id="clip0_13744_11386">
												<rect width="24" height="24" fill="white" />
											</clipPath>
										</defs>
									</svg>

									<span className="animte-menu">Правообладатели</span>
								</Link>
							</li>
						) : null}

						{userState.categories > 0 ? (
							<li
								className={`inner-menu-list__item ${
									currentPageState === "/categories" ? "active" : ""
								}`}
							>
								<Link to="/categories">
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M2 7h3V5H2v2Zm6 0h14V5H8v2Zm0 6h14v-2H8v2Zm14 6H8v-2h14v2ZM5 13H2v-2h3v2Zm-3 6h3v-2H2v2Z"
											fill="#212121"
										/>
									</svg>
									<span className="animte-menu">Категории типсов</span>
								</Link>
							</li>
						) : null}
						{userState.categories > 0 ? (
							<li
								className={`inner-menu-list__item ${
									currentPageState === "/category-attributes" ? "active" : ""
								}`}
							>
								<Link to="/category-attributes">
									<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M3 1H8c1.135 0 2.026.215 2.785.557.727.327 1.302.758 1.787 1.122l.028.021c.51.382.926.69 1.435.92.49.22 1.1.38 1.965.38H21a1 1 0 0 1 1 1v11a1 1 0 0 1-1 1H16c-1.135 0-2.026-.215-2.785-.557-.727-.327-1.302-.758-1.787-1.122L11.4 15.3c-.51-.382-.926-.69-1.435-.92-.49-.22-1.1-.38-1.965-.38H4v9H2V2a1 1 0 0 1 1-1Zm17 14V6H16c-1.135 0-2.026-.215-2.785-.557-.727-.327-1.302-.758-1.787-1.122L11.4 4.3c-.51-.382-.926-.69-1.435-.92C9.475 3.16 8.865 3 8 3H4v9H8c1.135 0 2.026.215 2.785.557.727.327 1.302.758 1.787 1.122l.028.021c.51.382.926.69 1.435.92.49.22 1.1.38 1.965.38H20Z"
											fill="#212121"
										/>
									</svg>
									<span className="animte-menu">Атрибуты</span>
								</Link>
							</li>
						) : null}

						{userState.playlists > 0 ? (
							<li
								className={`inner-menu-list__item ${
									currentPageState === "/playlist" ? "active" : ""
								}`}
							>
								<Link to="/playlist">
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M4 2C2.89543 2 2 2.89543 2 4L2 9C2 10.1046 2.89543 11 4 11H9C10.1046 11 11 10.1046 11 9V4C11 2.89543 10.1046 2 9 2L4 2ZM4 4L9 4V9H4V4ZM15 2C13.8954 2 13 2.89543 13 4V9C13 10.1046 13.8954 11 15 11L20 11C21.1046 11 22 10.1046 22 9V4C22 2.89543 21.1046 2 20 2L15 2ZM15 4H20V9H15V4ZM13 15C13 13.8954 13.8954 13 15 13L20 13C21.1046 13 22 13.8954 22 15V20C22 21.1046 21.1046 22 20 22H15C13.8954 22 13 21.1046 13 20L13 15ZM20 15L15 15L15 20H20V15ZM4 13C2.89543 13 2 13.8954 2 15V20C2 21.1046 2.89543 22 4 22H9C10.1046 22 11 21.1046 11 20L11 15C11 13.8954 10.1046 13 9 13H4ZM4 15H9V20H4V15Z"
											fill="#212121"
										/>
									</svg>
									<span className="animte-menu">Плейлисты</span>
								</Link>
							</li>
						) : null}

						{userState.prices > 0 ? (
							<li
								className={`inner-menu-list__item ${
									currentPageState === "/prices" ? "active" : ""
								}`}
							>
								<Link to="/prices">
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12ZM11 8H11.5C12.6751 8 13.5931 8.17962 14.1844 8.51879C14.6967 8.81264 15 9.23668 15 10C15 10.7633 14.6967 11.1874 14.1844 11.4812C13.5931 11.8204 12.6751 12 11.5 12H11V8ZM11 14H11.5C12.8102 14 14.1421 13.8111 15.1796 13.2161C16.296 12.5757 17 11.4997 17 10C17 8.50029 16.296 7.42433 15.1796 6.78394C14.1421 6.18887 12.8102 6 11.5 6H10C9.44772 6 9 6.44772 9 7V12H8H7V14H8H9V15H8H7V17H8H9V18V19H11V18V17H13H14V15H13H11V14Z"
											fill="#212121"
										/>
									</svg>
									<span className="animte-menu">Цены</span>
								</Link>
							</li>
						) : null}

						{userState.politics > 0 ? (
							<li
								className={`inner-menu-list__item ${
									currentPageState === "/politics" ? "active" : ""
								}`}
							>
								<Link to="/politics">
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<g clipPath="url(#a)">
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M4 6a1 1 0 0 1 1-1h9a1 1 0 1 0 0-2H5a3 3 0 0 0-3 3v13a3 3 0 0 0 3 3h13a3 3 0 0 0 3-3v-6a1 1 0 1 0-2 0v6a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6Zm7.707 9.707 10-10a1 1 0 0 0-1.414-1.414L11 13.586l-2.293-2.293a1 1 0 0 0-1.414 1.414l3 3a1 1 0 0 0 1.414 0Z"
												fill="#212121"
											/>
										</g>
										<defs>
											<clipPath id="a">
												<path fill="#fff" d="M0 0h24v24H0z" />
											</clipPath>
										</defs>
									</svg>
									<span className="animte-menu">Политика</span>
								</Link>
							</li>
						) : null}

						{userState.users > 0 ? (
							<li
								className={`inner-menu-list__item ${
									currentPageState === "/users" ||
									currentPageState === "/user" ||
									currentPageState === "/newuser"
										? "active"
										: ""
								}`}
							>
								<Link to="/users">
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M13.4458 0.654067C12.5455 0.158931 11.4545 0.15893 10.5542 0.654067L2.55424 5.05407C1.5956 5.58132 1 6.58864 1 7.68272V16.3176C1 17.4117 1.5956 18.419 2.55424 18.9463L10.5542 23.3463C11.4545 23.8414 12.5455 23.8414 13.4458 23.3463L21.4458 18.9463C22.4044 18.419 23 17.4117 23 16.3176V7.68271C23 6.58864 22.4044 5.58132 21.4458 5.05407L13.4458 0.654067ZM11.5181 2.4065C11.8182 2.24145 12.1818 2.24145 12.4819 2.4065L20.4819 6.8065C20.8015 6.98225 21 7.31802 21 7.68271V16.3176C21 16.6823 20.8015 17.0181 20.4819 17.1938L12.4819 21.5938C12.1818 21.7589 11.8182 21.7589 11.5181 21.5938L3.51808 17.1938C3.19853 17.0181 3 16.6823 3 16.3176V7.68272C3 7.31802 3.19853 6.98225 3.51808 6.8065L11.5181 2.4065ZM9 12.0002C9 10.3433 10.3431 9.00017 12 9.00017C13.6569 9.00017 15 10.3433 15 12.0002C15 13.657 13.6569 15.0002 12 15.0002C10.3431 15.0002 9 13.657 9 12.0002ZM12 7.00017C9.23858 7.00017 7 9.23875 7 12.0002C7 14.7616 9.23858 17.0002 12 17.0002C14.7614 17.0002 17 14.7616 17 12.0002C17 9.23875 14.7614 7.00017 12 7.00017Z"
											fill="#212121"
										/>
									</svg>
									<span className="animte-menu">Пользователи</span>
								</Link>
							</li>
						) : null}
						{userState.reports > 0 ? (
							<li
								className={`inner-menu-list__item ${
									currentPageState === "/reports" || currentPageState === "/report-detail"
										? "active"
										: ""
								}`}
							>
								<Link to="/reports">
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M5 10H4C3.44728 10 3 10.4473 3 11L3 14C3 14.5527 3.44728 15 4 15H5V10ZM12 1C7.82629 1 4.2929 4.03171 4.01729 8H4C2.34272 8 1 9.34272 1 11L1 14C1 15.6573 2.34272 17 4 17H5C6.10428 17 7 16.1043 7 15V10C7 9.26211 6.60006 8.61735 6.00521 8.27073C6.13567 5.40167 8.70252 3 12 3C15.2975 3 17.8643 5.40167 17.9948 8.27073C17.3999 8.61735 17 9.26211 17 10V15C17 15.753 17.4164 16.4089 18.0315 16.7501C18.0109 16.83 18 16.9137 18 17V18C18 18.5819 17.5511 19 17.0606 19H15.4707C14.9667 18.3756 14.1637 18 13.3333 18H11.6667C10.2961 18 9 19.0232 9 20.5C9 21.9768 10.2961 23 11.6667 23H13.3333C14.5432 23 15.6949 22.2027 15.9485 21H17.0606C18.7132 21 20 19.6281 20 18V17C21.6573 17 23 15.6573 23 14V11C23 9.34272 21.6573 8 20 8H19.9827C19.7071 4.03171 16.1737 1 12 1ZM19 10V15H20C20.5527 15 21 14.5527 21 14V11C21 10.4473 20.5527 10 20 10H19ZM11.6667 20C11.1972 20 11 20.3208 11 20.5C11 20.6792 11.1972 21 11.6667 21H13.3333C13.8028 21 14 20.6792 14 20.5C14 20.3208 13.8028 20 13.3333 20H11.6667Z"
											fill="#212121"
										/>
									</svg>

									<span className="animte-menu">Жалобы</span>
								</Link>
							</li>
						) : null}
					</>
				) : null}
			</ul>

			<button className="button_logout" onClick={() => handleLogout()}>
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M11 1V11H13V1H11ZM3 12.0476C3 8.44058 5.14515 5.32707 8.24421 3.90936L7.41221 2.09064C3.63168 3.82009 1 7.62543 1 12.0476C1 18.1009 5.92937 23 12 23C18.0706 23 23 18.1009 23 12.0476C23 7.62543 20.3683 3.82009 16.5878 2.09064L15.7558 3.90936C18.8549 5.32707 21 8.44058 21 12.0476C21 16.9874 16.9751 21 12 21C7.02493 21 3 16.9874 3 12.0476Z"
						fill="#212121"
					/>
				</svg>
				<span className="animte-menu">Выйти</span>
			</button>
		</aside>
	);
};

export default Sidebar;
