import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { DTBold15 } from "../../../../ui-kit/typography/Typography";

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	margin-top: 22px;
	margin-bottom: 24px;
	&::before {
		content: "";
		background: #e2e8f0;
		display: block;
		height: 1px;
		position: absolute;
		top: calc(50% - 1px);
		width: 100%;
	}
`;

const Tab = styled.div`
	z-index: 1;
	width: 48px;
	height: 48px;
	background: #ffffff;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 2px solid #e2e8f0;
	border-radius: 80px;
	color: #94a0b1;
	&.behind {
		border: 2px solid #0dc268;
		background: #0dc268;
		color: white;
	}
	&.active {
		background: #e2e8f0;
		color: #212121;
	}
`;

const AddOwnerTabs = ({ active = 2 }) => {
	let navigate = useNavigate();

	return (
		<Wrapper>
			{[1, 2, 3].map((elem) => {
				if (elem === active) {
					return (
						<Tab className="active" key={elem}>
							<DTBold15>{elem}</DTBold15>
						</Tab>
					);
				} else if (elem < active) {
					return (
						<Tab className="behind" key={elem}>
							<DTBold15>{elem}</DTBold15>
						</Tab>
					);
				} else
					return (
						<Tab key={elem}>
							<DTBold15>{elem}</DTBold15>
						</Tab>
					);
			})}
		</Wrapper>
	);
};

export default AddOwnerTabs;
