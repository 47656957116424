const initialOwners = {
	list: [],
	pagination: {
		max_elements: 0,
		max_pages: 0,
		page: 0,
		limit: 0,
	},
};

const initialOwnerTab = {
	active: initialOwners,
	blocked: initialOwners,
};

export const OwnersReducer = (state = initialOwnerTab, action) => {
	switch (action.type) {
		case "GET_ALL_ACTIVE_OWNERS":
			return {
				...state,
				active: {
					list: action.owner.data.items,
					pagination: {
						max_elements: action.owner.data.paginate_.total_rows,
						max_pages: Math.ceil(
							action.owner.data.paginate_.total_rows / action.owner.data.paginate_.limit
						),
						page: Math.round(
							action.owner.data.paginate_.offset / action.owner.data.paginate_.limit
						),
						limit: action.owner.data.paginate_.limit,
					},
				},
			};
		case "POST_OWNER":
			return {
				...state,
				active: {
					list: [...state.active.list, action.owner.data],
					pagination: {
						...state.active.pagination,
						max_elements: state.active.pagination.max_elements + 1,
					},
				},
			};
		case "GET_ALL_BLOCKED_OWNERS":
			return {
				...state,
				blocked: {
					list: action.owner.data.items,
					pagination: {
						max_elements: action.owner.data.paginate_.total_rows,
						max_pages: Math.ceil(
							action.owner.data.paginate_.total_rows / action.owner.data.paginate_.limit
						),
						page: Math.round(
							action.owner.data.paginate_.offset / action.owner.data.paginate_.limit
						),
						limit: action.owner.data.paginate_.limit,
					},
				},
			};
		default:
			return state;
	}
};

export const CurrentOwnerReducer = (state = null, action) => {
	switch (action.type) {
		case "GET_CURRENT_OWNER":
			return action.owner.data;
		case "PUT_CURRENT_OWNER":
			return action.owner.data;
		default:
			return state;
	}
};

const initialOwnersTotal = {
	1: 0,
	3: 0,
};

export const OwnersTotalReducer = (state = initialOwnersTotal, action) => {
	switch (action.type) {
		case "GET_ACTIVE_OWNERS_TOTAL": {
			return {
				...state,
				1: action.owner.data.paginate_.total_rows,
			};
		}
		case "GET_BLOCKED_OWNERS_TOTAL": {
			return {
				...state,
				3: action.owner.data.paginate_.total_rows,
			};
		}
		default:
			return state;
	}
};
