import React, { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { getSellerPriceList } from "../../../../Actions/PriceActions";
import { ModalContext } from "../../../modal/ModalContext/ModalContext.tsx";
import ModalAddSeller from "../unversalComponents/ModalAddSeller.jsx";
import ModalEditPrice from "../unversalComponents/ModalEditPrice.jsx";
import ModalSellerPriceHistory from "../unversalComponents/ModalSellerPriceHistory";
import ShowMoreButton from "../../../universal/ShowMore/ShowMoreButton";
import { useUserAccessContext } from "../../context/UserAccessContext";
import { checkUserWritePerm } from "../../scripts/utils";

const PersonalPricesComponent = ({ mainState, getSellerPriceList, sellerPriceList }) => {
	const { handleModal } = useContext(ModalContext);
	const [sellersState, setSellersState] = useState([]);
	const [pageState, setPageState] = useState(1);
	const { userAccessState } = useUserAccessContext();
	useEffect(() => {
		getSellerPriceList({ page: pageState, page_size: 100, base_price: false });
	}, [pageState]);

	useEffect(() => {
		setSellersState(sellerPriceList.list);
	}, [sellerPriceList]);

	return !!mainState.area ? (
		<>
			<div className="list-title">
				<div className="list-title-text">
					<h2>Персональные цены</h2>
				</div>
				<button
					className="button with-icon action-button"
					disabled={!checkUserWritePerm(userAccessState, "prices")}
					style={{ padding: "0 26px 0 20px" }}
					onClick={() =>
						handleModal(
							<ModalAddSeller
								onReady={() =>
									getSellerPriceList({ page: pageState, page_size: 100, base_price: false })
								}
							/>
						)
					}
				>
					<img src="images/icons/ui/actions/white/plus.svg" alt="плюс" />
					Добавить продавца
				</button>
			</div>

			<div className="list-table-header seler-price-list">
				<div className="list-table-header-element">Продавец</div>
				<div className="list-table-header-element">ID на платформе</div>
				<div className="list-table-header-element">Стоимость клика</div>
			</div>
			{sellersState.length > 0 ? (
				<div className="list-table seler-price-list">
					{sellersState.map((elem, index) => (
						<PersonalPriceItem
							dashoboardSeller={elem}
							mainState={mainState}
							key={index}
							onEditReady={() =>
								getSellerPriceList({ page: pageState, page_size: 100, base_price: false })
							}
						/>
					))}
					{pageState < sellerPriceList.pagination.max_pages ? (
						<ShowMoreButton variant="white" onClick={() => setPageState(pageState + 1)} />
					) : null}
				</div>
			) : null}
		</>
	) : null;
};

const PersonalPriceItem = ({ dashoboardSeller, mainState, onEditReady }) => {
	const { handleModal } = useContext(ModalContext);
	const { userAccessState } = useUserAccessContext();
	return (
		<div className="list-table-item">
			<div className="list-table-item-element">
				<span>{dashoboardSeller?.title}</span>
			</div>
			<div className="list-table-item-element">{dashoboardSeller?.id}</div>
			<div className="list-table-item-element">
				<b>
					{dashoboardSeller?.platforms?.find((item) => item?.platform_id === mainState.area.id)
						?.price || mainState.area?.default_price}{" "}
					₽
				</b>
				<div
					className="list-table-item-history action-button"
					action="modal"
					target="price-history-modal"
					onClick={() => handleModal(<ModalSellerPriceHistory seller={dashoboardSeller} />)}
				>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12ZM12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM13 8V7H11V8V12C11 12.3344 11.1671 12.6466 11.4453 12.8321L14.4453 14.8321L15.2774 15.3868L16.3868 13.7227L15.5547 13.1679L13 11.4648V8Z"
							fill="#94A0B1"
						/>
					</svg>
				</div>
				{checkUserWritePerm(userAccessState, "prices") ? (
					<div
						className="list-table-item-edit action-button"
						onClick={() =>
							handleModal(
								<ModalEditPrice
									actualPrice={
										dashoboardSeller?.platforms?.find(
											(item) => item?.platform_id === mainState.area.id
										)?.price || mainState.area?.default_price
									}
									seller={dashoboardSeller}
									platform_id={mainState.area.id}
									onReady={onEditReady}
								/>
							)
						}
					>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M16.6203 2.24939C16.4328 2.06184 16.1784 1.95647 15.9132 1.95646C15.648 1.95645 15.3936 2.06181 15.2061 2.24935L5.58581 11.8695C5.21073 12.2446 5.00001 12.7533 5.00001 13.2837L5.00001 16.9999C5.00001 17.2651 5.10537 17.5195 5.2929 17.707C5.48044 17.8946 5.73479 17.9999 6.00001 17.9999H9.71622C10.2467 17.9999 10.7554 17.7892 11.1305 17.4141L20.7503 7.79399C21.1408 7.40348 21.1409 6.77035 20.7504 6.37982L16.6203 2.24939ZM7.00001 13.2837L15.9131 4.3707L18.6291 7.08687L9.71622 15.9999H7.00001L7.00001 13.2837ZM3 20.0002H2V22.0002H3H21H22V20.0002H21H3Z"
								fill="#94A0B1"
							/>
						</svg>
					</div>
				) : null}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		sellerPriceList: state.sellerPriceList,
	};
};

const mapDispatchToProps = {
	getSellerPriceList,
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalPricesComponent);
