import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { StatisticBlockContainer } from "../../../../../ui-kit/wrappers-containers/StatisticBlockContainer.jsx";
import { SellerInfoRow } from "./SellerStatisticBody.jsx";

const RowDivider = styled.div`
	width: 100%;
	height: 1px;
	background: #e2e8f0;
`;

export const OwnerContacts = ({ ownerState }) => {
	return (
		<>
			<StatisticBlockContainer className="content-wrapper">
				<div className="gadgets-statistic-header">
					<div className="gadgets-statistic-header__title">Контактные данные</div>
				</div>
				<div className="table-wrapper" style={{ marginTop: "16px" }}>
					<SellerInfoRow title="Email" value={ownerState?.email || "Не указано"} />
					<RowDivider />
					<SellerInfoRow
						title="Название организации"
						value={ownerState?.legal_name || "Не указано"}
					/>
					<RowDivider />
					<SellerInfoRow title="ИНН" value={ownerState?.inn || "Не указано"} />
					<RowDivider />
					<SellerInfoRow
						title="ОГРН или ОГРНИП"
						value={ownerState?.ogrn || ownerState?.ogrnip || "Не указано"}
					/>
					<RowDivider />
					<SellerInfoRow
						title="Юридический адрес"
						value={ownerState?.legal_address || "Не указано"}
					/>
					<RowDivider />
					<SellerInfoRow
						title="Фактический адрес"
						value={ownerState?.actual_address || "Не указано"}
					/>
					<RowDivider />
					<SellerInfoRow
						title="ФИО гендиректора или представителя по доверенности"
						value={ownerState?.ceo_name || "Не указано"}
					/>
					<RowDivider />
					<SellerInfoRow title="Телефон" value={ownerState?.phone_number || "Не указано"} />
				</div>
			</StatisticBlockContainer>
			<StatisticBlockContainer className="content-wrapper">
				<div className="gadgets-statistic-header">
					<div className="gadgets-statistic-header__title">Банковские реквизиты</div>
				</div>
				<div className="table-wrapper" style={{ marginTop: "16px" }}>
					<SellerInfoRow title="ИНН" value={ownerState?.bank_inn || "Не указано"} />
					<RowDivider />
					<SellerInfoRow title="КПП" value={ownerState?.kpp || "Не указано"} />
					<RowDivider />
					<SellerInfoRow
						title="Расчетный счет"
						value={ownerState?.checking_account || "Не указано"}
					/>
					<RowDivider />
					<SellerInfoRow title="Банк" value={ownerState?.bank_name || "Не указано"} />
					<RowDivider />
					<SellerInfoRow
						title="Корреспондентский счет"
						value={ownerState?.correspondent_account || "Не указано"}
					/>
					<RowDivider />
					<SellerInfoRow title="БИК" value={ownerState?.bik || "Не указано"} />
				</div>
			</StatisticBlockContainer>
		</>
	);
};
