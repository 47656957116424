import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Stack } from "../../../../ui-kit/layouts/Stack";
import { DText14 } from "../../../../ui-kit/typography/Typography";
import { DynamicSelect } from "../../../../ui-kit/select/DynamicSelect";
import { Status } from "../../../../ui-kit/status/Status";
import { putCurrentReport } from "../../../../Actions/ReportsActions";

export const initialStatusesCollection = [
	{
		id: 1,
		title: "Не решена",
		color: "#ED0A34",
	},
	{
		id: 2,
		title: "На проверку",
		color: "#FF9E00",
	},
	{
		id: 3,
		title: "Решена",
		color: "#0DC268",
	},
];

const initialUpStatusesCollection = [
	{
		id: 2,
		title: "На проверку",
		color: "#FF9E00",
	},
	{
		id: 3,
		title: "Решена",
		color: "#0DC268",
	},
];

const StatusSelect = ({ ticket, putCurrentReport, disabled = false }) => {
	const [statusState, setStatusState] = useState(initialStatusesCollection[0]);

	useEffect(() => {
		if (!ticket.id) return;

		setStatusState(initialStatusesCollection.find((elem) => elem.id === ticket.status));
	}, [ticket]);

	const handleSelectStatusClick = (elem) => {
		putCurrentReport(ticket.id, {
			status: elem.id,
		});
	};

	return (
		<Stack space="5px" style={{ zIndex: 10 }}>
			<DText14 style={{ color: "#94A0B1" }}>Статус</DText14>
			<DynamicSelect
				collection={
					ticket?.creator_status >= 2 ? initialUpStatusesCollection : initialStatusesCollection
				}
				disabled={disabled}
				option={StatusesOption}
				selected={statusState}
				onSelect={handleSelectStatusClick}
			/>
		</Stack>
	);
};

const mapDispatchToProps = {
	putCurrentReport,
};

export default connect(null, mapDispatchToProps)(StatusSelect);

const StatusesOption = ({ title, color }) => {
	return <Status text={title} color={color} />;
};
