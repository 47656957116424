import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import {
	getAllActiveExternalSeller,
	getAllReviewExternalSeller,
} from "../../../../Actions/SellersActions.js";
import { handleFilteredClick } from "../../scripts/PersonalAreaScripts.js";
import "../styles/SellerStyles.scss";
import { removeEmpty } from "../../../../Actions/ActionsScirpts.js";
import { CancelButton, EmptyFallback, SubmitButton } from "./Buttons.jsx";
import ExtertnalPaginationBlock from "../../../universal/PaginationBlock/ExtertnalPaginationBlock.jsx";
import axios from "axios";
import { baseUrl } from "../../../../Actions/consts.js";
import { ModalContext } from "../../../modal/ModalContext/ModalContext";
import AcceptSellerModal from "./modal/AcceptSellerModal.jsx";
import RejectSellerModal from "./modal/RejectSellerModal.jsx";
import styled from "styled-components";
import { useUserAccessContext } from "../../context/UserAccessContext.js";
import { checkUserWritePerm } from "../../scripts/utils.js";

const ReviewSellerPage = ({
	getAllReviewExternalSeller,
	sellersList,
	mainFilterState,
	setMainFilterState,
}) => {
	const [firstLoad, setFirstLoad] = useState(true);
	const [lastSearch, setLastSearch] = useState(true);
	const { handleModal } = useContext(ModalContext);
	const [searchParams, setSearchParams] = useSearchParams();
	const [sellersState, setSellersState] = useState([]);
	const { userAccessState } = useUserAccessContext();

	useEffect(() => {
		const filteredState = removeEmpty(mainFilterState);
		const filteredFilters = removeEmpty(filteredState.filters);
		getAllReviewExternalSeller({ ...filteredState });
		if ((!firstLoad && lastSearch) || filteredFilters.page !== searchParams.get("page")) {
			setSearchParams({
				...filteredState,
				filters: JSON.stringify({ ...filteredFilters }),
			});
		} else if (!lastSearch) {
			setLastSearch(true);
		}
		setFirstLoad(false);
	}, [mainFilterState]);

	useEffect(() => {
		setSellersState(sellersList.list);
	}, [sellersList]);

	const handleCancelClick = (e, id) => {
		e.stopPropagation();
		e.preventDefault();
		handleModal(<RejectSellerModal id={id} filteredState={mainFilterState} />);
	};

	const handleSubmitClick = (e, id) => {
		e.stopPropagation();
		e.preventDefault();
		handleModal(<AcceptSellerModal id={id} filteredState={mainFilterState} />);
	};

	const handleOrderClick = (key, filterState, setFilterState) => {
		if (!("order_by" in filterState)) {
			setFilterState({ ...filterState, order_by: key });
			return;
		}
		if (filterState.order_by !== key) {
			setFilterState({ ...filterState, order_by: key });
		} else {
			setFilterState({ ...filterState, order_by: `-${key}` });
		}
	};

	return (
		<div className="review-seller-page">
			{sellersState.length > 0 ? (
				<>
					<div className="list-table-header seller-list">
						<div
							className="list-table-header-element"
							style={{ width: "100%" }}
							onClick={() => handleOrderClick("name", mainFilterState, setMainFilterState)}
						>
							<span>Название</span>
						</div>

						{checkUserWritePerm(userAccessState, "sellers") ? (
							<div className="list-table-header-element" style={{ width: "218px" }}></div>
						) : null}
					</div>
					<div className="list-table stat-list selers-list">
						{sellersState.map((elem, index) => (
							<SellerListItem
								handleCancelClick={handleCancelClick}
								elem={elem}
								key={index}
								handleSubmitClick={handleSubmitClick}
							/>
						))}
					</div>
				</>
			) : (
				<EmptyFallback>Пока нет рекламодателей</EmptyFallback>
			)}
			{Object.keys(sellersList.pagination).length !== 0 &&
			!!sellersList.pagination.max_pages &&
			sellersState.length > 0 &&
			sellersList.pagination.max_pages > 1 ? (
				<ExtertnalPaginationBlock
					filterState={mainFilterState}
					setFilterState={setMainFilterState}
					pagination={sellersList.pagination}
				/>
			) : null}
		</div>
	);
};

const SellerListItem = ({ elem, handleCancelClick, handleSubmitClick }) => {
	const [sellerState, setSellerState] = useState(null);
	const { userAccessState } = useUserAccessContext();

	useEffect(() => {
		axios
			.get(`${baseUrl}/domain/sellers/${elem.id}`)
			.then((seller) => {
				setSellerState(seller.data);
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch(() => {
				return null;
			});
	}, [elem]);

	return (
		<Link to={`/sellers-statistic?id=${elem?.id}`} className="list-table-item">
			<div className="list-table-item-element">
				{elem.avatar_url && elem.avatar_url?.length > 0 ? (
					<img src={elem.avatar_url} alt="profile" />
				) : (
					<img src="images/profile-frame.png" alt="profile" />
				)}
				<div className="list-table-item-element-info">
					<span>{elem.name}</span> <small>ID {sellerState?.id}</small>
				</div>
			</div>
			{checkUserWritePerm(userAccessState, "sellers") ? (
				<div className="list-table-item-element" style={{ paddingLeft: "0" }}>
					<SubmitButton onClick={(e) => handleSubmitClick(e, elem.id)}>Принять</SubmitButton>
					<CancelButton onClick={(e) => handleCancelClick(e, elem.id)}>Отклонить</CancelButton>
				</div>
			) : null}
		</Link>
	);
};

const mapStateToProps = (state) => {
	return {
		sellersList: state.allExternalSelllersReducer.review,
	};
};

const mapDispatchToProps = {
	getAllReviewExternalSeller,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewSellerPage);
