import React, { useContext, useState } from "react";
import axios from "axios";
import { baseUrl } from "../../../../../Actions/consts";
import styled from "styled-components";
import { Stack } from "../../../../../ui-kit/layouts/Stack";
import UploadDocuments from "./UploadDocuments";
import { ModalContext } from "../../../../modal/ModalContext/ModalContext";
import { DText14 } from "../../../../../ui-kit/typography/Typography";

const StyledStack = styled(Stack)`
	width: 100%;
`;

const UploadDocumentModal = ({ external_id, onSuccess }) => {
	const { handleModal } = useContext(ModalContext);
	const [uploadState, setUploadState] = useState(null);

	const [loading, setLoading] = useState(false);

	const [title, setTitle] = useState("");
	const handleInputChange = (evt, key) => {
		// обработчик заполнение инпутов
		const val = evt.target.value; // присваиваем значение инпута
		setTitle(val); // изменяем состояние
	};

	const handleAddClick = async () => {
		setLoading(true);
		await axios
			.post(`${baseUrl}/selleracts`, {
				external_id,
				title,
				file: uploadState,
			})
			.then(() => {
				handleModal();
			})
			.finally(() => {
				onSuccess();
				setLoading(false);
			})
			.catch((error) => {
				console.log(error);
			});
	};
	return (
		<>
			<div className="add-category-wrapper">
				<div className="modal-field-title">Загрузить документ</div>
				<div className="modal-field-close" onClick={() => handleModal()}>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M4.70701 3.29304L3.99991 2.58594L2.58569 4.00015L3.2928 4.70726L10.5857 12.0002L3.2928 19.293L2.58569 20.0002L3.99991 21.4144L4.70701 20.7073L11.9999 13.4144L19.2928 20.7073L19.9999 21.4144L21.4141 20.0002L20.707 19.293L13.4141 12.0002L20.707 4.70726L21.4141 4.00015L19.9999 2.58594L19.2928 3.29304L11.9999 10.5859L4.70701 3.29304Z"
							fill="#212121"></path>
					</svg>
				</div>
				<div className="flex-block">
					<div className="form-field">
						<div className="form-field-label">Название файла</div>
						<div className="input-field">
							<input
								type="text"
								placeholder="Ввведите название файла"
								style={{ width: "440px" }}
								id="category-title"
								onChange={(e) => handleInputChange(e)}
								value={title}
							/>
						</div>
					</div>
				</div>
				<StyledStack space="5px">
					<DText14 style={{ color: "#94A0B1" }}>Прикрепить файл (не обязательно)</DText14>
					<UploadDocuments uploadState={uploadState} setUploadState={setUploadState} />
				</StyledStack>
				<div className="modal-buttons">
					<button className="button action-button border-button" onClick={() => handleModal()}>
						<span>Отменить</span>
					</button>
					<button
						disabled={!title.length || !uploadState}
						className={`button action-button create-category-button ${
							loading === 1 ? "preloader" : ""
						} ${!title.length || !uploadState ? "disabled" : ""}`}
						onClick={() => handleAddClick()}>
						<span>Загрузить</span>
					</button>
				</div>
			</div>
		</>
	);
};

export default UploadDocumentModal;
