import { nativeMapBy } from "../components/PersonalArea/scripts/mapBy.js";
export const PoliticsReducer = (state = [], action) => {
	switch (action.type) {
		case "GET_POLITICS":
			return action.politic.data;
		case "POST_DOMAIN_POLITICS":
			state.push(action.politic.data);
			return state;
		case "PUT_DOMAIN_POLITICS":
			const newArray = state.map((elem) => {
				if (elem.id === action.politic.data.id) {
					return action.politic.data;
				} else return elem;
			});
			return newArray;
		case "DELETE_POLITIC":
			const filtererState = state.filter((item, index) => item.id !== action.politic.data.id);
			return filtererState;
		default:
			return state;
	}
};

export const PoliticsLocationReducer = (state = [], action) => {
	switch (action.type) {
		case "GET_POLITICS_LOCATION":
			return action.frame.data;
		default:
			return state;
	}
};
export const BloggerPoliticsLocationReducer = (state = { list: [], map: {} }, action) => {
	switch (action.type) {
		case "GET_POLITICS_LOCATION":
			const data = action.frame.data.attributes;
			const mappedData = nativeMapBy(data, "id");
			return {
				list: data,
				map: mappedData,
			};
		default:
			return state;
	}
};
