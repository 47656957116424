import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { putCategories } from "../../../Actions/CategoriesActions";
import "./styles/AddCategoryModal.scss";
import { takeId } from "../VideosPage/sctipts";
import { AttributeSelect } from "../../../common/attribute-select/AttributeSelect";
import axios from "axios";
import { baseUrl } from "../../../Actions/consts";
import { Cluster } from "../../../ui-kit/layouts/Cluster";
import { Button } from "../../../ui-kit/button/Button";

const EditCategories = ({
	elem,
	putCategories,
	loading,
	isService,
	categoryAttr,
	onClose,
	onCascadeEdit,
	authUser,
}) => {
	const dispatch = useDispatch();
	const [attrState, setAttrState] = useState([]);
	const [mainState, setMainState] = useState({
		title: elem?.title,
		parent_id: elem.parent_id,
	});
	const [changedAttrState, setChangedAttrState] = useState([]);
	const handleInputChange = (evt) => {
		// обработчик заполнение инпутов
		const val = evt.target.value; // присваиваем значение инпута
		setMainState({ ...mainState, title: val }); // изменяем состояние
	};
	const handleEditClick = () => {
		putCategories({ ...mainState, predefined_attributes: takeId(attrState) }, elem.id, isService);
		onClose();
	};

	useEffect(async () => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.get(`${baseUrl}/domain/categories/attributes/${elem.id}`)
			.then((categor) => {
				setAttrState(categor.data.category.predefined_attributes);
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	}, [elem]);

	return (
		<div className="main-modal">
			<div className="modal-content">
				<div className="add-category-wrapper" style={{ maxWidth: "504px" }}>
					<Cluster>
						<div className="modal-field-title">Изменить категорию</div>
						<div className="modal-field-close" onClick={() => onClose()}>
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M4.70701 3.29304L3.99991 2.58594L2.58569 4.00015L3.2928 4.70726L10.5857 12.0002L3.2928 19.293L2.58569 20.0002L3.99991 21.4144L4.70701 20.7073L11.9999 13.4144L19.2928 20.7073L19.9999 21.4144L21.4141 20.0002L20.707 19.293L13.4141 12.0002L20.707 4.70726L21.4141 4.00015L19.9999 2.58594L19.2928 3.29304L11.9999 10.5859L4.70701 3.29304Z"
									fill="#212121"
								></path>
							</svg>
						</div>
					</Cluster>

					<div className="flex-block">
						<div className="form-field">
							<div className="form-field-label">Название категории</div>
							<div className="input-field">
								<input
									type="text"
									placeholder="Ввведите название категории"
									style={{ width: "440px" }}
									id="category-title"
									onChange={(e) => handleInputChange(e)}
									value={mainState.title}
								/>
							</div>
						</div>
					</div>
					<div className="flex-block">
						<div className="form-field">
							<div className="form-field-label">Атрибуты</div>
							<div className="input-field">
								<AttributeSelect
									collection={categoryAttr}
									parentCollection={categoryAttr}
									selectedAttrs={attrState}
									onSelect={(e) => setAttrState(e)}
									onChangedAttrSelect={(e) => setChangedAttrState(e)}
								/>
							</div>
						</div>
					</div>
					<div className="modal-buttons">
						<button className="button action-button border-button" onClick={() => onClose()}>
							<span>Отменить</span>
						</button>
						{authUser?.id === 1 ||
						authUser?.id === 5 ||
						authUser?.id === 22 ||
						authUser?.id === 17 ? (
							<Button
								variant="hot"
								onClick={() => (
									onCascadeEdit({
										category: elem,
										predefined_attributes: takeId(attrState),
										add_attributes: changedAttrState.add_attributes,
										delete_attributes: changedAttrState.delete_attributes,
									}),
									onClose()
								)}
								text="Для всех"
							/>
						) : null}
						<button
							className={`button action-button create-category-button ${
								loading === 1 ? "preloader" : ""
							}`}
							onClick={() => handleEditClick()}
						>
							<span>Сохранить</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		loading: state.loadingStatus.loading,
		categoryAttr: state.categoryAttr,
		authUser: state.authUser,
	};
};

const mapDispatchToProps = {
	putCategories,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCategories);
