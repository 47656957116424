import styled from "styled-components";

export const TotalContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 46px;
	margin-right: 18px;
`;
export const TotalWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 12px;
`;

export const TotalTitle = styled.div`
	font-family: "Averta CY";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	display: flex;
	align-items: center;
	color: #94a0b1;
`;

export const TotalValue = styled.div`
	font-family: "Averta CY";
	font-style: normal;
	font-weight: 600;
	font-size: 15px;
	display: flex;
	align-items: center;
	color: #212121;
`;
