import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useSearchParams } from "react-router-dom";
import BloggerVideoSearch from "../../../../../common/BloggerVideoSearch.jsx";
import { getBloggerVideoCategory } from "../../../../../Actions/BloggersActions.js";
import { handleBloggerMultiSelectActiveClick, handleBloggerSelectFilters } from "./scripts.js";

const initialDropDownState = {
	categories: false,
	status: false,
	format: false,
};

const initialFormats = [
	{
		title: "Видео",
		id: 0,
	},
	{
		title: "Shorts",
		id: 1,
	},
];
const initialStatuses = [
	{
		title: "Опубликовано",
		id: 1,
	},
	{
		title: "Не опубликовано",
		id: 0,
	},
];

const BloggerVideosFilters = ({
	bloggerVideoCaregoryList,
	getBloggerVideoCategory,
	mainFilterState,
	setMainFilterState,
}) => {
	const [searchParams, setSearchParams] = useSearchParams();

	const [searchInput, setSearchInput] = useState(
		!!JSON.parse(searchParams.get("filters"))?.title
			? JSON.parse(searchParams.get("filters"))?.title
			: ""
	);

	const [videoGenresState, setVideoGenresState] = useState({
		currentValue: !!JSON.parse(searchParams.get("filters"))?.categories
			? JSON.parse(searchParams.get("filters")).categories
			: [],
		list: [],
	});

	const [videoFormatsState, setVideoFormatsState] = useState({
		currentValue: !!JSON.parse(searchParams.get("filters"))?.format
			? JSON.parse(searchParams.get("filters")).format
			: [],
		list: initialFormats,
	});
	const [videoStatusesState, setVideoStatusesState] = useState({
		currentValue: !!JSON.parse(searchParams.get("filters"))?.status
			? JSON.parse(searchParams.get("filters")).status
			: [],
		list: initialStatuses,
	});

	const [dropDownState, setDropdownState] = useState(initialDropDownState);

	useEffect(() => {
		setSearchInput(
			!!JSON.parse(searchParams.get("filters"))?.title
				? JSON.parse(searchParams.get("filters"))?.title
				: ""
		);
	}, [searchParams]);

	useEffect(() => {
		setVideoGenresState({
			...videoGenresState,
			currentValue: !!JSON.parse(searchParams.get("filters"))?.categories
				? JSON.parse(searchParams.get("filters")).categories
				: [],
			list: bloggerVideoCaregoryList,
		});
	}, [bloggerVideoCaregoryList]);

	useEffect(() => {
		!bloggerVideoCaregoryList.length && getBloggerVideoCategory();
	}, []);

	return (
		<>
			<div className="list-filter flex-block">
				<div className="form-field">
					<div className="form-field-label"></div>
					<div className="input-field left-icon">
						<BloggerVideoSearch
							setMainFilterState={setMainFilterState}
							mainFilterState={mainFilterState}
						/>
					</div>
				</div>
				<div className="form-field">
					<div className="form-field-label">Категории видео</div>
					<div className="input-field">
						<div
							className={`select-block multiselect ${dropDownState.categories ? "active" : ""}`}
							onFocus={() => null}
							onClick={() =>
								setDropdownState({
									...dropDownState,
									categories: !dropDownState.categories,
								})
							}
							onBlur={() => setDropdownState(initialDropDownState)}
							tabIndex="2"
						>
							<div className="select-block-button">
								<span>
									{videoGenresState.currentValue.length > 0 &&
									videoGenresState.currentValue.length !== videoGenresState.list.length
										? videoGenresState.currentValue.map((elem, index) =>
												index !== videoGenresState.currentValue.length - 1
													? `${videoGenresState.list.find((item) => item.id == elem)?.title}, `
													: `${videoGenresState.list.find((item) => item.id == elem)?.title}`
										  )
										: "Все"}
								</span>
							</div>
							<div className="select-block-list">
								<div className="select-block-list-scroll">
									{videoGenresState.list?.length > 0
										? videoGenresState.list.map((genres) => (
												<div
													key={genres.id}
													className={`select-block-item ${
														videoGenresState.currentValue.includes(genres.id) ? "selected" : ""
													}`}
													onClick={(e) =>
														handleBloggerMultiSelectActiveClick(
															e,
															videoGenresState,
															setVideoGenresState,
															genres
														)
													}
												>
													<span>{genres?.title}</span>
												</div>
										  ))
										: null}
								</div>
								<div className="select-block-control">
									<div
										className="select-block-cancel button-select"
										onClick={(event) =>
											handleBloggerSelectFilters(
												event,
												"categories",
												[],
												videoGenresState,
												setVideoGenresState,
												dropDownState,
												setDropdownState,
												mainFilterState,
												setMainFilterState
											)
										}
									>
										Очистить
									</div>
									<div
										className="select-block-select button-select"
										onClick={(event) =>
											handleBloggerSelectFilters(
												event,
												"categories",
												videoGenresState.currentValue,
												null,
												null,
												dropDownState,
												setDropdownState,
												mainFilterState,
												setMainFilterState
											)
										}
									>
										Применить
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="form-field">
					<div className="form-field-label">Формат</div>
					<div className="input-field">
						<div
							className={`select-block multiselect ${dropDownState.format ? "active" : ""}`}
							onFocus={() => null}
							onClick={() =>
								setDropdownState({
									...dropDownState,
									format: !dropDownState.format,
								})
							}
							onBlur={() => setDropdownState(initialDropDownState)}
							tabIndex="2"
						>
							<div className="select-block-button">
								<span>
									{videoFormatsState.currentValue.length > 0 &&
									videoFormatsState.currentValue.length !== videoFormatsState.list.length
										? videoFormatsState.currentValue.map((elem, index) =>
												index !== videoFormatsState.currentValue.length - 1
													? `${videoFormatsState.list.find((item) => item.id == elem)?.title}, `
													: `${videoFormatsState.list.find((item) => item.id == elem)?.title}`
										  )
										: "Все"}
								</span>
							</div>
							<div className="select-block-list">
								<div className="select-block-list-scroll">
									{videoFormatsState.list?.length > 0
										? videoFormatsState.list.map((status) => (
												<div
													key={status.id}
													className={`select-block-item ${
														videoFormatsState.currentValue.includes(status.id) ? "selected" : ""
													}`}
													onClick={(e) =>
														handleBloggerMultiSelectActiveClick(
															e,
															videoFormatsState,
															setVideoFormatsState,
															status
														)
													}
												>
													<span>{status?.title}</span>
												</div>
										  ))
										: null}
								</div>
								<div className="select-block-control">
									<div
										className="select-block-cancel button-select"
										onClick={(event) =>
											handleBloggerSelectFilters(
												event,
												"format",
												[],
												videoFormatsState,
												setVideoFormatsState,
												dropDownState,
												setDropdownState,
												mainFilterState,
												setMainFilterState
											)
										}
									>
										Очистить
									</div>
									<div
										className="select-block-select button-select"
										onClick={(event) =>
											handleBloggerSelectFilters(
												event,
												"format",
												videoFormatsState.currentValue,
												null,
												null,
												dropDownState,
												setDropdownState,
												mainFilterState,
												setMainFilterState
											)
										}
									>
										Применить
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="form-field">
					<div className="form-field-label">Статус</div>
					<div className="input-field">
						<div
							className={`select-block multiselect ${dropDownState.status ? "active" : ""}`}
							onFocus={() => null}
							onClick={() =>
								setDropdownState({
									...dropDownState,
									status: !dropDownState.status,
								})
							}
							onBlur={() => setDropdownState(initialDropDownState)}
							tabIndex="2"
						>
							<div className="select-block-button">
								<span>
									{videoStatusesState.currentValue.length > 0 &&
									videoStatusesState.currentValue.length !== videoStatusesState.list.length
										? videoStatusesState.currentValue.map((elem, index) =>
												index !== videoStatusesState.currentValue.length - 1
													? `${videoStatusesState.list.find((item) => item.id == elem)?.title}, `
													: `${videoStatusesState.list.find((item) => item.id == elem)?.title}`
										  )
										: "Все"}
								</span>
							</div>
							<div className="select-block-list">
								<div className="select-block-list-scroll">
									{videoStatusesState.list?.length > 0
										? videoStatusesState.list.map((status) => (
												<div
													key={status.id}
													className={`select-block-item ${
														videoStatusesState.currentValue.includes(status.id) ? "selected" : ""
													}`}
													onClick={(e) =>
														handleBloggerMultiSelectActiveClick(
															e,
															videoStatusesState,
															setVideoStatusesState,
															status
														)
													}
												>
													<span>{status?.title}</span>
												</div>
										  ))
										: null}
								</div>
								<div className="select-block-control">
									<div
										className="select-block-cancel button-select"
										onClick={(event) =>
											handleBloggerSelectFilters(
												event,
												"status",
												[],
												videoStatusesState,
												setVideoStatusesState,
												dropDownState,
												setDropdownState,
												mainFilterState,
												setMainFilterState
											)
										}
									>
										Очистить
									</div>
									<div
										className="select-block-select button-select"
										onClick={(event) =>
											handleBloggerSelectFilters(
												event,
												"status",
												videoStatusesState.currentValue,
												null,
												null,
												dropDownState,
												setDropdownState,
												mainFilterState,
												setMainFilterState
											)
										}
									>
										Применить
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		bloggerVideoCaregoryList: state.bloggerVideoCaregoryList,
	};
};

const mapDispatchToProps = {
	getBloggerVideoCategory,
};

export default connect(mapStateToProps, mapDispatchToProps)(BloggerVideosFilters);
