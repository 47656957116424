export const initialState = {
	title: "",
	duration: 0,
	published: false,
	year: "",
	movie_rating: null,
	preview: "",
	photos: [],
	childs: [],
	genres: [],
	characters: [
		{
			1: [],
		},
		{
			2: [],
		},
		{
			3: [],
		},
		{
			4: [],
		},
		{
			5: [],
		},
	],
	hashtags: [],
};

export const initialAdditionState = {
	detailed_description: "",
	short_description: "",
	URL_SD_PREVIEW: "",
	URL_SD_SOURCE: "",
	URL_HD_SOURCE: "",
	URL_FULL_HD_SOURCE: "",
	URL_SD_trailer: "",
	URL_HD_trailer: "",
	URL_FULL_HD_vrailer: "",
};
