export const UsersReducer = (state = [], action) => {
	switch (action.type) {
		case "GET_USERS":
			return action.users.data;
		default:
			return state;
	}
};

export const CurrentUserReducer = (state = [], action) => {
	switch (action.type) {
		case "POST_USER":
			return action.user.data;
		case "GET_CURRENT_USER":
			return action.user.data;
		case "PUT_USER":
			return action.user.data;
		case "RESET_USER":
			return [];
		default:
			return state;
	}
};

export const AreasReducer = (state = [], action) => {
	switch (action.type) {
		case "GET_AREAS":
			return action.areas.data;
		case "PUT_AREAS":
			const data = action.areas.data;
			const areaIdx = state.findIndex((elem) => elem.id === data.id);
			let newState = [...state];
			newState[areaIdx] = action.areas.data;
			return newState;
		default:
			return state;
	}
};