const compare = (a, b) => {
	if (a.service < b.serivce) {
		return -1;
	}
	if (a.service > b.service) {
		return 1;
	}
	return 0;
};

const initialState = {
	categories: [],
	service: [],
	categoriesFullList: [],
};

const sortCategories = (array) => {
	let firstLevel = array
		.filter((item) => item.parent_id === 1 || item.parent_id === 2)
		.sort(compare);
	firstLevel = firstLevel.map((elem) => {
		return { ...elem, orderIndex: 1 };
	});
	const categoriesFullList = firstLevel.map((elem) => {
		return { ...elem, isDisabled: true };
	});
	array = array.filter(
		(item) => item.parent_id !== null && item.parent_id !== 1 && item.parent_id != 2
	);
	array.map((item, index) => {
		const newIndex = firstLevel.findIndex((elem) => elem.id === item.parent_id);
		const fullListIndex = categoriesFullList.findIndex((elem) => elem.id === item.parent_id);
		if (newIndex >= 0) {
			if (firstLevel[newIndex].orderIndex === 2) {
				if (firstLevel[newIndex].child) {
					firstLevel[newIndex].child.push(item);
				} else {
					firstLevel[newIndex] = { ...firstLevel[newIndex], child: [item] };
				}
			} else {
				firstLevel.splice(newIndex + 1, 0, {
					...item,
					orderIndex: firstLevel[newIndex]["orderIndex"] + 1,
				});
			}
		}
		if (fullListIndex >= 0) {
			if (categoriesFullList[fullListIndex].orderIndex === 1) {
				categoriesFullList.splice(fullListIndex + 1, 0, {
					...item,
					orderIndex: categoriesFullList[fullListIndex]["orderIndex"] + 1,
					isDisabled: true,
				});
			} else if (categoriesFullList[fullListIndex].orderIndex === 2) {
				categoriesFullList.splice(fullListIndex + 1, 0, {
					...item,
					orderIndex: categoriesFullList[fullListIndex]["orderIndex"] + 1,
				});
			}
		}
	});
	array = firstLevel;
	array.map((item, index) => {
		if (item.orderIndex === 2) {
			item.child?.sort((a, b) => a.title.localeCompare(b.title));
			const newIndex = firstLevel.findIndex((elem) => elem.id === item.parent_id);
			if (newIndex >= 0 && firstLevel[newIndex].orderIndex === 1) {
				if (firstLevel[newIndex].child) {
					firstLevel[newIndex].child.push(item);
				} else {
					firstLevel[newIndex] = { ...firstLevel[newIndex], child: [item] };
				}
			} else {
				firstLevel.splice(newIndex + 1, 0, {
					...item,
					orderIndex: firstLevel[newIndex]["orderIndex"] + 1,
				});
			}
		}
	});
	firstLevel = firstLevel.filter((item) => item.orderIndex !== 2);
	const categories = firstLevel
		.filter((elem) => elem.parent_id === 1)
		.sort((a, b) => a.title.localeCompare(b.title));
	const service = firstLevel
		.filter((elem) => elem.parent_id === 2)
		.sort((a, b) => a.title.localeCompare(b.title));

	categories.forEach((element) => {
		element?.child?.sort((a, b) => a.title.localeCompare(b.title));
	});
	service.forEach((element) => {
		element?.child?.sort((a, b) => a.title.localeCompare(b.title));
	});
	return {
		categories: categories,
		service: service,
		categoriesFullList: categoriesFullList,
	};
};

const CategoriesReducer = (categor = initialState, action) => {
	switch (action.type) {
		case "GET_CATEGORIES":
			return action.categor.data;
		case "POST_CATEGORIES": {
			const newArray = [...categor];
			const isService = action.isService;
			const data = { ...action.categor.data, childs: [] };
			if (data.parent_id === 1) {
				return [[...newArray[0], data], [...newArray[1]]];
			}
			if (data.parent_id === 2) {
				return [[...newArray[0]], [...newArray[1], data]];
			}
			newArray[isService ? 1 : 0].forEach((elem) => {
				if (elem.id === data.parent_id) elem.childs.push(data);
				else if (elem.childs.length > 0) {
					elem.childs.forEach((elem1) => {
						if (elem1.id === data.parent_id) {
							elem1.childs.push(data);
						} else if (elem1.childs.length > 0) {
							elem1.childs.forEach((elem2) => {
								if (elem2.id === data.parent_id) {
									elem2.childs.push(data);
								} else if (elem2.childs.length > 0) {
									elem2.childs.forEach((elem3) => {
										if (elem3.id === data.parent_id) elem3.childs.push(data);
										else if (elem3.childs.length > 0) {
											elem3.childs.forEach((elem4) => {
												if (elem4.id === data.parent_id) elem4.childs.push(data);
											});
										}
									});
								}
							});
						}
					});
				}
			});
			return newArray;
		}

		case "DELETE_CATEGORIES": {
			const newArray = [...categor];
			const isService = action.isService;
			const data = { ...action.categor.data, childs: [] };
			if (data.parent_id === 1) {
				newArray[0] = newArray[0].filter((item) => item.id !== data.id);
				return newArray;
			}
			if (data.parent_id === 2) {
				newArray[1] = newArray[1].filter((item) => item.id !== data.id);
				return newArray;
			}
			newArray[isService ? 1 : 0].forEach((elem) => {
				if (elem.id === data.parent_id)
					elem.childs = elem.childs.filter((item) => item.id !== data.id);
				else if (elem.childs.length > 0) {
					elem.childs.forEach((elem1) => {
						if (elem1.id === data.parent_id) {
							elem1.childs = elem1.childs.filter((item) => item.id !== data.id);
						} else if (elem1.childs.length > 0) {
							elem1.childs.forEach((elem2) => {
								if (elem2.id === data.parent_id) {
									elem2.childs = elem2.childs.filter((item) => item.id !== data.id);
								} else if (elem2.childs.length > 0) {
									elem2.childs.forEach((elem3) => {
										if (elem3.id === data.parent_id)
											elem3.childs = elem3.childs.filter((item) => item.id !== data.id);
										else if (elem3.childs.length > 0) {
											elem3.childs.forEach((elem4) => {
												if (elem4.id === data.parent_id)
													elem4.childs = elem4.childs.filter((item) => item.id !== data.id);
											});
										}
									});
								}
							});
						}
					});
				}
			});
			return newArray;
		}
		case "PUT_CATEGORIES": {
			const newArray = [...categor];
			const isService = action.isService;
			const data = { ...action.categor.data, childs: [] };
			if (data.parent_id === 1) {
				newArray[0] = newArray[0].map((item) =>
					item.id === data.id ? { ...item, title: data.title } : item
				);
				return newArray;
			}
			if (data.parent_id === 2) {
				newArray[1] = newArray[1].map((item) =>
					item.id === data.id ? { ...item, title: data.title } : item
				);
				return newArray;
			}
			newArray[isService ? 1 : 0].forEach((elem) => {
				if (elem.id === data.parent_id)
					elem.childs = elem.childs.map((item) =>
						item.id === data.id ? { ...item, title: data.title } : item
					);
				else if (elem.childs.length > 0) {
					elem.childs.forEach((elem1) => {
						if (elem1.id === data.parent_id) {
							elem1.childs = elem1.childs.map((item) =>
								item.id === data.id ? { ...item, title: data.title } : item
							);
						} else if (elem1.childs.length > 0) {
							elem1.childs.forEach((elem2) => {
								if (elem2.id === data.parent_id) {
									elem2.childs = elem2.childs.map((item) =>
										item.id === data.id ? { ...item, title: data.title } : item
									);
								} else if (elem2.childs.length > 0) {
									elem2.childs.forEach((elem3) => {
										if (elem3.id === data.parent_id)
											elem3.childs = elem3.childs.map((item) =>
												item.id === data.id ? { ...item, title: data.title } : item
											);
										else if (elem3.childs.length > 0) {
											elem3.childs.forEach((elem4) => {
												if (elem4.id === data.parent_id)
													elem4.childs = elem4.childs.map((item) =>
														item.id === data.id ? { ...item, title: data.title } : item
													);
											});
										}
									});
								}
							});
						}
					});
				}
			});
			return newArray;
		}

		default:
			return categor;
	}
};

const ExtraCategoriesReducer = (categor = initialState, action) => {
	switch (action.type) {
		case "GET_EXTRA_CATEGORIES":
			const data = action.categor.data;
			const result = data[0].concat(data[1]);
			return data;
		default:
			return categor;
	}
};

const ClearCategoriesReducer = (categor = initialState, action) => {
	switch (action.type) {
		case "GET_CLEAR_CATEGORIES":
			const result = sortCategories(action.categor.data);
			return result;
		default:
			return categor;
	}
};

const CategoryAttrReducer = (categor = [], action) => {
	switch (action.type) {
		case "GET_CATEGORY_ATTR":
			return action.categor.data;
		default:
			return categor;
	}
};

export { CategoriesReducer, ClearCategoriesReducer, ExtraCategoriesReducer, CategoryAttrReducer };
