import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Cluster } from "../layouts/Cluster";
import { Stack } from "../layouts/Stack";

const Wrapper = styled.div`
	position: relative;
	display: block;
	width: 100%;
	display: flex;
	align-items: center;
	&.filled-arrow {
		svg {
			position: absolute;
			top: calc(50% - 12px);
			right: 16px;
		}
	}
	&.custom-arrow {
		svg {
			position: relative;
		}
	}
	svg {
		z-index: 1000;
		position: absolute;
		width: 24px;
		height: 24px;
		min-height: 24px;
		min-width: 24px;

		transition: all 0.3s ease-out;
	}
	&.opened {
		svg {
			transform: rotateX(180deg);
		}
	}
	transition: all 0.3s ease-out;
`;

const SelectedItem = styled(Cluster)`
	cursor: pointer;
	width: 100%;
	position: relative;
	border-radius: 8px;
	background: #fff;
	z-index: 1;
	padding: 16px 16px;
	&.disabled {
		cursor: default;
		background: #d1d8e1;
		color: #94a0b1;
	}
`;

const OptionsList = styled(Stack)`
	width: 100%;
	max-height: 220px;
	overflow: auto;
	position: absolute;
	top: calc(100% + 8px);
	box-shadow: 8px 8px 48px 0px rgba(46, 69, 102, 0.2);
	padding: 16px 0;
	border-radius: 8px;
	background: #fff;
	&::-webkit-scrollbar {
		width: 4px;
	}
	&::-webkit-scrollbar-thumb {
		background: rgba(148, 160, 177, 0.3);
		border-radius: 2px;
	}
	&::-webkit-scrollbar-track {
		background: none;
	}
`;

const OptionItemWrapper = styled.div`
	position: relative;
	padding: 6px 16px;
	cursor: pointer;
	&:hover {
		background-color: #f4f4f8;
		color: #005bff;
	}
`;

const Backdrop = styled.div`
	position: fixed;
	width: 100vw;
	left: 0;
	top: 0;
	height: 100vh;
`;

export const DynamicSelect = ({
	collection,
	option,
	selected = {},
	minWidth = "220px",
	maxWidth = "100%",
	onSelect,
	arrowType = "filled",
	disabled = false,
}) => {
	const [isOpen, setOpen] = useState(false);

	return (
		<Wrapper
			style={{ minWidth: minWidth, maxWidth: maxWidth }}
			className={(isOpen ? "opened" : "", arrowType === "filled" ? "filled-arrow" : "custom-arrow")}
		>
			<SelectedItem
				className={`dynamic-select-selected-item ${disabled ? "disabled" : ""}`}
				onClick={() => !disabled && setOpen(true)}
			>
				{option({ ...selected })}
			</SelectedItem>
			{isOpen && (
				<>
					<Backdrop onClick={() => setOpen(false)} />
					<OptionsList>
						{collection?.map((elem) => (
							<OptionItemWrapper onClick={() => (onSelect(elem), setOpen(false))}>
								{option({ ...elem })}
							</OptionItemWrapper>
						))}
					</OptionsList>
				</>
			)}
			{arrowType === "filled" ? (
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M15.5 10C15.6864 10 15.8574 10.1037 15.9435 10.2691C16.0296 10.4345 16.0165 10.634 15.9096 10.7867L12.4096 15.7867C12.3161 15.9204 12.1632 16 12 16C11.8368 16 11.6839 15.9204 11.5904 15.7867L8.09039 10.7867C7.98347 10.634 7.97041 10.4345 8.05651 10.2691C8.14261 10.1037 8.31356 10 8.5 10L15.5 10Z"
						fill={!disabled ? "#212121" : "#94a0b1"}
					/>
				</svg>
			) : (
				<svg
					width="24"
					height="22"
					viewBox="0 0 24 22"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M8.00015 7.58569L8.70726 8.2928L12.0002 11.5857L15.293 8.2928L16.0002 7.58569L17.4144 8.99991L16.7073 9.70701L12.7073 13.707C12.3167 14.0975 11.6836 14.0975 11.293 13.707L7.29304 9.70701L6.58594 8.99991L8.00015 7.58569Z"
						fill={!disabled ? "#212121" : "#94a0b1"}
					/>
				</svg>
			)}
		</Wrapper>
	);
};
