import axios from "axios";
import { baseUrl } from "./consts";

export const getCurrentReport = (id) => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.get(`${baseUrl}/domain/ticket/${id}`)
			.then((report) => {
				dispatch({
					type: "GET_CURRENT_REPORT",
					report,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const putCurrentReport = (id, data) => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.put(`${baseUrl}/domain/ticket/${id}`, data)
			.then((report) => {
				dispatch({
					type: "PUT_CURRENT_REPORT",
					status: data?.status,
					report,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const getAllChannelReports = (arg) => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.get(`${baseUrl}/domain/ticket`, {
				params: { ...arg },
			})
			.then((report) => {
				dispatch({
					type: "GET_ALL_CHANNEL_REPORTS",
					report,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const getAllVideoReports = (arg) => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.get(`${baseUrl}/domain/ticket`, {
				params: { ...arg },
			})
			.then((report) => {
				dispatch({
					type: "GET_ALL_VIDEO_REPORTS",
					report,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const getAllCommentReports = (arg) => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.get(`${baseUrl}/domain/ticket`, {
				params: { ...arg },
			})
			.then((report) => {
				dispatch({
					type: "GET_ALL_COMMENT_REPORTS",
					report,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const getChannelReportsTotal = (arg) => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.get(`${baseUrl}/domain/ticket`, {
				params: { offst: 0, limit: 1, ticket_type: 1 },
			})
			.then((report) => {
				dispatch({
					type: "GET_CHANNEL_REPORTS_TOTAL",
					report,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};
export const getVideoReportsTotal = (arg) => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.get(`${baseUrl}/domain/ticket`, {
				params: { offst: 0, limit: 1, ticket_type: 2 },
			})
			.then((report) => {
				dispatch({
					type: "GET_VIDEO_REPORTS_TOTAL",
					report,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};
export const getCommentReportsTotal = (arg) => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.get(`${baseUrl}/domain/ticket`, {
				params: { offst: 0, limit: 1, ticket_type: 3 },
			})
			.then((report) => {
				dispatch({
					type: "GET_COMMENT_REPORTS_TOTAL",
					report,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const getReportHistory = (arg) => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.get(`${baseUrl}/domain/tickethistory/`, {
				params: arg,
			})
			.then((report) => {
				dispatch({
					type: "GET_REPORTS_HISTORY",
					report,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};
