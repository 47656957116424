import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import { AuthorizationReducer, AuthUserReducer } from "./AuthorizationReducer.js";
import { BrightboxesReducer } from "./BrightBoxesReducer.js";
import {
	CategoriesReducer,
	CategoryAttrReducer,
	ClearCategoriesReducer,
	ExtraCategoriesReducer,
} from "./CategoriesReducer.js";
import { CountriesReducer } from "./LocationReducer.js";
import {
	LoadingReducer,
	MainLoadingReducer,
	ReorderLoadingReducer,
	SaveReducer,
	StatisticLoaderReducer,
} from "./LoadingReducer.js";
import { PriceHistoryReducer, PriceReducer, SellerPriceReducer } from "./PricesReducer.js";
import {
	AllExternalSellersReducer,
	ExternalAllSellerBalanceReducer,
	ExternalSellersTotalReducer,
	SellerBalanceReducer,
	SellerCurrentReducer,
	SellerDocumentsReducer,
	SellerExternalReducer,
	SellersReducer,
} from "./SellersReducer.js";
import { StatisticReducer } from "./StatisticReducer.js";
import { AreasReducer, CurrentUserReducer, UsersReducer } from "./UsersReducer.js";
import { VideoFormatsReducer } from "./VideoFormatsReducer.js";
import {
	SerialCurrentAdditionalReducer,
	UploadGalleryImageReducer,
	UploadImageReducer,
	VideoAgesReducer,
	VideoCurrentAdditionalReducer,
	VideoCurrentReducer,
	VideoFilmCrewReducer,
	VideoGenresReducer,
	VideoHashtagsReducer,
	VideoSingleFilmCrewReducer,
	VideosReducer,
} from "./VideosReducer.js";
import {
	ExternalViewersReducer,
	ExternalViewerTotalReducer,
	ViewersCurrentReducer,
	ViewersReducer,
} from "./ViewersReducer.js";
import { ProductReducer } from "./ProductReducer.js";
import {
	BloggerPoliticsLocationReducer,
	PoliticsLocationReducer,
	PoliticsReducer,
} from "./PoliticsReducer.js";
import { PlaylistReducer } from "./PlaylistReducer.js";
import {
	AllExternalBloggersReducer,
	BloggerCurrentReducer,
	BloggersReducer,
	BloggerVideoCaregoryReducer,
	BloggerVideosReducer,
	BloggerVideosViewsGraphReducer,
	ExternalBloggersTotalReducer,
} from "./BloggersReducer.js";
import {
	BloggerVideosCountByFormatsReducer,
	CurrentVideoViewsDepthGraphReducer,
	CurrentVideoViewsGraphReducer,
	VideoProfitGraphReducer,
	VideosCountByFormats,
	VideosViewsBloogerGraphReducer,
	VideosViewsGraphReducer,
} from "./MainStatisticReducer.js";
import {
	VideoViewGadgetGraphReducer,
	VideoViewGadgetStatisticReducer,
} from "./VideoStatisticReducer.js";
import { CurrentOwnerReducer, OwnersReducer, OwnersTotalReducer } from "./OwnerReducer.js";
import {
	CurrentReportReducer,
	ReportHistoryReducer,
	ReportsReducer,
	ReportsTotalReducer,
} from "./ReportReducer.js";
import { EditableFramesReducer } from "./FramesReducer.js";

const createRootReducer = (history) =>
	combineReducers({
		router: connectRouter(history), // состояние роутера
		login: AuthorizationReducer, // состояние авторизации

		loadingStatus: LoadingReducer, // статус загрузки (true / false)
		mainLoader: MainLoadingReducer, // статус главной подгрузки (получкние, обновление) списков данных (список видео, список статистики и т.д....) (счётчик)
		saveMoment: SaveReducer, // Индикатор сохранения (true/false)
		statisticLoadingStatus: StatisticLoaderReducer, // статус загруки списка статистики (true / false)
		reorderLoader: ReorderLoadingReducer, // состояние изменения сортировки у drag and drop элементов (счётчик)

		uploadImage: UploadImageReducer, // состояние сохранённого изображения
		uploadGalleryImage: UploadGalleryImageReducer, // состояние загрузки изображения галлереи

		videosList: VideosReducer, // список видео

		currentVideo: VideoCurrentReducer, // сущность movie

		additionalCurrentVideo: VideoCurrentAdditionalReducer, // сущность video
		additionalCurrentSerial: SerialCurrentAdditionalReducer, // сущность сериала

		filmCrewList: VideoFilmCrewReducer, // список съемочной группы
		singleFilmCrew: VideoSingleFilmCrewReducer, // сущность определенного участника съемочной группы
		videoHashtags: VideoHashtagsReducer, // список видео хештегов
		videoFormatsList: VideoFormatsReducer, // список форматоов видео

		videoGenresList: VideoGenresReducer, // список жанров
		videoAgesList: VideoAgesReducer, // список возрастных ограничений

		usersList: UsersReducer, // список пользователей
		currentUser: CurrentUserReducer, // сущность определенного пользователя

		brightboxesList: BrightboxesReducer, // список типсов
		areasList: AreasReducer, // список площадок
		sellerPriceList: SellerPriceReducer,
		priceState: PriceReducer, //
		priceHistoryList: PriceHistoryReducer, // список истории цен

		authUser: AuthUserReducer, // сущность авторизованного пользователя
		statisticList: StatisticReducer, // список статистики
		clearCategoriesList: ClearCategoriesReducer, // список категорий одномерным массивом
		categoriesList: CategoriesReducer, // список категорий
		extraCategoriesReducer: ExtraCategoriesReducer, // список форматированных категорий
		categoryAttr: CategoryAttrReducer, // аттрибуты категорий

		viewersList: ViewersReducer, // список зрителей
		currentViewer: ViewersCurrentReducer, // пользователь полученный по идентификатору
		externalViewer: ExternalViewersReducer, // external продавец
		externalViewerTotal: ExternalViewerTotalReducer, // кол-во зрителей по табам

		countriesList: CountriesReducer, // список стран
		currentProduct: ProductReducer, // конкретный продукт

		// dashboard
		videoProfitGraph: VideoProfitGraphReducer,
		videoViewsGraph: VideosViewsGraphReducer,
		videosCountByFormats: VideosCountByFormats,

		// politics
		politicsList: PoliticsReducer,
		politicsLocation: PoliticsLocationReducer,
		bloggerPoliticsLocation: BloggerPoliticsLocationReducer, // список стран для политики блогеров

		// playlist
		playlistsList: PlaylistReducer,

		// sellers
		sellersList: SellersReducer, // список продавцев
		currentSeller: SellerCurrentReducer, // продавец
		externalSeller: SellerExternalReducer, // external продавец
		sellerBalanceReducer: SellerBalanceReducer, // external продавец
		externalAllSellerBalanceReducer: ExternalAllSellerBalanceReducer, // инфо о балансе
		allExternalSelllersReducer: AllExternalSellersReducer, // список внешних продавцев
		externalSellersTotalReducer: ExternalSellersTotalReducer, // список внешних продавцев
		sellerDocuments: SellerDocumentsReducer, // список документов продавца

		// bloggers
		bloggerList: BloggersReducer, // список блогеров
		currentBlogger: BloggerCurrentReducer, // блогер
		allExternalBloggersReducer: AllExternalBloggersReducer, // список внешних блогеров
		externalBloggersTotalReducer: ExternalBloggersTotalReducer, // список внешних блогеров
		bloggerVideosList: BloggerVideosReducer, // список видео блогеров
		bloggerVideoCaregoryList: BloggerVideoCaregoryReducer,
		bloggerVideosViewsGraph: BloggerVideosViewsGraphReducer,
		bloggerVideoViewsGraph: VideosViewsBloogerGraphReducer,
		bloggerVideosCountByFormats: BloggerVideosCountByFormatsReducer,

		// owners
		ownersList: OwnersReducer,
		currentOwner: CurrentOwnerReducer,
		ownersTotal: OwnersTotalReducer,

		// report
		reportsList: ReportsReducer,
		currentReport: CurrentReportReducer,
		reportsTotal: ReportsTotalReducer,
		reportHistory: ReportHistoryReducer,

		// graphs
		currentVideoViewsGraph: CurrentVideoViewsGraphReducer,
		CurrentVideoViewsDepthGraph: CurrentVideoViewsDepthGraphReducer,

		videoViewGadgetGraph: VideoViewGadgetGraphReducer,
		videoViewGadgetStatistic: VideoViewGadgetStatisticReducer,

		// frames
		editableFrames: EditableFramesReducer, // список атрибутов для категорий
	});

export default createRootReducer;
