import * as React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useDebounce } from "../../components/PersonalArea/scripts/useDebounse.jsx";
import { EmptyNotification, ErrorNotification } from "./notifications.js";

const DropDownContainer = styled.div`
	position: relative;
	z-index: 100;
	width: 100%;
	height: 54px;
`;

const DropDownWrapper = styled.div`
	position: absolute;
	top: 62px;
	left: 0;
	width: 100%;
	box-shadow: 8px 8px 48px rgb(46 69 102 / 20%);
`;

const DropDownBackDrop = styled.div`
	background: transparent;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
`;

const ListWrapper = styled.div`
	z-index: 10;
	max-height: 204px;
	position: relative;
	background: white;
	padding: 16px 0;
	width: 100%;
	border-radius: 0px 0px 8px 8px;
	overflow: scroll;
	overflow-x: hidden;
	scrollbar-width: thin;
	scrollbar-color: rgba($color: #94a0b1, $alpha: 0.3) transparent;
	&::-webkit-scrollbar {
		width: 4px;
	}
	&::-webkit-scrollbar-thumb {
		position: relative;
		background: #94a0b130;
		border-radius: 2px;
	}
	&::-webkit-scrollbar-track {
		background: transparent;
		margin: 16px 0;
	}
`;

const StyledInput = styled.input`
	z-index: 11;
	background: white;
	border: none;
	border-bottom: 1px solid #f4f4f8;
	border-radius: 8px 8px 0px 0px !important;
	display: block;
	font-size: 15px;
	height: 54px;
	outline: none;
	position: relative;
	width: 100%;
	padding: 16px 20px;
	color: #212121;
	font-family: "Averta CY";
	font-weight: 400;
	font-size: 15px;
`;

const DropDownListItem = styled.div`
	width: 100%;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	padding: 6px 16px;
	font-weight: 400;
	font-size: 15px;
	cursor: pointer;
	&:hover {
		background: #f4f4f8;
	}
`;

const LoaderWrapper = styled.div`
	@keyframes loaderAnimation {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}
	z-index: 12;
	width: 24px;
	height: 24px;
	position: absolute;
	top: calc(50% - 12px);
	right: 20px;
	animation: loaderAnimation 1s linear infinite;
	background: url("images/icons/ui/status/blue/loader.svg") no-repeat;
	background-size: contain;
`;

const StyledClearButton = styled.button`
	background: #f4f4f8;
	z-index: 100;
	width: 24px;
	height: 24px;
	right: 40px;
	top: calc(50% - 12px);
	cursor: pointer;
	display: none;
	&.active {
		display: block;
	}
	position: absolute;
	border: none;
	outline: none;
	background: transparent;
`;

const SelectedBlock = styled.div`
	&.select-block.multiselect {
		padding: 16px 50px 16px 20px;
		text-overflow: ellipsis;
		position: absolute !important;
		width: 100%;
		overflow: hidden;
		white-space: nowrap;
		&::after {
			background: url("images/icons/ui/navigation/drop_down_filled.svg") no-repeat;
			content: "";
			display: block;
			height: 24px;
			pointer-events: none;
			position: absolute;
			right: 16px;
			top: 16px;
			width: 24px;
		}
	}
`;

const Search = ({
	results = [],
	value,
	onChange,
	onSelect,
	onDDClick,
	onClose,
	isOpen,
	onClear,
	selected,
	status,
	resolveTitle,
	placeholder = "Поиск по названию",
	loadNext,
}) => {
	const [focusedIndex, setFocusedIndex] = useState(-1);
	const resultContainer = useRef(null);
	const [defaultValue, setDefaultValue] = useState("");

	const handleSelection = (selectedIndex) => {
		const selectedItem = results[selectedIndex];
		if (!selectedItem) return resetSearchComplete();
		onSelect && onSelect(selectedItem);
		resetSearchComplete();
	};

	const debouncedSearch = useDebounce(() => defaultValue, 300);

	useEffect(() => {
		onChange && onChange(debouncedSearch);
	}, [debouncedSearch]);

	const resetSearchComplete = useCallback(() => {
		setDefaultValue("");
		onClose();
	}, [defaultValue]);

	const handleChange = (e) => {
		const value = e.target.value;
		setDefaultValue(value);
	};

	useEffect(() => {
		if (!resultContainer.current) return;

		resultContainer.current.scrollIntoView({
			block: "center",
		});
	}, [focusedIndex]);

	useEffect(() => {
		if (value) setDefaultValue(value);
	}, [value]);

	const handleScrollChange = (e) => {
		const target = e.target;
		if (target.scrollHeight - target.clientHeight - target.scrollTop < 50) {
			loadNext();
		}
	};
	return (
		<DropDownContainer className="input-field">
			<SelectedBlock className="select-block multiselect" onClick={onDDClick}>
				{selected?.length ? selected : "Все"}
			</SelectedBlock>
			<StyledClearButton
				className={`${!!selected?.length > 0 ? "active" : ""}`}
				onClick={() => onClear()}
			>
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M8.70701 7.29304L7.99991 6.58594L6.58569 8.00015L7.2928 8.70726L10.5857 12.0002L7.2928 15.293L6.58569 16.0002L7.99991 17.4144L8.70701 16.7073L11.9999 13.4144L15.2928 16.7073L15.9999 17.4144L17.4141 16.0002L16.707 15.293L13.4141 12.0002L16.707 8.70726L17.4141 8.00015L15.9999 6.58594L15.2928 7.29305L11.9999 10.5859L8.70701 7.29304Z"
						fill="#212121"
					></path>
				</svg>
			</StyledClearButton>
			{isOpen ? (
				<DropDownWrapper>
					<div style={{ position: "relative" }}>
						<StyledInput
							type="text"
							placeholder={placeholder}
							value={defaultValue}
							onChange={handleChange}
						/>
						{status.type === "load" && <LoaderWrapper />}
					</div>
					<DropDownBackDrop onClick={onClose} />
					<ListWrapper onScroll={handleScrollChange} tabIndex="1">
						{results?.length > 0 &&
							results.map((item, index) => {
								if (item.title !== selected)
									return (
										<DropDownListItem
											key={index}
											onClick={() => handleSelection(index)}
											ref={index === focusedIndex ? resultContainer : null}
										>
											{resolveTitle ? resolveTitle(item) : item.title}
										</DropDownListItem>
									);
							})}
						{status.type === "load" && results.length === 0 && (
							<EmptyNotification>Загрузка...</EmptyNotification>
						)}
						{results.length === 0 && status.type === "ready" && (
							<EmptyNotification>Ничего не найдено</EmptyNotification>
						)}
						{status.type === "error" && <ErrorNotification>{status.message}</ErrorNotification>}
					</ListWrapper>
				</DropDownWrapper>
			) : null}
		</DropDownContainer>
	);
};

export default Search;
