import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
	getVideoAges,
	getVideoGenres,
	getVideoHashtags,
	postUploadImages,
} from "../../../../Actions/videos-actions/VideosActions.js";
import "./styles/EditVideos.scss";
import { initialAdditionState, initialState } from "./videoStatic.js";
import { VideoBookmarks } from "./VideoBookmarks.jsx";
import EditVideoContent from "./content/EditVideoContent.jsx";
import EditVideoStatistic from "./statistic/EditVideoStatistic.jsx";
import EditVideoPolitics from "./politics/EditVideoPolitics.jsx";
import VideoEditSidebar from "./universal/VideoEditSidebar.jsx";
import { serializeCharacters, serializePhotos } from "../sctipts.js";
import { getVideo } from "../../../../Actions/videos-actions/getMovieVideo.js";
import { getPolitics } from "../../../../Actions/PoliticsAction.js";
import { Cluster } from "../../../../ui-kit/layouts/Cluster.jsx";
import { DText15 } from "../../../../ui-kit/typography/Typography.jsx";
import { PeriodFilters } from "../../HomePage/PeriodFilters.jsx";
import { formatedDate } from "../../static.js";
import { getAllActiveOwner } from "../../../../Actions/OwnerActions.js";
import SelectStatisticEpisodes from "./statistic/SelectStatisticEpisodes.jsx";
import { useUserAccessContext } from "../../context/UserAccessContext.js";

// 1 -  фильм, 2 - сериал, 3 - блог
const EditVideos = ({
	getVideo,
	videosList,
	additionalCurrentVideo,
	getVideoAges,
	getVideoGenres,
	getVideoHashtags,
	title,
	getPolitics,
	getAllActiveOwner,
	politicsList,
}) => {
	const { userAccessState } = useUserAccessContext();
	const navigate = useNavigate();
	const location = useLocation();
	const [activeBookMark, setActiveBookMark] = useState(0); // состояние активной вкладки
	const [errorState, setErrorState] = useState(false);
	const [filtersState, setFiltersState] = useState({});
	useEffect(() => {
		document.title = title;
	}, [title]);
	const [selectedVideoState, setSelectedVideoState] = useState();
	const [mainVideoState, setMainVideoState] = useState({
		title: "",
		duration: 0,
		published: false,
		year: "",
		rating: null,
		preview: "",
		movie_rating: null,
		slug: "",
		photos: [],
		childs: [],
		rate: 0,
		genres: [],
		politics: [],
		characters: [
			{
				1: [],
			},
			{
				2: [],
			},
			{
				3: [],
			},
			{
				4: [],
			},
			{
				5: [],
			},
		],
		hashtags: [],
		URL_SD_HORIZONTAL_PREVIEW: "",
		URL_SD_VERTICAL_PREVIEW: "",
		URL_SD_trailer: "",
		owner_id: 1,
		URL_HD_trailer: "",
		URL_FULL_HD_trailer: "",
	}); // главное состояние компонента
	const [additionalVideoState, setAdditionalVideoState] = useState({
		URL_SD_SOURCE: "",
		URL_HD_SOURCE: "",
		URL_FULL_HD_SOURCE: "",
		detailed_description: "",
		short_description: "",
	});
	useEffect(() => {
		// получение списка фильмов, возрастных ограничений, жанров, хештегов
		if (
			location.pathname !== "/newfilm" &&
			location.pathname !== "/newserial" &&
			location.pathname !== "/newblog"
		) {
			getVideo(location.search.split("=")[1]);
		} else {
			setMainVideoState({
				...mainVideoState,
				movie_format:
					location.pathname === "/newfilm"
						? { id: 1 }
						: location.pathname === "/newserial"
						? { id: 2 }
						: location.pathname === "/newblog"
						? { id: 3 }
						: { id: 1 },
			});
		}
		getVideoAges();
		getVideoGenres();
		getVideoHashtags();
		getPolitics();
		getAllActiveOwner({ is_active: true });
	}, []);

	useEffect(() => {
		// запись в состояние компонента списка фильмов
		if (
			videosList.length !== 0 &&
			(location.search.split("=")[1] == videosList.id ||
				location.pathname === "/newfilm" ||
				location.pathname === "/newserial" ||
				location.pathname === "/newblog")
		) {
			setMainVideoState({
				...videosList,
				characters: serializeCharacters(videosList.characters),
				photos: serializePhotos(videosList.photos),
			});
		}
	}, [videosList]);

	useEffect(() => {
		if (
			politicsList.length > 0 &&
			mainVideoState.politics?.length === 0 &&
			mainVideoState?.movie_format?.id
		) {
			setMainVideoState({ ...mainVideoState, politics: [politicsList[0]?.id] || [1] });
		}
	}, [politicsList, mainVideoState]);
	useEffect(() => {
		if (
			videosList &&
			additionalCurrentVideo.length !== 0 &&
			((additionalCurrentVideo?.movie &&
				location.search.split("=")[1] == additionalCurrentVideo.movie.id) ||
				location.pathname === "/newfilm" ||
				location.pathname === "/newserial" ||
				location.pathname === "/newblog")
		) {
			setAdditionalVideoState(additionalCurrentVideo);
		}
	}, [additionalCurrentVideo, videosList]);

	// useEffect(() => {
	// 	if (
	// 		videosList?.movie_format?.id === 1 && additionalCurrentVideo.length !== 0 &&
	// 		((additionalCurrentVideo?.movie &&
	// 			location.search.split("=")[1] == additionalCurrentVideo.movie.id) ||
	// 			location.pathname === "/newfilm" ||
	// 			location.pathname === "/newserial")
	// 	) {
	// 		setAdditionalVideoState(additionalCurrentVideo);
	// 	}
	// }, [additionalCurrentSerial, videosList]);

	const goBack = () => {
		setMainVideoState(initialState);
		setAdditionalVideoState(initialAdditionState);
		navigate(-1);
	};
	return (
		<section className="personal-area-item edit">
			<header className="personal-area edit-videos-header">
				<button className="form-page-back" onClick={() => goBack()}>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M11.9142 7.50015L11.2071 8.20726L8.41421 11.0002H18H19V13.0002H18H8.41421L11.2071 15.793L11.9142 16.5002L10.5 17.9144L9.79289 17.2073L5.29289 12.7073C4.90237 12.3167 4.90237 11.6836 5.29289 11.293L9.79289 6.79304L10.5 6.08594L11.9142 7.50015Z"
							fill="#94A0B1"
						/>
					</svg>
					Вернуться назад
				</button>
				{!!mainVideoState?.movie_format?.id ? (
					<h1>
						{mainVideoState.id
							? mainVideoState.title
							: mainVideoState.movie_format.id === 1
							? "Новый фильм"
							: mainVideoState.movie_format.id === 2
							? "Новый сериал"
							: mainVideoState.movie_format.id === 3
							? "Новый блог"
							: "Новый телесериал"}
					</h1>
				) : null}

				<div className="bookmark-wrapper">
					<VideoBookmarks activeBookMark={activeBookMark} setActiveBookMark={setActiveBookMark} />
					{activeBookMark === 1 ? (
						<Cluster space="24px" align="center">
							<DText15 style={{ color: "#94A0B1" }}>
								{`${
									filtersState.date_key == 4 ? "..." : formatedDate(filtersState.from_datetime)
								} — ${formatedDate(filtersState.to_datetime)}`}
							</DText15>
							<PeriodFilters filtersState={filtersState} setFiltersState={setFiltersState} />
							<SelectStatisticEpisodes
								mainVideoState={mainVideoState}
								seasons={mainVideoState?.serial?.[0]?.seasons}
								setSideVideoStaticState={setSelectedVideoState}
							/>
						</Cluster>
					) : null}
				</div>
			</header>
			<div className="form-container-blocks">
				{!!mainVideoState.movie_format?.id ? (
					activeBookMark === 2 ? (
						<>
							<EditVideoPolitics
								mainVideoState={mainVideoState}
								setMainVideoState={setMainVideoState}
							/>
							<VideoEditSidebar
								mainVideoState={mainVideoState}
								additionalVideoState={additionalVideoState}
								setMainVideoState={setMainVideoState}
								errorState={errorState}
								setErrorState={setErrorState}
							/>
						</>
					) : activeBookMark === 1 ? (
						<EditVideoStatistic
							filtersState={filtersState}
							mainVideoState={mainVideoState}
							setMainVideoState={setMainVideoState}
							additionalVideoState={additionalVideoState}
						/>
					) : (
						<>
							<EditVideoContent
								mainVideoState={mainVideoState}
								setMainVideoState={setMainVideoState}
								additionalVideoState={additionalVideoState}
								setAdditionalVideoState={setAdditionalVideoState}
								errorState={errorState}
								setErrorState={setErrorState}
							/>
							<VideoEditSidebar
								mainVideoState={mainVideoState}
								additionalVideoState={additionalVideoState}
								setMainVideoState={setMainVideoState}
								errorState={errorState}
								setErrorState={setErrorState}
							/>
						</>
					)
				) : null}
			</div>
		</section>
	);
};

const mapStateToProps = (state) => {
	return {
		videosList: state.currentVideo,
		additionalCurrentVideo: state.additionalCurrentVideo,
		additionalCurrentSerial: state.additionalCurrentSerial,
		videoAgesList: state.videoAgesList,
		videoGenresList: state.videoGenresList,
		uploadImage: state.uploadImage,
		uploadGalleryImage: state.uploadGalleryImage,
		loadingStatus: state.loadingStatus,
		videoHashtags: state.videoHashtags,
		politicsList: state.politicsList,
	};
};

const mapDispatchToProps = {
	getVideo,
	getVideoAges,
	getVideoGenres,
	getVideoHashtags,
	postUploadImages,
	getPolitics,
	getAllActiveOwner,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditVideos);
