import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addVideoById } from "../../../Actions/videos-actions/VideosActions";
import { ModalContext } from "../../modal/ModalContext/ModalContext.tsx";
import { onInputTextAreaChange } from "./sctipts";

const AddVideoByIdModal = ({ addVideoById, loading }) => {
	const { handleModal, modal } = useContext(ModalContext);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [videoId, setVideoId] = useState("");
	const [load, setLoad] = useState(false);

	useEffect(() => {
		if (loading === 2) {
			dispatch({ type: "HIDE_LOADER" });
			handleModal();
		}
	}, [loading, modal]);

	// useEffect(() => {
	// 	setVideoOptions({ videoOptions, list: videosList.list });
	// }, [videosList]);

	const handleVideoIdChange = (evt) => {
		// обработчик заполнение инпутов
		const value = evt.target.value; // присваимваем значение инпута
		setVideoId(value); //
	};

	const handleVideoClick = async () => {
		if (videoId.length === 0) return;
		setLoad(true);
		await axios.get(`https://atips.io/filmdata/?id=${videoId}`).then((result) => {
			handleModal();
			setLoad(false);
			navigate(`film?id=${result.data.movieid}`);
		});
		// addVideoById(videoId);
	};
	return (
		<div className="modal-field" style={{ width: "600px" }}>
			<div className="modal-field-title">Добавить новое видео</div>
			<div className="modal-field-close" onClick={() => handleModal()}>
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M4.70701 3.29304L3.99991 2.58594L2.58569 4.00015L3.2928 4.70726L10.5857 12.0002L3.2928 19.293L2.58569 20.0002L3.99991 21.4144L4.70701 20.7073L11.9999 13.4144L19.2928 20.7073L19.9999 21.4144L21.4141 20.0002L20.707 19.293L13.4141 12.0002L20.707 4.70726L21.4141 4.00015L19.9999 2.58594L19.2928 3.29304L11.9999 10.5859L4.70701 3.29304Z"
						fill="#212121"
					></path>
				</svg>
			</div>
			<div className="flex-block">
				<div className="form-field">
					<div className="form-field-label">ID видео</div>
					<div className="input-field" style={{ width: "100%" }}>
						<input
							type="string"
							value={videoId}
							placeholder="Введите ID видео"
							onChange={(e) => handleVideoIdChange(e)}
							style={{ width: "100%" }}
						/>
					</div>
				</div>
			</div>
			<div className="modal-buttons delete">
				<button className="button action-button border-button">
					<span>Отменить</span>
				</button>

				<button
					className={`button action-button create-category-button ${
						loading === 1 || load ? "preloader" : ""
					}`}
					onClick={handleVideoClick}
				>
					<span>Добавить</span>
				</button>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		loading: state.loadingStatus.loading,
	};
};

const mapDispatchToProps = {
	addVideoById,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddVideoByIdModal);
