import React, { useContext, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { deleteUser, getSortedUsers, getUsers } from "../../../Actions/UsersActions.js";
import PersonalAreaNavbar from "../../universal/Navbar/PersonalAreaNavbar.jsx";
import { ModalContext } from "../../modal/ModalContext/ModalContext.tsx";
import "./styles/UsersPage.scss";
import UniversalDeleteModal from "../../universal/UniversalDeleteModal.jsx";
import { formatDate } from "../scripts/formatDate.js";
import { handleFilteredClick } from "../scripts/PersonalAreaScripts.js";
import { removeEmpty } from "../../../Actions/ActionsScirpts.js";
import { useUserAccessContext } from "../context/UserAccessContext.js";
import { checkUserWritePerm } from "../scripts/utils.js";

const UsersPage = ({ usersList, deleteUser, title, getSortedUsers }) => {
	const { handleModal } = useContext(ModalContext);
	const [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();
	const { userAccessState } = useUserAccessContext();
	const dispatch = useDispatch();
	const [mainUsersState, setMainUsersState] = useState();
	const [editMenuState, setEditMenuState] = useState({
		id: "",
	});
	const [mainFilterState, setMainFilterState] = useState({});
	// т.е. пока false он даже не смотрит на аплоад
	useEffect(() => {
		document.title = title;
	}, [title]);

	useEffect(() => {
		const filteredState = removeEmpty(mainFilterState);
		getSortedUsers({ ...filteredState });
		setSearchParams({
			...filteredState,
		});
	}, [mainFilterState]);

	useEffect(() => {
		setMainUsersState(usersList);
	}, [usersList]);

	const handleCreateUserClick = () => {
		dispatch({ type: "RESET_USER" });
		navigate("/newuser");
	};
	return (
		<section className="personal-area-item">
			<header className="personal-area categories-header">
				<h1>
					Пользователи
					<sup>
						{/* {!!sellersList.pagination.max_elements ? sellersList.pagination.max_elements : ""} */}
						{mainUsersState?.length || 0}
					</sup>
				</h1>
				<button
					className="button_add-category"
					onClick={() => handleCreateUserClick()}
					disabled={!checkUserWritePerm(userAccessState, "users")}
				>
					<img src="images/icons/ui/actions/white/plus.svg" alt="плюс" />
					Добавить пользователя
				</button>
			</header>
			<div className="content-wrapper">
				<div className="list-table-header video-list" style={{ marginTop: "48px" }}>
					<div
						className="list-table-header-element non-sort"
						style={{ paddingLeft: "0", width: "31.5%" }}
						// onClick={() =>
						// 	handleFilteredClick("sort", "surname", mainFilterState, setMainFilterState)
						// }
					>
						<span>Имя</span>
					</div>
					<div
						className="list-table-header-element non-sort"
						style={{ width: "25.6%" }}
						// onClick={() =>
						// 	handleFilteredClick("sort", "login", mainFilterState, setMainFilterState)
						// }
					>
						<span>Логин</span>
					</div>
					<div
						className="list-table-header-element non-sort"
						style={{ width: "20.9%" }}
						// onClick={() =>
						// 	handleFilteredClick("sort", "activity", mainFilterState, setMainFilterState)
						// }
					>
						<span style={{ paddingRight: "40px" }}>Последняя активность</span>
					</div>
				</div>
				{mainUsersState?.length > 0 ? (
					<div className="list-table user-list">
						{mainUsersState.map((user, index) => (
							<div className="list-table-item" key={index}>
								<div className="list-table-item-element">
									{user.avatar_url && user.avatar_url?.length > 0 ? (
										<div
											className="form-container-block-preview-image"
											style={{
												backgroundImage: `url('https://atips.io/${user.avatar_url}')`,
												backgroundSize: "auto 100%",
												backgroundRepeat: "no-repeat",
												backgroundPosition: "50% 50%",
											}}
										></div>
									) : (
										<div
											className="form-container-block-preview-image"
											style={{
												backgroundColor: "#005bff",
											}}
										>
											<span>{`${user.first_name?.split("")[0]}${
												user.second_name?.split("")[0]
											}`}</span>
										</div>
									)}
									<span>
										<Link
											to={`/user?id=${user.id}`}
										>{`${user.first_name} ${user.second_name}`}</Link>
									</span>
								</div>
								{/* <div className="list-table-item-element">
									<span>
										{!!user.marketplace && user.marketplace.length > 0
											? user.marketplace
											: "Не указано"}
									</span>
								</div> */}
								<div className="list-table-item-element">
									<span>{user.email !== null && user.email.length > 0 ? user.email : null}</span>
								</div>
								<div className="list-table-item-element">
									<span>
										{user.activity && user.activity.date !== false
											? formatDate(String(user.activity.date))
											: "Не было активностей"}
									</span>
									{checkUserWritePerm(userAccessState, "users") ? (
										<div
											className="list-table-item-menu"
											tabIndex="0"
											onFocus={() => setEditMenuState({ ...editMenuState, id: user.id })}
											onBlur={() => setEditMenuState({ ...editMenuState, id: "" })}
										>
											<svg
												width="24"
												height="24"
												viewBox="0 0 24 24"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M11.5 7C10.6716 7 10 6.32843 10 5.5C10 4.67157 10.6716 4 11.5 4C12.3284 4 13 4.67157 13 5.5C13 6.32843 12.3284 7 11.5 7ZM11.5 14C10.6716 14 10 13.3284 10 12.5C10 11.6716 10.6716 11 11.5 11C12.3284 11 13 11.6716 13 12.5C13 13.3284 12.3284 14 11.5 14ZM10 19.5C10 20.3284 10.6716 21 11.5 21C12.3284 21 13 20.3284 13 19.5C13 18.6716 12.3284 18 11.5 18C10.6716 18 10 18.6716 10 19.5Z"
													fill="#94A0B1"
												></path>
											</svg>
											<div
												className={`list-table-item-submenu ${
													user.id === editMenuState.id ? "active" : ""
												}`}
											>
												<div
													className="list-table-item-submenu-item action-button"
													onClick={() => navigate(`/user?id=${user.id}`)}
												>
													Изменить
												</div>
												<div
													className="list-table-item-submenu-item action-button"
													onClick={() =>
														handleModal(
															<UniversalDeleteModal
																title={`${user.first_name} ${user.second_name}`}
																deleteFunc={deleteUser}
																id={user.id}
															/>
														)
													}
												>
													Удалить
												</div>
											</div>
										</div>
									) : null}
								</div>
							</div>
						))}
					</div>
				) : null}
			</div>
		</section>
	);
};

const mapStateToProps = (state) => {
	return {
		usersList: state.usersList,
	};
};

const mapDispatchToProps = {
	getUsers,
	deleteUser,
	getSortedUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersPage);
