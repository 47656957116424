import React, { useEffect } from "react";
import {
	LineChart,
	Line,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	ResponsiveContainer,
} from "recharts";
import { useState } from "react";

const LINE_COLOR = ["#015BFA", "#FD4AA7", "#0DC268", "#CC5EF5"];

const GadgetsChartComponent = ({ lines, period }) => {
	const [actualNameState, setActualNameState] = useState("");
	const [dataState, setDataState] = useState([]);
	const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

	useEffect(() => {
		if (lines.length && lines[0].length) setDataState(lines);
	}, [lines]);

	const handleOnMouseOver = (e) => {
		if (e && e.name && e.name !== actualNameState) {
			setActualNameState(e.name);
		}
	};

	const CustomTooltip = (props) => {
		const { active, payload, label } = props;
		if (active && payload && payload.length && actualNameState) {
			return (
				<div id="custom-tooltip" className="custom-tooltip">
					<img src="images/icons/ui/arrow.svg" alt="вниз" />
					<p className="value">{`${payload
						.find((elem) => elem.name == actualNameState)
						?.value.toLocaleString("ru-RU")} просмотров`}</p>
					<p className="name">{`${label}`}</p>
				</div>
			);
		}
		return null;
	};
	const CustomizedActiveDot = (props) => {
		const { cx, cy, stroke, payload, value } = props;
		if (payload.device_type == actualNameState) {
			if (cx !== tooltipPosition.x || cy !== tooltipPosition.y) {
				setTooltipPosition({
					x: cx,
					y: cy,
				});
			}
			return (
				<svg
					x={cx - 12}
					y={cy - 12}
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<circle opacity="0.2" cx="12" cy="12" r="12" fill={`${props.fill}`} />
					<svg
						width="8"
						height="8"
						viewBox="0 0 8 8"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
						x={8}
						y={8}
					>
						<circle cx="4" cy="4" r="4" fill={`${props.fill}`} />
					</svg>
				</svg>
			);
		}
		return null;
	};
	const CustomizedAxisTickX = (props) => {
		const { x, y, stroke, payload, index, visibleTicksCount } = props;
		let value = payload.value?.split(" ", 2).join(" ");
		if (period === "day") {
			value = payload.value?.split(" ")[2];
		}
		if (period === "week") {
			value = `${payload.value.split(", ")[1]}, ${payload.value?.split(", ")[0]}`;
		}
		if (payload.index === visibleTicksCount - 1) {
			return (
				<g transform={`translate(${x},${y})`}>
					<text
						x={0}
						y={0}
						textAnchor="end"
						fill="#94A0B1"
						style={{ fontWeight: "400", fontSize: "13px", width: "100px" }}
					>
						{value}
					</text>
				</g>
			);
		} else if (index === 0) {
			return (
				<g transform={`translate(${60},${y})`}>
					<text
						x={0}
						y={0}
						textAnchor="start"
						fill="#94A0B1"
						style={{ fontWeight: "400", fontSize: "13px" }}
					>
						{value}
					</text>
				</g>
			);
		} else if (
			((period === "month" || period === "day") &&
				payload.index % 3 === 0 &&
				visibleTicksCount - 1 - payload.index > 2) ||
			((period === "week" || period === "year" || period === "full") && payload.index % 2 === 0)
		) {
			return (
				<g transform={`translate(${x},${y})`}>
					<text
						x={0}
						y={0}
						textAnchor="middle"
						fill="#94A0B1"
						style={{ fontWeight: "400", fontSize: "13px" }}
					>
						{value}
					</text>
				</g>
			);
		} else {
			return (
				<g style={{ display: "none" }}>
					{/* <g transform={`translate(${x},${y})`}> */}
					<text
						x={0}
						y={0}
						textAnchor="middle"
						fill="#94A0B1"
						style={{ fontWeight: "400", fontSize: "13px" }}
					>
						{value}
					</text>
				</g>
			);
		}
	};
	const CustomizedAxisTickY = (props) => {
		const { x, y, stroke, payload } = props;
		return (
			<g transform={`translate(${x},${y})`}>
				<text
					x={0}
					y={4}
					textAnchor="end"
					fill="#94A0B1"
					style={{ fontWeight: "400", fontSize: "13px" }}
				>
					{Number(payload.value) > 5000
						? Number(payload.value) / 1000 + "к"
						: Number(payload.value)}
				</text>
			</g>
		);
	};
	return (
		<>
			<div className="income-info-block gadgets">
				<div className="income-info-block__content">
					{dataState && dataState.length > 0 && dataState[0]?.length ? (
						<ResponsiveContainer width="100%" height="100%">
							<LineChart
								width="100%"
								height="100%"
								// data={dataState}
								margin={{ top: 28, right: 0, left: 0, bottom: 16 }}
							>
								<CartesianGrid horizontal vertical={false} />
								<XAxis
									dataKey="date"
									axisLine={false}
									tickLine={false}
									type="category"
									tick={<CustomizedAxisTickX />}
									tickMargin={18.5}
									allowDuplicatedCategory={false}
									interval={0}
								/>
								<YAxis
									dataKey="amount"
									axisLine={false}
									tickLine={false}
									tick={<CustomizedAxisTickY />}
									tickMargin={16}
								/>

								<Tooltip
									position={tooltipPosition}
									content={<CustomTooltip />}
									offset={0}
									allowEscapeViewBox={{ x: true, y: true }}
									isAnimationActive={false}
									wrapperStyle={{ outline: "none" }}
								/>
								{dataState.map((elem, index) => (
									<Line
										dataKey="amount"
										data={elem}
										name={`${index}`}
										key={index}
										type="monotone"
										activeDot={<CustomizedActiveDot />}
										stroke={LINE_COLOR[index]}
										strokeWidth={2}
										dot={false}
										onMouseMove={handleOnMouseOver}
									/>
								))}
								{/* <Line
                  onMouseOver={handleOnMouseOver}
                  id="mobile"
                  type="monotone"
                  dataKey="mobile"
                  name="mobile"
                  stroke="#015BFA"
                  strokeWidth={2}
                  dot={false}
                  activeDot={<CustomizedActiveDot />}
                />
                <Line
                  onMouseOver={handleOnMouseOver}
                  id="desktop"
                  type="monotone"
                  dataKey="desktop"
                  name="desktop"
                  stroke="#FD4AA7"
                  strokeWidth={2}
                  dot={false}
                  activeDot={<CustomizedActiveDot />}
                />
                <Line
                  onMouseOver={handleOnMouseOver}
                  id="tv"
                  type="monotone"
                  dataKey="tv"
                  name="tv"
                  stroke="#0DC268"
                  strokeWidth={2}
                  dot={false}
                  activeDot={<CustomizedActiveDot />}
                />
                <Line
                  onMouseOver={handleOnMouseOver}
                  id="other"
                  type="monotone"
                  dataKey="other"
                  name="other"
                  stroke="#CC5EF5"
                  strokeWidth={2}
                  dot={false}
                  activeDot={<CustomizedActiveDot />}
                /> */}
							</LineChart>
						</ResponsiveContainer>
					) : null}
				</div>
			</div>
		</>
	);
};

export default GadgetsChartComponent;
