import React, { useEffect, useState } from "react";
import "./wrappers-containers.scss";

export const StatisticBlockContainer = ({
	children,
	padding = "24px",
	borderRadius = "8px",
	marginTop = "16px",
	className = "",
}) => {
	return (
		<div
			className={`statistic-block-container ${className}`}
			style={{ padding: padding, borderRadius: borderRadius, marginTop: marginTop }}
		>
			{children}
		</div>
	);
};
