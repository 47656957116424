import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { connect } from "react-redux";
import Authorization from "../components/Index/Authorization.jsx";
import { ModalProvider } from "../components/modal/ModalContext/ModalContext";
import "../../public/fonts.css";
import { PrivateRoute } from "./PrivateRoute.js";
import PersonalArea from "../components/PersonalArea/PersonalArea.jsx";
import { AsideToggleProvider } from "../components/PersonalArea/context/AsideToggleContext.js";
import { getAuthUser } from "../Actions/AuthorizationActions.js";
import AuthService from "../services/auth.service.js";
import { UserAccessProvider } from "../components/PersonalArea/context/UserAccessContext.js";

/**
 * Основной компонент, в котором происходит проверка на авторизацию
 * и LogOut если в localStorage не найдены данные пользователя
 */
const App = ({ authUser, getAuthUser }) => {
	const [creatorState, setCreatorState] = useState(null);

	useEffect(() => {
		const id = JSON.parse(localStorage.getItem("user"))?.id;

		// При каким обстоятельствах удаляется "user" из localStorage см. services/setupInterceptors.js
		if (!id) {
			AuthService.logout();
			return;
		}
		getAuthUser(id);
	}, []);

	useEffect(() => {
		setCreatorState(authUser);
	}, [authUser]);

	return (
		<Router>
			<ModalProvider>
				<UserAccessProvider>
					<AsideToggleProvider>
						<Routes>
							<Route exact path="*" element={<PrivateRoute user_id={creatorState} />}>
								<Route path="*" element={<PersonalArea />} />
							</Route>
							<Route exact path="/sign_in" element={<Authorization />} />
						</Routes>
					</AsideToggleProvider>
				</UserAccessProvider>
			</ModalProvider>
		</Router>
	);
};

const mapStateToProps = (state) => {
	return {
		authUser: state.authUser,
	};
};

const mapDispatchToProps = {
	getAuthUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
