import React, { useContext } from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import styled from "styled-components";
import { Stack } from "../../../../../ui-kit/layouts/Stack";
import { H5Text, DTBold13, DText15 } from "../../../../../ui-kit/typography/Typography";
import { Input } from "../../../../../ui-kit/input/Input";
import { Button } from "../../../../../ui-kit/button/Button";
import { initialUser } from "../initial";
import { baseUrl } from "../../../../../Actions/consts";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Cluster } from "../../../../../ui-kit/layouts/Cluster";
import { ModalContext } from "../../../../modal/ModalContext/ModalContext";

const ErrorDetail = styled(Cluster)``;



const ThirdAddOwnerStep = ({ userState = initialUser, setUserState, setActive }) => {
	const dispatch = useDispatch();
	const { handleModal } = useContext(ModalContext);
	const [errorDetail, setErrorDetail] = useState("");
	const navigate = useNavigate();

	const handleInputChange = (e, key, secondKey) => {
		e.preventDefault();
		const value = e.target.value;
		if (secondKey) {
			setUserState({
				...userState,
				[key]: { ...userState[key], [secondKey]: value },
			});
		} else {
			setUserState({
				...userState,
				[key]: value,
			});
		}
	};

	const handleRegisterClick = async () => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.post(`${baseUrl}/owner`, userState)
			.then((owner) => {
				dispatch({
					type: "POST_OWNER",
					owner,
				});
				setErrorDetail("");
				handleModal();
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				if (error.response.status === 400 && error.response && error.response?.data?.detail) {
					setErrorDetail(error.response?.data?.detail);
				} else {
					setErrorDetail("Что-то пошло не так:( Проверьте правильность введеныых данных");
				}
				console.log(error);
			});
	};

	return (
		<Stack space="32px">
			<Stack space="16px">
				<H5Text>Банковские реквизиты</H5Text>
				<Stack space="24px">
					<Input
						label="ИНН*"
						value={userState?.bank_inn}
						name="inn"
						type="number"
						onChange={(e) => handleInputChange(e, "bank_inn")}
					/>
					<Input
						label="КПП"
						value={userState?.kpp}
						type="number"
						onChange={(e) => handleInputChange(e, "kpp")}
					/>
					<Cluster space="16px">
						<Input
							label="Расчетный счет*"
							value={userState?.checking_account}
							type="number"
							onChange={(e) => handleInputChange(e, "checking_account")}
						/>
						<Input
							label="Банк*"
							value={userState?.bank_name}
							type="text"
							onChange={(e) => handleInputChange(e, "bank_name")}
						/>
					</Cluster>
					<Cluster space="16px">
						<Input
							label="Корреспондентский счет*"
							type="number"
							value={userState.correspondent_account}
							onChange={(e) => handleInputChange(e, "correspondent_account")}
						/>
						<Input
							label="БИК*"
							type="number"
							value={userState.bik}
							onChange={(e) => handleInputChange(e, "bik")}
						/>
					</Cluster>
				</Stack>
			</Stack>
			{errorDetail.length > 0 ? (
				<ErrorDetail align="center" justify="center">
					<DTBold13 style={{ color: "#ed0a34" }}>{errorDetail}</DTBold13>
				</ErrorDetail>
			) : null}
			<Stack space="24px">
				<Cluster justify="space-between" style={{ marginTop: "32px" }}>
					<Button size="big" variant="secondary" onClick={() => setActive(2)} text="Назад" />
					<Button
						size="big"
						variant="primary"
						disabled={
							!userState.bik.length ||
							!userState.bank_name.length ||
							!userState.correspondent_account.length ||
							!userState.checking_account.length ||
							!userState.bank_inn.length
							// !userState.kpp.length
						}
						onClick={() => handleRegisterClick()}
						text="Добавить"
					/>
				</Cluster>
			</Stack>
		</Stack>
	);
};

// const mapStateToProps = (state) => {
//   return {
//     login: state.login,
//   };
// };

// const mapDispatchToProps = {
//   postLogin,
// };

export default connect(null, null)(ThirdAddOwnerStep);
