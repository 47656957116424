import { gql } from "@apollo/client";

export const GET_VIEWER_TIPS = gql`
	# query getStatisticPage($lte: DateTime, $gte: DateTime) {
	query getStatisticPage($moviegoer_id: Int, $lte: DateTime, $gte: DateTime) {
		show: getStatisticPage(
			pagination: { page: 1, limit: 1 }
			filter: {
				owner_id: 1
				moviegoer_id: $moviegoer_id
				click: 0
				created_at: { gte: $gte, lte: $lte }
			}
			sort: { field: price, direction: DESC }
		) {
			items {
				price
			}
			pageInfo {
				itemsCount
			}
		}
		click: getStatisticPage(
			pagination: { page: 1, limit: 1 }
			filter: {
				owner_id: 1
				moviegoer_id: $moviegoer_id
				click: 1
				created_at: { gte: $gte, lte: $lte }
			}
			sort: { field: price, direction: DESC }
		) {
			items {
				price
			}
			pageInfo {
				itemsCount
			}
			aggregation {
				total_sum
				income_profit
			}
		}
	}
`;

export const GET_VIEWER_VIDEO_TIPS = gql`
	query getStatisticPage($moviegoer_id: Int, $video_id: Int) {
		show: getStatisticPage(
			pagination: { page: 1, limit: 1 }
			filter: { owner_id: 1, moviegoer_id: $moviegoer_id, click: 0, video_id: $video_id }
			sort: { field: price, direction: DESC }
		) {
			items {
				format
				genre
			}
			pageInfo {
				itemsCount
			}
		}
		click: getStatisticPage(
			pagination: { page: 1, limit: 1 }
			filter: { owner_id: 1, moviegoer_id: $moviegoer_id, click: 1, video_id: $video_id }
			sort: { field: price, direction: DESC }
		) {
			items {
				format
			}
			pageInfo {
				itemsCount
			}
			aggregation {
				total_sum
				income_profit
			}
		}
	}
`;

export const GET_VIEWER_TOP5_STATISTIC = gql`
	query getEfficiencyItem($lte: DateTime, $gte: DateTime, $moviegoer_id: Int) {
		getCatEfficiencyItem(
			filter: { owner_id: 1, moviegoer_id: $moviegoer_id, created_at: { gte: $gte, lte: $lte } }
		) {
			best_products_cat {
				name
				category_id
				views_count
				clicks_count
				profit
				percent
			}
			best_services_cat {
				name
				category_id
				views_count
				clicks_count
				profit
				percent
			}
		}
		getTipsEfficiencyItem(
			filter: { owner_id: 1, moviegoer_id: $moviegoer_id, created_at: { gte: $gte, lte: $lte } }
		) {
			best_service_tips {
				tips_id
				views_count
				clicks_count
				profit
				percent
			}
			best_products_tips {
				tips_id
				views_count
				clicks_count
				profit
				percent
			}
		}
	}
`;
