import React, { useContext, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { ModalContext } from "../../../modal/ModalContext/ModalContext";
import { patchExternalViewer } from "../../../../Actions/ViewersActions";
import axios from "axios";
import { baseUrl, cinemaUrl } from "../../../../Actions/consts";
import styled from "styled-components";

const ErrorWrapper = styled.div`
	color: #ed0a34;
`;

const ERROR_TEMPLATE = {
	USER_EMAIL_ALREADY_EXISTS: "Данная почта уже зарегистрирована",
	DEFAULT: "Ошибка при сохранении данных",
};

const AddNewFrame = ({ loading, viewer, patchExternalViewer }) => {
	const { handleModal } = useContext(ModalContext);
	const [errorText, setErrorText] = useState("");
	const dispatch = useDispatch();
	const [mainState, setMainState] = useState({
		email: viewer.email || "",
		phone_number: viewer.phone_number || "",
	});
	const handleInputChange = (evt, key) => {
		// обработчик заполнение инпутов
		const val = evt.target.value; // присваиваем значение инпута
		setMainState({ ...mainState, [key]: val }); // изменяем состояние
	};

	const handleAddClick = async () => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.post(`${baseUrl}/domain/other`, {
				method: "PATCH",
				service: 1,
				endpoint: `/api/dashboard/users/${viewer.id}`,
				data_json: mainState,
			})
			.then((viewer) => {
				dispatch({
					type: "PATCH_EXTERNAL_VIEWER",
					viewer,
				});
				handleModal();
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				if (error.response.data.detail in ERROR_TEMPLATE) {
					setErrorText(ERROR_TEMPLATE[error.response.data.detail]);
				} else {
					setErrorText(ERROR_TEMPLATE["DEFAULT"]);
				}
				console.log(error);
			});
	};
	return (
		<>
			<div className="add-category-wrapper">
				<div className="modal-field-title">Редактировать пользователя</div>
				<div className="modal-field-close" onClick={() => handleModal()}>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M4.70701 3.29304L3.99991 2.58594L2.58569 4.00015L3.2928 4.70726L10.5857 12.0002L3.2928 19.293L2.58569 20.0002L3.99991 21.4144L4.70701 20.7073L11.9999 13.4144L19.2928 20.7073L19.9999 21.4144L21.4141 20.0002L20.707 19.293L13.4141 12.0002L20.707 4.70726L21.4141 4.00015L19.9999 2.58594L19.2928 3.29304L11.9999 10.5859L4.70701 3.29304Z"
							fill="#212121"
						></path>
					</svg>
				</div>
				<div className="flex-block">
					<div className="form-field">
						<div className="form-field-label">Email</div>
						<div className="input-field">
							<input
								type="text"
								placeholder="Ввведите новый email"
								style={{ width: "440px" }}
								id="category-title"
								onChange={(e) => handleInputChange(e, "email")}
								value={mainState.email}
							/>
						</div>
					</div>
				</div>
				<div className="flex-block">
					<div className="form-field">
						<div className="form-field-label">Телефон</div>
						<div className="input-field">
							<input
								type="text"
								placeholder="Ввведите новый телефон"
								style={{ width: "440px" }}
								id="category-title"
								onChange={(e) => handleInputChange(e, "phone_number")}
								value={mainState.phone_number}
							/>
						</div>
					</div>
				</div>
				{errorText && errorText.length ? <ErrorWrapper>{errorText}</ErrorWrapper> : null}
				<div className="modal-buttons">
					<button className="button action-button border-button" onClick={() => handleModal()}>
						<span>Отменить</span>
					</button>
					<button
						className={`button action-button create-category-button ${
							loading === 1 ? "preloader" : ""
						}`}
						onClick={() => handleAddClick()}
					>
						<span>Редактировать</span>
					</button>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		loading: state.loadingStatus.loading,
	};
};

const mapDispatchToProps = {
	patchExternalViewer,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNewFrame);
