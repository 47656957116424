import axios from "axios";
import { cinemaUrl, baseUrl, bloggerUrl } from "../../../../Actions/consts";

export const getTotalSellers = async (vars, dispatch, onReady) => {
	dispatch({
		type: "SHOW_MAIN_LOADER",
	});
	await axios
		.get(`${baseUrl}/domain/sellers/count/`, {
			params: vars,
		})
		.then((sellers) => {
			onReady(sellers.data);
		})
		.finally(() => {
			dispatch({
				type: "HIDE_MAIN_LOADER",
			});
		})
		.catch((error) => {
			console.log(error);
		});
};

export const getTotalTips = async (vars, dispatch, onReady) => {
	dispatch({
		type: "SHOW_MAIN_LOADER",
	});
	await axios
		.get(`${baseUrl}/domain/tips/1/0?is_published=true`, {
			params: vars,
		})
		.then((tip) => {
			if ("status_code" in tip.data) {
				onReady(0);
			} else {
				onReady(tip.data.paginate_.total_rows);
			}
		})
		.finally(() => {
			dispatch({
				type: "HIDE_MAIN_LOADER",
			});
		})
		.catch((error) => {
			console.log(error);
		});
};

export const getTotalVideos = async (dispatch, onReady) => {
	dispatch({
		type: "SHOW_MAIN_LOADER",
	});
	await axios
		.get(`${baseUrl}/domain/movies/filter?limit=1&offset=0`)
		.then((video) => {
			onReady(video.data.paginate_.total_rows);
		})
		.finally(() => {
			dispatch({
				type: "HIDE_MAIN_LOADER",
			});
		})
		.catch((error) => {
			console.log(error);
		});
};

export const getTotalMov = async (vars, dispatch, onReady) => {
	dispatch({
		type: "SHOW_MAIN_LOADER",
	});
	await axios
		.get(`${baseUrl}/domain/moviegoers/count/`, {
			params: vars,
		})
		.then((video) => {
			onReady(video.data);
		})
		.finally(() => {
			dispatch({
				type: "HIDE_MAIN_LOADER",
			});
		})
		.catch((error) => {
			console.log(error);
		});
};
export const getTotalBloggers = async (vars, dispatch, onReady) => {
	dispatch({
		type: "SHOW_MAIN_LOADER",
	});
	const queryString = Object.keys(vars)
		.map((key) => key + "=" + vars[key])
		.join("&");
	await axios
		.post(`${baseUrl}/domain/other`, {
			method: "GET",
			service: 2,
			endpoint: `/api/dashboard/users?size=1&page=1&${queryString}`,
			data: "",
		})
		.then((video) => {
			onReady(video.data.total);
		})
		.finally(() => {
			dispatch({
				type: "HIDE_MAIN_LOADER",
			});
		})
		.catch((error) => {
			console.log(error);
		});
};

export const getTotalAverageTime = async (vars, dispatch, onReady) => {
	dispatch({
		type: "SHOW_MAIN_LOADER",
	});
	const queryString = Object.keys(vars)
		.map((key) => key + "=" + vars[key])
		.join("&");
	await axios
		.post(`${baseUrl}/domain/other`, {
			method: "GET",
			service: 1,
			endpoint: `/api/video/average-session-time?${queryString}`,
			data: "",
		})
		.then((blogger) => {
			onReady(blogger.data.average_session_time);
		})
		.finally(() => {
			dispatch({
				type: "HIDE_MAIN_LOADER",
			});
		})
		.catch((error) => {
			console.log(error);
		});
};
export const getTotalLikesCount = async (vars, dispatch, onReady) => {
	dispatch({
		type: "SHOW_MAIN_LOADER",
	});
	const queryString = Object.keys(vars)
		.map((key) => key + "=" + vars[key])
		.join("&");
	await axios
		.post(`${baseUrl}/domain/other`, {
			method: "GET",
			service: 2,
			endpoint: `/api/dashboard/videos/stats/likes?${queryString}`,
			data: "",
		})
		.then((res) => {
			onReady(res.data.total);
		})
		.finally(() => {
			dispatch({
				type: "HIDE_MAIN_LOADER",
			});
		})
		.catch((error) => {
			console.log(error);
		});
};
export const getTotalCommentsCount = async (vars, dispatch, onReady) => {
	dispatch({
		type: "SHOW_MAIN_LOADER",
	});
	await axios
		.get(`${bloggerUrl}/api/comment/comments`, {
			params: vars,
			ignoreGlobalCatch: true,
		})
		.then((ad) => {
			onReady(ad.data.total);
		})
		.finally(() => {
			dispatch({
				type: "HIDE_MAIN_LOADER",
			});
		})
		.catch((error) => {
			console.log(error);
		});
};

export const getTotalDislikesCount = async (vars, dispatch, onReady) => {
	dispatch({
		type: "SHOW_MAIN_LOADER",
	});
	const queryString = Object.keys(vars)
		.map((key) => key + "=" + vars[key])
		.join("&");
	await axios
		.post(`${baseUrl}/domain/other`, {
			method: "GET",
			service: 2,
			endpoint: `/api/dashboard/videos/stats/dislikes?${queryString}`,
			data: "",
		})
		.then((res) => {
			onReady(res.data.total);
		})
		.finally(() => {
			dispatch({
				type: "HIDE_MAIN_LOADER",
			});
		})
		.catch((error) => {
			console.log(error);
		});
};

export const getTotalShares = async (vars, dispatch, onReady) => {
	dispatch({
		type: "SHOW_MAIN_LOADER",
	});
	const queryString = Object.keys(vars)
		.map((key) => key + "=" + vars[key])
		.join("&");
	await axios
		.post(`${baseUrl}/domain/other`, {
			method: "GET",
			service: 2,
			endpoint: `/api/dashboard/videos/stats/shares?size=1&page=1&${queryString}`,
			data: "",
		})
		.then((blogger) => {
			onReady(blogger.data.total);
		})
		.finally(() => {
			dispatch({
				type: "HIDE_MAIN_LOADER",
			});
		})
		.catch((error) => {
			console.log(error);
		});
};
