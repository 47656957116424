const initialSellers = {
	list: [],
	pagination: {},
};

export const SellersReducer = (state = initialSellers, action) => {
	switch (action.type) {
		case "GET_SELLERS":
			return {
				list: action.sellers.data,
				pagination: {
					max_elements: action.sellers.headers?.max_elements,
					max_pages: action.sellers.headers?.max_pages,
				},
			};
		default:
			return state;
	}
};

export const SellerCurrentReducer = (state = null, action) => {
	switch (action.type) {
		case "GET_CURRENT_SELLER":
			return action.seller.data;
		default:
			return state;
	}
};
export const SellerExternalReducer = (state = null, action) => {
	switch (action.type) {
		case "GET_EXTERNAL_SELLER":
			return action.seller.data;
		case "PATCH_EXTERNAL_SELLER":
			return action.seller.data;
		default:
			return state;
	}
};

export const SellerBalanceReducer = (state = initialSellers, action) => {
	switch (action.type) {
		case "GET_SELLER_BALANCE":
			const data = action.seller.data;
			return {
				list: data.items,
				pagination: {
					max_elements: data.total,
					max_pages: Math.ceil(data.total / data.size),
					page: data.page - 1,
					limit: data.size,
				},
			};
		default:
			return state;
	}
};
export const SellerDocumentsReducer = (state = initialSellers, action) => {
	switch (action.type) {
		case "GET_SELLER_DOCUMENTS":
			const data = action.seller.data;
			const { limit, offset, total_rows } = data.paginate_;
			return {
				list: data.items,
				pagination: {
					max_elements: total_rows,
					max_pages: Math.ceil(total_rows / limit),
					page: offset / limit,
					limit: limit,
				},
			};
		default:
			return state;
	}
};

export const ExternalAllSellerBalanceReducer = (state = initialSellers, action) => {
	switch (action.type) {
		case "GET_ALL_EXTERNAL_SELLER_BALANCE": {
			const data = action.seller.data;
			const result = {
				current_balance: data.current_balance / 100,
				total_replenish_amount: data.total_replenish_amount / 100,
			};
			return result;
		}
		default:
			return state;
	}
};

const initialExternalSellersList = {
	active: initialSellers,
	review: initialSellers,
	blocked: initialSellers,
	deleted: initialSellers,
};

export const AllExternalSellersReducer = (state = initialExternalSellersList, action) => {
	switch (action.type) {
		case "GET_ALL_ACTIVE_EXTERNAL_SELLER": {
			const data = action.seller.data;
			return {
				...state,
				active: {
					list: data.items,
					pagination: {
						max_elements: data.total,
						max_pages: Math.ceil(data.total / data.size),
						page: data.page,
						limit: data.size,
					},
				},
			};
		}
		case "GET_ALL_REVIEW_EXTERNAL_SELLER": {
			const data = action.seller.data;
			return {
				...state,
				review: {
					list: data.items,
					pagination: {
						max_elements: data.total,
						max_pages: Math.ceil(data.total / data.size),
						page: data.page,
						limit: data.size,
					},
				},
			};
		}
		case "GET_ALL_BLOCKED_EXTERNAL_SELLER": {
			const data = action.seller.data;

			return {
				...state,
				blocked: {
					list: data.items,
					pagination: {
						max_elements: data.total,
						max_pages: Math.ceil(data.total / data.size),
						page: data.page,
						limit: data.size,
					},
				},
			};
		}
		case "GET_ALL_DELETED_EXTERNAL_SELLER": {
			const data = action.seller.data;

			return {
				...state,
				deleted: {
					list: data.items,
					pagination: {
						max_elements: data.total,
						max_pages: Math.ceil(data.total / data.size),
						page: data.page,
						limit: data.size,
					},
				},
			};
		}
		default:
			return state;
	}
};

const initialExternalSellersTotal = {
	1: 0,
	2: 0,
	3: 0,
	4: 0,
};

export const ExternalSellersTotalReducer = (state = initialExternalSellersTotal, action) => {
	switch (action.type) {
		case "GET_ACTIVE_EXTERNAL_SELLER_TOTAL": {
			const data = action.seller.data;
			return {
				...state,
				1: data.total,
			};
		}
		case "GET_REVIEW_EXTERNAL_SELLER_TOTAL": {
			const data = action.seller.data;
			return {
				...state,
				2: data.total,
			};
		}
		case "GET_BLOCKED_EXTERNAL_SELLER_TOTAL": {
			const data = action.seller.data;
			return {
				...state,
				3: data.total,
			};
		}
		case "GET_DELETED_EXTERNAL_SELLER_TOTAL": {
			const data = action.seller.data;
			return {
				...state,
				4: data.total,
			};
		}
		default:
			return state;
	}
};
