const initialSellers = {
	list: [],
	pagination: {},
};

export const BloggersReducer = (state = initialSellers, action) => {
	switch (action.type) {
		case "GET_BLOGGERS":
			return {
				list: action.sellers.data,
				pagination: {
					max_elements: action.sellers.headers?.max_elements,
					max_pages: action.sellers.headers?.max_pages,
				},
			};
		default:
			return state;
	}
};

export const BloggerCurrentReducer = (state = null, action) => {
	switch (action.type) {
		case "GET_CURRENT_BLOGGER":
			return action.seller.data;
		case "PATCH_EXTERNAL_BLOGGER":
			return action.seller.data;
		default:
			return state;
	}
};

const initialExternalSellersList = {
	active: initialSellers,
	review: initialSellers,
	blocked: initialSellers,
	deleted: initialSellers,
};

export const AllExternalBloggersReducer = (state = initialExternalSellersList, action) => {
	switch (action.type) {
		case "GET_ALL_ACTIVE_EXTERNAL_BLOGGER": {
			const data = action.seller.data;
			return {
				...state,
				active: {
					list: data.items,
					pagination: {
						max_elements: data.total,
						max_pages: Math.ceil(data.total / data.size),
						page: data.page,
						limit: data.size,
					},
				},
			};
		}
		case "GET_ALL_REVIEW_EXTERNAL_BLOGGER": {
			const data = action.seller.data;
			return {
				...state,
				review: {
					list: data.items,
					pagination: {
						max_elements: data.total,
						max_pages: Math.ceil(data.total / data.size),
						page: data.page,
						limit: data.size,
					},
				},
			};
		}
		case "GET_ALL_BLOCKED_EXTERNAL_BLOGGER": {
			const data = action.seller.data;

			return {
				...state,
				blocked: {
					list: data.items,
					pagination: {
						max_elements: data.total,
						max_pages: Math.ceil(data.total / data.size),
						page: data.page,
						limit: data.size,
					},
				},
			};
		}
		case "GET_ALL_DELETED_EXTERNAL_BLOGGER": {
			const data = action.seller.data;

			return {
				...state,
				deleted: {
					list: data.items,
					pagination: {
						max_elements: data.total,
						max_pages: Math.ceil(data.total / data.size),
						page: data.page,
						limit: data.size,
					},
				},
			};
		}
		default:
			return state;
	}
};

const initialExternalSellersTotal = {
	1: 0,
	2: 0,
	3: 0,
	4: 0,
};

export const ExternalBloggersTotalReducer = (state = initialExternalSellersTotal, action) => {
	switch (action.type) {
		case "GET_ACTIVE_EXTERNAL_BLOGGER_TOTAL": {
			const data = action.seller.data;
			return {
				...state,
				1: data.total,
			};
		}
		case "GET_REVIEW_EXTERNAL_BLOGGER_TOTAL": {
			const data = action.seller.data;
			return {
				...state,
				2: data.total,
			};
		}
		case "GET_BLOCKED_EXTERNAL_BLOGGER_TOTAL": {
			const data = action.seller.data;
			return {
				...state,
				3: data.total,
			};
		}
		case "GET_DELETED_EXTERNAL_BLOGGER_TOTAL": {
			const data = action.seller.data;
			return {
				...state,
				4: data.total,
			};
		}
		default:
			return state;
	}
};

const initialBloggerVideos = {
	list: [],
	pagination: {
		max_elements: 0,
		max_pages: 0,
		page: 0,
		limit: 0,
	},
};

export const BloggerVideosReducer = (video = initialBloggerVideos, action) => {
	switch (action.type) {
		case "GET_BLOGGER_VIDEOS":
			const data = action.video.data;
			return {
				list: data.items,
				pagination: {
					max_elements: data.total,
					max_pages: data.pages,
					page: data.page,
					limit: data.size,
				},
			};
		case "PATCH_BLOGGER_CURRENT_VIDEO":
			return {
				...video,
				list: (video.list = video.list.map((item, index) =>
					item.id === action.video.data.id
						? { ...item, is_active: action.video.data.is_active }
						: item
				)),
			};

		default:
			return video;
	}
};

export const BloggerVideoCaregoryReducer = (state = [], action) => {
	switch (action.type) {
		case "GET_VIDEO_CATEGORY":
			const data = action.genre.data;
			return data;

		default:
			return state;
	}
};

const initialVideoViewsGraph = {
	day: {
		video: [],
		shorts: [],
	},
	week: {
		video: [],
		shorts: [],
	},
	month: {
		video: [],
		shorts: [],
	},
	year: {
		video: [],
		shorts: [],
	},
	full: {
		video: [],
		shorts: [],
	},
	video: [],
};

export const BloggerVideosViewsGraphReducer = (state = initialVideoViewsGraph, action) => {
	switch (action.type) {
		case "GET_BLOGGER_VIDEOS_VIEWS_GRAPH":
			const data = action;
			return {
				...state,
				[data.period]: { ...state[data.period], [data.format]: data.views },
			};
		default:
			return state;
	}
};
