import React, { useContext, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { ModalContext } from "../../../../modal/ModalContext/ModalContext";
import axios from "axios";
import { baseUrl } from "../../../../../Actions/consts";

const ModalBloggerPercentEdit = ({ loader, actualPrice, blogger_id, onUpdatePrice }) => {
	const { handleModal } = useContext(ModalContext);
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [newPriceState, setNewPriceState] = useState({
		price: actualPrice,
	});
	const [errorState, setErrorState] = useState(false);

	const handlePriceChange = (e) => {
		if (errorState) {
			setErrorState(false);
		}
		setNewPriceState({ ...newPriceState, price: e.target.value });
	};

	const handleSendPriceClick = async () => {
		if (newPriceState.price <= 0) {
			setErrorState(true);
		} else {
			setLoading(true);
			await axios
				.post(`${baseUrl}/creatorpercent`, {
					creator_id: blogger_id,
					percent: newPriceState.price,
				})
				.then((price) => {
					handleModal();
					onUpdatePrice(price.data.percent);
				})
				.finally(() => {
					dispatch({
						type: "HIDE_MAIN_LOADER",
					});
					setLoading(false);
				})
				.catch((error) => {
					setErrorState(true);
					console.log(error);
				});
		}
	};
	return (
		<div className="modal-field edit-new-price active" style={{ width: "auto", minWidth: "425px" }}>
			<div className="modal-field-title">Изменить процент дохода</div>
			<div className="modal-field-close" onClick={() => handleModal()}>
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M4.70701 3.29304L3.99991 2.58594L2.58569 4.00015L3.2928 4.70726L10.5857 12.0002L3.2928 19.293L2.58569 20.0002L3.99991 21.4144L4.70701 20.7073L11.9999 13.4144L19.2928 20.7073L19.9999 21.4144L21.4141 20.0002L20.707 19.293L13.4141 12.0002L20.707 4.70726L21.4141 4.00015L19.9999 2.58594L19.2928 3.29304L11.9999 10.5859L4.70701 3.29304Z"
						fill="#212121"
					></path>
				</svg>
			</div>
			<div className="flex-block">
				<div className="form-field">
					<div className="form-field-label">Процент, %</div>
					<div className="input-field" style={{ width: "160px" }}>
						<input
							className={`${errorState ? "error-input" : ""}`}
							type="number"
							value={newPriceState.price}
							placeholder="0"
							onChange={handlePriceChange}
						/>
					</div>
				</div>
			</div>
			<div className="modal-buttons">
				<div className="button action-button border-button" onClick={() => handleModal()}>
					<span>Отменить</span>
				</div>
				<div
					className={`button action-button ${loading ? "preloader" : ""}`}
					onClick={() => handleSendPriceClick()}
				>
					<span>Применить</span>
				</div>
			</div>
		</div>
	);
};

const mapStateTpProps = (state) => {
	return {
		loader: state.loadingStatus,
	};
};

const mapDispatchToProps = {};

export default connect(mapStateTpProps, mapDispatchToProps)(ModalBloggerPercentEdit);
