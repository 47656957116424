import React, { useContext, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { onInputTextAreaChange } from "../../sctipts.js";
import "./EditVideosContent.scss";
// import VideoCinemaCategory from "./VideoCinemaCategory.jsx";
import { Textarea } from "../../../../../ui-kit/textarea/Textarea.jsx";
import { Input } from "../../../../../ui-kit/input/Input.jsx";
import { VideoCinemaCategory } from "./VideoCinemaCategory.jsx";
import styled from "styled-components";
import { Cluster } from "../../../../../ui-kit/layouts/Cluster.jsx";

const StyledImg = styled.img`
	display: block;
	cursor: default !important;
	max-height: 100%;
	width: auto !important;
	left: auto !important;
	right: auto;
	pointer-events: none;
	top: 0;
	max-width: 100%;
	border-radius: 16px;
	margin: auto;
	height: auto;
	width: auto;
`;

const EditBloggerVideoContent = ({ mainVideoState, setMainVideoState, errorState }) => {
	return (
		<div className="form-container first-col">
			<div className="form-container">
				<div className="form-container-block">
					<div className="form-container-block-title">
						<b>Описание</b>
					</div>
					<div className="flex-block flex-start">
						<div className="form-field">
							<div className="form-field-label">Название видео</div>
							<div className="input-field">
								<Input
									disabled={true}
									error={errorState && mainVideoState?.title?.length === 0 ? "error" : ""}
									value={mainVideoState?.title === null ? "" : mainVideoState?.title}
									onChange={(e) => {
										onInputTextAreaChange(e, "title", mainVideoState, setMainVideoState);
									}}
								/>
							</div>
						</div>
					</div>

					<div className="flex-block flex-start">
						<div className="form-field">
							<div className="form-field-label">Описание видео</div>
							<div className="input-field">
								<Textarea
									disabled={true}
									error={
										errorState &&
										mainVideoState.format === 0 &&
										mainVideoState?.description?.length === 0
											? "error"
											: ""
									}
									name=""
									value={mainVideoState?.description === null ? "" : mainVideoState?.description}
									onChange={(e) =>
										onInputTextAreaChange(e, "description", mainVideoState, setMainVideoState)
									}
								></Textarea>
							</div>
						</div>
					</div>
					<div className="form-container-block-subtitle">Превью</div>
					{location.pathname === "/new-video" || mainVideoState?.movie_format_id === 3 ? (
						<Cluster
							justify="center"
							className={`flex-block form-container-block-preview ${
								errorState && mainVideoState?.video?.[0].URL_SD_PREVIEW.length === 0 ? "error" : ""
							}`}
						>
							<div
								className="form-container-block-preview-data"
								style={{
									display: !mainVideoState?.video?.[0].URL_SD_PREVIEW ? "none" : "block",
								}}
							>
								<img src="images/icons/uploadimage.svg" alt="upload" />
								<div>
									<span>
										Перетащите изображение в это окно
										<br />
										или нажмите, чтобы выбрать
									</span>{" "}
									Рекомендуемый размер изображений
									<br />
									не менее 1280×720 px
									<br />
									<br />
									Формат изображений: jpg, webp
									<br />
									Размер файла не должен превышать 5 мб
								</div>
							</div>
							{mainVideoState?.video?.[0].URL_SD_PREVIEW ? (
								<StyledImg
									src={`https://atips.io/${mainVideoState?.video?.[0].URL_SD_PREVIEW}`}
									className="form-container-block-preview-image"
									alt="preview"
								/>
							) : null}
							{/* <div
                className="form-container-block-preview-image"
                style={{
                  backgroundImage: `url('${mainVideoState?.preview}')`,
                  backgroundSize: "auto 90%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "50% 50%",
                }}
              ></div> */}
							<input
								name="URL_SD_HORIZONTAL_PREVIEW"
								accept="image/jpg, image/jpeg, image/webp"
							/>
						</Cluster>
					) : (
						<div
							className={`vertical-preview ${
								errorState && mainVideoState?.video?.[0].URL_SD_PREVIEW === 0 ? "error" : ""
							}`}
							style={{ display: "flex", justifyContent: "center" }}
						>
							<div
								className="flex-block form-container-block-preview"
								style={{ height: "720px", width: "540px" }}
							>
								<div
									className="form-container-block-preview-data"
									style={{
										display: !mainVideoState?.video?.[0].URL_SD_PREVIEW ? "none" : "block",
									}}
								>
									<img src="images/icons/uploadimage.svg" alt="upload" />
									<>
										<span>
											Перетащите изображение в это окно
											<br />
											или нажмите, чтобы выбрать
										</span>{" "}
										Рекомендуемый размер изображений
										<br />
										не менее 720×1280 px
										<br />
										<br />
										Формат изображений: jpg, webp
										<br />
										Размер файла не должен превышать 5 мб
									</>
								</div>
								{mainVideoState?.video?.[0].URL_SD_PREVIEW ? (
									<StyledImg
										src={`https://atips.io/${mainVideoState?.video?.[0].URL_SD_PREVIEW}`}
										className="form-container-block-preview-image"
										alt="preview"
									/>
								) : null}
								{/* <div
                  className="form-container-block-preview-image"
                  style={{
                    backgroundImage: `url('${mainVideoState?.preview}')`,
                    backgroundSize: "auto 90%",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "50% 50%",
                  }}
                ></div> */}
								<input
									name="URL_SD_VERTICAL_PREVIEW"
									accept="image/jpg, image/jpeg, image/webp"
								/>
							</div>
						</div>
					)}
				</div>
				<div className="form-container-block">
					<div className="form-container-block-title">
						<b>Раздел в кинотеатре</b>
					</div>
					<div className="flex-block">
						<div className="form-field">
							<div className="input-field">
								<VideoCinemaCategory collection={mainVideoState?.genres || []} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = {};

export default connect(null, null)(EditBloggerVideoContent);
