import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { DText14 } from "../typography/Typography";
import { Stack } from "../layouts/Stack";
import { Cluster } from "../layouts/Cluster";

const StyledStack = styled(Stack)`
	width: 100%;
`;

const StyledInput = styled.input`
	background: #f4f4f8;
	border-radius: 8px;

	border: 1px solid #f4f4f8;
	border-radius: 8px;
	font-family: "Averta CY";
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	line-height: 160%;
	color: #212121;
	width: 100%;
	padding: 15px 20px;
	outline: none;
	&.error {
		border: 1px solid #ed0a34;
		&:hover,
		&:focus {
			border: 1px solid #ed0a34;
		}
	}
	&:hover {
		border: 1px solid #d1d8e1;
	}
	&::placeholder {
		color: #94a0b1;
	}
	&:focus {
		border: 1px solid #7027c2;
	}
	&:read-only,
	&:disabled {
		background: #d1d8e1;
		border: 1px solid #d1d8e1;
		cursor: default;
		color: #94a0b1;
		&:hover {
			border: 1px solid #d1d8e1;
		}
	}
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}
`;

export const Input = ({
	label = "",
	value,
	name = "",
	onChange,
	placeholder,
	error = false,
	type = "text",
	onKeyDown = null,
	readOnly = false,
	disabled = false,
}) => {
	return (
		<StyledStack space="5px">
			{label && label.length > 0 ? (
				<DText14 style={{ color: error ? "#ed0a34" : "#94A0B1" }}>{label}</DText14>
			) : null}
			<StyledInput
				className={error ? "error" : ""}
				type={type}
				name={name}
				value={value}
				onChange={onChange}
				placeholder={placeholder}
				onKeyDown={onKeyDown}
				readOnly={readOnly}
				disabled={disabled}
			/>
		</StyledStack>
	);
};
