import React, { useEffect, useState } from "react";
import UserStatisticInfoAccordionField from "./UserStatisticInfoAccordionField.jsx";
import { UserStatisticInfoField } from "./UserStatisticInfoField.jsx";
import { connect } from "react-redux";
import { useLazyQuery } from "@apollo/client";
import { GET_MOVIEGOER_BLOGGER_AGG } from "../../../../../GraphQL/BloggerStatistic.js";
import "./blogger-users-statistic.scss";
import { StatisticBlockContainer } from "../../../../../ui-kit/wrappers-containers/StatisticBlockContainer.jsx";
import { Cluster } from "../../../../../ui-kit/layouts/Cluster.jsx";
/**
 */
const BloggerUserStatistic = ({ bloggerState }) => {
	const [getStatistic, { data, loading, error }] = useLazyQuery(GET_MOVIEGOER_BLOGGER_AGG, {
		variables: {
			blogger_id: null,
		},
	});
	useEffect(() => {
		if (!bloggerState) return;
		getStatistic({
			variables: {
				blogger_id: bloggerState.serial_id,
			},
		});
	}, [bloggerState]);

	return (
		<>
			<StatisticBlockContainer className="content-wrapper">
				<div className="home-table half blogger-users" style={{ width: "100%" }}>
					<div className="form-container-block-title">
						<b>Портреты зрителей</b>
					</div>
					<UserStatisticInfoAccordionField
						collection={data?.getMoviegoerAggItem?.countries || []}
						title="Страны и регионы"
					/>
					<UserStatisticInfoField
						collection={data?.getMoviegoerAggItem?.sex_info || []}
						title="Пол"
						color="blue"
					/>
					<UserStatisticInfoField
						collection={data?.getMoviegoerAggItem?.age_info || []}
						title="Возраст"
						color="pink"
					/>
				</div>
			</StatisticBlockContainer>
			<StatisticBlockContainer className="content-wrapper">
				<div className="home-table half blogger-users" style={{ width: "100%" }}>
					<div className="form-container-block-title">
						<b>Портреты рекламодателей</b>
					</div>
					<UserStatisticInfoAccordionField
						collection={data?.getMoviegoerAggItem?.countries || []}
						title="Страны и регионы"
					/>
				</div>
			</StatisticBlockContainer>
		</>
	);
};

// const mapStateToProps = (state) => {
//   return {
//     bloggerState: state.bloggerState,
//   };
// };

// const mapDispatchToProps = {
//   // getViewers,
// };

export default connect(null, null)(BloggerUserStatistic);
