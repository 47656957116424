export const initialMonthData = () => {
	const actualDate = new Date();
	const acutalDateNumber = actualDate.getDate();
	const result = [];
	for (let i = 30; i > 0; i--) {
		result.push({
			actualDate: 0,
			date: new Date(new Date().setDate(acutalDateNumber - i))
				.toLocaleString("ru", options)
				.split(" ", 3)
				.join(" "),
		});
	}
	return result;
};
export const initialWeekData = () => {
	const actualDate = new Date();
	const acutalDateNumber = actualDate.getDate();
	const result = [];
	for (let i = 7; i > 0; i--) {
		result.push({
			actualDate: 0,
			date: new Date(new Date().setDate(acutalDateNumber - i))
				.toLocaleString("ru", options)
				.split(" ", 3)
				.join(" "),
		});
	}
	return result;
};

export const initialDayData = () => {
	const actualDate = new Date();
	const hours = actualDate.getHours();
	const result = [];
	for (let i = 24; i >= 0; i--) {
		result.push({
			actualDate: 0,
			date: new Date(new Date().setHours(hours - i))
				.toLocaleString("ru", options)
				.split(" ", 3)
				.join(" "),
		});
	}
	return result;
};

export let options = {
	year: "numeric",
	month: "long",
	day: "numeric",
};
