import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, Route, Routes, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import "./styles/VideosPage.scss";
import { ModalContext } from "../../modal/ModalContext/ModalContext.tsx";
import ChoseFilmSerial from "./ChoseFilmSerial.jsx";
import AddVideoByIdModal from "./AddVideoByIdModal.jsx";
import DashboardVideos from "./pages/dashboard-videos/DashboardVideos.jsx";
import BloggerVideos from "./pages/blogger-videos/BloggerVideos";
import OwnerVideos from "./pages/owner-videos/OwnerVideos";
import { useUserAccessContext } from "../context/UserAccessContext";
import { checkUserWritePerm } from "../scripts/utils";

const VideosPage = ({ title }) => {
	const navigate = useNavigate();
	const { userAccessState } = useUserAccessContext();
	const { handleModal } = useContext(ModalContext);
	const [total, setTotal] = useState(0);
	const location = useLocation();
	const [currentPageState, setCurrentPageState] = useState("/");
	useEffect(() => {
		setCurrentPageState(location.pathname);
	}, [location]);
	useEffect(() => {
		document.title = title;
	}, [title]);

	return (
		<section className="personal-area-item">
			<header className="personal-area categories-header">
				<h1 className="categories-header-text">
					Видео
					<sup>{total}</sup>
				</h1>
				{/* <button
					className="button_add-category"
					disabled={!checkUserWritePerm(userAccessState, "videos")}
					onClick={() => handleModal(<AddVideoByIdModal />)}
					style={{ marginRight: "20px", background: "#212121" }}
				>
					<img src="images/icons/ui/actions/white/plus.svg" alt="плюс" />
					<img
						src="images/kinopoisk_logo_full.png"
						alt="плюс"
						style={{
							height: "16px",
							position: "relative",
							left: "0px",
							top: "0px",
							bottom: "0px",
						}}
					/>
				</button> */}
				<button
					disabled={!checkUserWritePerm(userAccessState, "videos")}
					className="button_add-category"
					onClick={() => handleModal(<ChoseFilmSerial />)}
				>
					<img src="images/icons/ui/actions/white/plus.svg" alt="плюс" />
					Добавить видео
				</button>
			</header>
			<div className="statistics-bookmarks">
				<button
					className={`statistics-bookmark ${currentPageState === "/videos" ? "active" : ""}`}
					onClick={() => navigate("/videos")}
				>
					Наше видео
				</button>
				<button
					className={`statistics-bookmark ${currentPageState === "/owner-videos" ? "active" : ""}`}
					onClick={() => navigate("/owner-videos")}
				>
					Видео правообладателей
				</button>
				<button
					className={`statistics-bookmark ${
						currentPageState === "/blogger-videos" ? "active" : ""
					}`}
					onClick={() => navigate("/blogger-videos")}
				>
					Видео блогеров
				</button>
			</div>
			{currentPageState === "/videos" ? (
				<DashboardVideos setTotal={setTotal} />
			) : currentPageState === "/owner-videos" ? (
				<OwnerVideos setTotal={setTotal} />
			) : currentPageState === "/blogger-videos" ? (
				<BloggerVideos setTotal={setTotal} />
			) : null}
		</section>
	);
};

export default connect(null, null)(VideosPage);
