const initialReports = {
	list: [],
	pagination: {
		max_elements: 0,
		max_pages: 0,
		page: 0,
		limit: 0,
	},
};

const initialReportTab = {
	channel: initialReports,
	video: initialReports,
	comment: initialReports,
};

export const ReportsReducer = (state = initialReportTab, action) => {
	switch (action.type) {
		case "GET_ALL_CHANNEL_REPORTS":
			return {
				...state,
				channel: {
					list: action.report.data.items,
					pagination: {
						max_elements: action.report.data.paginate_.total_rows,
						max_pages: action.report.data.paginate_.total_pages,
						page: action.report.data.paginate_.page_in.page,
						limit: action.report.data.paginate_.offset_in.limit,
					},
				},
			};
		case "GET_ALL_VIDEO_REPORTS":
			return {
				...state,
				video: {
					list: action.report.data.items,
					pagination: {
						max_elements: action.report.data.paginate_.total_rows,
						max_pages: action.report.data.paginate_.total_pages,
						page: action.report.data.paginate_.page_in.page,
						limit: action.report.data.paginate_.offset_in.limit,
					},
				},
			};
		case "GET_ALL_COMMENT_REPORTS":
			return {
				...state,
				comment: {
					list: action.report.data.items,
					pagination: {
						max_elements: action.report.data.paginate_.total_rows,
						max_pages: action.report.data.paginate_.total_pages,
						page: action.report.data.paginate_.page_in.page,
						limit: action.report.data.paginate_.offset_in.limit,
					},
				},
			};
		default:
			return state;
	}
};

export const CurrentReportReducer = (state = null, action) => {
	switch (action.type) {
		case "PUT_CURRENT_REPORT":
			return { ...state, status: action.status, creator_status: action.creator_status };
		case "GET_CURRENT_REPORT":
			return action.report.data;
		default:
			return state;
	}
};

const initialReportsTotal = {
	1: 0,
	2: 0,
	3: 0,
};

export const ReportsTotalReducer = (state = initialReportsTotal, action) => {
	switch (action.type) {
		case "GET_CHANNEL_REPORTS_TOTAL": {
			return {
				...state,
				1: action.report.data.paginate_.total_rows,
			};
		}
		case "GET_VIDEO_REPORTS_TOTAL": {
			return {
				...state,
				2: action.report.data.paginate_.total_rows,
			};
		}
		case "GET_COMMENT_REPORTS_TOTAL": {
			return {
				...state,
				3: action.report.data.paginate_.total_rows,
			};
		}
		default:
			return state;
	}
};

const initialReportHistory = {
	list: [],
	pagination: {
		max_elements: 0,
		max_pages: 0,
		page: 0,
		limit: 0,
	},
};

export const ReportHistoryReducer = (state = initialReportHistory, action) => {
	switch (action.type) {
		case "GET_REPORTS_HISTORY": {
			return {
				list: action.report.data.items,
				pagination: {
					max_elements: action.report.data.paginate_.total_rows,
					max_pages: Math.ceil(
						action.report.data.paginate_.total_rows / action.report.data.paginate_.limit
					),
					page: Math.round(
						action.report.data.paginate_.offset / action.report.data.paginate_.limit
					),
					limit: action.report.data.paginate_.limit,
				},
			};
		}
		default:
			return state;
	}
};

export const UndreadReportsTotalReducer = (state = 0, action) => {
	switch (action.type) {
		case "GET_UNREAD_REPORT_COUNTS": {
			return action.report.data.paginate_.total_rows;
		}
		default:
			return state;
	}
};
