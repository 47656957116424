import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import axios from "axios";
import { baseUrl } from "../../../../../Actions/consts.js";
import { Tooltip } from "../../../../../ui-kit/tooltip/Tooltip.jsx";

const initialVideosCountByFormats = [
	{
		id: 0,
		title: "Всего видео:",
	},
	{
		id: 1,
		title: "Всего Shorts:",
	},
];

const VideosAmountTooltip = ({ videosCountByFormats, blogger }) => {
	const dispatch = useDispatch();
	const [videosCount, setVideosCount] = useState([]);
	useEffect(() => {
		if (!blogger?.id) return;
		initialVideosCountByFormats.forEach(async (elem) => {
			dispatch({
				type: "SHOW_MAIN_LOADER",
			});
			await axios
				.post(`${baseUrl}/domain/other`, {
					method: "GET",
					service: 2,
					endpoint: `/api/dashboard/videos?user_id=${blogger.id}&format=${elem.id}&page=1&size=1`,
					data: "",
				})
				.then((video) => {
					dispatch({
						type: "GET_BLOGGER_VIDEO_FORMAT_COUNT",
						video,
						formatId: elem.id,
					});
				})
				.finally(() => {
					dispatch({
						type: "HIDE_MAIN_LOADER",
					});
				})
				.catch((error) => {
					console.log(error);
				});
		});
	}, [blogger]);

	useEffect(() => {
		setVideosCount(videosCountByFormats);
	}, [videosCountByFormats]);

	return (
		<Tooltip
			text={videosCount.map(
				(elem) => `${elem.title} ${Number(elem.value)?.toLocaleString("ru-RU")}\n`
			)}
		/>
	);
};

const mapStateToProps = (state) => {
	return {
		videosCountByFormats: state.bloggerVideosCountByFormats,
	};
};

export default connect(mapStateToProps, null)(VideosAmountTooltip);
