import axios from "axios";
import { bloggerUrl, baseUrl, cinemaUrl } from "../../../../../Actions/consts";

// Кол-во просмотров
export const getTotalViewAmount = async (vars, dispatch, onReady) => {
	dispatch({
		type: "SHOW_MAIN_LOADER",
	});

	const queryString = Object.keys(vars)
		.map((key) => key + "=" + vars[key])
		.join("&");

	await axios
		.post(`${baseUrl}/domain/other`, {
			method: "GET",
			service: 1,
			endpoint: `/api/video/watch-amount?${queryString}`,
			data: "",
		})
		.then((blogger) => {
			onReady(blogger.data.amount);
		})
		.finally(() => {
			dispatch({
				type: "HIDE_MAIN_LOADER",
			});
		})
		.catch((error) => {
			console.log(error);
		});
};

export const getTotalSellers = async (vars, dispatch, onReady) => {
	dispatch({
		type: "SHOW_MAIN_LOADER",
	});

	const queryString = Object.keys(vars)
		.map((key) => key + "=" + vars[key])
		.join("&");

	await axios
		.post(`${baseUrl}/domain/other`, {
			method: "GET",
			service: 2,
			endpoint: `/api/dashboard/ad-order/advertisers?size=1&${queryString}`,
			data: "",
		})
		.then((sellers) => {
			onReady(sellers.data.total);
		})
		.finally(() => {
			dispatch({
				type: "HIDE_MAIN_LOADER",
			});
		})
		.catch((error) => {
			console.log(error);
		});
};

export const getBloggerPercent = async (vars, dispatch, onReady) => {
	dispatch({
		type: "SHOW_MAIN_LOADER",
	});
	await axios
		.get(`${baseUrl}/domain/creatorpercent`, {
			params: {
				...vars,
			},
		})
		.then((price) => {
			onReady(price.data.items[0]);
		})
		.finally(() => {
			dispatch({
				type: "HIDE_MAIN_LOADER",
			});
		})
		.catch((error) => {
			console.log(error);
		});
};

export const getTotalVideos = async (vars, dispatch, onReady) => {
	dispatch({
		type: "SHOW_MAIN_LOADER",
	});

	const queryString = Object.keys(vars)
		.map((key) => key + "=" + vars[key])
		.join("&");

	await axios
		.post(`${baseUrl}/domain/other`, {
			method: "GET",
			service: 2,
			endpoint: `/api/dashboard/videos?user_id=${localStorage.getItem(
				"creator_id"
			)}&size=1&page=1&${queryString}`,
			data: "",
		})
		.then((video) => {
			onReady(video.data.total);
		})
		.finally(() => {
			dispatch({
				type: "HIDE_MAIN_LOADER",
			});
		})
		.catch((error) => {
			console.log(error);
		});
};

export const getTotalAd = async (vars, dispatch, onReady) => {
	dispatch({
		type: "SHOW_MAIN_LOADER",
	});

	const queryString = Object.keys(vars)
		.map((key) => key + "=" + vars[key])
		.join("&");

	await axios
		.post(`${baseUrl}/domain/other`, {
			method: "GET",
			service: 2,
			endpoint: `/api/dashboard/ad-order/order-products?size=1&page=1&${queryString}`,
			data: "",
		})
		.then((blogger) => {
			onReady(blogger.data.total);
		})
		.finally(() => {
			dispatch({
				type: "HIDE_MAIN_LOADER",
			});
		})
		.catch((error) => {
			console.log(error);
		});
};

export const getTotalSubcriptions = async (vars, dispatch, onReady) => {
	dispatch({
		type: "SHOW_MAIN_LOADER",
	});

	const queryString = Object.keys(vars)
		.map((key) => key + "=" + vars[key])
		.join("&");
	await axios
		.post(`${baseUrl}/domain/other`, {
			method: "GET",
			service: 2,
			endpoint: `/api/dashboard/subscriptions/graphics?size=1&page=1&${queryString}`,
			data: "",
		})
		.then((blogger) => {
			const data = blogger.data;
			const sum = data[data.length - 1].amount - data[0].amount;
			onReady(sum);
		})
		.finally(() => {
			dispatch({
				type: "HIDE_MAIN_LOADER",
			});
		})
		.catch((error) => {
			console.log(error);
		});
};

export const getTotalTips = async (vars, dispatch, onReady) => {
	dispatch({
		type: "SHOW_MAIN_LOADER",
	});
	await axios
		.get(`${baseUrl}/domain/tips/1/0`, {
			params: {
				...vars,
				is_published: true,
				owner_: 1,
			},
		})
		.then((blogger) => {
			if ("status_code" in blogger.data) {
				onReady(0);
			} else {
				onReady(blogger.data.paginate_.total_rows);
			}
		})
		.finally(() => {
			dispatch({
				type: "HIDE_MAIN_LOADER",
			});
		})
		.catch((error) => {
			console.log(error);
		});
};
export const getTotalAverageTime = async (vars, dispatch, onReady) => {
	dispatch({
		type: "SHOW_MAIN_LOADER",
	});
	const queryString = Object.keys(vars)
		.map((key) => key + "=" + vars[key])
		.join("&");

	await axios
		.post(`${baseUrl}/domain/other`, {
			method: "GET",
			service: 1,
			endpoint: `/api/video/average-session-time?${queryString}`,
			data: "",
		})
		.then((blogger) => {
			onReady(blogger.data.average_session_time);
		})
		.finally(() => {
			dispatch({
				type: "HIDE_MAIN_LOADER",
			});
		})
		.catch((error) => {
			console.log(error);
		});
};

export const getTotalLikesCount = async (vars, dispatch, onReady) => {
	dispatch({
		type: "SHOW_MAIN_LOADER",
	});
	const queryString = Object.keys(vars)
		.map((key) => key + "=" + vars[key])
		.join("&");

	await axios
		.post(`${baseUrl}/domain/other`, {
			method: "GET",
			service: 2,
			endpoint: `/api/dashboard/videos/stats/likes?${queryString}`,
			data: "",
		})
		.then((res) => {
			onReady(res.data.total);
		})
		.finally(() => {
			dispatch({
				type: "HIDE_MAIN_LOADER",
			});
		})
		.catch((error) => {
			console.log(error);
		});
};

export const getTotalDislikesCount = async (vars, dispatch, onReady) => {
	dispatch({
		type: "SHOW_MAIN_LOADER",
	});
	const queryString = Object.keys(vars)
		.map((key) => key + "=" + vars[key])
		.join("&");

	await axios
		.post(`${baseUrl}/domain/other`, {
			method: "GET",
			service: 2,
			endpoint: `/api/dashboard/videos/stats/dislikes?${queryString}`,
			data: "",
		})
		.then((res) => {
			onReady(res.data.total);
		})
		.finally(() => {
			dispatch({
				type: "HIDE_MAIN_LOADER",
			});
		})
		.catch((error) => {
			console.log(error);
		});
};
