import React, { useContext } from "react";
import { sortableContainer, sortableElement } from "react-sortable-hoc";
import { ChildDragHandle } from "../../../universal/dragHandle/DragHandle.jsx";
import { ModalContext } from "../../../modal/ModalContext/ModalContext.tsx";
import { useEffect } from "react";
import UniversalDeleteModal from "../../../universal/UniversalDeleteModal.jsx";
import { takeId } from "../../scripts/PersonalAreaScripts.js";
import { useUserAccessContext } from "../../context/UserAccessContext.js";
import { checkUserWritePerm } from "../../scripts/utils.js";

export const PlaylistSortableItem = sortableElement(
	({ value, filmState, setFilmState, playlistIndex, deleteMoviePlaylist }) => {
		const { handleModal } = useContext(ModalContext);
		const { userAccessState } = useUserAccessContext();
		const index = value.index;
		const elem = value.childElem;

		useEffect(() => {
			if (!elem.order || elem.order === null) {
				let timeArray = filmState;
				timeArray[playlistIndex].movies[index] = {
					...elem,
					order: index + 1,
				};
				elem.order = index + 1;
				setFilmState(timeArray);
			}
		}, []);

		const handleDeleteClick = (movieId) => {
			let timeArray = filmState;
			let arr = [...timeArray[playlistIndex].movies];
			arr = takeId(arr, "movie_id");

			const movieIdx = arr.findIndex((elem) => elem == movieId);
			arr.splice(movieIdx, 1);
			const resultobj = arr.map((elem, index) => ({ [String(elem)]: String(index + 1) }));
			deleteMoviePlaylist(timeArray[playlistIndex].id, {
				...timeArray[playlistIndex],
				movies: resultobj,
			});
		};
		return (
			<div className="form-container-block-season-episode">
				<div
					className="form-container-block-season-episode-title action-button"
					style={{ width: "544%" }}
					onClick={() => handleModal()}
				>
					Видео {index + 1}, {elem?.movie?.title}
				</div>

				<div
					className="form-container-block-season-episode-title action-button"
					style={{ width: "139%" }}
				>
					<span>{elem?.movie?.movie_format?.title || "Нет данных"}</span>
				</div>
				<div
					className="form-container-block-season-episode-title action-button"
					style={{ width: "325%" }}
				>
					<span>
						{elem?.movie?.genres?.length > 0
							? elem?.movie?.genres?.map((gen, index) =>
									index >= 1 ? `, ${gen?.title}` : gen?.title
							  )
							: "Не указано"}
					</span>
				</div>
				<div className="season-episode-control" style={{ width: "40%" }}>
					{/* <div
						className="season-episode-control-item action-button"
						onClick={() => handleModal(<MoveVideoPlaylistModal />)}
					>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="m7.207 3.207.707-.707L6.5 1.086l-.707.707-3.5 3.5a1 1 0 0 0 0 1.414l3.5 3.5.707.707L7.914 9.5l-.707-.707L5.414 7H22V5H5.414l1.793-1.793Zm9.586 17.586-.707.707 1.414 1.414.707-.707 3.5-3.5a1 1 0 0 0 0-1.414l-3.5-3.5-.707-.707-1.414 1.414.707.707L18.586 17H2v2h16.586l-1.793 1.793Z"
								fill="#94A0B1"
							/>
						</svg>
					</div> */}
					{checkUserWritePerm(userAccessState, "playlists") ? (
						<div
							className="season-episode-control-item action-button"
							onClick={() =>
								handleModal(
									<UniversalDeleteModal
										title="видео из плейлиста"
										id={elem.movie_id}
										deleteFunc={handleDeleteClick}
									/>
								)
							}
						>
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M7 5C7 3.34315 8.34315 2 10 2L14 2C15.6569 2 17 3.34315 17 5V6H18L20 6H21V8H20H18.9311L18.1301 19.2137C18.018 20.7837 16.7117 22 15.1378 22H8.86224C7.28832 22 5.982 20.7837 5.86986 19.2137L5.06888 8H4H3V6H4H6H7V5ZM9 6L15 6V5C15 4.44772 14.5523 4 14 4L10 4C9.44772 4 9 4.44772 9 5V6ZM7.07398 8L7.86478 19.0712C7.90216 19.5946 8.3376 20 8.86224 20H15.1378C15.6624 20 16.0978 19.5946 16.1352 19.0712L16.926 8H16L8.0003 8H8H7.9997H7.07398Z"
									fill="#94A0B1"
								></path>
							</svg>
						</div>
					) : null}
				</div>
				{checkUserWritePerm(userAccessState, "playlists") ? <ChildDragHandle /> : null}
			</div>
		);
	}
);

export const PlaylistSortableContainer = sortableContainer(({ children }) => {
	return (
		<div
			className="form-container-block-season-episodes playlists ui-sortable"
			style={{ overflowY: "auto" }}
		>
			{children}
		</div>
	);
});
