import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "./styles/BrightboxesPage.scss";
import AtipsBrightboxes from "./pages/AtipsBrightboxes";
import { useLocation, useNavigate } from "react-router-dom";
import BloggerBrightboxes from "./pages/BloggerBrightboxes";
import OwnerBrightboxes from "./pages/OwnerBrightboxes";

const BrightboxesPage = ({ brightboxesList, title }) => {
	useEffect(() => {
		document.title = title;
	}, [title]);
	const [currentPageState, setCurrentPageState] = useState("/");
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		setCurrentPageState(location.pathname);
	}, [location]);

	return (
		<section className="personal-area-item">
			<header className="personal-area categories-header">
				<h1>
					Типсы
					<sup>
						{!!brightboxesList.pagination.max_elements
							? brightboxesList.pagination.max_elements
							: 0}{" "}
					</sup>
				</h1>
			</header>
			<div className="statistics-bookmarks">
				<button
					className={`statistics-bookmark ${currentPageState === "/tips" ? "active" : ""}`}
					onClick={() => navigate("/tips")}
				>
					Наши типсы
				</button>
				<button
					className={`statistics-bookmark ${currentPageState === "/owner-tips" ? "active" : ""}`}
					onClick={() => navigate("/owner-tips")}
				>
					Типсы правообладателей
				</button>
				<button
					className={`statistics-bookmark ${currentPageState === "/blogger-tips" ? "active" : ""}`}
					onClick={() => navigate("/blogger-tips")}
				>
					Типсы блогеров
				</button>
			</div>
			{currentPageState === "/tips" ? (
				<AtipsBrightboxes />
			) : currentPageState === "/blogger-tips" ? (
				<BloggerBrightboxes />
			) : currentPageState === "/owner-tips" ? (
				<OwnerBrightboxes />
			) : null}
		</section>
	);
};

const mapStateToProps = (state) => {
	return {
		brightboxesList: state.brightboxesList,
		videosList: state.videosList.list,
		categoriesList: state.clearCategoriesList,
	};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(BrightboxesPage);
