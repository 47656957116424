import axios from "axios";
import { baseUrl } from "./consts";

const getFormData = (object) => {
	const formData = new FormData();
	Object.keys(object).forEach((key) => formData.append(key, object[key]));
	return formData;
};

function buildFormData(formData, data, parentKey) {
	if (data && typeof data === "object" && !(data instanceof Date) && !(data instanceof File)) {
		Object.keys(data).forEach((key) => {
			buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
		});
	} else {
		const value = data == null ? null : data;

		formData.append(parentKey, value);
	}
}

function removeEmpty(obj) {
	return Object.fromEntries(
		Object.entries(obj).filter(([_, v]) => v != null && v != "" && v != [])
	);
}

export const postSeason = (val) => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_LOADER",
		});
		await axios
			.post(`${baseUrl}/season`, {
				title: val.title,
				serial_id: val.serial_id,
				order: val.order,
				slug: val.slug,
			})
			.then((season) => {
				dispatch({
					type: "POST_SEASON",
					season,
				});
			})
			.then(() => {
				dispatch({
					type: "HIDE_ALL",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const putSeason = (val, id) => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_LOADER",
		});
		await axios
			.put(`${baseUrl}/season/${id}`, {
				title: val.title,
				slug: val.slug,
				serial_id: val.serial_id,
				order: val.order,
			})
			.then((season) => {
				dispatch({
					type: "PUT_SEASON",
					season,
				});
			})
			.then(() => {
				dispatch({
					type: "HIDE_ALL",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const postSeries = (episode, video, movie_id, videoTitle) => {
	const filteredState = removeEmpty(video);
	return async (dispatch) => {
		dispatch({
			type: "SHOW_LOADER",
		});
		await axios
			.post(`${baseUrl}/video`, { ...filteredState, movie_id: movie_id, title: videoTitle })
			.then((video) => {
				dispatch(postEpisodes(episode, video.data.id));
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const postEpisodes = (episode, videoId) => {
	const seasonId = episode.season_id;
	const filteredState = removeEmpty(episode);
	return async (dispatch) => {
		await axios
			.post(`${baseUrl}/episode`, { ...filteredState, video_id: videoId })
			.then((series) => {
				dispatch({
					type: "POST_SERIES",
					payload: { series: series, seasonId: seasonId },
				});
			})
			.then(() => {
				dispatch({
					type: "HIDE_ALL",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const reorderEpisodes = (state) => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_REORDER_LOADER",
		});
		await axios
			.put(`${baseUrl}/episode/${state.id}`, state)
			.then((series) => {
				dispatch({
					type: "PUT_SERIES",
					series,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_REORDER_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const reorderSeasons = (state) => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_REORDER_LOADER",
		});
		await axios
			.put(`${baseUrl}/season/${state.id}`, state)
			.then((season) => {
				dispatch({
					type: "PUT_SEASON",
					season,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_REORDER_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const putSeries = (videos, episodes, videoTitle) => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_LOADER",
		});
		await axios
			.put(`${baseUrl}/video/${videos.id}`, { ...videos, title: videoTitle })
			.then((series) => {
				dispatch(putEpisodes(episodes));
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const putEpisodes = (state) => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_LOADER",
		});
		await axios
			.put(`${baseUrl}/episode/${state.id}`, state)
			.then((series) => {
				dispatch({
					type: "PUT_SERIES",
					series,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_ALL",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const deleteSeason = (id) => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_LOADER",
		});
		await axios
			.delete(`${baseUrl}/season/${id}`)
			.finally(() => {
				dispatch({
					type: "HIDE_ALL",
				});
				dispatch({
					type: "DELETE_SEASON",
					payload: id,
				});
			})
			// .then(() => {
			// 	dispatch({
			// 		type: "HIDE_ALL",
			// 	});
			// })
			.catch((error) => {
				console.log(error);
			});
	};
};

export const deleteSerial = (id, seasonId, video_id) => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_LOADER",
		});
		await axios
			.delete(`${baseUrl}/episode/${id}`)
			.finally(() => {
				dispatch({
					type: "DELETE_SERIES",
					payload: {
						id: id,
						season_id: seasonId,
					},
				});
				dispatch(deleteVideo(video_id));
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const deleteVideo = (id) => {
	return async (dispatch) => {
		await axios
			.delete(`${baseUrl}/video/${id}`)
			.finally(() => {
				dispatch({
					type: "HIDE_ALL",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};
