import React, { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { getAreas } from "../../../../Actions/UsersActions.js";

const AreasComponent = ({ areasList, getAreas, mainState, setMainState }) => {
	const [areasState, setAreasState] = useState([]);
	useEffect(() => {
		getAreas();
	}, []);

	useEffect(() => {
		if (areasList.length > 0) {
			setAreasState(areasList);
			setMainState({
				...mainState,
				area: areasList.find((elem) => elem?.id === mainState.area?.id)
			});
			// getPrices(areasList[0].id);
		}
	}, [areasList]);

	const handleAreaClick = (id) => {
		if (mainState?.area?.id !== id) {
			setMainState({
				...mainState,
				area: areasState.find((elem) => elem.id === id),
			});
		}
	};
	return (
		<div id="prices-shops">
			{areasList.length > 0
				? areasList.map((elem) => (
						<div
							key={elem.id}
							className={`prices-shop ${
								!!mainState?.area?.id && mainState?.area?.id === elem.id ? "active" : ""
							}`}
							onClick={() => handleAreaClick(elem.id)}
						>
							{elem.title}
						</div>
				  ))
				: null}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		areasList: state.areasList,
	};
};

const mapDispatchToProps = {
	getAreas,
};

export default connect(mapStateToProps, mapDispatchToProps)(AreasComponent);
