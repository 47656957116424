// TODO: разделить по смысловым фолдерам

import axios from "axios";
import { baseUrl } from "../consts";
import * as qs from "qs";

export const addVideoById = (id) => {
	const args = {
		limit: 15,
	};
	return async (dispatch) => {
		dispatch({
			type: "SHOW_LOADER",
		});
		await axios
			.get(`https://atips.io/kinopoisk/?id=${id}`)
			.then(() => {
				dispatch(getSortedVideos(args));
			})
			.finally(() => {
				dispatch({
					type: "HIDE_ALL",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const getVideos = () => {
	return async (dispatch) => {
		await axios
			.get(`${baseUrl}/movie`)
			.then((video) => {
				dispatch({
					type: "GET_FULL_VIDEOS",
					video,
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const getSortedVideos = (arg) => {
	// if (
	// 	((arg?.filters && Object.keys(arg?.filters)?.length > 0) || !!arg?.movie_title) &&
	// 	!!arg?.order_by
	// )
	// 	delete arg.order_by;
	if (!!arg.filters && arg?.filters !== null) {
		for (let key in arg.filters) {
			arg = { ...arg, [key]: arg.filters[key] };
		}
		delete arg.filters;
	}
	if (!arg?.is_published?.length || arg?.is_published?.length === 2) {
		delete arg.is_published;
	}
	if (!("formats_ids" in arg)) {
		arg.formats_ids = [1, 2];
	}
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.get(`${baseUrl}/domain/movies/filter`, {
				params: {
					...arg,
				},
				paramsSerializer: (params) => {
					return qs.stringify(params, { arrayFormat: "repeat" });
				},
			})
			.then((video) => {
				if (video.data?.status_code === 204) {
					dispatch({
						type: "GET_EMPTY_VIDEOS",
						video: { data: [] },
					});
				} else {
					dispatch({
						type: "GET_VIDEOS",
						video,
					});
				}
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const getVideoGenres = () => {
	return async (dispatch) => {
		await axios
			.get(`${baseUrl}/genre`)
			.then((videoGenres) => {
				dispatch({
					type: "GET_VIDEO_GENRES",
					videoGenres,
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const getVideoAges = () => {
	return async (dispatch) => {
		await axios
			.get(`${baseUrl}/rating`)
			.then((videoAges) => {
				dispatch({
					type: "GET_VIDEO_AGES",
					videoAges,
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const getVideoHashtags = () => {
	return async (dispatch) => {
		await axios
			.get(`${baseUrl}/hashtag`)
			.then((hashtags) => {
				dispatch({
					type: "GET_VIDEO_HASHTAGS",
					hashtags,
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const getFilmCrew = () => {
	return async (dispatch) => {
		await axios
			.get(`${baseUrl}/character`)
			.then((filmCrew) => {
				dispatch({
					type: "GET_FILM_CREW",
					filmCrew,
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const postUploadImages = (imgFile, type, width = null, height = null, crop = null) => {
	var bodyFormData = new FormData();
	const params = {};
	if (width && height && crop !== null) {
		params["width"] = width;
		params.height = height;
		params.crop = crop;
	}
	bodyFormData.append("file", imgFile);
	return async (dispatch) => {
		if (type === "gallery") {
			dispatch({
				type: "SHOW_LOADER",
			});
		}
		await axios
			.post(`https://atips.io/uploads/upload.php`, bodyFormData, {
				params: {
					...params,
				},
			})
			.then((uploadImg) => {
				if (type === "preview") {
					dispatch({
						type: "POST_UPLOAD_IMG",
						uploadImg,
					});
				} else if (type === "gallery") {
					dispatch({
						type: "POST_UPLOAD_GALLERY_IMG",
						uploadImg,
					});
				} else if (type === "vertical") {
					dispatch({
						type: "POST_UPLOAD_VERTICAL_IMG",
						uploadImg,
					});
				} else if (type === "horizontal") {
					dispatch({
						type: "POST_UPLOAD_HORIZONTAL_IMG",
						uploadImg,
					});
				}
			})
			.finally(() => {
				dispatch({
					type: "HIDE_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const postFilmCrew = (val) => {
	return async (dispatch) => {
		await axios
			.post(`${baseUrl}/character`, val)
			.then((filmCrew) => {
				dispatch({
					type: "POST_FILM_CREW",
					filmCrew,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const putFilmCrew = (state, id) => {
	return async (dispatch) => {
		await axios
			.put(`${baseUrl}/character/${id}`, state)
			.then((filmCrew) => {
				dispatch({
					type: "PUT_FILM_CREW",
					filmCrew,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

// search
export const getSearchVideo = () => {
	return async (dispatch) => {
		await axios
			.get(`${baseUrl}/movie`)
			.then((video) => {
				dispatch({
					type: "GET_SEARCH_VIDEOS",
					video,
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};
