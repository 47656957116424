import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import PersonalAreaNavbar from "../../universal/Navbar/PersonalAreaNavbar.jsx";
import AreasComponent from "./AreasComponent/AreasComponent.jsx";
import BasePriceComponent from "./BasePriceComponent/BasePriceComponent.jsx";
import PersonalPricesComponent from "./PersonalPricesComponent/PersonalPricesComponent.jsx";
import { initialPriceState } from "./static.js";
import { ModalContext } from "../../modal/ModalContext/ModalContext.tsx";
import "./styles/PricesPage.scss";

const PricesPage = ({ loader, title }) => {
	const dispatch = useDispatch();
	const { handleModal, modal } = useContext(ModalContext);
	const [mainPriceState, setMainPriceState] = useState(() => initialPriceState);
	useEffect(() => {
		document.title = title;
	}, [title]);
	useEffect(() => {
		if (loader.loading === 2) {
			dispatch({ type: "HIDE_LOADER" });
			handleModal();
		}
	}, [loader, modal]);

	return (
		<section className="personal-area-item prices">
			<header className="personal-area categories-header">
				<h1 className="categories-header-text">Цены</h1>
			</header>
			<div className="prices-content-wrapper content-wrapper">
				<AreasComponent mainState={mainPriceState} setMainState={setMainPriceState} />

				<BasePriceComponent
					mainState={mainPriceState}
					setMainState={setMainPriceState}
					mainPriceState={mainPriceState}
					setMainPriceState={setMainPriceState}
				/>

				<PersonalPricesComponent mainState={mainPriceState} setMainState={setMainPriceState} />
			</div>
		</section>
	);
};

const mapStateToProps = (state) => {
	return {
		loader: state.loadingStatus,
	};
};

// const mapDispatchToProps = {
// 	getAreas,
// };

export default connect(mapStateToProps, null)(PricesPage);
