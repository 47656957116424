import { gql } from "@apollo/client";

export const GET_TIPS_CLICK_SHOW = gql`
	query getStatisticPage($tips_id: Int) {
		show: getStatisticPage(
			pagination: { page: 1, limit: 1 }
			filter: { owner_id: 1, tips_id: $tips_id, click: 0 }
			sort: { field: click, direction: DESC }
		) {
			items {
				price
			}
			pageInfo {
				itemsCount
			}
			aggregation {
				total_sum
				income_profit
			}
		}
		click: getStatisticPage(
			pagination: { page: 1, limit: 1 }
			filter: { owner_id: 1, tips_id: $tips_id, click: 1 }
			sort: { field: click, direction: DESC }
		) {
			items {
				price
			}
			pageInfo {
				itemsCount
			}
			aggregation {
				total_sum
				income_profit
			}
		}
	}
`;
export const GET_TOTAL_TIPS_CLICK_SHOW = gql`
	query getStatisticPage($format_id: Int) {
		click: getStatisticPage(
			pagination: { page: 1, limit: 1 }
			filter: { owner_id: 1, format_id: $format_id, click: 1 }
			sort: { field: click, direction: DESC }
		) {
			items {
				price
			}
			pageInfo {
				itemsCount
			}
			aggregation {
				total_sum
				income_profit
			}
		}
	}
`;
