export const checkUserWritePerm = (state, key) => {
	return Boolean(state?.[key] === 2);
};

export const isArr = (item) => Array.isArray(item);

export const sortArrByAlphabet = ({ arr = [], key = "title" }) => {
	return (
		isArr(arr) &&
		arr?.sort((a, b) => {
			if (a[key].toLowerCase() < b[key].toLowerCase()) {
				return -1;
			}
			if (a[key].toLowerCase() > b[key].toLowerCase()) {
				return 1;
			}
			return 0;
		})
	);
};

export const sortStringArrByAlphabet = (arr = []) => {
	return (
		isArr(arr) &&
		arr?.sort((a, b) => {
			if (a.toLowerCase() < b.toLowerCase()) {
				return -1;
			}
			if (a.toLowerCase() > b.toLowerCase()) {
				return 1;
			}
			return 0;
		})
	);
};
