import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { DText13 } from "../../../../../ui-kit/typography/Typography";
import { Stack } from "../../../../../ui-kit/layouts/Stack";
import { Cluster } from "../../../../../ui-kit/layouts/Cluster";

const StyledStack = styled(Stack)`
	width: 100%;
`;

const StyledInput = styled.div`
	background: #f4f4f8;
	border-radius: 8px;

	border: 1px solid #f4f4f8;
	border-radius: 8px;

	padding: 12px 16px;
	line-height: 160%;
	color: #212121;
	width: 100%;

	min-height: 56px;
	cursor: pointer;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 4px;
	&:hover {
		border: 1px solid #d1d8e1;
	}

	&:focus {
		border: 1px solid #7027c2;
	}
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}
`;

export const VideoCinemaCategory = ({ collection }) => {
	return (
		<StyledStack space="5px">
			<StyledInput>
				{collection.map((elem) => (
					<Tag item={elem.title} />
				))}
			</StyledInput>
		</StyledStack>
	);
};

const TagWrapper = styled(Cluster)`
	background: #d1d8e1;
	border-radius: 6px;
	padding: 4px 8px;
	gap: 4px;
	align-items: center;
	svg:hover {
		cursor: pointer;
		path {
			fill: #7027c2;
		}
	}
`;

export const Tag = ({ item }) => {
	return (
		<TagWrapper>
			<DText13>{item}</DText13>
		</TagWrapper>
	);
};
