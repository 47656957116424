import React from "react";
import { memo } from "react";
import { Cluster } from "../layouts/Cluster";
import styled from "styled-components";
import { DText13 } from "../typography/Typography";

const Circle = styled.div`
	position: block;
	box-sizing: border-box;
	border-radius: 50%;
	-webkit-background-clip: padding-box;
	-moz-background-clip: padding;
	background-clip: padding-box;
	width: 24px;
	min-height: 24px;
	min-width: 24px;
	height: 24px;
	content: "";
	border: 8px solid ${(props) => props.color + "20"};
	background-color: ${(props) => props.color};
`;

const StyledText = styled(DText13)`
	width: 100%;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
`;

/**
 * Статус в виде (индикатор - текст)
 * color - must be in hex
 */
// TODO: разработать более универсальный вариант (горизонтальное, вертикальное расположение), error, disabled, resolveId
export const Status = memo(
	({ space = "10px", className = "", color = "#ed0a34", text = "", reversed = false }) => {
		return (
			<Cluster
				align="center"
				space={space}
				className={className}
				style={{ flexDirection: reversed ? "row-reverse" : "row" }}
			>
				<Circle color={color} />
				<StyledText>{text}</StyledText>
			</Cluster>
		);
	}
);
