import React, { useEffect, useState } from "react";
import { memo } from "react";
import "./RadioButtons.scss";

export const RadioButton = memo(({ selected, value, setValue, label }) => {
	return (
		<div className={`radio-button-wrapper`} onClick={() => setValue(value)}>
			<div className={`radio ${selected ? "selected" : ""}`}></div>
			{Boolean(label) && <span className="radio-label">{label}</span>}
		</div>
	);
});

/**
 * Радио кнопки
 */
// TODO: разработать более универсальный вариант (горизонтальное, вертикальное расположение), error, disabled, resolveId
export const RadioButtons = memo(
	({ collection = [], value = null, setValue, textKey = "name", gap = "24px", className = "" }) => {
		return (
			<div className={`radio-buttons-wrapper`} style={{ gap: gap }}>
				{collection.length &&
					collection.map((elem) => (
						<RadioButton
							key={elem.id}
							selected={elem.id === value}
							label={elem[textKey]}
							value={elem.id}
							setValue={setValue}
						/>
					))}
			</div>
		);
	}
);
