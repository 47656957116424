import Multiselect from "multiselect-react-dropdown";
import React from "react";
import { connect } from "react-redux";
import { putSeries, reorderEpisodes } from "../../../../../Actions/SerialsActions";
import { SerialSortableContainer, SerialSortableItem } from "./SerialSortableItem.jsx";
import { useUserAccessContext } from "../../../context/UserAccessContext";

// врапперы серий

const SerialItem = ({
	filmState,
	setFilmState,
	loader,
	seasonItem,
	index,
	putSeries,
	reorderEpisodes,
}) => {
	const { userAccessState } = useUserAccessContext();
	// const { handleModal, modal } = useContext(ModalContext);

	const reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		result.map((elem, index) => (elem.order = index + 1));
		return result;
	};
	const onSortEnd = ({ oldIndex, newIndex }) => {
		const items = reorder(filmState.seasons[index].episodes, oldIndex, newIndex);
		const timeArray = filmState.seasons;
		timeArray[index].episodes = items;
		// setFilmState({
		// 	...filmState,
		// 	seasons: timeArray,
		// });
		for (let i = 0; i < items.length; i++) {
			reorderEpisodes(items[i]);
		}
	};
	const onSortStart = ({ node }) => {};
	return seasonItem.episodes?.length > 0 ? (
		<SerialSortableContainer
			onSortEnd={onSortEnd}
			useDragHandle
			onSortStart={onSortStart}
			helperClass="form-container-block-season-episode2"
		>
			{seasonItem.episodes.map((childElem, index) => (
				<SerialSortableItem
					key={`item-${childElem.id}`}
					index={index}
					value={{ childElem, index }}
					seasonIndex={seasonItem.order}
					filmState={filmState}
					setFilmState={setFilmState}
				/>
			))}
		</SerialSortableContainer>
	) : null;
};

const mapStateToProps = (state) => {
	return {
		loader: state.loadingStatus,
	};
};

const mapDispatchToProps = {
	putSeries,
	reorderEpisodes,
};

export default connect(mapStateToProps, mapDispatchToProps)(SerialItem);
