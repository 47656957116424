import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getPlaylist } from "../../../../Actions/PlayListActions.js";
import PlaylistsComponent from "./PlaylistsComponent.jsx";

/**
 *	Родительский компонент списка плейлистов
 *
 *  В нем запрашивается и сетится список плейлистов 
 */
const FilmsPlaylistContent = ({ playlistsList, getPlaylist, playlistState, setPlaylistState }) => {
	useEffect(() => {
		getPlaylist();
	}, []);

	useEffect(() => {
		setPlaylistState(playlistsList);
	}, [playlistsList]);

	return (
		<div className="playlist-content-wrapper">
			<PlaylistsComponent playlistState={playlistState} setPlaylistState={setPlaylistState} />
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		playlistsList: state.playlistsList,
	};
};
const mapDispatchToProps = {
	getPlaylist,
};

export default connect(mapStateToProps, mapDispatchToProps)(FilmsPlaylistContent);
