import React, { useContext } from "react";
import { ModalContext } from "../../../modal/ModalContext/ModalContext.tsx";
import ModalNewPrice from "../unversalComponents/ModalNewPrice.jsx";
import ModalPriceHistory from "../unversalComponents/ModalPriceHistory.jsx";
import { useUserAccessContext } from "../../context/UserAccessContext.js";
import { Button } from "../../../../ui-kit/button/Button.jsx";
import { checkUserWritePerm } from "../../scripts/utils.js";
const BasePriceComponent = ({ mainState }) => {
	const { handleModal } = useContext(ModalContext);
	const { userAccessState } = useUserAccessContext();

	return !!mainState.area ? (
		<div id="base-price">
			<div className="base-price-part">
				<div className="base-price-part-title">Базовая цена</div>
				<div
					className="base-price-history action-button"
					onClick={() => handleModal(<ModalPriceHistory areaId={mainState.area.id} />)}
				>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12ZM12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM13 8V7H11V8V12C11 12.3344 11.1671 12.6466 11.4453 12.8321L14.4453 14.8321L15.2774 15.3868L16.3868 13.7227L15.5547 13.1679L13 11.4648V8Z"
							fill="#94A0B1"
						/>
					</svg>
					История изменений
				</div>
			</div>
			<div className="base-price-part">
				<small>Стоимость клика</small>
				<b>
					{`${
						typeof mainState.area.default_price != "undefined"
							? mainState.area.default_price !== null
								? mainState.area.default_price + " ₽"
								: "Не указано"
							: ""
					}`}
				</b>
				{checkUserWritePerm(userAccessState, "prices") ? (
					<Button
						className="add-base-price action-button"
						variant="link"
						disabled={!checkUserWritePerm(userAccessState, "prices")}
						text="Добавить новую цену"
						onClick={() => handleModal(<ModalNewPrice area={mainState.area} />)}
					/>
				) : null}
			</div>
		</div>
	) : null;
};

export default BasePriceComponent;
