import { gql } from "@apollo/client";

export const GET_MAIN_BLOGGER_PROFIT = gql`
	query getStatisticPage($blogger_id: Int, $lte: DateTime, $gte: DateTime) {
		getStatisticPage(
			pagination: { page: 1, limit: 1 }
			filter: {
				owner_id: 1
				blogger_id: $blogger_id
				click: 1
				created_at: { gte: $gte, lte: $lte }
			}
			sort: { field: click, direction: DESC }
		) {
			items {
				price
			}
			aggregation {
				total_sum
				income_profit
			}
			pageInfo {
				itemsCount
			}
		}
	}
`;

export const GET_TIPS_BLOGGER_SHOW = gql`
	query getStatisticPage($blogger_id: Int, $lte: DateTime, $gte: DateTime) {
		getStatisticPage(
			pagination: { page: 1, limit: 1 }
			filter: {
				owner_id: 1
				blogger_id: $blogger_id
				click: 0
				created_at: { gte: $gte, lte: $lte }
			}
			sort: { field: click, direction: DESC }
		) {
			items {
				price
			}
			pageInfo {
				itemsCount
			}
		}
	}
`;
export const GET_TOP5_BLOGGER_STATISTIC = gql`
	query getEfficiencyItem($blogger_id: Int, $lte: DateTime, $gte: DateTime) {
		getEfficiencyItem(
			filter: { owner_id: 1, blogger_id: $blogger_id, created_at: { gte: $gte, lte: $lte } }
		) {
			best_products {
				name
				product_id
				views_count
				clicks_count
				profit
				percent
			}
			best_services {
				name
				product_id
				views_count
				clicks_count
				profit
				percent
			}
		}
		getSellersEfficiencyItem(
			filter: { owner_id: 1, blogger_id: $blogger_id, created_at: { gte: $gte, lte: $lte } }
		) {
			best_service_sellers {
				seller
				seller_id
				views_count
				clicks_count
				profit
				percent
			}
			best_product_sellers {
				seller
				seller_id
				views_count
				clicks_count
				profit
				percent
			}
		}
		getTipsEfficiencyItem(
			filter: { owner_id: 1, blogger_id: $blogger_id, created_at: { gte: $gte, lte: $lte } }
		) {
			best_service_tips {
				tips_id
				views_count
				clicks_count
				profit
				percent
			}
			best_products_tips {
				tips_id
				views_count
				clicks_count
				profit
				percent
			}
		}
	}
`;

export const GET_MOVIEGOER_BLOGGER_AGG = gql`
	query getMoviegoerAggItem($blogger_id: Int, $lte: DateTime, $gte: DateTime) {
		getMoviegoerAggItem(
			filter: { owner_id: 1, blogger_id: $blogger_id, created_at: { gte: $gte, lte: $lte } }
		) {
			countries {
				percent
				total_count
				name
				cities {
					percent
					total_count
					name
				}
			}
			sex_info {
				percent
				total_count
				name
			}
			age_info {
				percent
				total_count
				name
			}
		}
	}
`;
