import axios from "axios";
import { baseUrl } from "./consts";
import * as qs from "qs";

export const getBrightboxes = (arg) => {
	if (!!arg?.filters && arg?.filters !== null) {
		for (let key in arg.filters) {
			// arg = { ...arg, [key]: JSON.stringify(arg.filters[key]) };
			arg = { ...arg, [key]: arg.filters[key] };
		}
		delete arg.filters;
	}
	if (!("order_by" in arg)) arg = { ...arg, order_by: "id", reversed_: true };
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.get(`${baseUrl}/domain/tips/${arg.limit}/${arg.offset}`, {
				params: {
					...arg,
					is_published: true,
				},
				paramsSerializer: (params) => {
					return qs.stringify(params, { arrayFormat: "repeat" });
				},
			})
			.then((brightboxes) => {
				if (brightboxes.status === 204) {
					dispatch({
						type: "GET_BRIGHTBOXES",
						brightboxes: { data: [] },
					});
				} else {
					dispatch({
						type: "GET_BRIGHTBOXES",
						brightboxes,
					});
				}
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};
