import axios from "axios";
import { graphqlDomen } from "../../../../Actions/consts";
import { options } from "./static";

const formatGraphDate = (date, type) => {
  if (type === "year" || type === "full") {
    return new Date(date)
      .toLocaleString("ru", {
        year: "numeric",
        month: "long",
      })
      .split(" ", 3)
      .join(" ");
  } else if (type === "month") {
    return new Date(date)
      .toLocaleString("ru", {
        year: "numeric",
        month: "long",
        day: "numeric",
      })
      .split(" ", 3)
      .join(" ");
  } else if (type === "week") {
    return new Date(date)
      .toLocaleString("ru", {
        day: "numeric",
        weekday: "short",
        month: "long",
      })
      .split(" ", 3)
      .join(" ");
  } else if (type === "day") {
    return new Date(date)
      .toLocaleString("ru", {
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      })
      .split(" ", 3)
      .join(" ");
  }
};

export const getVideoProfitByData = async ({
  vars,
  onReady,
  dispatch,
  index,
  period,
  format,
}) => {
  const graphqlQuery = {
    operationName: "getStatisticPage",
    query: `query getStatisticPage(
      $gte: DateTime,
      $lte: DateTime,
      $format_id: Int,
    ) {
      getStatisticPage(
        pagination: { page: 1, limit: 1 },
        filter: {
          owner_id: 1,
          click: 1,
          created_at: { gte: $gte, lte: $lte },
          format_id: $format_id,
        },
        sort: { field: click, direction: DESC }
      ) {
        items {
          create_date
          __typename
        },
        aggregation {
          total_sum
          __typename
        },
        pageInfo {
          itemsCount
        }
      }
    }`,
    variables: vars,
  };
  dispatch({
    type: "SHOW_MAIN_LOADER",
  });
  await axios
    .post(`${graphqlDomen}`, graphqlQuery)
    .then((profit) => {
      dispatch({
        type: "GET_VIDEIO_PROFIT_POINT",
        value: {
          actualData:
            profit?.data?.data?.getStatisticPage?.aggregation?.total_sum,
          date: formatGraphDate(vars.lte, period),
        },
        index,
        period,
        format,
      });
    })
    .finally(() => {
      dispatch({
        type: "HIDE_MAIN_LOADER",
      });
    })
    .catch((error) => {
      console.log(error);
    });
};
