import axios from "axios";
import { baseUrl, cinemaUrl } from "../../../../../../Actions/consts";

const prepareDepthCollection = (collection, duration) => {
	const array = [];
	const interval = duration / 31;
	const max = collection[0].amount;
	collection.forEach((element) => {
		const time = interval * (element.number + 1);
		if (time < 3600)
			array.push({
				time: new Date(interval * element.number * 1000).toISOString().slice(14, 19),
				amount: max > 0 ? (element.amount / max) * 100 : 0,
			});
		else
			array.push({
				time: new Date(interval * element.number * 1000).toISOString().slice(11, 19),
				amount: max > 0 ? (element.amount / max) * 100 : 0,
			});
	});
	return array;
};

export const fetchCurrentVideoViewDepthByPeriod = async ({ vars, dispatch, period, duration }) => {
	dispatch({
		type: "SHOW_MAIN_LOADER",
	});
	const queryString = Object.keys(vars)
		.map((key) => key + "=" + vars[key])
		.join("&");
	await axios
		.post(`${baseUrl}/domain/other`, {
			method: "GET",
			service: 1,
			endpoint: `/api/video/retention-rate?${queryString}`,
			data: "",
		})
		.then((views) => {
			const res = prepareDepthCollection(views.data, duration);
			dispatch({
				type: "GET_VIDEO_VIEWS_DEPTH_GRAPH",
				views: res,
				period,
			});
		})
		.finally(() => {
			dispatch({
				type: "HIDE_MAIN_LOADER",
			});
		})
		.catch((error) => {
			console.log(error);
		});
};
