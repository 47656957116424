import Multiselect from "multiselect-react-dropdown";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { connect, useDispatch } from "react-redux";
import { ModalContext } from "../../../modal/ModalContext/ModalContext.tsx";
import { sortableContainer, sortableElement } from "react-sortable-hoc";
import "./styles/CreateSeasons.scss";
import AddSeasonModal from "./AddSeasonModal.jsx";
import AddSeriesModal from "./AddSeriesModal.jsx";
import DeleteModal from "./DeleteModal.jsx";
import SerialItem from "./SerialItem/SerialItem.jsx";
import { ParentDragHandle } from "../../../universal/dragHandle/DragHandle.jsx";
import { putSeason, reorderSeasons } from "../../../../Actions/SerialsActions";
import OverlayLoader from "../../../universal/Loader/OverlayLoader.jsx";
import { useUserAccessContext } from "../../context/UserAccessContext.js";
import { checkUserWritePerm } from "../../scripts/utils.js";

const SortableItem = sortableElement(({ value, filmState, setFilmState }) => {
	const { userAccessState } = useUserAccessContext();
	const elem = value.elem;
	const index = value.index;
	const { handleModal, modal } = useContext(ModalContext);

	useEffect(() => {
		if (elem.order === 0 || elem.episodes.length > 0) {
			let timeArray = filmState.seasons;
			if (elem.order === null) {
				timeArray[index] = {
					...elem,
					sort: index + 1,
				};
			}
			if (timeArray[index].episodes.length > 0) {
				timeArray[index].episodes.sort(function (a, b) {
					if (a.order > b.order) {
						if (a.order === 0) {
							return -1;
						}
						return 1;
					}
					if (a.order < b.order) {
						if (a.order === 0) {
							return 1;
						}
						return -1;
					}
					if (a.order === b.order && a.id > b.id) {
						return 1;
					} else {
						return -1;
					}
				});
			}
			setFilmState({ ...filmState, seasons: timeArray });
		}
	}, []);

	const handleOpenContent = (evt) => {
		evt.stopPropagation();
		const current = evt.currentTarget;
		const parrent = current.parentNode;
		parrent.classList.toggle("open");
	};
	return (
		<div className="form-container-block-season">
			<div className="form-container-block-season-header" onClick={(e) => handleOpenContent(e)}>
				<div className="form-container-block-season-title">
					Сезон {index + 1}, <span>{elem.title}</span>
				</div>
				<div className="form-container-block-season-arrow">
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M7.99997 9.58545L8.70707 10.2926L12 13.5854L15.2929 10.2926L16 9.58545L17.4142 10.9997L16.7071 11.7068L12.7071 15.7068C12.3166 16.0973 11.6834 16.0973 11.2929 15.7068L7.29286 11.7068L6.58575 10.9997L7.99997 9.58545Z"
							fill="#212121"
						></path>
					</svg>
				</div>
			</div>
			{checkUserWritePerm(userAccessState, "videos") && <ParentDragHandle />}
			<div className="form-container-block-season-container">
				<SerialItem
					filmState={filmState}
					setFilmState={setFilmState}
					seasonItem={elem}
					index={index}
				/>
				{checkUserWritePerm(userAccessState, "videos") && (
					<div className="season-control">
						<div
							className="button add-episode action-button border-button small-button"
							onClick={() =>
								handleModal(
									<AddSeriesModal
										userAccessState={userAccessState}
										seasonId={elem.id}
										filmState={filmState}
										seasonIndex={index}
									/>
								)
							}
						>
							<span>Добавить эпизод</span>
						</div>
						<div className="season-control-buttons">
							<div
								className="edite-season season-control-button action-button"
								onClick={() =>
									handleModal(
										<AddSeasonModal
											userAccessState={userAccessState}
											elem={elem}
											index={index}
											filmState={filmState}
											setFilmState={setFilmState}
										/>
									)
								}
							>
								Изменить сезон
							</div>
							<div
								className="remove-season season-control-button action-button"
								onClick={() => handleModal(<DeleteModal userAccessState={userAccessState} type="season" elem={elem} />)}
							>
								Удалить сезон
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
});

const SortableContainer = sortableContainer(({ children }) => {
	return (
		<div className="form-container-block-seasons ui-sortable" style={{ overflowY: "auto" }}>
			{children}
		</div>
	);
});

// врапперы серий

const CreateSeasons = ({ filmState, setFilmState, loader, putSeason, reorderSeasons }) => {
	const dispatch = useDispatch();
	const { handleModal, modal } = useContext(ModalContext);
	const { userAccessState } = useUserAccessContext();
	useEffect(() => {
		if (loader.loading === 2) {
			dispatch({ type: "HIDE_LOADER" });
			handleModal();
		}
	}, [loader, modal]);

	useEffect(() => {
		if (filmState.seasons.length > 0) {
			let timeArray = filmState.seasons;
			timeArray.sort(function (a, b) {
				if (a.order > b.order) {
					if (a.order === 0) {
						return -1;
					}
					return 1;
				}
				if (a.order < b.order) {
					if (a.order === 0) {
						return 1;
					}
					return -1;
				}
				if (a.order === b.order && a.id > b.id) {
					return 1;
				} else {
					return -1;
				}
			});

			setFilmState({ ...filmState, seasons: timeArray });
		}
	}, []);

	const reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		result.map((elem, index) => (elem.order = index + 1));
		return result;
	};
	const onSortEnd = ({ oldIndex, newIndex }) => {
		const items = reorder(filmState.seasons, oldIndex, newIndex);
		setFilmState({ ...filmState, seasons: items });
		for (let i = 0; i < items.length; i++) {
			reorderSeasons(items[i]);
		}
	};

	const onSortStart = ({ node }) => {};
	return (
		<div className="form-container-block">
			<OverlayLoader />
			<div className="form-container-block-title">
				<b>Сезоны</b>
			</div>
			{filmState.seasons?.length > 0 && filmState.seasons ? (
				<SortableContainer
					onSortEnd={onSortEnd}
					useDragHandle
					onSortStart={onSortStart}
					helperClass="form-container-block-season2"
				>
					{filmState.seasons.length > 0 && filmState.seasons
						? filmState.seasons.map((elem, index) =>
								elem !== null ? (
									<SortableItem
										key={`item-${elem.id}`}
										index={index}
										value={{ elem, index }}
										filmState={filmState}
										setFilmState={setFilmState}
									/>
								) : null
						  )
						: null}
				</SortableContainer>
			) : null}
			{checkUserWritePerm(userAccessState, "videos") && (
				<div
					className={`button add-season action-button border-button small-button`}
					style={{ marginTop: "24px" }}
					onClick={() =>
						handleModal(
							<AddSeasonModal
								userAccessState={userAccessState}
								filmState={filmState}
								setFilmState={setFilmState}
								videoId={filmState.id}
							/>
						)
					}
				>
					<span>Добавить сезон</span>
				</div>
			)}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		loader: state.loadingStatus,
	};
};

const mapDispatchToProps = {
	putSeason,
	reorderSeasons,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateSeasons);
