import React, { useEffect } from "react";

export const VideoBookmarks = ({ activeBookMark, setActiveBookMark }) => {
    const styles = 'width: "auto"'
	return (
		<div className="video-edit-bookmarks bookmarks">
			<button
				className={`video-edit-bookmark bookmark ${activeBookMark === 0 ? "active" : ""}`}
				onClick={() => setActiveBookMark(0)}
			>
				Контент
			</button>
			<button
				className={`video-edit-bookmark bookmark ${activeBookMark === 1 ? "active" : ""}`}
				onClick={() => setActiveBookMark(1)}
			>
				Статистика
			</button>
			<button
				className={`video-edit-bookmark bookmark ${activeBookMark === 2 ? "active" : ""}`}
				onClick={() => setActiveBookMark(2)}
			>
				Политика
			</button>
		</div>
	);
};
