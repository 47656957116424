import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import BloggerFiltersComponent from "./BloggerFilters/BloggerFiltersComponent.jsx";
import "./styles/BloggerStyles.scss";

import { history } from "../../../Store/Store.ts";
import { initialSellerTab } from "./static.js";
import SellerTabs from "./blogger-tabs/BloggerTabs.jsx";
import axios from "axios";
import { baseUrl, bloggerUrl } from "../../../Actions/consts.js";
import BlockedBloggerPage from "./pages/BlockedBloggerPage.jsx";
import ReviewBloggerPage from "./pages/ReviewBloggerPage.jsx";
import ActiveBloggerPage from "./pages/ActiveBloggerPage.jsx";
import DeletedBloggerPage from "./pages/DeletedBloggerPage.jsx";

const BloggerPage = ({ title }) => {
	const [tab, setTab] = useState(initialSellerTab[0]);
	useEffect(() => {
		document.title = title;
	}, [title]);
	const [lastSearch, setLastSearch] = useState(true);

	const [searchParams, setSearchParams] = useSearchParams();
	const [total, setTotal] = useState(0);

	const [mainFilterState, setMainFilterState] = useState({
		filters: searchParams.get("filters") !== null ? JSON.parse(searchParams.get("filters")) : {},
		size: searchParams.get("size") !== null ? searchParams.get("size") : 20,
		page: searchParams.get("page") !== null ? searchParams.get("page") : 1,
	});

	useEffect(() => {
		return history.listen((location, action) => {
			if (action === "POP") {
				const params = new URLSearchParams(location.search);
				setLastSearch(false);
				setMainFilterState({
					filters: params.get("filters") !== null ? JSON.parse(params.get("filters")) : {},
					size: searchParams.get("size") !== null ? searchParams.get("size") : 20,
					page: searchParams.get("page") !== null ? searchParams.get("page") : 1,
				});
			}
		});
	}, [history]);

	useEffect(() => {
		axios
			.post(`${baseUrl}/domain/other`, {
				method: "GET",
				service: 2,
				endpoint: `/api/dashboard/users?size=1&page=1`,
				data: "",
			})
			.then((blogger) => {
				setTotal(blogger.data.total);
			});
	}, []);

	return (
		<section className="personal-area-item sellers">
			<header className="personal-area categories-header">
				<h1 className="categories-header-text">
					Блогеры
					<sup>
						{/* {!!sellersList.pagination.max_elements ? sellersList.pagination.max_elements : ""} */}
						{total || 0}
					</sup>
				</h1>
			</header>
			<SellerTabs collection={initialSellerTab} active={tab} onChange={setTab} />
			<div className="videos-content-wrapper content-wrapper">
				<BloggerFiltersComponent
					filterState={mainFilterState}
					setFilterState={setMainFilterState}
				/>
				{tab.id === 1 ? (
					<ActiveBloggerPage
						mainFilterState={mainFilterState}
						setMainFilterState={setMainFilterState}
					/>
				) : tab.id === 2 ? (
					<ReviewBloggerPage
						mainFilterState={mainFilterState}
						setMainFilterState={setMainFilterState}
					/>
				) : tab.id === 3 ? (
					<BlockedBloggerPage
						mainFilterState={mainFilterState}
						setMainFilterState={setMainFilterState}
					/>
				) : tab.id === 4 ? (
					<DeletedBloggerPage
						mainFilterState={mainFilterState}
						setMainFilterState={setMainFilterState}
					/>
				) : null}
			</div>
		</section>
	);
};

export default BloggerPage;
