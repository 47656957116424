import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { sortArrByAlphabet } from "../../components/PersonalArea/scripts/utils";
import { Cluster } from "../../ui-kit/layouts/Cluster";
import { ModalContext } from "../../components/modal/ModalContext/ModalContext";
import { Stack } from "../../ui-kit/layouts/Stack";
import { Button } from "../../ui-kit/button/Button.jsx";
import { AddAttributesModal } from "./AddAttributesModal";
import { useSelector } from "react-redux";

const ModalContentWrapper = styled.div`
	box-sizing: border-box;
	padding: 32px 8px 32px 32px;
	width: 1160px;
	height: 640px;
	margin: -50px 0px;
	position: relative;
	background: #fff;
	overflow: hidden;
	border-radius: 16px;
`;

const ContentWrapper = styled.div`
	display: flex;
	height: calc(100% - 40px);
	overflow-y: scroll;
	width: 100%;
	&::-webkit-scrollbar {
		width: 4px;
	}
	&::-webkit-scrollbar-thumb {
		background: #94a0b14d;
		border-radius: 2px;
	}
	&::-webkit-scrollbar-track {
		background: none;
	}
	border-top: 1px solid #e2e8f0;
`;

const CloseButton = styled.button`
	display: block;
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	cursor: pointer;
	background: transparent;
	border: none;
	svg {
		width: 24px;
		height: 24px;
		&:hover {
			path {
				fill: #005bff;
			}
		}
	}
`;

export const AttributesModal = ({ selected, onSelect, onChangedAttrSelect }) => {
	const [searchResCategories, setSearchResCategories] = useState(collection || []);
	const { handleModal } = useContext(ModalContext);
	const [localSelected, setLocalSelected] = useState(selected);
	const collection = useSelector((state) => state.categoryAttr);
	const [addAttributeState, setAddAttributeState] = useState(() => false);
	const [changedAttrsState, setChangedAttrsState] = useState({
		added: {},
		deleted: {},
	});
	const [sortedSubCategories, setSortedSubCategories] = useState([]);

	useEffect(() => {
		const sorted = sortArrByAlphabet({
			arr: searchResCategories,
		});
		setSortedSubCategories(sorted);
	}, [searchResCategories]);

	useEffect(() => {
		setSearchResCategories(collection);
	}, [collection]);

	useEffect(() => {
		return () => {
			onSelect(localSelected);
			if (onChangedAttrSelect) {
				const addedArray = Object.keys(changedAttrsState.added).map((key) => Number(key));
				const deletedArray = Object.keys(changedAttrsState.deleted).map((key) => Number(key));
				onChangedAttrSelect({
					add_attributes: addedArray,
					delete_attributes: deletedArray,
				});
			}
		};
	}, [localSelected]);

	const handleAttrSelect = (attr) => {
		let array = [...localSelected];
		const findIndex = array.findIndex((elem) => elem.id === attr.id);
		if (findIndex === -1) {
			array.push(attr);
			if (attr.id in changedAttrsState.deleted) {
				const newObject = { ...changedAttrsState };
				delete newObject.deleted[attr.id];
				setChangedAttrsState(newObject);
			} else {
				setChangedAttrsState({
					...changedAttrsState,
					added: { ...changedAttrsState.added, [attr.id]: true },
				});
			}
			setLocalSelected(array);
		} else {
			const res = array.splice(findIndex, 1);
			if (attr.id in changedAttrsState.added) {
				const newObject = { ...changedAttrsState };
				delete newObject.added[attr.id];
				setChangedAttrsState(newObject);
			} else {
				setChangedAttrsState({
					...changedAttrsState,
					deleted: { ...changedAttrsState.deleted, [attr.id]: true },
				});
			}
			setLocalSelected(array);
		}
	};

	const handleToggleAll = (frame_id, childs, isSelect) => {
		let result = [...localSelected];
		if (!isSelect) {
			const filtered = childs.filter(
				(elem) => !Boolean(localSelected.find((sel) => sel.id === elem.id))
			);
			let addedObject = changedAttrsState.added;
			let deletedObject = changedAttrsState.deleted;
			filtered.forEach((elem) => {
				if (elem.id in deletedObject) {
					delete deletedObject[elem.id];
				} else {
					addedObject = { ...addedObject, [elem.id]: true };
				}
			});
			result = [...localSelected, ...filtered];
			setChangedAttrsState({ ...changedAttrsState, added: addedObject, deleted: deletedObject });
		} else {
			let filtered = localSelected.filter((elem) => childs.find((sel) => sel.id === elem.id));
			result = localSelected.filter((elem) => !childs.find((sel) => sel.id === elem.id));

			let addedObject = changedAttrsState.added;
			let deletedObject = changedAttrsState.deleted;
			filtered.forEach((elem) => {
				if (elem.id in addedObject) {
					delete addedObject[elem.id];
				} else {
					deletedObject = { ...deletedObject, [elem.id]: true };
				}
			});
			setChangedAttrsState({ ...changedAttrsState, added: addedObject, deleted: deletedObject });
		}
		setLocalSelected(result);
	};

	const columnArray = (columnNumber, array) => {
		const countOfColumn = Math.round(sortedSubCategories.length / 4);
		switch (columnNumber) {
			case 1:
				const firstArray = sortedSubCategories.slice(0, countOfColumn);
				return firstArray;
			case 2:
				const secondArray = sortedSubCategories.slice(countOfColumn, countOfColumn * 2);
				return secondArray;
			case 3:
				const thirdArray = sortedSubCategories.slice(countOfColumn * 2, countOfColumn * 3);
				return thirdArray;
			case 4:
				const fourthArray = sortedSubCategories.slice(countOfColumn * 3, array.length);
				return fourthArray;
			default:
				return [];
		}
	};
	return (
		<ModalContentWrapper
			className="modal-content__wrapper category-popup scrollbar"
			id="collection-scroll-container"
		>
			{addAttributeState ? (
				<AddAttributesModal frames={collection} onClose={() => setAddAttributeState(false)} />
			) : null}
			<div className="modal-header modal-header--with-title">
				<Cluster align="center">
					<h3 className="modal-title">Выбрать атрибут товара или услуг</h3>
				</Cluster>
				<Cluster space="24px" align="center">
					<Button
						variant="secondary"
						text="Добавить атрибут"
						onClick={() => setAddAttributeState(true)}
					/>
					<CloseButton
						type="button"
						className="icon-button icon-btn modal-close-btn icon-btn--xs"
						onClick={() => handleModal()}
					>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M4.70701 3.29304L3.99991 2.58594L2.58569 4.00015L3.2928 4.70726L10.5857 12.0002L3.2928 19.293L2.58569 20.0002L3.99991 21.4144L4.70701 20.7073L11.9999 13.4144L19.2928 20.7073L19.9999 21.4144L21.4141 20.0002L20.707 19.293L13.4141 12.0002L20.707 4.70726L21.4141 4.00015L19.9999 2.58594L19.2928 3.29304L11.9999 10.5859L4.70701 3.29304Z"
								fill="#212121"
							></path>
						</svg>
					</CloseButton>
				</Cluster>
			</div>
			<ContentWrapper>
				<div className="category-popup__child-list">
					<Cluster>
						<Stack style={{ width: "100%" }}>
							{columnArray(1, sortedSubCategories).map(({ id, title, attributes }, i) => (
								<ChildCategoryList
									changedState={changedAttrsState}
									selected={localSelected}
									toggleAll={handleToggleAll}
									onSelect={(e) => handleAttrSelect(e)}
									items={attributes}
									title={title}
									frame_id={id}
									key={id}
								/>
							))}
						</Stack>
						<Stack style={{ width: "100%" }}>
							{columnArray(2, sortedSubCategories).map(({ id, title, attributes }, i) => (
								<ChildCategoryList
									changedState={changedAttrsState}
									selected={localSelected}
									toggleAll={handleToggleAll}
									onSelect={(e) => handleAttrSelect(e)}
									items={attributes}
									title={title}
									frame_id={id}
									key={id}
								/>
							))}
						</Stack>
						<Stack style={{ width: "100%" }}>
							{columnArray(3, sortedSubCategories).map(({ id, title, attributes }, i) => (
								<ChildCategoryList
									changedState={changedAttrsState}
									selected={localSelected}
									toggleAll={handleToggleAll}
									onSelect={(e) => handleAttrSelect(e)}
									items={attributes}
									title={title}
									frame_id={id}
									key={id}
								/>
							))}
						</Stack>
						<Stack style={{ width: "100%" }}>
							{columnArray(4, sortedSubCategories).map(({ id, title, attributes }, i) => (
								<ChildCategoryList
									changedState={changedAttrsState}
									selected={localSelected}
									toggleAll={handleToggleAll}
									onSelect={(e) => handleAttrSelect(e)}
									items={attributes}
									title={title}
									frame_id={id}
									key={id}
								/>
							))}
						</Stack>
					</Cluster>
				</div>
			</ContentWrapper>
		</ModalContentWrapper>
	);
};

const CheckboxWrapper = styled.div`
	color: #94a0b1;
	cursor: pointer;
	display: flex;
	align-items: center;
	font-size: 14px;
	font-weight: 400;
	position: relative;
	&:before {
		background: #f4f4f8;
		border: 1px solid #d1d8e1;
		border-radius: 4px;
		box-sizing: border-box;
		content: "";
		display: block;
		height: 20px;
		left: 0;
		position: absolute;
		top: calc(50% - 10px);
		width: 21px;
	}
	&.changed {
		&:before {
			border-color: #5817a0;
		}
	}
	&.selected {
		&.changed {
			&:before {
				border-color: #5817a0;
				background-color: #5817a0;
			}
		}
		&:before {
			background: #005bff url("/images/icons/ui/forms/white/check.svg") no-repeat 50% 50%;
			border-color: #005bff;
		}
	}
`;

const StyledInput = styled.input`
	display: none;
`;
const StyledLabel = styled.label`
	color: #94a0b1;
	cursor: pointer;
	display: block;
	font-size: 14px;
	font-weight: 400;
	padding-left: 28px;
	position: relative;
`;

export const CustomCheckbox = ({ id, title, onSelect, isActive, isChanged }) => {
	return (
		<CheckboxWrapper
			className={`${isActive ? "selected" : ""} ${isChanged ? "changed" : ""}`}
			onClick={onSelect}
		>
			<StyledInput type="checkbox" id={id} />
			<StyledLabel>{title}</StyledLabel>
		</CheckboxWrapper>
	);
};
const ChildCategoryList = ({
	title,
	items,
	onSelect,
	selected = [],
	toggleAll,
	frame_id,
	changedState,
}) => {
	const sortedItems = sortArrByAlphabet({
		arr: items,
	});
	const [isActiveAll, setActiveAll] = useState(() =>
		Boolean(items.find((elem) => selected.find((item) => item.id === elem.id)))
	);

	const handleToggleAllClick = () => {
		if (isActiveAll) {
			toggleAll(frame_id, items, isActiveAll);
		} else {
			toggleAll(frame_id, items, isActiveAll);
		}

		setActiveAll(!isActiveAll);
	};

	return (
		<ul className="col top rGap-8 pv-8">
			<p className="T15-bold" style={{ cursor: "pointer" }} onClick={handleToggleAllClick}>
				{title}
			</p>
			{sortedItems?.map((attr) => {
				return (
					<CustomCheckbox
						onSelect={() => {
							onSelect(attr);
						}}
						isChanged={attr.id in changedState.deleted || attr.id in changedState.added}
						isActive={selected.find((elem) => elem.id === attr.id)}
						id={attr.id}
						key={attr.id}
						title={attr.title}
					/>
				);
			})}
		</ul>
	);
};
