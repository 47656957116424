import axios from "axios";
import { baseUrl } from "./consts";

export const getPolitics = () => {
	return async (dispatch) => {
		await axios
			.get(`${baseUrl}/politic`)
			.then((politic) => {
				dispatch({
					type: "GET_POLITICS",
					politic,
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const getPoliticsLocation = (frameId) => {
	return async (dispatch) => {
		await axios
			.get(`${baseUrl}/frame/${frameId}`)
			.then((frame) => {
				dispatch({
					type: "GET_POLITICS_LOCATION",
					frame,
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const postPolitic = (data) => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_LOADER",
		});
		await axios
			.post(`${baseUrl}/domain/politics`, data)
			.then((politic) => {
				dispatch(getPolitics());
				// dispatch({
				// 	type: "POST_DOMAIN_POLITICS",
				// 	politic,
				// });
			})
			.finally(() => {
				dispatch({
					type: "HIDE_ALL",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const putPolitic = (id, data) => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_LOADER",
		});
		await axios
			.put(`${baseUrl}/domain/politics/${id}`, data)
			.then((politic) => {
				dispatch(getPolitics());
				// dispatch({
				// 	type: "PUT_DOMAIN_POLITICS",
				// 	politic,
				// });
			})
			.finally(() => {
				dispatch({
					type: "HIDE_ALL",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const deletePolitic = (id) => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_LOADER",
		});
		await axios
			.delete(`${baseUrl}/politic/${id}`)
			.then((politic) => {
				dispatch(getPolitics());
				// dispatch({
				// 	type: "DELETE_POLITIC",
				// 	politic,
				// });
			})
			.finally(() => {
				dispatch({
					type: "HIDE_ALL",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};
