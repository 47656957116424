import * as React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { handleToggleActiveClick } from "../../../scripts/PersonalAreaScripts";

const AreaFilters = ({ areasList, filtersState, setFiltersState }) => {
	const [areasState, setAreasState] = useState([]);
	const [activeDropDown, setActiveDropDown] = useState(false);
	useEffect(() => {
		const array = [...areasList];
		array.unshift({
			title: "Все площадки",
		});
		setAreasState(array);
	}, [areasList]);

	const handleSelectAreaClick = (event, id) => {
		event.stopPropagation();
		if (id) {
			setFiltersState({ ...filtersState, platforms_ids: id });
		} else {
			setFiltersState({ ...filtersState, platforms_ids: null });
		}
		setActiveDropDown(!activeDropDown);
	};
	return (
		<>
			<div
				className={`list-title-select ${activeDropDown ? "active" : ""}`}
				tabIndex="0"
				onClick={(e) => setActiveDropDown(!activeDropDown)}
				onBlur={(e) => setActiveDropDown(false)}
			>
				<div className="list-title-select-btn">
					{!!filtersState?.platforms_ids && areasState.length > 0
						? areasState.find((elem) => elem.id === filtersState?.platforms_ids)?.title
						: "Все площадки"}
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M7.99991 9.58545L8.70701 10.2926L11.9999 13.5854L15.2928 10.2926L15.9999 9.58545L17.4141 10.9997L16.707 11.7068L12.707 15.7068C12.3165 16.0973 11.6833 16.0973 11.2928 15.7068L7.2928 11.7068L6.58569 10.9997L7.99991 9.58545Z"
							fill="#212121"
						></path>
					</svg>
				</div>
				<div className="list-title-select-submenu">
					{areasState.length > 0 ? (
						areasState.map((elem, index) => (
							<div
								className="list-title-select-submenu-item"
								onClick={(e) => handleSelectAreaClick(e, elem?.id)}
								key={index}
							>
								{elem.title}
							</div>
						))
					) : (
						<div className="list-title-select-submenu-item">Нет значений</div>
					)}
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		areasList: state.areasList,
	};
};

export default connect(mapStateToProps, null)(AreaFilters);
