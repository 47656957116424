import axios from "axios";
import { baseUrl, cinemaUrl } from "../../../../../Actions/consts";

const formatGraphDate = (date, type) => {
	if (type === "year" || type === "full") {
		return new Date(date)
			.toLocaleString("ru", {
				year: "numeric",
				month: "long",
			})
			.split(" ", 3)
			.join(" ");
	} else if (type === "month") {
		return new Date(date)
			.toLocaleString("ru", {
				year: "numeric",
				month: "long",
				day: "numeric",
			})
			.split(" ", 3)
			.join(" ");
	} else if (type === "week") {
		return new Date(date)
			.toLocaleString("ru", {
				day: "numeric",
				weekday: "short",
				month: "long",
			})
			.split(" ", 3)
			.join(" ");
	} else if (type === "day") {
		return new Date(date)
			.toLocaleString("ru", {
				month: "short",
				day: "numeric",
				hour: "2-digit",
				minute: "2-digit",
			})
			.split(" ", 3)
			.join(" ");
	}
};

export const fetchVideoGadgetsViewByPeriod = async ({ vars, dispatch, period }) => {
	dispatch({
		type: "SHOW_MAIN_LOADER",
	});
	const queryString = Object.keys(vars)
		.map((key) => key + "=" + vars[key])
		.join("&");
	await axios
		.post(`${baseUrl}/domain/other`, {
			method: "GET",
			service: 1,
			endpoint: `/api/video/watch-sessions-by-device-type?${queryString}`,
			data: "",
		})
		.then((views) => {
			const res = [[], [], [], []];
			views.data.forEach((elem) => {
				res[elem.device_type] = elem.views.map((el) => {
					return {
						...el,
						date: formatGraphDate(el.date, period),
						device_type: elem.device_type,
					};
				});
			});
			dispatch({
				type: "GET_VIDEO_GADGETS_GRAPH",
				views: res,
				period,
			});
		})
		.finally(() => {
			dispatch({
				type: "HIDE_MAIN_LOADER",
			});
		})
		.catch((error) => {
			console.log(error);
		});
};

// статистика

// Кол-во просмотров
export const fetchVideoGadgetsAmountStatistic = async ({
	vars,
	dispatch,
	period,
	device_type,
	key,
}) => {
	dispatch({
		type: "SHOW_MAIN_LOADER",
	});
	const queryString = Object.keys(vars)
		.map((key) => key + "=" + vars[key])
		.join("&");
	await axios
		.post(`${baseUrl}/domain/other`, {
			method: "GET",
			service: 1,
			endpoint: `/api/video/watch-amount?${queryString}`,
			data: "",
		})
		.then((views) => {
			dispatch({
				type: "GET_VIDEO_GADGETS_STATISTIC",
				views: views.data.amount,
				period,
				device_type,
				key,
			});
		})
		.finally(() => {
			dispatch({
				type: "HIDE_MAIN_LOADER",
			});
		})
		.catch((error) => {
			console.log(error);
		});
};

// Общее время просмотра
export const fetchVideoGadgetsWatchTimeStatistic = async ({
	vars,
	dispatch,
	period,
	device_type,
	key,
}) => {
	dispatch({
		type: "SHOW_MAIN_LOADER",
	});
	const queryString = Object.keys(vars)
		.map((key) => key + "=" + vars[key])
		.join("&");
	await axios
		.post(`${baseUrl}/domain/other`, {
			method: "GET",
			service: 1,
			endpoint: `/api/video/total-watch-time?${queryString}`,
			data: "",
		})
		.then((views) => {
			dispatch({
				type: "GET_VIDEO_GADGETS_STATISTIC",
				views: views.data.total_watch_time,
				period,
				device_type,
				key,
			});
		})
		.finally(() => {
			dispatch({
				type: "HIDE_MAIN_LOADER",
			});
		})
		.catch((error) => {
			console.log(error);
		});
};

// Среднее время просмотра
export const fetchVideoGadgetsAverageTimeStatistic = async ({
	vars,
	dispatch,
	period,
	device_type,
	key,
}) => {
	dispatch({
		type: "SHOW_MAIN_LOADER",
	});
	const queryString = Object.keys(vars)
		.map((key) => key + "=" + vars[key])
		.join("&");
	await axios
		.post(`${baseUrl}/domain/other`, {
			method: "GET",
			service: 1,
			endpoint: `/api/video/total-watch-time?${queryString}`,
			data: "",
		})
		.then((views) => {
			dispatch({
				type: "GET_VIDEO_GADGETS_STATISTIC",
				views: views.data.average_session_time,
				period,
				device_type,
				key,
			});
		})
		.finally(() => {
			dispatch({
				type: "HIDE_MAIN_LOADER",
			});
		})
		.catch((error) => {
			console.log(error);
		});
};
