import axios from "axios";
import { baseUrl } from "../consts";

const getAdditionalVideo = (id) => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.get(`${baseUrl}/video/${`${id}`}`)
			.then((video) => {
				dispatch({
					type: "GET_CURRENT_ADDITIONAL_VIDEO",
					video,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const getAdditionalSerial = (id) => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.get(`${baseUrl}/serial/${`${id}`}`)
			.then((video) => {
				dispatch({
					type: "GET_CURRENT_ADDITIONAL_VIDEO",
					video,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const getVideo = (id) => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			// .get(`${baseUrl}/movie/${`${id}`}`)
			.get(`${baseUrl}/domain/movies/id/${`${id}`}`)
			.then((video) => {
				if (
					video.data.movie_format_id === 1 ||
					video.data.movie_format_id === 3 ||
					video.data.movie_format_id === 4 ||
					video.data.movie_format_id === 6
				) {
					dispatch(getAdditionalVideo(video.data.video[0].id));
				} else {
					dispatch(getAdditionalSerial(video.data.serial[0].id));
				}
				dispatch({
					type: "GET_CURRENT_VIDEO",
					video,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};
