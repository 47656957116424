import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { connect, useDispatch } from "react-redux";

const TabBadge = styled.div`
	align-items: center;
	padding: 1px 8px;
	border-radius: 80px;
	color: #212121;
	background: #f4f4f8;
	font-family: "Averta CY";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 160%;
`;

const TabsWrapper = styled.div`
	border-bottom: 1px solid #e2e8f0;
	display: flex;
	gap: 32px;
	margin-bottom: 56px;
	margin-top: 23px;
	position: relative;
`;

const Tab = styled.button`
	border: none;
	border-bottom: 2px solid transparent;
	outline: none;
	background: transparent;
	align-items: center;
	box-sizing: border-box;
	color: #212121;
	font-family: "Averta CY";
	font-size: 15px;
	font-weight: 400;
	line-height: 160%;
	padding-bottom: 18px;
	position: relative;
	gap: 8px;
	display: flex;
	align-items: center;
	cursor: pointer;
	&:hover {
		color: #005bff;
	}
	&.active {
		border-bottom: 2px solid #005bff;
		${TabBadge} {
			background: #005bff;
			color: #ffffff;
		}
	}
`;
export const initialCategoryTabsState = [
	{
		id: 1,
		title: "Товары",
	},
	{
		id: 2,
		title: "Услуги",
	},
];

const CategoryTabs = ({ active, onChange }) => {
	const [tabs, setTabs] = useState(initialCategoryTabsState);
	return (
		<TabsWrapper>
			{tabs.length &&
				tabs.map((elem, index) => (
					<Tab
						key={elem.id}
						className={`statistics-bookmark ${elem.id === active.id ? "active" : ""}`}
						onClick={() => onChange(elem)}
					>
						{elem.title}
					</Tab>
				))}
		</TabsWrapper>
	);
};

export default connect(null, null)(CategoryTabs);
