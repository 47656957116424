import axios from "axios";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import { getCategories, getCategyAttr } from "../../../Actions/CategoriesActions";
import { ModalContext } from "../../modal/ModalContext/ModalContext";
import AddNewCategories from "./AddNewCategories.jsx";
import DeleteCategories from "./DeleteCategories.jsx";
import EditCategories from "./EditCategories.jsx";
import "./styles/CategoriesPage.scss";
import styled from "styled-components";
import { Cluster } from "../../../ui-kit/layouts/Cluster";
import CategoryTabs from "./category-tabs/CategoryTabs";
import {
	CategoryColumnWrapper,
	CategoryContentWrapper,
	ChildElement,
	ColumnTitle,
	FilteredItemWrapper,
	ParentElement,
} from "./wrappers";
import { DTBold15, DText15 } from "../../../ui-kit/typography/Typography";
import { Button } from "../../../ui-kit/button/Button";
import { useUserAccessContext } from "../context/UserAccessContext";
import { checkUserWritePerm } from "../scripts/utils";
import CascadeCategoryRequestModal from "./CascadeCategoryRequestModal";

const StyledInput = styled.input`
	border: 0;
	border-bottom: 1px solid #ccc;
	font-size: 16px;
	line-height: 30px;
	margin-left: 30px;
	outline: none;
	width: 230px;
	margin-top: 22px;
`;

const initialCategoryState = {
	0: {
		list: [],
		active: null,
		index: null,
	},
	1: {
		list: [],
		active: null,
		index: null,
	},
	2: {
		list: [],
		active: null,
		index: null,
	},
	3: {
		list: [],
		active: null,
		index: null,
	},
	4: {
		list: [],
		active: null,
		index: null,
	},
};
export const initialCategoryTabsState = [
	{
		id: 1,
		title: "Товары",
	},
	{
		id: 2,
		title: "Услуги",
	},
];

const CategoriesPage = ({ getCategories, categoriesList, loader, title, getCategyAttr }) => {
	const dispatch = useDispatch();
	const { handleModal, modal = false } = useContext(ModalContext);
	const [categoriesListState, setCategoriesListState] = useState(initialCategoryState);
	const [categoryTabState, setCategoryTabState] = useState(initialCategoryTabsState[0]);
	const [searchState, setSearchState] = useState("");
	const [filteredCategory, setFilteredCategory] = useState([]);
	const [skipTabChange, setSkipTabChange] = useState(false);

	const [cascadeCategoryReqModal, setCascadeCategoryModalReqModal] = useState(null);
	const [editCategoriesVisible, setEditCategoriesVisible] = useState(null);

	useEffect(() => {
		getCategories();
		getCategyAttr();
	}, []);

	useEffect(() => {
		document.title = title;
	}, [title]);
	const { userAccessState } = useUserAccessContext();
	useEffect(() => {
		if (
			!searchState.length ||
			!categoriesList.length ||
			!categoriesList[categoryTabState.id - 1].length
		) {
			setFilteredCategory([]);
			return;
		}

		let filteredArray = [];
		const collection = [...categoriesList[0], ...categoriesList[1]];

		collection.forEach((elem, index) => {
			if (elem.childs.length === 0) {
				if (elem.title.toLowerCase().indexOf(searchState.toLocaleLowerCase()) !== -1) {
					filteredArray.push([{ ...elem, index }]);
				}
			} else {
				elem.childs.forEach((elem1, index1) => {
					if (elem1.childs.length === 0) {
						if (elem1.title.toLowerCase().indexOf(searchState.toLocaleLowerCase()) !== -1) {
							filteredArray.push([
								{ ...elem, index: index },
								{ ...elem1, index: index1 },
							]);
						}
					} else {
						elem1.childs.forEach((elem2, index2) => {
							if (elem2.childs.length === 0) {
								if (elem2.title.toLowerCase().indexOf(searchState.toLocaleLowerCase()) !== -1) {
									filteredArray.push([
										{ ...elem, index: index },
										{ ...elem1, index: index1 },
										{ ...elem2, index: index2 },
									]);
								}
							} else {
								elem2.childs.forEach((elem3, index3) => {
									if (elem3.childs.length === 0) {
										if (elem3.title.toLowerCase().indexOf(searchState.toLocaleLowerCase()) !== -1) {
											filteredArray.push([
												{ ...elem, index: index },
												{ ...elem1, index: index1 },
												{ ...elem2, index: index2 },
												{ ...elem3, index: index3 },
											]);
										}
									} else {
										elem3.childs.forEach((elem4, index4) => {
											if (elem4.childs.length === 0) {
												if (
													elem4.title.toLowerCase().indexOf(searchState.toLocaleLowerCase()) !== -1
												) {
													filteredArray.push([
														{ ...elem, index: index },
														{ ...elem1, index: index1 },
														{ ...elem2, index: index2 },
														{ ...elem3, index: index3 },
														{ ...elem4, index: index4 },
													]);
												}
											}
										});
									}
								});
							}
						});
					}
				});
			}
		});
		setFilteredCategory(filteredArray);
	}, [searchState, categoryTabState]);

	useEffect(() => {
		if (categoriesList.length > 0) {
			const collection = categoriesList[categoryTabState.id - 1];

			setCategoriesListState({
				...categoriesListState,
				0: {
					...categoriesListState[0],
					list: collection,
				},
				1: {
					...categoriesListState[1],
					list:
						categoriesListState[0].index !== null
							? collection[categoriesListState[0].index]?.childs || []
							: [],
				},
				2: {
					...categoriesListState[2],
					list:
						categoriesListState[0].index !== null && categoriesListState[1].index !== null
							? collection[categoriesListState[0].index].childs[categoriesListState[1].index]
									?.childs || []
							: [],
				},
				3: {
					...categoriesListState[3],
					list:
						categoriesListState[0].index !== null &&
						categoriesListState[1].index !== null &&
						categoriesListState[2].index !== null
							? collection[categoriesListState[0].index].childs[categoriesListState[1].index]
									.childs[categoriesListState[2].index]?.childs || []
							: [],
				},
				4: {
					...categoriesListState[4],
					list:
						categoriesListState[0].index !== null &&
						categoriesListState[1].index !== null &&
						categoriesListState[2].index !== null &&
						categoriesListState[3].index !== null
							? collection[categoriesListState[0].index].childs[categoriesListState[1].index]
									.childs[categoriesListState[2].index].childs[categoriesListState[3].index]
									?.childs || []
							: [],
				},
			});
			// if (categoriesListState[1].index)
		}
	}, [categoriesList]);

	useEffect(() => {
		if (skipTabChange) {
			setSkipTabChange(false);
			return;
		}
		if (categoryTabState && categoriesList.length > 0) {
			setCategoriesListState({
				...initialCategoryState,
				0: { list: categoriesList[categoryTabState.id - 1], active: null, index: null },
			});
		}
	}, [categoryTabState]);

	useEffect(() => {
		if (loader.loading === 2) {
			dispatch({ type: "HIDE_LOADER" });
			handleModal();
		}
	}, [loader, modal]);

	const handleDowloadLinkClick = () => {
		window.open(`https://atips.io/export/categories`);
	};

	const handleSelectCategoryClick = (index, category, idx) => {
		switch (index) {
			case 0:
				setCategoriesListState({
					...categoriesListState,
					0: { ...categoriesListState[index], active: category, index: idx },
					1: { active: null, list: category.childs },
					2: initialCategoryState[2],
					3: initialCategoryState[3],
					4: initialCategoryState[4],
				});
				return;
			case 1:
				setCategoriesListState({
					...categoriesListState,
					1: { ...categoriesListState[index], active: category, index: idx },
					2: { active: null, list: category.childs },
					3: initialCategoryState[3],
					4: initialCategoryState[4],
				});
				return;
			case 2:
				setCategoriesListState({
					...categoriesListState,
					2: { ...categoriesListState[index], active: category, index: idx },
					3: { active: null, list: category.childs },
					4: initialCategoryState[4],
				});
				return;
			case 3:
				setCategoriesListState({
					...categoriesListState,
					3: { ...categoriesListState[index], active: category, index: idx },
					4: { active: null, list: category.childs },
				});
				return;
			case 4:
				setCategoriesListState({
					...categoriesListState,
					4: { ...categoriesListState[4], active: category, index: idx },
				});
				return;
			default:
				return;
		}
	};

	const handleDeleteCategoryClick = (index) => {
		switch (index) {
			case 0:
				setCategoriesListState({
					...categoriesListState,
					0: { ...categoriesListState[0], active: null, index: null },
					1: initialCategoryState[1],
					2: initialCategoryState[2],
					3: initialCategoryState[3],
					4: initialCategoryState[4],
				});
				return;
			case 1:
				setCategoriesListState({
					...categoriesListState,
					0: { ...categoriesListState[0], active: null, index: null },
					1: initialCategoryState[1],
					2: initialCategoryState[2],
					3: initialCategoryState[3],
					4: initialCategoryState[4],
				});
				return;
			case 2:
				setCategoriesListState({
					...categoriesListState,
					1: { ...categoriesListState[1], active: null, index: null },
					2: initialCategoryState[2],
					3: initialCategoryState[3],
					4: initialCategoryState[4],
				});
				return;
			case 3:
				setCategoriesListState({
					...categoriesListState,
					2: { ...categoriesListState[2], active: null, index: null },
					3: initialCategoryState[3],
					4: initialCategoryState[4],
				});
				return;
			case 4:
				setCategoriesListState({
					...categoriesListState,
					3: { ...categoriesListState[3], active: null, index: null },
					4: initialCategoryState[4],
				});
				return;
			default:
				return;
		}
	};

	const handleSelectBySearch = (index, list) => {
		setSkipTabChange(true);
		setSearchState("");
		setFilteredCategory([]);
		setCategoryTabState(initialCategoryTabsState[list[0].parent_id - 1]);
		switch (index) {
			case 0:
				setCategoriesListState({
					...initialCategoryState,
					0: {
						list: categoriesList[list[0].parent_id - 1],
						active: list[0],
						index: list[0]?.index,
					},
					1: initialCategoryState[1],
					2: initialCategoryState[2],
					3: initialCategoryState[3],
					4: initialCategoryState[4],
				});
				return;
			case 1:
				setCategoriesListState({
					...initialCategoryState,
					0: {
						list: categoriesList[list[0].parent_id - 1],
						active: list[0],
						index: list[0]?.index,
					},
					1: {
						list: list[0].childs,
						active: list[1],
						index: list[1]?.index,
					},
					2: initialCategoryState[2],
					3: initialCategoryState[3],
					4: initialCategoryState[4],
				});
				return;
			case 2:
				setCategoriesListState({
					...initialCategoryState,
					0: {
						list: categoriesList[list[0].parent_id - 1],
						active: list[0],
						index: list[0]?.index,
					},
					1: {
						list: list[0].childs,
						active: list[1],
						index: list[1]?.index,
					},
					2: {
						list: list[1].childs,
						active: list[2],
						index: list[2]?.index,
					},
					3: initialCategoryState[3],
					4: initialCategoryState[4],
				});
				return;
			case 3:
				setCategoriesListState({
					...initialCategoryState,
					0: {
						list: categoriesList[list[0].parent_id - 1],
						active: list[0],
						index: list[0]?.index,
					},
					1: {
						list: list[0].childs,
						active: list[1],
						index: list[1]?.index,
					},
					2: {
						list: list[1].childs,
						active: list[2],
						index: list[2]?.index,
					},
					3: {
						list: list[2].childs,
						active: list[3],
						index: list[3]?.index,
					},
					4: initialCategoryState[3],
				});
				return;
			case 4:
				setCategoriesListState({
					...initialCategoryState,
					0: {
						list: categoriesList[list[0].parent_id - 1],
						active: list[0],
						index: list[0]?.index,
					},
					1: {
						list: list[0].childs,
						active: list[1],
						index: list[1]?.index,
					},
					2: {
						list: list[1].childs,
						active: list[2],
						index: list[2]?.index,
					},
					3: {
						list: list[2].childs,
						active: list[3],
						index: list[3]?.index,
					},
					4: {
						list: list[3].childs,
						active: list[4],
						index: list[4]?.index,
					},
				});
				return;
			default:
				return;
		}
	};

	return (
		<>
			{cascadeCategoryReqModal !== null ? (
				<CascadeCategoryRequestModal
					item={cascadeCategoryReqModal}
					onClose={() => setCascadeCategoryModalReqModal(null)}
				/>
			) : null}
			{editCategoriesVisible !== null ? (
				<EditCategories
					onCascadeEdit={setCascadeCategoryModalReqModal}
					elem={editCategoriesVisible?.elem}
					isService={editCategoriesVisible?.isService}
					onClose={() => setEditCategoriesVisible(null)}
				/>
			) : null}
			<section className="personal-area-item categories" style={{ paddingBottom: "0" }}>
				<header className="personal-area categories-header">
					<Cluster align="center" style={{ width: "100%" }}>
						<h1 className="categories-header-text" style={{ width: "auto" }}>
							Категории
						</h1>
						<StyledInput
							placeholder="Введите название категории"
							value={searchState}
							onChange={(e) => setSearchState(e.target.value)}
						/>
					</Cluster>

					{/* <button
						className="button_add-category"
						onClick={() => handleDowloadLinkClick()}
						style={{ marginRight: "20px" }}
					>
						<img src="images/icons/ui/files/white/upload.svg" alt="плюс" />
						Выгрузить
					</button> */}
				</header>
				{filteredCategory.length > 0 ? (
					filteredCategory.map((elem, index) => (
						<FilteredItemWrapper
							onClick={() => handleSelectBySearch(elem.length - 1, elem)}
							key={index}
						>
							<DTBold15>{elem[elem.length - 1].title}</DTBold15>
							<DText15>
								{elem.map((item, index) => {
									let res = "";
									if (index === 0) {
										if (item.parent_id === 1) {
											res = `Товары > `;
										} else {
											res = `Услуги > `;
										}
									}
									if (index !== elem.length - 1) {
										return res + `${item.title} > `;
									} else return res + item.title;
								})}
							</DText15>
						</FilteredItemWrapper>
					))
				) : searchState.length > 0 ? (
					<DText15 style={{ color: "#94a0b1" }}>Ничего не найдено</DText15>
				) : (
					<>
						<CategoryTabs active={categoryTabState} onChange={(e) => setCategoryTabState(e)} />
						<CategoryContentWrapper>
							{[0, 1, 2, 3, 4].map((column, index) => (
								<CategoryColumnWrapper key={`${index}-${column}`}>
									<Cluster
										justify="space-between"
										align="center"
										style={{ padding: "0 10px", marginBottom: "16px" }}
									>
										<ColumnTitle>
											{column === 0
												? categoryTabState.title
												: categoriesListState[column - 1].active
												? categoriesListState[column - 1].active.title
												: ""}
										</ColumnTitle>
										{checkUserWritePerm(userAccessState, "categories") &&
										(column === 0 ||
											(categoriesListState[column - 1].active &&
												categoriesListState[column - 1].active.id)) ? (
											<Button
												variant="transparent"
												text="Добавить"
												onClick={() =>
													handleModal(
														<AddNewCategories
															parentId={
																column === 0
																	? categoryTabState.id
																	: categoriesListState[column - 1].active.id
															}
															isService={categoryTabState.id === 2}
														/>
													)
												}
												startIcon={
													<svg
														width="24"
														height="24"
														viewBox="0 0 24 24"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															fillRule="evenodd"
															clipRule="evenodd"
															d="M13 6V5L11 5V6L11 11H6H5L5 13H6H11L11 18V19H13V18L13 13H18L19 13V11L18 11H13L13 6Z"
															fill="#94A0B1"
														/>
													</svg>
												}
											/>
										) : null}
									</Cluster>
									{categoriesListState[column].list.map((elem, index) =>
										elem.childs.length > 0 ? (
											<ParentElement
												key={elem.id}
												onClick={() => handleSelectCategoryClick(column, elem, index)}
												className={
													categoriesListState[column].active &&
													categoriesListState[column].active.id === elem.id
														? "selected"
														: ""
												}
											>
												<span>{elem.title}</span>
												{checkUserWritePerm(userAccessState, "categories") ? (
													<Cluster
														align="center"
														space="16px"
														className="actions-wrapper"
														justify="flex-end"
													>
														<svg
															onClick={(e) => (
																e.stopPropagation(),
																setEditCategoriesVisible({
																	elem,
																	isService: categoryTabState.id === 2,
																})
															)}
															width="24"
															height="24"
															viewBox="0 0 24 24"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																fillRule="evenodd"
																clipRule="evenodd"
																d="M16.6203 2.24935C16.4328 2.0618 16.1784 1.95643 15.9132 1.95642C15.648 1.95641 15.3936 2.06177 15.2061 2.24931L5.58581 11.8695C5.21073 12.2445 5.00001 12.7532 5.00001 13.2837L5.00001 16.9999C5.00001 17.2651 5.10537 17.5195 5.2929 17.707C5.48044 17.8945 5.73479 17.9999 6.00001 17.9999H9.71622C10.2467 17.9999 10.7554 17.7892 11.1305 17.4141L20.7503 7.79396C21.1408 7.40344 21.1409 6.77031 20.7504 6.37979L16.6203 2.24935ZM7.00001 13.2837L15.9131 4.37066L18.6291 7.08683L9.71622 15.9999H7.00001L7.00001 13.2837ZM3 20.0001H2L2 22.0001H3H21H22V20.0001H21H3Z"
																fill="#94A0B1"
															/>
														</svg>
														{elem.childs.length === 0 ? (
															<svg
																onClick={(e) => (
																	e.stopPropagation(),
																	handleDeleteCategoryClick(column),
																	handleModal(
																		<DeleteCategories
																			id={elem.id}
																			isService={categoryTabState.id === 2}
																		/>
																	)
																)}
																width="24"
																height="24"
																viewBox="0 0 24 24"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path
																	fillRule="evenodd"
																	clipRule="evenodd"
																	d="M7 5C7 3.34315 8.34315 2 10 2L14 2C15.6569 2 17 3.34315 17 5V6H18L20 6H21V8H20H18.9311L18.1301 19.2137C18.018 20.7837 16.7117 22 15.1378 22H8.86224C7.28832 22 5.982 20.7837 5.86986 19.2137L5.06888 8H4H3V6H4H6H7V5ZM9 6L15 6V5C15 4.44772 14.5523 4 14 4L10 4C9.44772 4 9 4.44772 9 5V6ZM7.07398 8L7.86478 19.0712C7.90216 19.5946 8.3376 20 8.86224 20H15.1378C15.6624 20 16.0978 19.5946 16.1352 19.0712L16.926 8H16L8.0003 8H8H7.9997H7.07398Z"
																	fill="#94A0B1"
																/>
															</svg>
														) : null}
													</Cluster>
												) : null}
											</ParentElement>
										) : (
											<ChildElement
												key={elem.id}
												onClick={() => handleSelectCategoryClick(column, elem, index)}
												className={
													categoriesListState[column].active &&
													categoriesListState[column].active.id === elem.id
														? "selected"
														: ""
												}
											>
												<span>{elem.title}</span>
												{checkUserWritePerm(userAccessState, "categories") ? (
													<Cluster
														align="center"
														space="16px"
														justify="flex-end"
														className={`actions-wrapper ${!elem?.id ? "hidden" : ""}`}
													>
														<svg
															onClick={(e) => (
																e.stopPropagation(),
																setEditCategoriesVisible({
																	elem,
																	isService: categoryTabState.id === 2,
																})
															)}
															width="24"
															height="24"
															viewBox="0 0 24 24"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																fillRule="evenodd"
																clipRule="evenodd"
																d="M16.6203 2.24935C16.4328 2.0618 16.1784 1.95643 15.9132 1.95642C15.648 1.95641 15.3936 2.06177 15.2061 2.24931L5.58581 11.8695C5.21073 12.2445 5.00001 12.7532 5.00001 13.2837L5.00001 16.9999C5.00001 17.2651 5.10537 17.5195 5.2929 17.707C5.48044 17.8945 5.73479 17.9999 6.00001 17.9999H9.71622C10.2467 17.9999 10.7554 17.7892 11.1305 17.4141L20.7503 7.79396C21.1408 7.40344 21.1409 6.77031 20.7504 6.37979L16.6203 2.24935ZM7.00001 13.2837L15.9131 4.37066L18.6291 7.08683L9.71622 15.9999H7.00001L7.00001 13.2837ZM3 20.0001H2L2 22.0001H3H21H22V20.0001H21H3Z"
																fill="#94A0B1"
															/>
														</svg>
														{elem.childs.length === 0 ? (
															<svg
																onClick={(e) => (
																	e.stopPropagation(),
																	handleDeleteCategoryClick(column),
																	handleModal(
																		<DeleteCategories
																			id={elem.id}
																			isService={categoryTabState.id === 2}
																		/>
																	)
																)}
																width="24"
																height="24"
																viewBox="0 0 24 24"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path
																	fillRule="evenodd"
																	clipRule="evenodd"
																	d="M7 5C7 3.34315 8.34315 2 10 2L14 2C15.6569 2 17 3.34315 17 5V6H18L20 6H21V8H20H18.9311L18.1301 19.2137C18.018 20.7837 16.7117 22 15.1378 22H8.86224C7.28832 22 5.982 20.7837 5.86986 19.2137L5.06888 8H4H3V6H4H6H7V5ZM9 6L15 6V5C15 4.44772 14.5523 4 14 4L10 4C9.44772 4 9 4.44772 9 5V6ZM7.07398 8L7.86478 19.0712C7.90216 19.5946 8.3376 20 8.86224 20H15.1378C15.6624 20 16.0978 19.5946 16.1352 19.0712L16.926 8H16L8.0003 8H8H7.9997H7.07398Z"
																	fill="#94A0B1"
																/>
															</svg>
														) : null}
													</Cluster>
												) : null}
											</ChildElement>
										)
									)}
								</CategoryColumnWrapper>
							))}
						</CategoryContentWrapper>
					</>
				)}
			</section>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		loader: state.loadingStatus,
		categoriesList: state.categoriesList,
	};
};

const mapDispatchToProps = {
	getCategories,
	getCategyAttr,
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesPage);
