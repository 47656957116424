import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { ModalContext } from "../../../../modal/ModalContext/ModalContext";
import axios from "axios";
import { baseUrl } from "../../../../../Actions/consts";
import { formatDate } from "../../../VideosPage/sctipts";

const ModalOwnerPercentHistory = ({ owner_id }) => {
	const dispatch = useDispatch();
	const { handleModal } = useContext(ModalContext);
	const [priceHistoryState, setPriceHistoryState] = useState([]);

	useEffect(async () => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.get(`${baseUrl}/domain/ownerpercent`, {
				params: {
					owner_id: owner_id,
					limit: 20,
					offset: 0,
				},
			})
			.then((price) => {
				// если указана только площадка, то меняем лишь базовую цену
				setPriceHistoryState(price.data.items);
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	}, [owner_id]);
	return (
		<div className="modal-field price-history-modal active">
			<div className="modal-field-title">История изменений</div>
			<div className="modal-field-close" onClick={() => handleModal()}>
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M4.70701 3.29304L3.99991 2.58594L2.58569 4.00015L3.2928 4.70726L10.5857 12.0002L3.2928 19.293L2.58569 20.0002L3.99991 21.4144L4.70701 20.7073L11.9999 13.4144L19.2928 20.7073L19.9999 21.4144L21.4141 20.0002L20.707 19.293L13.4141 12.0002L20.707 4.70726L21.4141 4.00015L19.9999 2.58594L19.2928 3.29304L11.9999 10.5859L4.70701 3.29304Z"
						fill="#212121"
					></path>
				</svg>
			</div>
			<div className="price-history-list">
				<div className="price-history-content-scroll">
					{priceHistoryState?.length > 0 ? (
						<div className="price-history-content">
							{priceHistoryState.map((item, index) =>
								index === 0 ? (
									<div className="price-history-item price-history-item-current" key={index}>
										<small>Текущий процент</small>
										<div className="price-history-item-date">{formatDate(item.created_at, 3)}</div>
										{/* <div className="price-history-item-user">{seller.title}</div> */}
										<div className="price-history-item-price">{item.percent} %</div>
									</div>
								) : (
									<div className="price-history-item" key={index}>
										<div className="price-history-item-date">{formatDate(item.created_at, 3)}</div>
										{/* <div className="price-history-item-user">{seller.title}</div> */}
										<div className="price-history-item-price">{item.percent} %</div>
									</div>
								)
							)}
						</div>
					) : (
						<div className="price-history-content">
							<div className="price-history-item price-history-item-current">
								<small>Текущая стоимость клика</small>
							</div>
						</div>
					)}
				</div>
				<div className="price-history-list-bar">
					<div className="price-history-list-thumb" style={{ top: "0px" }}></div>
				</div>
			</div>
		</div>
	);
};

export default connect(null, null)(ModalOwnerPercentHistory);
