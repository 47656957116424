import axios from "axios";
import * as React from "react";
import { useEffect, useState } from "react";
import { baseUrl } from "../../../../Actions/consts";
import Search from "../../../../ui-kit/search/Search.jsx";
import * as qs from "qs";
import ProdSearch from "./ProdSearch";

const DropdownSellerProductsSearch = ({ selected, onSelect, onClear, placeholder, sellerId }) => {
	const [collection, setCollection] = useState([]);
	const [queryState, setQueryState] = useState({
		offset: 1,
		query: "",
	});
	const limit = 1000;
	const [open, setOpen] = useState(false);
	const [total, setTotal] = useState(false);

	const [status, setStatus] = useState({
		type: "ready",
	});

	useEffect(async () => {
		setStatus({ type: "load" });
		await axios
			.get(`${baseUrl}/domain/seller_products`, {
				params: {
					seller_id: sellerId,
					is_service: false,
					// movie_title: queryState.query,
					// page: queryState.offset,
					page_size: limit,
					// formats_ids: formats,
				},
				paramsSerializer: (params) => {
					return qs.stringify(params, { arrayFormat: "repeat" });
				},
			})
			.then((video) => {
				setCollection(video.data.products);
				setTotal(video.data.total);
				setStatus({ type: "ready" });
			})
			.catch((error) => {
				setStatus({ type: "error", message: error.message });
				console.log(error);
			});
	}, [queryState, sellerId]);

	const loadNext = () => {
		if (status.type === "ready" && Number(total) > Number(queryState.offset * limit)) {
			setQueryState({ ...queryState, offset: queryState.offset + 1 });
		}
	};

	const handleChange = (value) => {
		setQueryState({ offset: 1, query: value });
	};

	const handleDDClick = () => {
		if (!open) {
			setOpen(true);
		}
	};
	return (
		<ProdSearch
			results={
				collection.length > 0
					? collection.filter((subChild) => {
							if (
								subChild.title?.toLowerCase()?.indexOf(queryState?.query?.toLocaleLowerCase()) !==
								-1
							)
								return true;
							return false;
					  })
					: []
			}
			value={queryState.query}
			selected={selected}
			onChange={(query) => handleChange(query)}
			onSelect={onSelect}
			onClear={onClear}
			isOpen={open}
			onDDClick={() => handleDDClick()}
			onClose={() => setOpen(false)}
			status={status}
			loadNext={loadNext}
		/>
	);
};

export default DropdownSellerProductsSearch;
