import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import styled from "styled-components";
import { Stack } from "../../../../../../ui-kit/layouts/Stack";
import { DText15, H3Text } from "../../../../../../ui-kit/typography/Typography";
import { MeanCardContainer } from "../../../../../../common/mean-cards/MeanCard";
import { bloggerUrl } from "../../../../../../Actions/consts";
import axios from "axios";
import {
	getTotalAverageTime,
	getTotalDislikesCount,
	getTotalLikesCount,
} from "../../meaningful-cards/get-totals";

const Containter = styled.div`
	display: flex;
	gap: 16px;
	width: 100%;
	justify-content: space-between;
`;

const IncomeCards = ({ title, bloggerState, filtersState }) => {
	const [commentState, setCommentState] = useState(0);
	const [averageViewState, setAverageViewState] = useState(0);
	const [likesState, setLikesState] = useState(0);
	const [dislikesState, setDisikesState] = useState(0);
	const dispatch = useDispatch();
	useEffect(async () => {
		if (
			(!bloggerState,
			!filtersState.from_datetime || !filtersState.to_datetime || !filtersState.period_name)
		)
			return;
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.get(`${bloggerUrl}/api/comment/comments`, {
				params: {
					size: 1,
					page: 1,
					content_creator_id: bloggerState.id,
					date_start: new Date(filtersState.from_datetime).toISOString(),
					date_end: new Date(filtersState.to_datetime).toISOString(),
				},
				ignoreGlobalCatch: true,
			})
			.then((ad) => {
				setCommentState(ad.data.total);
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
		getTotalLikesCount(
			{
				content_creator_id: bloggerState.id,
				date_start: new Date(filtersState.from_datetime).toISOString(),
				date_end: new Date(filtersState.to_datetime).toISOString(),
			},
			dispatch,
			(e) => setLikesState(e)
		);
		getTotalDislikesCount(
			{
				content_creator_id: bloggerState.id,
				date_start: new Date(filtersState.from_datetime).toISOString(),
				date_end: new Date(filtersState.to_datetime).toISOString(),
			},
			dispatch,
			(e) => setDisikesState(e)
		);
		getTotalAverageTime(
			{
				content_creator_id: bloggerState.id,
				date_start: new Date(filtersState.from_datetime).toISOString(),
				date_end: new Date(filtersState.to_datetime).toISOString(),
			},
			dispatch,
			(e) => setAverageViewState(e)
		);
	}, [filtersState]);

	return (
		<Stack space="16px">
			<Containter>
				<MeanCardContainer className="white">
					<Stack stretch={true} space="auto">
						<H3Text>{Number(likesState || 0).toLocaleString("ru-RU")}</H3Text>
						<DText15>Отметки «Нравится»</DText15>
					</Stack>
				</MeanCardContainer>
				<MeanCardContainer className="white">
					<Stack stretch={true} space="auto">
						<H3Text> {Number(dislikesState || 0).toLocaleString("ru-RU")}</H3Text>
						<DText15>Отметки «Не нравится»</DText15>
					</Stack>
				</MeanCardContainer>
				<MeanCardContainer className="white">
					<Stack stretch={true} space="auto">
						<H3Text>{(commentState || 0).toLocaleString("ru-RU")}</H3Text>
						<DText15>Комментарии</DText15>
					</Stack>
				</MeanCardContainer>
				<MeanCardContainer className="white">
					<Stack stretch={true} space="auto">
						<H3Text>
							{" "}
							{averageViewState > 60
								? `${(averageViewState / 60).toFixed(2)} мин.`
								: `${Math.round(averageViewState)} сек.`}
						</H3Text>
						<DText15>Средняя продолжительность просмотра</DText15>
					</Stack>
				</MeanCardContainer>
			</Containter>
		</Stack>
	);
};

// const mapStateToProps = (state) => {
// 	return {
// 		bloggerState: state.bloggerState,
// 	};
// };

// const mapDispatchToProps = {};

export default connect(null, null)(IncomeCards);
