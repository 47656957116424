import { nativeMapBy } from "../components/PersonalArea/scripts/mapBy";

const initialVideoProfitGraph = {
	day: {
		film: [],
		serial: [],
		video: [],
		shorts: [],
	},
	week: {
		film: [],
		serial: [],
		video: [],
		shorts: [],
	},
	month: {
		film: [],
		serial: [],
		video: [],
		shorts: [],
	},
	year: {
		film: [],
		serial: [],
		video: [],
		shorts: [],
	},
	full: {
		film: [],
		serial: [],
		video: [],
		shorts: [],
	},
	video: [],
};

const initialVideoViewGraph = {
	day: {
		film: {
			last: [],
			actual: [],
		},
		actual: {
			last: [],
			actual: [],
		},
		video: {
			last: [],
			actual: [],
		},
		shorts: {
			last: [],
			actual: [],
		},
	},
	week: {
		film: {
			last: [],
			actual: [],
		},
		actual: {
			last: [],
			actual: [],
		},
		video: {
			last: [],
			actual: [],
		},
		shorts: {
			last: [],
			actual: [],
		},
	},
	month: {
		film: {
			last: [],
			actual: [],
		},
		actual: {
			last: [],
			actual: [],
		},
		video: {
			last: [],
			actual: [],
		},
		shorts: {
			last: [],
			actual: [],
		},
	},
	year: {
		film: {
			last: [],
			actual: [],
		},
		actual: {
			last: [],
			actual: [],
		},
		video: {
			last: [],
			actual: [],
		},
		shorts: {
			last: [],
			actual: [],
		},
	},
	full: {
		film: {
			last: [],
			actual: [],
		},
		actual: {
			last: [],
			actual: [],
		},
		video: {
			last: [],
			actual: [],
		},
		shorts: {
			last: [],
			actual: [],
		},
	},
};
export const VideoProfitGraphReducer = (state = initialVideoProfitGraph, action) => {
	switch (action.type) {
		case "GET_VIDEIO_PROFIT_POINT":
			const data = action;
			const videos = [...state[data.period][data.format]];
			videos[data.index] = data.value;
			return {
				...state,
				[data.period]: { ...state[data.period], [data.format]: videos },
			};
		default:
			return state;
	}
};
export const VideosViewsGraphReducer = (state = initialVideoViewGraph, action) => {
	switch (action.type) {
		case "GET_VIDEOS_VIEWS_GRAPH":
			const data = action;
			return {
				...state,
				[data.period]: {
					...state[data.period],
					[data.format]: data.views,
				},
			};
		default:
			return state;
	}
};
export const VideosViewsBloogerGraphReducer = (state = initialVideoViewGraph, action) => {
	switch (action.type) {
		case "GET_VIDEOS_BLOGGER_VIEWS_GRAPH":
			const data = action;
			return {
				...state,
				[data.period]: {
					...state[data.period],
					[data.format]: data.views,
				},
			};
		default:
			return state;
	}
};

const initialCurrentVideoProfitGraph = {
	day: [],
	week: [],
	month: [],
	year: [],
	full: [],
};

export const CurrentVideoViewsGraphReducer = (state = initialCurrentVideoProfitGraph, action) => {
	switch (action.type) {
		case "GET_VIDEO_VIEWS_GRAPH":
			const data = action;
			return {
				...state,
				[data.period]: data.views,
			};
		default:
			return state;
	}
};

export const CurrentVideoViewsDepthGraphReducer = (
	state = initialCurrentVideoProfitGraph,
	action
) => {
	switch (action.type) {
		case "GET_VIDEO_VIEWS_DEPTH_GRAPH":
			const data = action;
			return {
				...state,
				[data.period]: data.views,
			};
		default:
			return state;
	}
};

const initialVideosCountByFormats = [
	{
		id: 1,
		title: "Всего фильмов:",
		value: 0,
	},
	{
		id: 2,
		title: "Всего сериалов:",
		value: 0,
	},
	{
		id: 3,
		title: "Всего видео блогеров:",
		value: 0,
	},
	{
		id: 4,
		title: "Всего видео Shorts:",
		value: 0,
	},
	{
		id: 6,
		title: "Всего фильмов правообладателей",
		value: 0,
	},
	{
		id: 7,
		title: "Всего сериалов правообладателей:",
		value: 0,
	},
];

export const VideosCountByFormats = (state = initialVideosCountByFormats, action) => {
	switch (action.type) {
		case "GET_VIDEO_FORMAT_COUNT":
			const count = action?.video?.data?.paginate_?.total_rows || 0;
			const formatId = action.formatId;
			const newArray = state.map((elem) =>
				elem.id === formatId ? { ...elem, value: count } : elem
			);
			return newArray;
		default:
			return state;
	}
};


const initialBloggerVideosCountByFormats = [
	{
	  id: 0,
	  title: "Всего видео:",
	  value: 0,
	},
	{
	  id: 1,
	  title: "Всего Shorts:",
	  value: 0,
	},
  ];

export const BloggerVideosCountByFormatsReducer = (state = initialBloggerVideosCountByFormats, action) => {
	switch (action.type) {
		case "GET_BLOGGER_VIDEO_FORMAT_COUNT":
			const count = action.video.data.total;
			const formatId = action.formatId;
			const newArray = state.map((elem) =>
				elem.id === formatId ? { ...elem, value: count } : elem
			);
			return newArray;
		default:
			return state;
	}
};
