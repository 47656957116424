export const getGraphPeriod = (type) => {
	if (type === "full") return getYearPeriod();
	if (type === "year") return getYearPeriod();
	if (type === "month") return getMonthPeriod();
	if (type === "week") return getWeekPeriod();
	if (type === "day") return getDayPeriod();
};

const getDayPeriod = () => {
	const actualDate = new Date();
	const hours = actualDate.getHours();
	const result = [];
	for (let i = 24; i >= 0; i--) {
		result.push({
			s1: new Date(new Date().setHours(hours - i - 1)).toString(),
			e1: new Date(new Date().setHours(hours - i)).toString(),
		});
	}
	return result;
};
const getWeekPeriod = () => {
	const actualDate = new Date();
	const acutalDateNumber = actualDate.getDate();
	const result = [];
	for (let i = 7; i >= 0; i--) {
		result.push({
			s1: new Date(
				new Date(new Date().setHours(23, 59, 59)).setDate(acutalDateNumber - i - 1)
			).toString(),
			e1: new Date(
				new Date(new Date().setHours(23, 59, 59)).setDate(acutalDateNumber - i)
			).toString(),
		});
	}
	return result;
};
const getMonthPeriod = () => {
	const actualDate = new Date();
	const acutalDateNumber = actualDate.getDate();
	const result = [];
	for (let i = 30; i >= 0; i--) {
		result.push({
			s1: new Date(
				new Date(new Date().setHours(23, 59, 59)).setDate(acutalDateNumber - i - 1)
			).toString(),
			e1: new Date(
				new Date(new Date().setHours(23, 59, 59)).setDate(acutalDateNumber - i)
			).toString(),
		});
	}
	return result;
};
const getYearPeriod = () => {
	const actualDate = new Date();
	const acutalDateNumber = actualDate.getMonth();
	const result = [];
	for (let i = 12; i >= 0; i--) {
		result.push({
			s1: new Date(
				new Date(new Date().setHours(23, 59, 59)).setMonth(acutalDateNumber - i - 1)
			).toString(),
			e1: new Date(
				new Date(new Date().setHours(23, 59, 59)).setMonth(acutalDateNumber - i)
			).toString(),
		});
	}
	return result;
};

export let options = {
	year: "numeric",
	month: "long",
	day: "numeric",
};
export const initialMonthData = () => {
	const actualDate = new Date();
	const acutalDateNumber = actualDate.getDate();
	const result = [];
	for (let i = 30; i > 0; i--) {
		result.push({
			actualDate: 0,
			date: new Date(new Date().setDate(acutalDateNumber - i))
				.toLocaleString("ru", options)
				.split(" ", 3)
				.join(" "),
		});
	}
	return result;
};
