export const initialSellerTab = [
	{
		id: 1,
		title: "Активные",
	},
	{
		id: 2,
		title: "На рассмотрении",
	},
	{
		id: 3,
		title: "Отключенные",
	},
	{
		id: 4,
		title: "Удаленные",
	},
];
