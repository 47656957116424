import styled from "styled-components";

export const MeanCardContainer = styled.div`
  height: 168px;
  position: relative;
  border-radius: 16px;
  padding: 24px;
  width: 100%;
  display: flex;
  background: #ffffff;
  &.purple {
    background: #7027c2;
    color: #ffffff;
  }
  &.white {
    background: #ffffff;
    color: #212121;
  }
`;
