import axios from "axios";
import { removeEmpty } from "./ActionsScirpts";
import { baseUrl, cinemaUrl } from "./consts";
import * as qs from "qs";

export const getViewers = (arg) => {
	if (!!arg?.filters && arg?.filters !== null) {
		for (let key in arg.filters) {
			arg = { ...arg, [key]: arg.filters[key] };
		}
		delete arg.filters;
	}
	let first_name = "";
	if (arg) {
		if ("title" in arg) {
			first_name = arg?.title;
			delete arg.title;
		}
	}
	if ("is_verified" in arg) {
		if (arg.is_verified.length > 1) {
			delete arg.is_verified;
		}
	}
	if ("reversed_" in arg) arg = { ...arg, reversed: arg.reversed_ };
	delete arg.reversed_;
	if (!("order_by" in arg)) arg = { ...arg, order_by: "id", reversed: true };

	const queryString = Object.keys(arg)
		.map((key) => key + "=" + arg[key])
		.join("&");

	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});

		await axios
			.post(`${baseUrl}/domain/other`, {
				method: "GET",
				service: 1,
				endpoint: `/api/dashboard/users?first_name=${first_name}&${queryString}`,
				data: "",
			})
			.then((viewers) => {
				dispatch({
					type: "GET_VIEWERS",
					viewers,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const getCurrentViewer = (id) => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.get(`${baseUrl}/domain/moviegoer/${id}`)
			.then((viewers) => {
				dispatch({
					type: "GET_CURRENT_VIEWERS",
					viewers,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const getExternalViewer = (id) => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.post(`${baseUrl}/domain/other`, {
				method: "GET",
				service: 1,
				endpoint: `/api/dashboard/users/${id}`,
				data: "",
			})
			.then((viewer) => {
				dispatch({
					type: "GET_EXTERNAL_VIEWER",
					viewer,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const getActiveExternalViewerTotal = () => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.post(`${baseUrl}/domain/other`, {
				method: "GET",
				service: 1,
				endpoint: `/api/dashboard/users?size=1&page=1&application_status=accepted`,
				data: "",
			})
			.then((viewer) => {
				dispatch({
					type: "GET_ACTIVE_EXTERNAL_VIEWER_TOTAL",
					viewer,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};
export const getBlockedExternalViewerTotal = () => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.post(`${baseUrl}/domain/other`, {
				method: "GET",
				service: 1,
				endpoint: `/api/dashboard/users?size=1&page=1&application_status=rejected`,
				data: "",
			})
			.then((viewer) => {
				dispatch({
					type: "GET_BLOCKED_EXTERNAL_VIEWER_TOTAL",
					viewer,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};
export const getDeletedExternalViewerTotal = () => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.post(`${baseUrl}/domain/other`, {
				method: "GET",
				service: 1,
				endpoint: `/api/dashboard/users?size=1&page=1&application_status=deleted`,
				data: "",
			})
			.then((viewer) => {
				dispatch({
					type: "GET_DELETED_EXTERNAL_VIEWER_TOTAL",
					viewer,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const patchExternalViewer = (id, data) => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.post(`${baseUrl}/domain/other`, {
				method: "PATCH",
				service: 1,
				endpoint: `/api/dashboard/users/${id}`,
				data_json: data,
			})
			.then((viewer) => {
				dispatch({
					type: "PATCH_EXTERNAL_VIEWER",
					viewer,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};
