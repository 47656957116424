import React, { useState } from "react";
import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import "./styles/ViewerStyles.scss";
import ActiveViewersPage from "./pages/ActiveViewersPage.jsx";
import ViewerFiltersComponent from "./ViewerFilters/ViewerFiltersComponent.jsx";
import ViewerTabs from "./viewer-tabs/ViewerTabs.jsx";
import { baseUrl, cinemaUrl } from "../../../Actions/consts.js";
import axios from "axios";
import DeletedViewersPage from "./pages/DeletedViewersPage.jsx";
import BlockedViewersPage from "./pages/BlockedViewersPage.jsx";

const ViewerPage = ({ title }) => {
	const dispatch = useDispatch();
	const [total, setTotal] = useState(0);
	const [tab, setTab] = useState({
		id: 1,
		title: "Активные",
	});
	useEffect(() => {
		document.title = title;
	}, [title]);
	const [searchParams, setSearchParams] = useSearchParams();

	const [mainFilterState, setMainFilterState] = useState({
		filters: searchParams.get("filters") !== null ? JSON.parse(searchParams.get("filters")) : {},
		order_by: "-serial_id",
		size: searchParams.get("size") !== null ? searchParams.get("size") : 20,
		page: searchParams.get("page") !== null ? searchParams.get("page") : 1,
	});

	useEffect(async () => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});

		await axios
			.post(`${baseUrl}/domain/other`, {
				method: "GET",
				service: 1,
				endpoint: `/api/dashboard/users?size=1`,
				data: "",
			})
			.then((viewers) => {
				setTotal(viewers.data.total);
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	return (
		<section className="personal-area-item viewers">
			<header className="personal-area categories-header">
				<h1 className="categories-header-text">
					Зрители
					<sup>{total}</sup>
				</h1>
			</header>
			<div className="videos-content-wrapper content-wrapper">
				<ViewerTabs active={tab} onChange={setTab} />
				<ViewerFiltersComponent filterState={mainFilterState} setFilterState={setMainFilterState} />
				{tab.id === 1 ? (
					<ActiveViewersPage
						mainFilterState={mainFilterState}
						setMainFilterState={setMainFilterState}
					/>
				) : tab.id === 2 ? (
					<DeletedViewersPage
						mainFilterState={mainFilterState}
						setMainFilterState={setMainFilterState}
					/>
				) : (
					<BlockedViewersPage
						mainFilterState={mainFilterState}
						setMainFilterState={setMainFilterState}
					/>
				)}
			</div>
		</section>
	);
};

export default connect(null, null)(ViewerPage);
