import axios from "axios";
import { marketUrl, baseUrl } from "./consts";

export const getPriceHistory = (seller = null) => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		console.log(seller);
		await axios
			.get(`${baseUrl}/domain/platformhistory/`, {
				params: {
					limit: 100,
					platform_id: 1,
				},
			})
			.then((price) => {
				// если указана только площадка, то меняем лишь базовую цену
				dispatch({
					type: "GET_PRICES_HISTORY",
					price,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const putArea = (item, price) => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_LOADER",
		});
		await axios
			.put(`${baseUrl}/domain/platforms/${item.id}`, { ...item, default_price: price })
			.then((areas) => {
				dispatch({
					type: "PUT_AREAS",
					areas,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_ALL",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const putSellerPrice = (id, seller, onReady) => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.put(`${baseUrl}/domain/sellers/${id}`, seller)
			.then((sellers) => {
				onReady();
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const getSellerPriceList = (arg) => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.get(`${baseUrl}/domain/sellers/seller_filter/`, {
				params: { ...arg },
			})
			.then((sellers) => {
				dispatch({
					type: "GET_SELLERS_PRICE",
					sellers,
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};
