export const onInputTextAreaChange = (evt, objName, state, setState) => {
	// evt - event, objName - значение поля, следующего изменить (предусмотрена вложенность, два значения передаются черед запятую)
	// state, setstate - изменение самого состояния
	const value = evt.target.value; // присваимваем значение инпута;
	const arr = objName.split(",");
	if (arr?.length === 1) {
		setState({ ...state, [arr[0]]: value });
	} else if (arr?.length === 2) {
		setState({ ...state, [arr[0]]: { ...state[arr[0]], [arr[1]]: value } });
	}
};

export const handleFilteredClick = (type, value, mainFilterState, setMainFilterState) => {
	if (type === "search") {
		if (mainFilterState.filters?.title?.length === 0) {
			const timeObj = mainFilterState.filters;
			delete timeObj.title;
			setMainFilterState({
				...mainFilterState,
				offset: 0,
				filters: timeObj,
			});
		} else {
			setMainFilterState({
				...mainFilterState,
				offset: 0,
				filters: { ...mainFilterState.filters, title: value },
			});
		}
	} else if (type === "search_page") {
		if (mainFilterState.filters?.title?.length === 0) {
			const timeObj = mainFilterState.filters;
			delete timeObj.title;
			setMainFilterState({
				...mainFilterState,
				page: 1,
				filters: timeObj,
			});
		} else {
			setMainFilterState({
				...mainFilterState,
				page: 1,
				filters: { ...mainFilterState.filters, title: value },
			});
		}
	} else if (type === "sellerSearch") {
		if (mainFilterState.filters?.name?.length === 0) {
			const timeObj = mainFilterState.filters;
			delete timeObj.name;
			setMainFilterState({
				...mainFilterState,
				offset: 0,
				filters: timeObj,
			});
		} else {
			setMainFilterState({
				...mainFilterState,
				offset: 0,
				filters: { ...mainFilterState.filters, name: value },
			});
		}
	} else if (type === "sort") {
		if (
			mainFilterState?.order_by === value &&
			(mainFilterState?.reversed_ === false || mainFilterState?.reversed_ === true)
		) {
			setMainFilterState({
				...mainFilterState,
				order_by: value,
				reversed_: !mainFilterState.reversed_,
				offset: 0,
			});
		} else {
			setMainFilterState({
				...mainFilterState,
				order_by: value,
				reversed_: false,
				offset: 0,
			});
		}
	} else if (value.length === 0) {
		if (!!mainFilterState.filters && type in mainFilterState.filters) {
			let objectCopy = { ...mainFilterState, offset: 0 };
			delete objectCopy.filters[type];
			setMainFilterState(objectCopy);
		}
	} else if (
		type === "formats_ids" ||
		type === "video_format_id_" ||
		type === "genres_ids" ||
		type === "area" ||
		type === "is_verified" ||
		type === "is_published" ||
		type === "areaId" ||
		type === "areaid" ||
		type === "email"
	) {
		setMainFilterState({
			...mainFilterState,
			offset: 0,
			filters: { ...mainFilterState.filters, [type]: value },
		});
	} else if (type === "years_") {
		setMainFilterState({
			...mainFilterState,
			offset: 0,
			filters: { ...mainFilterState.filters, [type]: value },
		});
	}
};

export const handleSelectFilters = (
	event,
	filterKey,
	item,
	deleteState = null,
	setDeleteState = null,
	dropDownState,
	setDropdownState,
	mainState,
	setMainState
) => {
	event.stopPropagation();
	if (deleteState !== null && setDeleteState !== null) {
		setDeleteState({ ...deleteState, currentValue: [] });
	}
	handleFilteredClick(filterKey, item, mainState, setMainState);
	setDropdownState({ ...dropDownState, [filterKey]: !dropDownState[filterKey] });
};

export const handleLibSelectFilters = (
	filterKey,
	item,
	deleteState = null,
	setDeleteState = null,
	mainState,
	setMainState
) => {
	if (deleteState !== null && setDeleteState !== null) {
		setDeleteState({ ...deleteState, currentValue: [] });
	}
	handleFilteredClick(filterKey, item, mainState, setMainState);
};

export const handleMultiSelectActiveClick = (evt, localState, setLocalState, item) => {
	evt.stopPropagation();
	const current = evt.currentTarget;
	let timeArray = localState.currentValue;
	let newArray = timeArray;
	if (current.classList.contains("selected")) {
		!!item.id || item.id === false
			? (newArray = timeArray.filter((elem) => item.id !== elem))
			: (newArray = timeArray.filter((elem) => item !== elem));
	} else {
		!!item.id || item.id === false ? newArray.push(item.id) : newArray.push(item);
	}
	// current.classList.toggle("selected");
	setLocalState({ ...localState, currentValue: newArray });
};

export const handleToggleOpenClick = (evt) => {
	evt.stopPropagation();
	const current = evt.currentTarget;
	const parrent = current.parentNode;
	parrent.classList.toggle("open");
};

export const handleToggleActiveClick = (e) => {
	const elem = e.target;
	elem.classList.toggle("active");
};

export const takeId = (elem, key) => {
	if (elem.length === 0) return [];
	let iter = [];
	elem.map((item) => {
		if (key) {
			iter.push(item[key]);
		} else {
			iter.push(item?.id);
		}
	});
	return iter;
};
