import axios from "axios";
import * as React from "react";
import { useEffect, useState } from "react";
import { baseUrl } from "../../../Actions/consts";
import * as qs from "qs";
import Search from "../../../ui-kit/search/Search";

// Компонент поиска и выбора characters
const FilmCrewSelect = ({ selected, onSelect, onClear, type }) => {
	const [collection, setCollection] = useState([]);
	const [queryState, setQueryState] = useState({
		offset: 0,
		query: "",
	});
	const limit = 15;
	const [open, setOpen] = useState(false);
	const [total, setTotal] = useState(false);

	const [status, setStatus] = useState({
		type: "ready",
	});

	useEffect(async () => {
		setStatus({ type: "load" });
		await axios
			.get(`${baseUrl}/domain/characters`, {
				params: {
					position_id: type,
					title: queryState.query,
					offset: queryState.offset,
					limit: limit,
				},
				paramsSerializer: (params) => {
					return qs.stringify(params, { arrayFormat: "repeat" });
				},
			})
			.then((video) => {
				const result = video.data?.items;
				if (video?.status === 200 && result?.length > 0) {
					const totalPages = video?.data?.paginate_?.total_rows;
					if (totalPages && totalPages !== total) {
						setTotal(totalPages);
					}
					if (queryState.offset === 0) {
						setCollection(result);
					} else {
						const timeArray = [...collection];
						timeArray.push(...result);
						setCollection(timeArray);
					}
				}
				if (video.data.status_code === 204 && queryState.offset === 0) {
					setCollection([]);
				}
				setStatus({ type: "ready" });
			})
			.catch((error) => {
				setStatus({ type: "error", message: error.message });
				console.log(error);
			});
	}, [queryState]);

	const loadNext = () => {
		if (status.type === "ready" && Number(total) > Number(queryState.offset)) {
			setQueryState({ ...queryState, offset: queryState.offset + limit });
		}
	};

	const handleChange = (value) => {
		setQueryState({ offset: 0, query: value });
	};

	const handleDDClick = () => {
		if (!open) {
			setOpen(true);
		}
	};

	return (
		<Search
			placeholder="Поиск по имени или фамилии"
			results={collection}
			value={queryState.query}
			selected={selected}
			onChange={(query) => handleChange(query)}
			onSelect={onSelect}
			onClear={onClear}
			isOpen={open}
			resolveTitle={(filmCrew) => `${filmCrew.first_name} ${filmCrew.second_name}`}
			onDDClick={() => handleDDClick()}
			onClose={() => setOpen(false)}
			status={status}
			loadNext={loadNext}
		/>
	);
};

export default FilmCrewSelect;
