import axios from "axios";
import { baseUrl, marketUrl } from "../../../../Actions/consts";

export const handleFetchProdCounts = async (vars, dispatch, onReady) => {
	dispatch({
		type: "SHOW_MAIN_LOADER",
	});
	await axios
		.get(`${baseUrl}/domain/seller_products`, {
			params: vars,
		})
		.then((res) => {
			onReady(res.data.total);
		})
		.finally(() => {
			dispatch({
				type: "HIDE_MAIN_LOADER",
			});
		})
		.catch((error) => {
			console.log(error);
		});
};

export const getSellerBalance = async (vars, dispatch, onReady) => {
	dispatch({
		type: "SHOW_MAIN_LOADER",
	});
	const queryString = Object.keys(vars)
		.map((key) => key + "=" + vars[key])
		.join("&");
	await axios
		.post(`${baseUrl}/domain/other`, {
			method: "GET",
			service: 3,
			endpoint: `/api/users/stats/total-balance?${queryString}`,
			data: "",
		})
		.then((seller) => {
			const { data } = seller;
			const result = {
				current_balance: data.current_balance / 100,
				total_replenish_amount: data.total_replenish_amount / 100,
			};
			onReady(result);
		})
		.finally(() => {
			dispatch({
				type: "HIDE_MAIN_LOADER",
			});
		})
		.catch((error) => {
			console.log(error);
		});
};
