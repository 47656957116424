export const initialColumnState = {
	tips_id: {
		active: true,
		title: "ID типса",
		width: "104%",
		field: "atips",
		sortable: true,
		nested: false,
	}, // ID типса
	price: {
		active: true,
		title: "Цена, ₽",
		width: "81%",
		field: "price",
		sortable: true,
		nested: false,
	}, // Цена, Р
	percent: {
		active: true,
		title: "Процент, %",
		width: "81%",
		field: "percent",
		sortable: false,
		nested: false,
	}, // Процент блогера, %
	profit: {
		active: true,
		title: "Цена партнера, ₽",
		width: "81%",
		field: "profit",
		sortable: false,
		nested: false,
	}, // Цена партнера, %
	platform: {
		active: false,
		title: "Площадка",
		width: "100%",
		field: "area",
		sortable: true,
		nested: false,
	}, // площадка
	seller: {
		active: true,
		title: "Рекламодатель",
		width: "116%",
		field: "seller",
		sortable: true,
		nested: false,
	}, // продавец
	product: {
		active: true,
		title: "Товар",
		width: "130%",
		field: "product",
		sortable: true,
		nested: "title",
	}, // товар
	category: {
		active: true,
		title: "Категория",
		sortable: true,
		width: "127%",
	}, // категория
	video: {
		active: true,
		title: "Видео",
		sortable: true,
		width: "200%",
	}, // Видео
	blogger: {
		active: false,
		title: "Правообладатель",
		sortable: false,
		width: "100%",
	}, // Правообладатель
	genre: {
		active: false,
		title: "Жанр",
		sortable: true,
		width: "108%",
	}, // жанр
	sex: {
		active: false,
		title: "Пол",
		sortable: true,
		width: "100%",
	}, // пол
	age: {
		active: false,
		title: "Возраст",
		sortable: true,
		width: "100%",
	}, // возраст
	country: {
		active: false,
		title: "Страна",
		sortable: true,
		width: "100%",
	}, // страна
	city: {
		active: false,
		title: "Город",
		sortable: true,
		width: "100%",
	}, // город
	click: {
		active: true,
		title: "Статус",
		sortable: true,
		width: "85%",
	}, // статус
	create_date: {
		active: true,
		title: "Дата, время",
		sortable: true,
		width: "125%",
	}, // дата, время
};

export const initialViewerState = {
	moviegoer: {
		active: true,
		title: "Зритель",
		sortable: false,
		width: "116%",
	},
	platform: {
		active: false,
		title: "Площадка",
		sortable: true,
		width: "100%",
	},
	age: {
		active: true,
		title: "Возраст",
		sortable: true,
		width: "100%",
	},
	sex: {
		active: true,
		title: "Пол",
		sortable: true,
		width: "100%",
	},
	country: {
		active: true,
		title: "Страна",
		sortable: true,
		width: "100%",
	},
	city: {
		active: true,
		title: "Город",
		sortable: true,
		width: "100%",
	},
	create_date: {
		active: true,
		title: "Дата, время",
		sortable: true,
		width: "125%",
	},
	click: {
		active: true,
		title: "Статус",
		sortable: true,
		width: "85%",
	},
	video: {
		active: true,
		title: "Видео",
		sortable: true,
		width: "200%",
	},
	blogger: {
		active: false,
		title: "Правообладатель",
		sortable: false,
		width: "100%",
	}, // Правообладатель
	genre: {
		active: true,
		title: "Жанр",
		sortable: true,
		width: "108%",
	},
	category: {
		active: false,
		title: "Категория",
		sortable: true,
		width: "127%",
	},
	product: {
		active: false,
		title: "Товар",
		sortable: true,
		width: "130%",
	},
	seller: {
		active: false,
		title: "Рекламодатель",
		sortable: true,
		width: "116%",
	},
	price: {
		active: false,
		title: "Цена, Р",
		sortable: true,
		width: "81%",
	},
	percent: {
		active: false,
		title: "Процент, %",
		width: "81%",
		field: "percent",
		sortable: false,
		nested: false,
	}, // Процент блогера, %
	profit: {
		active: false,
		title: "Цена партнера, ₽",
		width: "81%",
		field: "profit",
		sortable: false,
		nested: false,
	}, // Цена партнера, %
	tips_id: {
		active: false,
		title: "ID типса",
		sortable: true,
		width: "104%",
	},
};

export const initialVideoState = {
	video: {
		active: true,
		title: "Видео",
		sortable: true,
		width: "200%",
	},
	blogger: {
		active: true,
		title: "Правообладатель",
		sortable: false,
		width: "100%",
	}, // Правообладатель
	platform: {
		active: false,
		title: "Площадка",
		sortable: true,
		width: "100%",
	},
	format: {
		active: true,
		title: "Формат видео",
		sortable: true,
		width: "150%",
	},
	genre: {
		active: true,
		title: "Жанр",
		width: "108%",
	},
	year: {
		active: true,
		title: "Год видео",
		sortable: false,
		width: "150%",
	},
	moviegoer: {
		active: true,
		title: "Зритель",
		sortable: false,
		width: "116%",
	},
	sex: {
		active: true,
		title: "Пол",
		sortable: true,
		width: "100%",
	},
	age: {
		active: true,
		title: "Возраст",
		sortable: true,
		width: "100%",
	},
	country: {
		active: true,
		title: "Страна",
		sortable: true,
		width: "100%",
	},
	city: {
		active: true,
		title: "Город",
		width: "100%",
		sortable: true,
	},
	click: {
		active: false,
		title: "Статус",
		width: "85%",
		sortable: true,
	},
	category: {
		active: false,
		title: "Категория",
		width: "127%",
		sortable: true,
	},
	product: {
		active: false,
		title: "Товар",
		width: "130%",
		sortable: true,
	},
	seller: {
		active: false,
		title: "Рекламодатель",
		width: "116%",
		sortable: true,
	},
	price: {
		active: false,
		title: "Цена, Р",
		width: "81%",
		sortable: true,
	},
	percent: {
		active: false,
		title: "Процент, %",
		width: "81%",
		field: "percent",
		nested: false,
		sortable: false,
	}, // Процент блогера, %
	profit: {
		active: false,
		title: "Цена партнера, ₽",
		width: "81%",
		field: "profit",
		nested: false,
	}, // Цена партнера, %
	create_date: {
		active: false,
		title: "Дата, время",
		width: "125%",
		sortable: true,
	},
	tips_id: {
		active: false,
		title: "ID типса",
		width: "104%",
		sortable: true,
	},
};

export const initialSellerState = {
	seller: {
		active: true,
		title: "Рекламодатель",
		width: "116%",
		sortable: true,
	},
	platform: {
		active: false,
		title: "Площадка",
		width: "100%",
		sortable: true,
	},
	tips_id: {
		active: true,
		title: "ID типса",
		width: "104%",
		sortable: true,
	},
	price: {
		active: true,
		title: "Цена, Р",
		width: "81%",
		sortable: true,
	},
	percent: {
		active: false,
		title: "Процент, %",
		width: "81%",
		field: "percent",
		nested: false,
		sortable: false,
	}, // Процент блогера, %
	profit: {
		active: false,
		title: "Цена партнера, ₽",
		width: "81%",
		field: "profit",
		nested: false,
		sortable: false,
	}, // Цена партнера, %
	category: {
		active: true,
		title: "Категория",
		width: "127%",
		sortable: true,
	},
	video: {
		active: true,
		title: "Видео",
		width: "200%",
		sortable: true,
	},
	blogger: {
		active: false,
		title: "Правообладатель",
		width: "100%",
		sortable: false,
	}, // Правообладатель
	genre: {
		active: true,
		title: "Жанр",
		width: "108%",
		sortable: true,
	},
	click: {
		active: true,
		title: "Статус",
		width: "85%",
		sortable: true,
	},
	create_date: {
		active: true,
		title: "Дата, время",
		width: "125%",
		sortable: true,
	},
	moviegoer: {
		active: false,
		title: "Зритель",
		width: "116%",
		sortable: false,
	},
	sex: {
		active: false,
		title: "Пол",
		width: "100%",
		sortable: true,
	},
	age: {
		active: false,
		title: "Возраст",
		width: "100%",
		sortable: true,
	},
	country: {
		active: false,
		title: "Страна",
		width: "100%",
		sortable: true,
	},
	city: {
		active: false,
		title: "Город",
		width: "100%",
		sortable: true,
	},
	product: {
		active: false,
		title: "Товар",
		width: "130%",
		sortable: true,
	},
};
