import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { ModalContext } from "../../modal/ModalContext/ModalContext.tsx";
import FilmsPlaylistContent from "./Films/FilmsPlaylistContent.jsx";
import { PlaylistBookmarks } from "./PlaylistBookmarks.jsx";
import CreateEditPlaylistModal from "./modals/CreatePlaylistModal.jsx";
import "./Playlist.scss";
import { useUserAccessContext } from "../context/UserAccessContext.js";
import { checkUserWritePerm } from "../scripts/utils.js";
import { getVideoGenres } from "../../../Actions/videos-actions/VideosActions.js";

const PlaylistsPage = ({ title, getVideoGenres }) => {
	const { handleModal } = useContext(ModalContext);
	const { userAccessState } = useUserAccessContext();
	const [playlistState, setPlaylistState] = useState([]);
	const [activeBookMark, setActiveBookMark] = useState(0); // состояние активной вкладки

	useEffect(() => {
		document.title = title;
	}, [title]);

	useEffect(() => {
		getVideoGenres();
	}, []);

	return (
		<section className="personal-area-item categories">
			<header className="personal-area categories-header personal-area edit-videos-header">
				<h1>
					Плейлисты
					<sup>{playlistState?.length || 0}</sup>
				</h1>
				<button
					className="button_add-category"
					onClick={() =>
						handleModal(
							<CreateEditPlaylistModal
								userAccessState={userAccessState}
								playlistState={playlistState}
							/>
						)
					}
					disabled={!checkUserWritePerm(userAccessState, "playlists")}
					style={{ fontSize: "15px" }}>
					<img src="images/icons/ui/actions/white/plus.svg" alt="плюс" />
					Добавить новый плейлист
				</button>
			</header>
			<PlaylistBookmarks activeBookMark={activeBookMark} setActiveBookMark={setActiveBookMark} />
			<div className="categories-content-wrapper">
				{/* {activeBookMark === 1 ? ( */}
				<>
					<FilmsPlaylistContent playlistState={playlistState} setPlaylistState={setPlaylistState} />
				</>
				{/* ) : ( */}
				{/* <>
					<FilmsPlaylistContent />
				</> */}
				{/* )} */}
			</div>
		</section>
	);
};

const mapDispatchToProps = {
	getVideoGenres,
};

export default connect(null, mapDispatchToProps)(PlaylistsPage);
