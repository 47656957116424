import React, { useEffect, useState, useContext } from "react";
import { connect } from "react-redux";
import { getVideoGenres, getVideos } from "../../../../Actions/videos-actions/VideosActions";
import { getCategories } from "../../../../Actions/CategoriesActions";
import {
	handleMultiSelectActiveClick,
	handleSelectFilters,
} from "../../scripts/PersonalAreaScripts";
import { useSearchParams } from "react-router-dom";
import { getBrightboxes } from "../../../../Actions/BrightboxesActions";
import DropdownVideoSearch from "../dropdownVideoSearch/DropdownVideoSearch.jsx";
import { CategoryModal } from "../../../../common/category-modal/CategoryModal.jsx";
import { ModalContext } from "../../../modal/ModalContext/ModalContext";
import styled from "styled-components";

export const CategorySelect = styled.div`
	background: #f4f4f8;
	border: 1px solid #f4f4f8;
	border-radius: 8px;
	box-sizing: border-box;
	color: #212121;
	font-family: "Averta CY";
	font-size: 15px;
	height: auto;
	min-height: 54px;
	outline: none;
	padding: 8px 80px 8px 16px;
	position: relative;
	width: 100% !important;
	display: flex;
	cursor: pointer;
	&:hover {
		border: 1px solid #d1d8e1;
	}
	&:hover {
		border: 1px solid #d1d8e1;
	}
	align-items: center;
	svg {
		position: absolute;
		right: 12px;
		top: calc(50% - 12px);
	}
	span.category-value {
		display: block;
		overflow: hidden;
		position: absolute;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: calc(100% - 60px);
		font-size: 15px;
		line-height: 54px;
	}
`;
const initialDropDownState = {
	genres_ids: false,
	video_format_id_: false,
};

const AtipsBrightboxesFiltersComponent = ({
	videosList,
	categoriesList,
	videoGenresList,
	getVideoGenres,
	getCategories,
	paramsState,
	setParamsState,
}) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const { handleModal } = useContext(ModalContext);
	const [videosState, setVideosState] = useState({
		currentValue: "",
		list: [],
	});
	const [categoriesState, setCategoriesState] = useState({
		currentValue: "",
		list: [],
	});
	const [videoGenresState, setVideoGenresState] = useState({
		currentValue: [],
		list: [],
	});
	const [videoFormatsState, setVideoFormatsState] = useState({
		currentValue: [],
		list: [
			{
				id: 1,
				title: "Фильмы",
			},
			{
				id: 2,
				title: "Сериалы",
			},
		],
	});
	const [dropDownState, setDropdownState] = useState(initialDropDownState);

	useEffect(() => {
		getVideoGenres();
		getCategories();
	}, []);

	useEffect(() => {
		setCategoriesState({
			...categoriesState,
			list: categoriesList,
			currentValue: {
				id: paramsState.filters?.categories_ids,
				title: paramsState.filters?.category_title,
			},
		});
	}, [categoriesList]);

	useEffect(() => {
		setVideoGenresState({
			...videoGenresState,
			list: videoGenresList,
			currentValue: !!JSON.parse(searchParams.get("filters"))?.genres_ids
				? JSON.parse(searchParams.get("filters")).genres_ids
				: [],
		});
	}, [videoGenresList]);

	useEffect(() => {
		setVideoGenresState({
			...videoGenresState,
			currentValue: !!JSON.parse(searchParams.get("filters"))?.genres_ids
				? JSON.parse(searchParams.get("filters")).genres_ids
				: [],
		});
		setVideoFormatsState({
			...videoFormatsState,
			currentValue: !!JSON.parse(searchParams.get("filters"))?.video_format_id_ 
				? JSON.parse(searchParams.get("filters")).video_format_id_ 
				: [],
		});
		setCategoriesState({
			...categoriesState,
			list: categoriesList,
			currentValue: {
				id: paramsState.filters?.categories_ids,
				title: paramsState.filters?.category_title,
			},
		});
		setVideosState({
			...videosState,
			list: videosList.list,
			currentValue: !!paramsState.filters?.video_id_ ? paramsState.filters?.video_id_ : "",
		});
	}, [searchParams]);

	return (
		<div className="list-filter flex-block">
			<div className="form-field">
				<div className="form-field-label">Видео</div>

				<DropdownVideoSearch
					selected={paramsState?.filters?.video_title || ""}
					onClear={() =>
						setParamsState({
							...paramsState,
							filters: {
								...paramsState.filters,
								video_id_: "",
								video_title: "",
							},
						})
					}
					onSelect={(newValue) => (
						setVideosState({ ...videosState, currentValue: newValue.id }),
						setParamsState({
							...paramsState,
							offset: 0,
							filters: {
								...paramsState.filters,
								video_id_: newValue.id,
								video_title: newValue.title,
							},
						})
					)}
				/>
			</div>
			<div className="form-field">
				<div className="form-field-label">Жанр</div>
				<div className="input-field">
					<div
						className={`select-block multiselect ${dropDownState.genres_ids ? "active" : ""}`}
						onFocus={() => null}
						onClick={() =>
							setDropdownState({ ...dropDownState, genres_ids: !dropDownState.genres_ids })
						}
						onBlur={() => setDropdownState(initialDropDownState)}
						tabIndex="2"
					>
						<div className="select-block-button">
							<span>
								{videoGenresState.currentValue.length > 0 &&
								videoGenresState.currentValue.length !== videoGenresState.list.length
									? videoGenresState.currentValue.map((elem, index) =>
											index !== videoGenresState.currentValue.length - 1
												? `${videoGenresState.list.find((item) => item.id == elem)?.title}, `
												: `${videoGenresState.list.find((item) => item.id == elem)?.title}`
									  )
									: "Все"}
							</span>
						</div>
						<div className="select-block-list">
							<div className="select-block-list-scroll">
								{videoGenresState.list.length > 0
									? videoGenresState.list.map((genres) => (
											<div
												key={genres.id}
												className={`select-block-item ${
													videoGenresState.currentValue.includes(genres.id) ? "selected" : ""
												}`}
												onClick={(e) =>
													handleMultiSelectActiveClick(
														e,
														videoGenresState,
														setVideoGenresState,
														genres
													)
												}
											>
												<span>{genres?.title}</span>
											</div>
									  ))
									: null}
							</div>
							<div className="select-block-control">
								<div
									className="select-block-cancel button-select"
									onClick={(event) =>
										handleSelectFilters(
											event,
											"genres_ids",
											[],
											videoGenresState,
											setVideoGenresState,
											dropDownState,
											setDropdownState,
											paramsState,
											setParamsState
										)
									}
								>
									Очистить
								</div>
								<div
									className="select-block-select button-select"
									onClick={(event) =>
										handleSelectFilters(
											event,
											"genres_ids",
											videoGenresState.currentValue,
											null,
											null,
											dropDownState,
											setDropdownState,
											paramsState,
											setParamsState
										)
									}
								>
									Применить
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="form-field">
				<div className="form-field-label">Формат</div>
				<div className="input-field">
					<div
						className={`select-block multiselect ${dropDownState.video_format_id_  ? "active" : ""}`}
						onFocus={() => null}
						onClick={() =>
							setDropdownState({ ...dropDownState, video_format_id_ : !dropDownState.video_format_id_  })
						}
						onBlur={() => setDropdownState(initialDropDownState)}
						tabIndex="2"
					>
						<div className="select-block-button">
							<span>
								{videoFormatsState.currentValue.length > 0 &&
								videoFormatsState.currentValue.length !== videoFormatsState.list.length
									? videoFormatsState.currentValue.map((elem, index) =>
											index !== videoFormatsState.currentValue.length - 1
												? `${videoFormatsState.list.find((item) => item.id == elem)?.title}, `
												: `${videoFormatsState.list.find((item) => item.id == elem)?.title}`
									  )
									: "Все"}
							</span>
						</div>
						<div className="select-block-list">
							<div className="select-block-list-scroll">
								{videoFormatsState.list.length > 0
									? videoFormatsState.list.map((formats) => (
											<div
												key={formats.id}
												className={`select-block-item ${
													videoFormatsState.currentValue.includes(formats.id) ? "selected" : ""
												}`}
												onClick={(e) =>
													handleMultiSelectActiveClick(
														e,
														videoFormatsState,
														setVideoFormatsState,
														formats
													)
												}
											>
												<span>{formats?.title}</span>
											</div>
									  ))
									: null}
							</div>
							<div className="select-block-control">
								<div
									className="select-block-cancel button-select"
									onClick={(event) =>
										handleSelectFilters(
											event,
											"video_format_id_",
											[],
											videoFormatsState,
											setVideoFormatsState,
											dropDownState,
											setDropdownState,
											paramsState,
											setParamsState
										)
									}
								>
									Очистить
								</div>
								<div
									className="select-block-select button-select"
									onClick={(event) =>
										handleSelectFilters(
											event,
											"video_format_id_",
											videoFormatsState.currentValue,
											null,
											null,
											dropDownState,
											setDropdownState,
											paramsState,
											setParamsState
										)
									}
								>
									Применить
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="form-field">
				<div className="form-field-label">Категория товаров/услуг</div>
				<div className="input-field">
					<CategorySelect
						onClick={() =>
							handleModal(
								<CategoryModal
									categories={categoriesState.list}
									handleCategoryClick={(newValue) => (
										setCategoriesState({ ...categoriesState, currentValue: newValue }),
										setParamsState({
											...paramsState,
											filters: {
												...paramsState.filters,
												categories_ids: newValue.id,
												category_title: newValue.title,
											},
										})
									)}
								/>
							)
						}
					>
						<span className="category-value">{categoriesState.currentValue?.title || "Все"}</span>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M2 7H5V5H2L2 7ZM8 7L22 7V5L8 5V7ZM8 13L22 13V11L8 11V13ZM22 19H8V17H22V19ZM5 13H2V11H5V13ZM2 19H5L5 17H2L2 19Z"
								fill="#212121"
							></path>
						</svg>
					</CategorySelect>
					<button
						className={`clear-chosen-container ${
							!!categoriesState.currentValue?.title > 0 ? "active" : ""
						}`}
						onClick={() => (
							setCategoriesState({ ...categoriesState, currentValue: "" }),
							setParamsState({
								...paramsState,
								filters: { ...paramsState.filters, categories_ids: "", category_title: "" },
							})
						)}
					>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M8.70701 7.29304L7.99991 6.58594L6.58569 8.00015L7.2928 8.70726L10.5857 12.0002L7.2928 15.293L6.58569 16.0002L7.99991 17.4144L8.70701 16.7073L11.9999 13.4144L15.2928 16.7073L15.9999 17.4144L17.4141 16.0002L16.707 15.293L13.4141 12.0002L16.707 8.70726L17.4141 8.00015L15.9999 6.58594L15.2928 7.29305L11.9999 10.5859L8.70701 7.29304Z"
								fill="#212121"
							></path>
						</svg>
					</button>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		videosList: state.videosList,
		videoGenresList: state.videoGenresList,
		categoriesList: state.categoriesList,
	};
};

const mapDispatchToProps = {
	getVideos,
	getVideoGenres,
	getCategories,
	getBrightboxes,
};

export default connect(mapStateToProps, mapDispatchToProps)(AtipsBrightboxesFiltersComponent);
