const initialStatistic = {
	list: [],
	pagination: {},
	sum: 0,
};

export const StatisticReducer = (state = initialStatistic, action) => {
	switch (action.type) {
		case "GET_STATISTIC":
			const data = action.statistic.data.data.getStatisticPage;
			return {
				list: data.items,
				// sum: data.total_revenue,
				pagination: {
					max_elements: data.pageInfo.itemsCount,
					max_pages: Math.floor(data.pageInfo.itemsCount / data.pageInfo.currentLimit),
					page: data.pageInfo.currentPage,
					limit: data.pageInfo.currentLimit,
				},
			};

		case "GET_STATISTIC_TOTAL_TICKETS":
			const LIMIT = 20;
			let total_rows;
			let sum;
			const dopData = action.statistic.data.BOARD_BASE;
			if (action.is_show) {
				total_rows = dopData?.TOTAL_SHOWCASES;
				sum = 0;
			} else if (action.is_click) {
				total_rows = dopData?.TOTAL_CLICKTHROUGH;
				sum = dopData.TOTAL_REVENUE;
			} else {
				total_rows = dopData?.TOTAL_SHOWCASES + dopData?.TOTAL_CLICKTHROUGH;
				sum = dopData.TOTAL_REVENUE;
			}
			const total_pages = Math.ceil(total_rows / LIMIT);
			return {
				...state,
				sum: sum,
				pagination: {
					...state.pagination,
					max_elements: total_rows,
					max_pages: total_pages,
				},
			};
		default:
			return state;
	}
};
