import React, { useContext, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import "./BloggerStatistiс.scss";
import { SellerContacts } from "./PersonalInfo/SellerContacts.jsx";
import BestCategories from "./bestCategories/BestCategories.jsx";
import { getAreas } from "../../../../Actions/UsersActions";
import styled from "styled-components";
import { CancelButton, SubmitButton } from "../pages/Buttons.jsx";
import { getCurrentBlogger, patchExternalBlogger } from "../../../../Actions/BloggersActions";
import MeaningfulCards from "./meaningful-cards/MeaningfulCards";
import BloggerUserStatistic from "./UsersStatistic/BloggerUserStatistic";
import BestVideos from "./best-videos/BestVideos";
import VideoViewBlock from "./VideoViewsBlock/VideoViewBlock";
import { DText14, DText15, H3Text } from "../../../../ui-kit/typography/Typography";
import { Stack } from "../../../../ui-kit/layouts/Stack";
import { PeriodFilters } from "../../HomePage/UsersStatistic/PeriodFilters/PeriodFilters";
import { Cluster } from "../../../../ui-kit/layouts/Cluster";
import { formatedDate } from "../../static";
import { formatDate } from "../../VideosPage/sctipts";
import { useUserAccessContext } from "../../context/UserAccessContext";
import { checkUserWritePerm } from "../../scripts/utils";
import { baseUrl } from "../../../../Actions/consts.js";
import axios from "axios";

const SelectButtonsWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const InfoButton = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 5px 12px;

	height: 32px;
	background: #e2e8f0;
	border-radius: 80px;

	font-family: "Averta CY";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 160%;
	color: #94a0b1;
`;

const HeaderWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	/* padding-right: 150px; */
	&.small-container {
		display: flex;
		align-items: center;
	}
`;

const StyledTitleWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 24px;
	img {
		object-fit: cover;
		min-width: 80px;
		width: 80px;
		height: 80px;
		border-radius: 80px;
	}
`;

const BloggerStatisticComponent = ({
	title,
	getCurrentBlogger,
	currentSeller,
	patchExternalBlogger,
}) => {
	const { userAccessState } = useUserAccessContext();
	const dispatch = useDispatch();

	const navigate = useNavigate();
	const location = useLocation();
	const [filtersState, setFiltersState] = useState({});
	const [sellerState, setSellerState] = useState(null);
	const [externalSellerState, setExternalSellerState] = useState(null);
	useEffect(() => {
		document.title = title;
	}, [title]);

	useEffect(() => {
		getCurrentBlogger(location.search.split("=")[1]);
	}, []);

	useEffect(() => {
		if (location.search.split("=")[1] === currentSeller?.id) setExternalSellerState(currentSeller);
	}, [currentSeller]);

	const handleCancelClick = () => {
		patchExternalBlogger(externalSellerState.id, {
			is_active: false,
			application_status: "rejected",
		});
	};
	const handleSubmitClick = async () => {
		dispatch({
			type: "SHOW_MAIN_LOADER",
		});
		await axios
			.post(`${baseUrl}/creatorpercent`, {
				creator_id: externalSellerState.id,
				percent: "30",
			})
			.then((price) => {
				patchExternalBlogger(externalSellerState.id, {
					is_active: true,
					application_status: "accepted",
				});
			})
			.finally(() => {
				dispatch({
					type: "HIDE_MAIN_LOADER",
				});
			})
			.catch((error) => {
				setErrorState(true);
				console.log(error);
			});
	};

	const handleToggleClick = () => {
		if (
			"is_active" in externalSellerState &&
			!externalSellerState?.is_active &&
			externalSellerState.application_status === "rejected"
		)
			patchExternalBlogger(externalSellerState.id, {
				is_active: true,
				application_status: "accepted",
			});
		else {
			patchExternalBlogger(externalSellerState.id, {
				is_active: false,
				application_status: "rejected",
			});
		}
	};

	return externalSellerState ? (
		<section className="personal-area-item edit viewer-statistic">
			<header className="personal-area edit-videos-header" style={{ marginBottom: "0" }}>
				<button className="form-page-back" onClick={() => navigate("/bloggers")}>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M11.9142 7.50015L11.2071 8.20726L8.41421 11.0002H18H19V13.0002H18H8.41421L11.2071 15.793L11.9142 16.5002L10.5 17.9144L9.79289 17.2073L5.29289 12.7073C4.90237 12.3167 4.90237 11.6836 5.29289 11.293L9.79289 6.79304L10.5 6.08594L11.9142 7.50015Z"
							fill="#94A0B1"
						/>
					</svg>
					Вернуться назад
				</button>
				<div className="title-wrapper">
					<StyledTitleWrapper>
						{externalSellerState &&
						externalSellerState.avatar_url &&
						externalSellerState.avatar_url?.length > 0 ? (
							<img src={externalSellerState.avatar_url} alt="profile" />
						) : (
							<img src="images/profile-frame.png" alt="profile" />
						)}
						<h1 style={{ margin: "0" }}>
							{externalSellerState.name},
							<br />
							ID {externalSellerState.serial_id}
						</h1>
					</StyledTitleWrapper>
					{externalSellerState && externalSellerState?.application_status === "accepted" ? (
						<Cluster align="center" space="16px">
							<DText15 style={{ color: "#94A0B1" }}>
								{`${
									filtersState?.period_name === "full"
										? "..."
										: formatedDate(filtersState?.from_datetime)
								} — ${formatedDate(filtersState?.to_datetime)}`}
							</DText15>
							<PeriodFilters filtersState={filtersState} setFiltersState={setFiltersState} />
						</Cluster>
					) : null}
				</div>
			</header>
			<HeaderWrapper className="statistic-type-title-seller">
				<Cluster space="16px" align="center">
					{externalSellerState &&
					externalSellerState.application_status === "accepted" &&
					externalSellerState.is_active === true ? (
						<div className="small-container-seller">
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M13 0C12.4477 0 12 0.447715 12 1V11C12 11.5523 12.4477 12 13 12H23C23.5523 12 24 11.5523 24 11C24 4.92487 19.0751 0 13 0ZM14 10V2.05493C18.1716 2.51608 21.4839 5.82838 21.9451 10H14ZM0 13.0001C0 7.26204 4.3935 2.55015 10 2.04492V4.05501C5.50005 4.55245 2 8.36753 2 13.0001C2 17.9706 6.02944 22.0001 11 22.0001C15.6326 22.0001 19.4476 18.5 19.9451 14.0001H21.9552C21.4499 19.6066 16.738 24.0001 11 24.0001C4.92487 24.0001 0 19.0752 0 13.0001Z"
									fill="#212121"
								/>
							</svg>
							<span
								className="animte-menu"
								disabled
								onClick={() =>
									navigate(
										`/statistic?selected_sellers=${sellerState.id}&sellerName=${sellerState.title}`
									)
								}
							>
								Статистика по типсам
							</span>
						</div>
					) : externalSellerState && externalSellerState.application_status === "not_accepted" ? (
						<InfoButton>На рассмотрении</InfoButton>
					) : (
						<InfoButton>Отключен</InfoButton>
					)}
					<DText14 style={{ color: "#94A0B1" }}>
						Дата регистрации {formatDate(externalSellerState?.created_at)?.split(",")?.[0]}
					</DText14>
				</Cluster>
				{externalSellerState && externalSellerState.application_status === "not_accepted" ? (
					checkUserWritePerm(userAccessState, "bloggers") ? (
						<SelectButtonsWrapper>
							<SubmitButton onClick={() => handleSubmitClick()}>Принять</SubmitButton>
							<CancelButton onClick={() => handleCancelClick()}>Отклонить</CancelButton>
						</SelectButtonsWrapper>
					) : null
				) : externalSellerState.application_status !== "deleted" ? (
					<div
						id="product-tumbler"
						className={`${!externalSellerState?.is_active && "disabled"} ${
							!checkUserWritePerm(userAccessState, "bloggers") ? "readOnly" : ""
						}`}
						onClick={() => checkUserWritePerm(userAccessState, "bloggers") && handleToggleClick()}
					>
						<div id="product-tumbler-text">
							<span>Активно</span>
							<span>Приостановлено</span>
						</div>
					</div>
				) : null}
			</HeaderWrapper>

			{externalSellerState && externalSellerState.application_status === "accepted" ? (
				<>
					<MeaningfulCards bloggerState={externalSellerState} filtersState={filtersState} />
					<div className="flex-wrapper">
						<SellerContacts externalSellerState={externalSellerState} />
					</div>
					<Stack style={{ marginTop: "16px" }} space="35px">
						<H3Text>Видео блогера</H3Text>
						<VideoViewBlock bloggerState={externalSellerState} filtersState={filtersState} />
					</Stack>
					<BestCategories bloggerState={externalSellerState} filtersState={filtersState} />
					<div className="flex-wrapper">
						<BestVideos bloggerState={externalSellerState} />
					</div>
					<div className="flex-wrapper">
						<BloggerUserStatistic bloggerState={externalSellerState} />
					</div>
				</>
			) : (
				<div className="flex-wrapper">
					<SellerContacts externalSellerState={externalSellerState} />
				</div>
			)}
		</section>
	) : null;
};

const mapStateToProps = (state) => {
	return {
		currentSeller: state.currentBlogger,
	};
};

const mapDispatchToProps = {
	getCurrentBlogger,
	patchExternalBlogger,
};

export default connect(mapStateToProps, mapDispatchToProps)(BloggerStatisticComponent);
