export const initialPeriodCollection = [
  {
    key: "day",
    id: 1,
    title: "За день",
  },
  {
    key: "week",
    id: 2,
    title: "За неделю",
  },
  {
    key: "month",
    id: 3,
    title: "За месяц",
  },
  {
    key: "year",
    id: 4,
    title: "За год",
  },
  {
    key: "full",
    id: 5,
    title: "За всё время",
  },
];
